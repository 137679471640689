import { Helmet } from 'react-helmet';

import { makeMatchObject } from 'src/tools/object.tools';

const HELMETS = makeMatchObject(
  {
    'dev.reqpay.com': () => (
      <Helmet>
        <link rel='shortcut icon' href='/favicon_default.ico' />
        <link rel='manifest' href='/manifest_default.json' />
      </Helmet>
    ),
    'testing.reqpay.com': () => (
      <Helmet>
        <link rel='shortcut icon' href='/favicon_default.ico' />
        <link rel='manifest' href='/manifest_default.json' />
      </Helmet>
    ),
    'staging.reqpay.com': () => (
      <Helmet>
        <link rel='shortcut icon' href='/favicon_staging.ico' />
        <link rel='manifest' href='/manifest_staging.json' />
      </Helmet>
    ),
    'platform.reqpay.com': () => (
      <Helmet>
        <link rel='shortcut icon' href='/favicon_default.ico' />
        <link rel='manifest' href='/manifest_default.json' />
        <script src='/segment-platform.js' />
      </Helmet>
    ),
  },
  () => (
    <Helmet>
      <link rel='shortcut icon' href='/favicon_default.ico' />
      <link rel='manifest' href='/manifest_default.json' />
    </Helmet>
  ),
);

export const Headers = HELMETS[window.location.hostname];
