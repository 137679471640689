import FileSaver from 'file-saver';
import { useDispatch } from 'react-redux';
import Axios from 'axios';

import { addMessage } from 'src/store/app/app.reducer';
import { MessageCode } from 'src/models';
import { logError } from 'src/tools/log.tools';
import { EXTENSIONS } from 'src/data/extensions.data';

export function useDownloader() {
  const dispatch = useDispatch();

  function filePath(url: string): string {
    const split = url.split('/');

    let base = window.location.origin;

    /** on localhost app and server, which is use as proxy for retrieving the files
     * are sitting on different ports
     */
    if (RegExp(/localhost/).test(base)) base = 'http://localhost:8080';

    /** remove 'https' and server name, join back and add base server */
    return base + '/' + split.splice(3, split.length).join('/');
  }

  function makeFileName(name: string, type: string): string {
    return `${name}.${EXTENSIONS[type]}`;
  }

  function downloadAndSave(url: string, fileName?: string) {
    async function client() {
      try {
        const response = await Axios.get(filePath(url), {
          responseType: 'blob',
          timeout: 30000,
        });

        if (response.data) {
          const blob = await response.data;

          FileSaver.saveAs(blob, fileName ?? makeFileName('file', blob.type));
        } else {
          logError('response.blob() request returned an error.', response.statusText);
          dispatch(addMessage({ code: MessageCode.ERROR, content: 'Could not process download.' }));
        }
      } catch (err) {
        logError('(client) caught an error. ', err.message);
        dispatch(addMessage({ code: MessageCode.ERROR, content: 'Could not download the file.' }));
      }
    }

    client();
  }

  return { downloadAndSave };
}
