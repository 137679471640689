import { Scenario, ShowAs } from 'src/models';
import { FormScenario, FormShowAs } from 'src/models/form.model';

export const USERS_SORT_BY = [
  { id: 'LastName', label: 'Last Name' },
  { id: 'FirstName', label: 'First Name' },
];

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const MEMBERS_TABLE_SCENARIO: Scenario = {
  resource_name: {
    order: 1,
    label: 'Assigned to',
    width: '20.9rem',
    showAs: ShowAs.RESOURCE_LINK,
  },
  role_name: {
    order: 2,
    label: 'Permission',
    width: '14.8rem',
    showAs: ShowAs.ROLE_DROPDOWN,
    dataField: 'role_id',
  },
  linked: {
    order: 3,
    label: 'Status',
    width: '14.8rem',
    showAs: ShowAs.LINK_STATUS,
  },
  link: {
    order: 4,
    label: 'Link',
    width: '5.7rem',
    showAs: ShowAs.LINK_BUTTON,
  },
};

export const INVITES_TABLE_SCENARIO: Scenario = {
  resource_name: {
    order: 1,
    label: 'Assigned to',
    width: '33.5rem',
    showAs: ShowAs.RESOURCE_LINK,
  },
  role_name: {
    order: 2,
    label: 'Permission',
    width: '14.8rem',
    showAs: ShowAs.ROLE_DROPDOWN,
    dataField: 'role_id',
  },
  remove: {
    order: 3,
    label: 'Remove',
    width: '7.9rem',
    showAs: ShowAs.ICON,
    customization: 'trash',
  },
};

export const UPDATE_PASSWORD_FORM_SCENARIO: FormScenario = {
  _init: { validationDebounceDelay: 0 },
  password: {
    label: 'Create password',
    showAs: FormShowAs.TEXT,
    isRequired: true,
    placeholder: '●●●●●●●●',
    type: 'password',
    min: 8,
    bottomLabel: 'Must be at least 8 characters.',
  },
  password_confirmation: {
    label: 'Confirm password',
    showAs: FormShowAs.TEXT,
    isRequired: true,
    isSubmittable: true,
    placeholder: 'Repeat the password...',
    type: 'password',
    min: 8,
    bottomLabel: 'Both passwords must match.',
  },
};

export const FORGOT_PASSWORD_FORM_SCENARIO: FormScenario = {
  _init: { noRequired: true, validationDebounceDelay: 0 },
  email: {
    label: 'Email',
    showAs: FormShowAs.TEXT,
    placeholder: 'Enter your email',
    type: 'email',
    isRequired: true,
    isSubmittable: true,
    min: 8,
  },
};

export const RESET_PASSWORD_FORM_SCENARIO: FormScenario = {
  _init: { noRequired: true, validationDebounceDelay: 0 },
  ...UPDATE_PASSWORD_FORM_SCENARIO,
};

export const ALLOWED_SEARCH_REGEX = /[a-zA-Z0-9]/;

export const ALLOWED_TYPES = ['password', 'email', 'tel', 'url'];

export const INVITED_MEMBERS_SEARCH_KEYS = ['email'];

export const MEMBERS_SEARCH_KEYS = ['name', 'email', 'role', 'company', 'title'];
