import * as React from 'react';

export default function SvgIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='4' stroke='none' viewBox='0 0 19 4'>
      <path
        stroke='none'
        fillRule='evenodd'
        d='M2.16 4c1.192 0 2.158-.895 2.158-2S3.352 0 2.16 0C.967 0 0 .895 0 2s.967 2 2.16 2zm6.908 0c1.193 0 2.16-.895 2.16-2s-.967-2-2.16-2C7.876 0 6.91.895 6.91 2s.967 2 2.16 2zM19 2c0 1.105-.967 2-2.16 2-1.191 0-2.158-.895-2.158-2s.966-2 2.159-2C18.033 0 19 .895 19 2z'
        clipRule='evenodd'
      />
    </svg>
  );
}
