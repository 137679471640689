export const ServiceRequests = {
  // USER
  CHECK_TOKEN: '/api/v1/user/check_token',
  USER_LOGIN: '/api/v1/user/login',
  USER_LOGOUT: '/api/v1/user/logout',
  USER_SIGN_UP: '/api/v1/users/signup',
  UPDATE_USER: '/api/v1/users/update',
  MAIN_PROJECTS: '/api/v1/main_projects',
  MAIN_PROJECT: '/api/v1/main_projects/%1',
  // SUBCONTRACT
  SUBCONTRACT: '/api/v1/projects/%1/sub_contracts', // %1 :: project ID
  // PROJECTS
  PROJECTS: '/api/v1/main_projects/%1/projects',
  PROJECTS_BASE: '/api/v1/projects',
  PROJECTS_UPDATE: '/api/v1/projects/%1',
  // CHANGE ORDERS
  CHANGE_ORDERS: '/api/v1/projects/%1/change_orders',
  CHANGE_ORDERS_TOTALS: '/api/v1/projects/%1/change_orders/totals',
  UPDATE_CHANGE_ORDERS: '/api/v1/projects/%1/change_orders/%2',
  LIST_CONTINGENCY_LINE_ITEMS: '/api/v1/projects/%1/change_orders/contingency_line_items',
  // SUBCONTRACTORS
  SUBCONTRACTORS: '/api/v1/projects/%1/subcontractors',
  SUBCONTRACTORS_ASSIGN: '/api/v1/projects/%1/subcontractors/%2/assign',
  SUBCONTRACTORS_AUTOCOMPLETE: '/api/v1/projects/%1/subcontractors/autocomplete',
  // COPMANIES
  COMPANIES: '/api/v1/projects/%1/companies',
  COMPANIES_ASSIGN: '/api/v1/projects/%1/companies/%2/assign',
  COMPANIES_AUTOCOMPLETE: '/api/v1/projects/%1/companies/autocomplete',
  UPLOAD_COMPANY_FORM: '/api/v1/companies',
  // COMMON
  SUB_CONTRACT_AUTOCOMPLETE: '/api/v1/projects/%1/trade_contracts/autocomplete',
  REPORTS: '/api/v1/attachments/report.%1',
  COMMENTS: '/api/v1/comments',
  // ATTACHMENTS
  ATTACHMENTS: '/api/v1/attachments',
  ALL_DOCUMENTS: '/api/v1/attachments/all', // get all document for the entry
  ALL_COMMENTS: '/api/v1/comments/all', // get all comments for an entry
  PDF_SEARCH: '/api/v1/attachments/text_search', // returns highligh data for searched text
  TAGS: '/api/v1/attachments/tag', // add or remove tags, PATCH request
  DRAWINGS: '/api/v1/pictures/%1/draw', // get picture drawings
  // PICTURES
  PICTURES: '/api/v1/pictures',
  UPLOAD_PICTURE: '/api/v1/pictures/upload',
  // DOCUMENTS
  DOCUMENTS: '/api/v1/documents',
  DOCUMENT: '/api/v1/documents/%1',
  DOCUMENT_SEARCH: '/api/v1/documents/%1/search',
  DOCUMENT_DOWNLOAD: '/api/v1/documents/%1/download',
  DOCUMENT_PREVIEW: '/api/v1/documents/%1/preview',
  UPLOAD_DOCUMENT: '/api/v1/documents/upload',
  ADD_REQUIRED_DOCUMENT: '/api/v1/required_documents',
  UPLOAD_REQUIRED_DOCUMENT: '/api/v1/required_documents/upload',
  UPDATE_DOCUMENT: '/api/v1/documents/%1',
  REQ_DATA_TEMPLATE: '/api/v1/req_detail/template',
  REQ_DATA_UPLOAD: '/api/v1/req_detail/upload',
  MANAGER_REQ_DATA_TEMPLATE: '/api/v1/req_managers/template',
  MANAGER_REQ_DATA_UPLOAD: '/api/v1/req_managers/upload',
};
