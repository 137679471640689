import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_grid'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M21 14h-7v7h7v-7z' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M10 14H3v7h7v-7z' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M21 3h-7v7h7V3z' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M10 3H3v7h7V3z' />
    </svg>
  );
}

export default Icon;
