import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_consultant'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M10.472 8.473a4 4 0 10-4.946 0 6.667 6.667 0 00-4.147 5.454.671.671 0 001.333.146 5.333 5.333 0 0110.6 0 .666.666 0 00.667.594h.073a.666.666 0 00.587-.734 6.667 6.667 0 00-4.167-5.46zM8 8a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333z'
      />
    </svg>
  );
}

export default Icon;
