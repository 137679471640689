import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useDownloader } from 'src/hooks';
import clsx from 'clsx';

import { P2, P4 } from 'src/components/Typography';
import { recentOnlyRelativeDateTime } from 'src/tools/date.tools';
import { CloseButton, SecondaryButton } from 'src/components/Button';
import { Pill } from '../Pill';
import { renderMarkdown } from 'src/tools/string.tools';
import { NotificationMessage } from 'src/models/notification.model';
import { sendInfo } from 'src/store/app/app.actions';
import { t } from 'src/tools/text.tools';

import classes from './NotificationItem.module.scss';
import { ifTrue } from 'src/tools/logic.tools';

const TXT = t(['notificationItem']);

export interface Props {
  notification: NotificationMessage;
  onRead: () => void;
  onDelete: () => void;
}

export function NotificationItem({ notification, onRead, onDelete }: Props) {
  const dispatch = useDispatch();

  const { downloadAndSave } = useDownloader();

  function handleOnDownload() {
    if (!notification.file) return;

    downloadAndSave(notification.file.url, notification.file.name);
    dispatch(sendInfo(TXT('downloadingFile')));
  }

  function handleOnCopyLink() {
    if (!notification.file) return;

    globalThis.navigator.clipboard.writeText(notification.file.url);
    dispatch(sendInfo(TXT('linkCopied')));
  }

  function handleOnClick() {
    if (!notification.read) onRead();
  }

  function handleOnDelete(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onDelete();
  }

  function renderOpenInNewTabButton() {
    if (notification.file)
      return <SecondaryButton label={TXT('open')} to={notification.file.url} targetBlank isXSmall />;
  }

  function renderButtons() {
    if (notification.file)
      return (
        <div className='row items-p8'>
          <SecondaryButton label={TXT('copyLink')} onClick={handleOnCopyLink} isXSmall />
          <SecondaryButton label={TXT('download')} onClick={handleOnDownload} isXSmall />
          {ifTrue(notification.file.type === 'pdf', renderOpenInNewTabButton)}
        </div>
      );
  }

  function renderPill() {
    if (notification.notification_type) {
      return <Pill type={notification.notification_type} />;
    }
  }

  return (
    <div
      data-testid='notification'
      className={clsx(classes.container, 'row a-start w-100p o-hidden', { [classes.unread]: !notification.read })}
      onClick={handleOnClick}
    >
      <div data-testid='notification-status' className={classes['read-status']} />
      <div className='column items-p8 f-1 w-100p o-hidden'>
        <div className='row a-center items-p4'>
          {renderPill()}
          <P4 className={classes.date}>{recentOnlyRelativeDateTime(notification.created_at)}</P4>
        </div>
        <P2>
          <span dangerouslySetInnerHTML={{ __html: renderMarkdown(notification.content) }} />
        </P2>
        {renderButtons()}
      </div>
      <CloseButton className={classes.close} onClick={handleOnDelete} isSmall />
    </div>
  );
}
