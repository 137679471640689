import { v4 as generateId } from 'uuid';

import { SocketParams } from 'src/models/socket.model';
import { requestReport } from 'src/store/reports/reports.actions';
import { ApiResponse, ApiResultFalse, ApiResultTrue } from 'src/models/api.model';

type MakeReportRequestsType = Pick<SocketParams, 'domain' | 'type' | 'ids' | 'note' | 'extra'>;

export const makeReportRequest = ({ domain, type, ids, note, extra }: MakeReportRequestsType) =>
  requestReport({
    request_id: generateId(),
    domain,
    type,
    ids,
    note,
    extra,
    request_timestamp: new Date().toISOString(),
  });

export function success<Payload>(payload: ApiResponse<Payload>): ApiResultTrue<Payload> {
  return { isOK: true, payload };
}

export function failure(payload: unknown): ApiResultFalse {
  return { isOK: false, payload };
}
