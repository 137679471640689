import { Maybe } from 'src/models';

export function render<T, K>(data: Maybe<T[]>, fn: (item: T, index: number) => K): K[] {
  if (!data || !Array.isArray(data)) return [];

  const sections: K[] = [];

  let index = 0;

  for (const element of data) {
    sections.push(fn(element, index));
    index += 1;
  }

  return sections;
}

export function renderWithIndex<T, K>(data: Maybe<T[]>, fn: (item: T, index: number) => K): K[] {
  if (!data || !Array.isArray(data)) return [];

  const sections = [];

  let i = 0;

  for (const element of data) {
    sections.push(fn(element, i));
    i += 1;
  }

  return sections;
}
