import { AddToLabel, FormScenario, FormShowAs } from 'src/models/form.model';
import { UseIcons } from 'src/models/interface.model';

// new-ui
export const ASSIGN_CM_SCENARIO: FormScenario = {
  company_id: {
    label: 'Name or Nickname',
    showAs: FormShowAs.COMPANIES_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select company name or nickname',
    type: 'number',
    writeKeys: ['name_input'],
    addNew: 'Add a Company',
  },
};

export const ROLE_ICON_ID: Record<string, UseIcons> = {
  owner: 'owner',
  construction_manager: 'cm-or-pm',
  subcontractor: 'subcontractor',
  consultant: 'consultant',
  engineer: 'engineer',
  architect: 'architect',
  supplier: 'supplier',
  vendor: 'vendor',
  'municipal/government': 'government',
};

// new-ui
export const ASSIGN_COMPANY_FORM_SCENARIO: FormScenario = {
  company_id: {
    label: 'Name or Nickname',
    showAs: FormShowAs.COMPANIES_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Search for a company to add...',
    type: 'number',
    writeKeys: ['name_input'],
    addNew: 'Add a Company',
  },
  roles: {
    label: 'Role on %1 (select all that apply)',
    addToLabel: AddToLabel.SUB_NAME,
    showAs: FormShowAs.LARGE_SELECT,
    multiSelect: true,
    isRequired: true,
    type: 'csv',
    items: [
      { id: 'consultant', label: 'Consultant', iconId: 'consultant' },
      { id: 'architect', label: 'Architect', iconId: 'architect' },
      { id: 'owner', label: 'Owner', iconId: 'owner' },
      { id: 'construction_manager', label: 'CM/PM/GC', iconId: 'cm-or-pm' },
      { id: 'engineer', label: 'Engineer', iconId: 'engineer' },
      { id: 'subcontractor', label: 'Subcontractor', iconId: 'subcontractor' },
      { id: 'supplier', label: 'Supplier', iconId: 'supplier' },
      { id: 'vendor', label: 'Vendor', iconId: 'vendor' },
      {
        id: 'municipal/government',
        label: 'Government',
        iconId: 'government',
      },
    ],
  },
};

// new-ui
export const ADD_COMPANY_FORM_SCENARIO: FormScenario = {
  _init: { useFormData: true },
  name: {
    label: 'Name',
    showAs: FormShowAs.TEXT,
    isRequired: true,
    placeholder: 'Enter company name',
    type: 'string',
    min: 3,
  },
  nickname: {
    label: 'Nickname',
    showAs: FormShowAs.TEXT,
    isRequired: true,
    placeholder: 'Enter company nickname',
    type: 'string',
  },
  phone_number: {
    label: 'Phone Number',
    showAs: FormShowAs.PHONE_NUMBER,
    isRequired: true,
    placeholder: 'Enter company phone number',
    type: 'tel',
    min: 10,
    max: 13,
  },
  address: {
    label: 'Address',
    showAs: FormShowAs.PLACES,
    isRequired: true,
    placeholder: 'Enter company address',
    type: 'string',
    min: 5,
  },
  logo: { showAs: FormShowAs.UPLOAD_PICTURE_BUTTON, buttonLabel: 'Add Logo', type: 'File' },
  website: {
    label: 'Website',
    showAs: FormShowAs.TEXT,
    placeholder: 'Enter company website',
    type: 'url',
  },
  email: {
    label: 'Email',
    showAs: FormShowAs.TEXT,
    placeholder: 'Enter company email',
    type: 'email',
    min: 8,
  },
  roles: {
    label: 'Role (select all that apply)',
    addToLabel: AddToLabel.SUB_NAME,
    showAs: FormShowAs.LARGE_SELECT,
    multiSelect: true,
    isRequired: true,
    type: 'csv',
    items: [
      { id: 'consultant', label: 'Consultant', iconId: 'consultant' },
      { id: 'architect', label: 'Architect', iconId: 'architect' },
      { id: 'owner', label: 'Owner', iconId: 'owner' },
      { id: 'construction_manager', label: 'CM/PM/GC', iconId: 'cm-or-pm' },
      { id: 'engineer', label: 'Engineer', iconId: 'engineer' },
      { id: 'subcontractor', label: 'Subcontractor', iconId: 'subcontractor' },
      { id: 'supplier', label: 'Supplier', iconId: 'supplier' },
      { id: 'vendor', label: 'Vendor', iconId: 'vendor' },
      {
        id: 'municipal/government',
        label: 'Government',
        iconId: 'government',
      },
    ],
  },
};

export enum CompanyDefaultRoles {
  OWNER = 'owner',
  CONSTRUCTION_MANAGER = 'construction_manager',
  SUBCONTRACTOR = 'subcontractor',
}
