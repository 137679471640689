import { Property } from 'csstype';
import PhoneNumberFormatter from 'phone-number-formats';
import { words, upperFirst } from 'tiny-case';
import { all, assoc, compose, forEach, is, isNil, keys, not, replace, split, toLower } from 'ramda';
import { micromark } from 'micromark';

import { isEmpty } from './validate.tools';

import { logError, logUnableToProceed } from './log.tools';
import { ID, Maybe, ParagraphIds } from 'src/models/general.model';
import { makeNumberString } from './number.tools';
import { dateToDateString } from './date.tools';
import { isString } from 'src/validators/simple';
import { t } from './text.tools';

export function renderMarkdown(value: string): string {
  return micromark(value);
}

export function capitalize(s: string): string {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function capitalizeFirst(s: string): string {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getTextAlign(align?: Property.TextAlign) {
  if (!align) return '';

  return ` text-${align}`;
}

export function makePluralIfNeeded(quantity: number, name: string, withCapital = true): string {
  function withCapitalIfNeeded(val: string) {
    if (withCapital) return capitalize(val);

    return val;
  }

  if (quantity === 0) return withCapitalIfNeeded(`${name}s`);

  if (quantity < 2) return withCapitalIfNeeded(name);

  return withCapitalIfNeeded(`${name}s`);
}

export function stringToPhone(data: string): string {
  if (!data) return '';

  if (typeof data !== 'string') {
    logError('[stringToPhone] Wrong prop. Should be string. Got: ', data);
    return '';
  }

  try {
    const isWithPlus = data[0] === '+' || data.length > 10;

    let result = new PhoneNumberFormatter(data).format({ type: 'international' }).string ?? '';

    if (!isWithPlus) {
      result = result.replace('+', '');
    }

    // fix formatting for short phone numbers
    return result.replace('()', '').replace('  -', '');
  } catch (err) {
    logError(`Unable to convert string [${data}] to phone.`, err);
    return '';
  }
}

export function getClearPhone(value: string) {
  const isWithPlus = value[0] === '+';

  let clearValue = value.toString().replace(/[^\d]/g, '');

  if (isWithPlus && clearValue && clearValue.length >= 10) clearValue = `+${clearValue}`;

  return clearValue;
}

export function processParagraphData(data: unknown, id: ParagraphIds): string {
  if (isNil(data)) return '';

  if (typeof data !== 'string' && typeof data !== 'number' && not(data instanceof Date)) {
    logError('[processParagraphData]. Wrong prop. Should be string, number or Date. Got: ', data);
    return '';
  }

  try {
    if (id === ParagraphIds.PERCENT) return makeNumberString(data as string, { max: 1, min: 1, suffix: '%' });

    if (id === ParagraphIds.DATE) return dateToDateString(data as string);

    if (id === ParagraphIds.FIXED) return makeNumberString(data as string, { max: 0, min: 0 });

    if (id === ParagraphIds.FLOAT) return makeNumberString(data as string, { max: 2, min: 2 });

    if (id === ParagraphIds.REQ_NUMBER) return (data as string).replace(/\D/g, '');

    if (id === ParagraphIds.PHONE) {
      const parsed = stringToPhone(data as string);

      return parsed ?? data;
    }

    if (id === ParagraphIds.STRING || id === ParagraphIds.NUMBERS_ONLY) return data as string;

    logUnableToProceed('[processParagraphData] No id to use is found. Returning as is.', { data, id });
    return '';
  } catch (err) {
    logError(`[processParagraphData] Unable to convert string [${data}].`, err);
    return '';
  }
}

export function makeStringFromTemplate(template: string, params: (string | number)[]): string {
  let result = template;

  if (params) {
    params.forEach((param, key) => {
      result = result.replace(new RegExp(`%${key + 1}`, 'g'), String(param));
    });
  }

  return result;
}

export enum FormattedStringTypes {
  LINK = 'link',
}

export interface FormattedStringParams {
  id: ID;
  type: FormattedStringTypes;
  payload?: string;
}

function formatString(s: string, type: FormattedStringTypes, payload?: string): string {
  if (type === FormattedStringTypes.LINK && payload)
    return `<a href=${payload} rel='noreferrer noopener' target='_blank'>${s}</a>`;

  return s;
}

// TODO: convert to work with bold text
export function makeFormattedStringFromTemplate(template: string, params: FormattedStringParams[]): string {
  let result = template;

  params.forEach(param => {
    const split = result.split(`$(${param.type}-${param.id})`).filter(Boolean);

    if (split.length > 1) result = split[0] + formatString(split[1], param.type, param.payload);
  });

  return result;
}

export function deserialize<T>(data: Maybe<string>, defaultReturn?: T): Maybe<T> {
  if (isNil(data) || isEmpty(data)) return defaultReturn ?? undefined;

  if (!isString(data)) return defaultReturn ?? undefined;

  try {
    // eslint-disable-next-line no-eval
    return ((eval('(' + data + ')') as T) || defaultReturn) ?? undefined;
  } catch (err) {
    logError('Error in deserialize', err);
    return defaultReturn;
  }
}

export function makeTitleOfFileName(name?: string) {
  if (!name) return '';

  return upperFirst(words(name).slice(0, -1).join(' '));
}

export function processTextIfNeeded(text: string) {
  if (text.includes('_')) {
    return words(text).map(capitalize).join(' ');
  }

  return text;
}

const TXT_ROLES = t(['members', 'roles']);

export function makeRoleName(role = '') {
  return TXT_ROLES(role) || makeTitleOfFileName(role);
}

function makeOccurrenceObj(words: string): Record<string, number> {
  let occurrenceObj: Record<string, number> = {};

  function fn(letter: string): void {
    if (letter !== ' ') occurrenceObj = assoc(letter, (occurrenceObj[letter] || 0) + 1, occurrenceObj);
  }

  compose(forEach(fn), split(''), toLower, replace(' ', ''))(words);

  return occurrenceObj;
}

export function fuzzyFinder(query: string, target: string): boolean {
  if (!query) return true;

  if (!target) return false;

  if (not(is(String, query)) || not(is(String, target))) return false;

  const queryObj = makeOccurrenceObj(query);

  const targetObj = makeOccurrenceObj(target);

  const checkFn = (key: string): boolean => queryObj[key] <= targetObj[key];

  return compose(all(checkFn), keys)(queryObj);
}

export function strictFinder(query: string, target: string): boolean {
  if (!query) return true;

  if (!target) return false;

  if (not(is(String, query)) || not(is(String, target))) return false;

  return target.toLowerCase().includes(query.toLowerCase());
}

export function makeWelcomeMessage(name: string): string {
  return `Welcome, ${name || 'User'}!`;
}

export function getFirstLetter(s: string, defaultReturn = 'A') {
  if (s) return capitalize(s[0]);

  return defaultReturn;
}

export interface InputDiff {
  value: string; // inserted string
  position: number; // insertion is after position index (relatively prev value)
  size: number; // how many characters were replaced/removed in prev value
}

export function getInputDiff(prev: string, next: string, caretPosition: number): Maybe<InputDiff> {
  // if no changes found
  if (prev === next) {
    return;
  }

  if (!prev) {
    return {
      value: next,
      position: -1,
      size: 0,
    };
  }

  if (!next) {
    return {
      value: '',
      position: -1,
      size: prev.length,
    };
  }

  const prevSize = prev.length;

  const nextSize = next.length;

  // if diff is in the end
  if (caretPosition === nextSize) {
    let i = 0;

    // looking for startPosition
    while (prev[i] && prev[i] === next[i]) {
      i += 1;
    }

    // some string was added or removed in the end of prev
    return {
      value: next[i] ? next.slice(i) : '',
      position: i - 1,
      size: prevSize - i,
    };
  }

  // if something was removed in the beginning
  if (caretPosition === 0) {
    let j = 0;

    // looking for endPosition
    while (prev[prevSize - j - 1] && prev[prevSize - j - 1] === next[nextSize - j - 1]) {
      j += 1;
    }

    return {
      value: '',
      position: -1,
      size: prevSize - j,
    };
  }

  let i = 0;

  // looking for startPosition
  while (prev[i] && prev[i] === next[i] && i < caretPosition) {
    i += 1;
  }

  let j = 0;

  // looking for endPosition
  while (
    prev[prevSize - 1 - j] &&
    prev[prevSize - 1 - j] === next[nextSize - 1 - j] &&
    nextSize - 1 - j >= caretPosition
  ) {
    j += 1;
  }

  // some string was added before prev
  if (j === prevSize) {
    return {
      value: next.slice(0, nextSize - j),
      position: -1,
      size: 0,
    };
  }

  // something was inserted
  if (i !== prevSize - j + 1) {
    return {
      value: next.slice(i, nextSize - j),
      position: i - 1,
      size: prevSize - j - i,
    };
  }

  // something was removed
  return {
    value: '',
    position: i - 1,
    size: prevSize - j - i,
  };
}
