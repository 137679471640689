import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_supplier'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        fillRule='evenodd'
        d='M12.333 11.333a1 1 0 100 2 1 1 0 000-2zm-2.333 1a2.333 2.333 0 114.667 0 2.333 2.333 0 01-4.667 0z'
        clipRule='evenodd'
      />
      <path
        stroke='none'
        fillRule='evenodd'
        d='M3.667 11.333a1 1 0 100 2 1 1 0 000-2zm-2.333 1a2.333 2.333 0 114.667 0 2.333 2.333 0 01-4.667 0z'
        clipRule='evenodd'
      />
      <path
        stroke='none'
        fillRule='evenodd'
        d='M10 5.333c0-.368.299-.666.667-.666h2.666c.177 0 .347.07.472.195l2 2a.666.666 0 01.195.471v3.334a.667.667 0 01-.667.666h-4.666a.667.667 0 01-.667-.666V5.333zM11.333 6v4h3.334V7.61L13.057 6h-1.724z'
        clipRule='evenodd'
      />
      <path
        stroke='none'
        fillRule='evenodd'
        d='M0 2c0-.368.298-.667.667-.667h10c.368 0 .666.299.666.667v8.667a.667.667 0 01-.666.666h-10A.667.667 0 010 10.667V2zm1.333.667V10H10V2.667H1.333z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default Icon;
