import { Action, ActionMeta, ID, Maybe, Period, ReqDetailActions } from 'src/models';
import { ReqDetailPageParams, UpdateReqDetailPagePayload } from 'src/models/reqDetail.model';

export const requestReqDetailPageSubscription = (payload: ID): Action => ({
  type: ReqDetailActions.ADD_PAGE_SUBSCRIPTION,
  payload,
});

export const removeReqDetailPageSubscription = (payload: ID): Action => ({
  type: ReqDetailActions.REMOVE_PAGE_SUBSCRIPTION,
  payload,
});

export const requestReqDetailPageData = (payload: ReqDetailPageParams = {}, meta?: ActionMeta): Action => ({
  type: ReqDetailActions.SHOW,
  payload,
  meta,
});

export const requestReqDetailBaseContract = (payload: ReqDetailPageParams = {}, meta?: ActionMeta): Action => ({
  type: ReqDetailActions.BASE_CONTRACT,
  payload,
  meta,
});

export const requestReqDetailChangeOrders = (payload: ReqDetailPageParams = {}, meta?: ActionMeta): Action => ({
  type: ReqDetailActions.CHANGE_ORDERS,
  payload,
  meta,
});

export const getBaseContractSectionMore = (meta?: ActionMeta): Action => ({
  type: ReqDetailActions.BASE_CONTRACT_MORE,
  meta,
});

export const requestMoreReqDetailChangeOrders = (meta?: ActionMeta): Action => ({
  type: ReqDetailActions.CHANGE_ORDERS_MORE,
  meta,
});

export const requestReqDetailPageBalanceAndTotals = (meta?: ActionMeta): Action => ({
  type: ReqDetailActions.PAGE_BALANCE_AND_TOTALS,
  meta,
});

export const patchLineItem = (line_item_id: ID, data: Record<string, unknown>, meta?: ActionMeta) => ({
  type: ReqDetailActions.PATCH_BASE_CONTRACT,
  payload: { line_item_id, data },
  meta,
});

export const postLineItem = (payload: Record<string, unknown>, meta?: ActionMeta) => ({
  type: ReqDetailActions.POST_BASE_CONTRACT,
  payload,
  meta,
});

export const deleteLineItem = (payload: Record<string, unknown>, meta?: ActionMeta) => ({
  type: ReqDetailActions.DELETE_LINE_ITEM,
  payload,
  meta,
});

export const updateReqDetailPage = (payload: UpdateReqDetailPagePayload, meta?: ActionMeta): Action => ({
  type: ReqDetailActions.UPDATE_PAGE,
  payload,
  meta: { ...meta, isUpdate: true },
});

export const requestReqDetailHistory = (payload?: Maybe<Period>): Action => ({
  type: ReqDetailActions.HISTORY,
  payload,
});

export const performReqAction = (actionId: string): Action => ({
  type: ReqDetailActions.PERFORM_ACTION,
  payload: actionId,
});
