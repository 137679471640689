import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_ATTRIBUTE_CHANGES_STATE } from './predefinedLists.model';
import { Maybe } from 'src/models/general.model';
import {
  PredefinedListsObject,
  PredefinedNestedListsObject,
  PredefinedStateMachinesObject,
} from 'src/models/predefined_lists.model';
import { PredefinedLists, PredefinedStateMachines } from 'src/models';
import { getMatchersFromLists, getMatchersFromNestedLists } from 'src/tools/predefinedLists.tools';

export const slice = createSlice({
  name: 'predefinedLists',
  initialState: INITIAL_ATTRIBUTE_CHANGES_STATE,
  reducers: {
    clearPredefinedListsState: () => INITIAL_ATTRIBUTE_CHANGES_STATE,
    setPredefinedLists: (state, action: PayloadAction<Maybe<PredefinedListsObject>>) => {
      state.lists = action.payload;
      state.listsMatches = { ...state.listsMatches, ...getMatchersFromLists(action.payload) };
    },
    addPredefinedLists: (state, action: PayloadAction<Maybe<PredefinedListsObject>>) => {
      if (state.lists) state.lists = { ...state.lists, ...action.payload };
      else state.lists = action.payload;

      state.listsMatches = { ...state.listsMatches, ...getMatchersFromLists(action.payload) };
    },
    removePredefinedList: (state, action: PayloadAction<PredefinedLists>) => {
      if (state.lists) delete state.lists[action.payload];

      if (state.listsMatches) delete state.listsMatches[action.payload];
    },
    setPredefinedNestedLists: (state, action: PayloadAction<Maybe<PredefinedNestedListsObject>>) => {
      state.nestedLists = action.payload;
      state.listsMatches = { ...state.listsMatches, ...getMatchersFromNestedLists(action.payload) };
    },
    addPredefinedNestedLists: (state, action: PayloadAction<Maybe<PredefinedNestedListsObject>>) => {
      if (state.nestedLists) state.nestedLists = { ...state.nestedLists, ...action.payload };
      else state.nestedLists = action.payload;

      state.listsMatches = { ...state.listsMatches, ...getMatchersFromNestedLists(action.payload) };
    },
    setPredefinedStateMachines: (state, action: PayloadAction<Maybe<PredefinedStateMachinesObject>>) => {
      state.stateMachines = action.payload;
    },
    addPredefinedStateMachines: (state, action: PayloadAction<Maybe<PredefinedStateMachinesObject>>) => {
      if (state.stateMachines) state.stateMachines = { ...state.stateMachines, ...action.payload };
      else state.stateMachines = action.payload;
    },
    removePredefinedStateMachine: (state, action: PayloadAction<PredefinedStateMachines>) => {
      if (state.stateMachines) delete state.stateMachines[action.payload];
    },
  },
});

export const {
  clearPredefinedListsState,
  setPredefinedLists,
  addPredefinedLists,
  removePredefinedList,
  setPredefinedStateMachines,
  addPredefinedStateMachines,
  removePredefinedStateMachine,
  setPredefinedNestedLists,
  addPredefinedNestedLists,
} = slice.actions;

export default slice.reducer;
