import * as React from 'react';
import clsx from 'clsx';

import { UseIcon } from 'src/components/UseIcon';

import classes from './CloseButton.module.scss';

interface Props {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isSmall?: boolean;
  dataTestId?: string;
  isDisabled?: boolean;
}

export function CloseButton({ className, onClick, isSmall, dataTestId, isDisabled }: Props) {
  return (
    <UseIcon
      dataTestId={dataTestId}
      wrapElement='button'
      iconId='close'
      className={clsx(classes.icon, 'row', className, { [classes.small]: isSmall })}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}
