import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ID, Maybe } from 'src/models/general.model';
import { InputAddUser, InputInviteUser, InvitedMember, Member, UserRole } from 'src/models/team.model';

import { INITIAL_TEAM_STATE } from './team.model';

export const slice = createSlice({
  name: 'team',
  initialState: INITIAL_TEAM_STATE,
  reducers: {
    clearTeamState: () => INITIAL_TEAM_STATE,
    setMembers: (state, action: PayloadAction<Maybe<Member[]>>) => {
      state.members = action.payload;
    },
    setInputAddedUsers: (state, action: PayloadAction<Maybe<InputAddUser[]>>) => {
      state.inputAddedUsers = action.payload;
    },
    setInputInvitedUsers: (state, action: PayloadAction<Maybe<InputInviteUser[]>>) => {
      state.inputInvitedUsers = action.payload;
    },
    setIsShowAll: (state, action: PayloadAction<Maybe<boolean>>) => {
      state.isShowAll = action.payload;
    },
    setSearch: (state, action: PayloadAction<Maybe<string>>) => {
      state.search = action.payload;
    },
    resetToolbar: state => {
      state.isShowAll = INITIAL_TEAM_STATE.isShowAll;
      state.search = INITIAL_TEAM_STATE.search;
    },
    clearInputUsers: state => {
      state.inputAddedUsers = INITIAL_TEAM_STATE.inputAddedUsers;
      state.inputInvitedUsers = INITIAL_TEAM_STATE.inputInvitedUsers;
      state.roles = INITIAL_TEAM_STATE.roles;
    },
    addInputAddedUser: (state, action: PayloadAction<InputAddUser>) => {
      if (!state.inputAddedUsers) state.inputAddedUsers = [action.payload];
      else {
        const existingUser = state.inputAddedUsers.find(user => user.id === action.payload?.id);

        if (!existingUser) state.inputAddedUsers = [...state.inputAddedUsers, action.payload];
      }
    },
    addInputInvitedUser: (state, action: PayloadAction<InputInviteUser>) => {
      if (!state.inputInvitedUsers) state.inputInvitedUsers = [action.payload];
      else {
        const existingUser = state.inputInvitedUsers.find(user => user.email === action.payload?.email);

        if (!existingUser) state.inputInvitedUsers = [...state.inputInvitedUsers, action.payload];
      }
    },
    updateInputAddedUser: (state, action: PayloadAction<Maybe<InputAddUser>>) => {
      if (state.inputAddedUsers)
        state.inputAddedUsers = state.inputAddedUsers.map(user =>
          user.id === action.payload?.id ? { ...user, role_id: action.payload.role_id } : user,
        );
    },
    updateInputInvitedUsers: (state, action: PayloadAction<Maybe<InputInviteUser>>) => {
      if (state.inputInvitedUsers)
        state.inputInvitedUsers = state.inputInvitedUsers.map(user =>
          user.email === action.payload?.email ? { ...user, role_id: action.payload.role_id } : user,
        );
    },
    setRoles: (state, action: PayloadAction<{ roles: Maybe<UserRole[]>; resource_id: ID; resource_type: string }>) => {
      const { resource_type, resource_id, roles } = action.payload;

      state.roles[`${resource_type}::${resource_id}`] = roles;
    },
    setInvitedMembers: (state, action: PayloadAction<Maybe<InvitedMember[]>>) => {
      state.invitedMembers = action.payload;
    },
    updateInvitedMember: (state, action: PayloadAction<InvitedMember>) => {
      if (state.invitedMembers)
        state.invitedMembers = state.invitedMembers.map(m => (m.id === action.payload.id ? action.payload : m));
    },
    deleteInvitedMember: (state, action: PayloadAction<ID>) => {
      if (state.invitedMembers) state.invitedMembers = state.invitedMembers.filter(m => m.id !== action.payload);
    },
    updateMember: (state, action: PayloadAction<Member>) => {
      if (state.members) state.members = state.members.map(m => (m.id === action.payload.id ? action.payload : m));
    },
  },
});

export const {
  clearTeamState,
  setMembers,
  setInvitedMembers,
  updateInvitedMember,
  deleteInvitedMember,
  addInputInvitedUser,
  addInputAddedUser,
  updateInputInvitedUsers,
  updateInputAddedUser,
  setInputInvitedUsers,
  setInputAddedUsers,
  setRoles,
  clearInputUsers,
  setIsShowAll,
  setSearch,
  resetToolbar,
  updateMember,
} = slice.actions;

export default slice.reducer;
