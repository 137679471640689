import { Action, ActionMeta, CompaniesActions, ID } from 'src/models';
import { CompaniesParams } from './companies.model';

export const requestAssignCompany = (company_id: ID, roles: string[], meta?: ActionMeta): Action => ({
  type: CompaniesActions.ASSIGN,
  payload: { company_id, roles },
  meta,
});

export const requestCompanies = (params: CompaniesParams = {}): Action => ({
  type: CompaniesActions.GET,
  payload: params,
});

export const requestMoreCompanies = (): Action => ({
  type: CompaniesActions.MORE,
});

export const requestSingleCompany = (company_id: ID) => ({
  type: CompaniesActions.SINGLE,
  payload: { company_id },
});

export const requestUserCompany = (company_id: ID) => ({
  type: CompaniesActions.USER,
  payload: { company_id },
});
