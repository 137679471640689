import { Company, Meta, PageQuery, Maybe, Query, RequestMeta } from 'src/models';
import { Permission } from 'src/models/permissions.model';

export interface CompaniesParams {
  query?: Partial<Query>;
  meta?: Partial<RequestMeta>;
}

export interface CompaniesState extends Record<string, unknown> {
  companies?: Maybe<Company[]>;
  query?: Maybe<PageQuery>;
  meta?: Maybe<Meta>;
  company?: Maybe<Company>;
  userCompany?: Maybe<Company>;
  permissions?: Maybe<Permission[]>;
}

export const INITIAL_COMPANIES_STATE: CompaniesState = {};
