/* eslint-disable max-lines */
export enum ResourceType {
  CHANGE_ORDER = 'ChangeOrder',
  PICTURE = 'Attachments::Picture',
  TRADE_CONTRACT = 'TradeContract',
  LINE_ITEM = 'LineItem',
  MAIN_PROJECT = 'MainProject',
  SUB_PROJECT = 'Project',
  MAIN_CONTRACT = 'MainContract',
  PROJECT = 'Project',
}

export enum PredefinedLists {
  CHANGE_ORDER_CATEGORIES = 'change_order_categories',
  CHANGE_ORDER_REASONS = 'change_order_reasons',
  CHANGE_ORDER_REPORTS = 'change_order_reports',
  DOCUMENTS_TYPES = 'document_types',
  DOCUMENT_CONTEXTS = 'context',
  MAIN_CONTRACT_PHASES = 'main_contract_phases',
  MAIN_CONTRACT_STATUSES = 'main_contract_statuses',
  TRADE_CONTRACT_PHASES = 'trade_contract_phases',
  TRADE_CONTRACT_STATUSES = 'trade_contract_statuses',
  REQ_DETAIL_STATUSES = 'req_detail_statuses',
  CHANGE_ORDER_STATUSES = 'change_order_statuses',
  REQ_DETAIL_SECTIONS = 'req_detail_sections',
  MANAGER_REQ_STATUSES = 'req_manager_statuses',
}

export enum PredefinedNestedLists {
  REQ_DETAIL_CATEGORIES = 'req_detail_categories',
}

export enum PredefinedStateMachines {
  MAIN_CONTRACT_PHASES = 'main_contract_phases_state_machine',
  MAIN_CONTRACT_STATUSES = 'main_contract_statuses_state_machine',
  TRADE_CONTRACT_PHASES = 'trade_contract_phases_state_machine',
  TRADE_CONTRACT_STATUSES = 'trade_contract_statuses_state_machine',
  REQ_DETAIL_STATUSES = 'req_detail_statuses_state_machine',
  CHANGE_ORDER_STATUSES = 'change_order_statuses_state_machine',
  MANAGER_REQ_STATUSES = 'req_manager_statuses_state_machine',
}

export enum QueryActions {
  ASSIGN_CONTACT = 'assignContract',
  CHANGE_ORDERS_TOTALS = 'changeOrderTotals',
  DELETE_CONTACT = 'deleteContract',
  GET_CHANGE_ORDERS_MORE = 'getChangeOrdersMore',
  GET_DRAWING = 'getDrawing',
  GET_SINGLE_CHANGE_ORDER = 'getSingleChangeOrder',
  POST_DRAWING = 'postDrawing',
}

export enum SocketQueryActions {
  GET_SERVER_CONFIG = 'getServerConfig',
  UPDATE_REQ_PAGE = 'updateReqPage',
  USER_LOGIN = 'userLogin',
  USER_LOGOUT = 'userLogout',
  GET_INVITE = 'getInvite',
  SIGN_UP = 'signUp',
}

export enum UserActions {
  CHECK_TOKEN = 'checkToken',
  GET = 'requestUserProfile',
  GET_USER_ADMIN = 'requestUserAdmin',
  UPDATE = 'requestUserProfileUpdate',
  SEND_UPDATE_PASSWORD_TOKEN = 'requestSendUpdatePasswordToken',
  SEND_RESET_PASSWORD_TOKEN = 'requestResetUpdatePasswordToken',
  CHECK_UPDATE_PASSWORD_TOKEN = 'requestCheckUpdatePasswordToken',
  UPDATE_PASSWORD = 'requestUpdatePassword',
  RESET_PASSWORD = 'requestResetPassword',
  UPDATE_USER_ADMIN = 'updateUserAdmin',
}

export const QueryDomain = {
  AUTOCOMPLETE: 'autoComplete',
  BILLING: 'billing',
  BILLING_VERSION: 'billingVersion',
  BREADCRUMBS: 'breadcrumbs',
  CHANGE_ORDER: 'changeOrder',
  COMMENTS: 'comments',
  COMMENT_THREADS: 'commentThreads',
  COMPANY: 'company',
  CONTRACT_TERMS: 'contractTerms',
  DOCUMENTS: 'documents',
  REQUIRED_DOCUMENTS: 'requiredDocuments',
  PREDEFINED_LISTS: 'predefinedLists',
  INTERFACE: 'interface',
  MANAGER_REQ: 'managerReq',
  MAIN_CONTRACT: 'mainContract',
  MAIN_PROJECT: 'mainProject',
  PICTURES: 'pictures',
  REPORT: 'report',
  REQ_DETAIL: 'reqDetail',
  REQ_SUMMARY: 'reqSummary',
  RETAINAGE: 'retainage',
  SERVER: 'server',
  SUB_PROJECT: 'subProject',
  TEAM: 'team',
  ATTRIBUTE_CHANGES: 'attributeChanges',
  NOTIFICATIONS: 'notifications',
  TRADE: 'trade',
  USER: 'user',
  LINE_ITEMS: 'lineItems',
  LINE_ITEMS_TRANSFERS: 'lineItemsTransfers',
  ONBOARDING: 'onboarding',
  REQ_PERIODS: 'reqPeriods',
  ROLES: 'roles',
  SIGNATURES: 'signatures',
  TAGS: 'tags',
  TIMEZONES: 'timezones',
  UI_SCENARIOS: 'uiScenarios',
  LEVELS: 'levels',
  HISTORICAL_UPLOADS: 'historicalUploads',
};

export enum QueryAction {
  ACTION = 'action',
  ASSIGN = 'assign',
  AUTOCOMPLETE = 'autocomplete',
  BASE = 'base',
  CONFIG = 'config',
  CREATE = 'create',
  CREATE_DRAWING = 'createDrawing',
  DASHBOARD = 'dashboard',
  DELETE = 'del',
  DELETE_LINE_ITEM = 'deleteLineItem',
  DRAWING = 'drawing',
  EMAIL = 'email',
  GENERATE = 'generate',
  GET = 'get',
  GET_USER_ADMIN = 'getUserAdmin',
  GET_BALANCE = 'getBalance',
  GET_BASE = 'getBase',
  GET_CHANGE_ORDERS = 'getChangeOrders',
  GET_CONTINGENCY_LIST = 'getContingencyList',
  GET_BASE_ERRORS = 'getBaseErrors',
  GET_CHANGE_ORDERS_ERRORS = 'getChangeOrdersErrors',
  GET_HISTORY = 'getHistory',
  GET_ONE = 'getOne',
  GET_PERIODS = 'getPeriods',
  GET_TEAM = 'getTeam',
  GET_TEAM_MEMBER = 'getTeamMember',
  GET_TEAM_INVITE = 'getTeamInvite',
  GET_TOTALS = 'getTotals',
  GET_ALL = 'getAll',
  HISTORY = 'history',
  FORM = 'form',
  LINK = 'download',
  LOGIN = 'login',
  LOGOUT = 'logout',
  PATCH = 'patch',
  PLACES = 'places',
  PERMISSIONS = 'permissions',
  POST = 'post',
  PREVIEW = 'preview',
  ROLES = 'roles',
  SEARCH = 'search',
  SHOW = 'show',
  STATISTICS = 'statistics',
  SUMMARY = 'summary',
  TERMS = 'terms',
  TOTALS = 'totals',
  TRADES = 'trades',
  TRADES_SUB_ONLY = 'tradesSubOnly',
  UPDATE = 'update',
  UPDATE_USER_ADMIN = 'updateUserAdmin',
  UPDATE_LINE_ITEM = 'updateLineItem',
  UPDATE_BILLING = 'updateBilling',
  UPDATE_INVITE = 'updateInvite',
  UPDATE_MANAGER_REQ_PAGE = 'updateManagerReqPage',
  RESEND = 'resend',
  SEND_UPDATE_PASSWORD_TOKEN = 'sendUpdatePasswordToken',
  SEND_RESET_PASSWORD_TOKEN = 'sendResetPasswordToken',
  CHECK_UPDATE_PASSWORD_TOKEN = 'checkUpdatePasswordToken',
  UPDATE_PASSWORD = 'updatePassword',
  RESET_PASSWORD = 'resetPassword',
  GET_TEMPLATE_CONTENT = 'getTemplateContent',
  UPDATE_TEMPLATE_CONTENT = 'updateTemplateContent',
  RESET_TEMPLATE_CONTENT = 'resetTemplateContent',
}

export enum ReqDetailActions {
  BASE_CONTRACT = 'getReqDetailBaseContract',
  BASE_CONTRACT_MORE = 'getReqDetailBaseContractMore',
  CHANGE_ORDERS = 'getReqDetailChangeOrders',
  CHANGE_ORDERS_MORE = 'getReqDetailChangeOrdersMore',
  HISTORY = 'getReqDetailHistory',
  PATCH_BASE_CONTRACT = 'reqDetailPatchBaseContract',
  PAGE_BALANCE_AND_TOTALS = 'reqDetailPageBalanceAndTotals',
  PERFORM_ACTION = 'reqDetailPerformAction',
  POST_BASE_CONTRACT = 'reqDetailPostBaseContract',
  SHOW = 'getReqDetailPageData',
  STATS = 'getReqDetailStats',
  TOTALS = 'getReqDetailTotals',
  UPDATE_PAGE = 'updatePage',
  ADD_PAGE_SUBSCRIPTION = 'requestReqDetailPageSubscription',
  REMOVE_PAGE_SUBSCRIPTION = 'removeReqDetailPageSubscription',
  DELETE_LINE_ITEM = 'deleteReqDetailLineItem',
}

export enum CommentsActions {
  GET = 'getComments',
  POST = 'postComment',
}

export enum CommentThreadActions {
  GET = 'getCommentThread',
  SINGLE = 'getSingleCommentThread',
  POST = 'postCommentThread',
}

export enum DocumentsActions {
  DELETE = 'requestDeleteDocument',
  GET = 'getDocuments',
  UPDATE = 'updateDocument',
  SEARCH = 'searchDocument',
  GET_DOCUMENT = 'getDocument',
}

export enum RequiredDocumentsActions {
  GET = 'getRequiredDocuments',
  UPDATE = 'updateRequiredDocument',
  CREATE = 'createRequiredDocument',
  DELETE = 'deleteRequiredDocument',
  GENERATE = 'generateRequiredDocument',
  GET_TEMPLATE_CONTENT = 'getRequiredDocumentTemplate',
  UPDATE_TEMPLATE_CONTENT = 'updateRequiredDocumentTemplate',
  RESET_TEMPLATE_CONTENT = 'resetRequiredDocumentTemplate',
}

export enum CompaniesActions {
  ASSIGN = 'assignCompany',
  GET = 'getAllCompanies',
  MORE = 'getModeCompanies',
  SINGLE = 'getSingleCompany',
  USER = 'getUserCompany',
}

export enum PicturesActions {
  DELETE = 'deletePicture',
  ADD_TAGS = 'addPicturesTags',
  DELETE_TAGS = 'daletePicturesTags',
  GET = 'getAllPictures',
  UPDATE = 'updatePicture',
}

export enum ContractTermsActions {
  CREATE = 'createContractTerms',
  DELETE = 'deleteContractTerms',
  GET = 'getContractTerms',
  PATCH = 'updateContractTerms',
}

export enum ManagerReqActions {
  BASE_CONTRACT = 'getManagerReqBaseContractData',
  CHANGE_ORDERS = 'getManagerReqChangeOrdersData',
  CHANGE_ORDERS_MORE = 'getManagerReqChangeOrdersMore',
  BASE_CONTRACT_MORE = 'getManagerReqBaseContactMore',
  HISTORY = 'getManagerReqHistory',
  PATCH_LINE_ITEM = 'patchManagerReqLineItem',
  PERFORM_ACTION = 'performManagerReqAction',
  POST_LINE_ITEM = 'postManagerReqLineItem',
  SHOW = 'getManagerReqPageData',
  TOTALS = 'getManagerReqTotals',
  ADD_PAGE_SUBSCRIPTION = 'requestManagerReqPageSubscription',
  REMOVE_PAGE_SUBSCRIPTION = 'removeManagerReqPageSubscription',
  DELETE_LINE_ITEM = 'deleteManagerReqLineItem',
  UPDATE_PAGE = 'updateManagerReqPage',
  GET_STATUS = 'getManagerReqPageStatus',
}

export enum UploadActions {
  UPLOAD = 'upload',
}

export enum ProjectActions {
  CREATE = 'createProject',
  GET = 'getSubProjects',
  UPDATE = 'updateSubProjects',
  GET_TEAM = 'getSubProjectTeam',
  GET_TEAM_MEMBER = 'getSubProjectTeamMember',
  GET_TEAM_INVITE = 'getSubProjectTeamInvite',
  SHOW = 'getCurrentSubProject',
  ADD_PAGE_SUBSCRIPTION = 'requestSubProjectPageSubscription',
  REMOVE_PAGE_SUBSCRIPTION = 'removeSubProjectPageSubscription',
}

export enum TradeActions {
  SHOW = 'requestTrade',
  GET = 'requestTrades',
  GET_MORE = 'requestMoreTrades',
  CREATE = 'requestTradeCreate',
  UPDATE = 'requestTradeUpdate',
  DELETE = 'requestTradeDelete',
}

export enum AttributeChangesActions {
  GET = 'requestAttributeChanges',
}

export enum RetainageActions {
  GET = 'requestRetainageGet',
  POST = 'requestRetainagePost',
  UPDATE = 'requestRetainageUpdate',
  DELETE = 'requestRetainageDelete',
}

export enum TeamActions {
  GET = 'requestTeam',
  GET_ROLES = 'requestTeamRoles',
  CREATE = 'requestCreateTeam',
  UPDATE_MEMBER = 'requestUpdateMember',
  UPDATE_INVITE = 'requestUpdateInvite',
  RESEND_INVITE = 'requestResendInvite',
  CANCEL_INVITE = 'requestCancelInvite',
}

export const ChangeOrderActions = {
  PATCH: 'requestChangeOrderPatch',
  CREATE: 'requestChangeOrderCreate',
  GET: 'requestChangeOrders',
  HISTORY: 'requestChangeOrderHistory',
  AUTOCOMPLETE: 'requestChangeOrderAutocomplete',
  CONTINGENCY_LIST: 'requestContingencyLineItemsList',
};

export const ReportActions = {
  CREATE: 'requestReport',
};

export const ReqSummaryActions = {
  SHOW: 'reqSummaryShow',
  // STATISTICS: 'reqSummaryStatistics',
  TOTALS: 'requestReqSummaryTotals',
  BILLING_STATUS: 'requestReqSummaryBillingStatus',
  // new-ui
  TRADES: 'reqSummaryTrades',
  TERMS: 'reqSummaryTerms',
  ACTION: 'reqSummaryAction',
  GET_TRADES_MORE: 'reqSummaryTradesMore',
  AUTOCOMPLETE: 'reqSummaryAutocomplete',
  DASHBOARD: 'reqSummaryDashboard',
};

export const GeneralActions = {
  GET_AUTOCOMPLETE: 'requestAutocomplete',
  GET_PLACES_AUTOCOMPLETE: 'requestPlacesAutocomplete',
  GET_EMAIL_AUTOCOMPLETE: 'requestEmailAutocomplete',
};

export enum SignaturesActions {
  GET = 'requestSignatures',
  CREATE = 'requestSignatureCreate',
  DELETE = 'requestSignatureDelete',
}

export enum BillingVersionActions {
  UPDATE = 'requestUpdateBillingVersion',
  MAKE_CURRENT = 'requestMakeCurrentBillingVersion',
}

export enum NotificationsActions {
  UPDATE = 'requestNotificationsUpdate',
  DELETE = 'requestNotificationsDelete',
}

export const RolesActions = {
  GET: 'requestRoles',
  CREATE: 'requestRoleCreate',
  UPDATE: 'requestUpdateRole',
  DELETE: 'requestDeleteRole',
  PROJECT_PERMISSIONS: 'requestProjectPermissions',
};

export const LevelsActions = {
  GET: 'requestLevels',
  CREATE: 'requestLevelsCreate',
  UPDATE: 'requestLevelUpdate',
  DELETE: 'requestLevelDelete',
  GET_ALL: 'requestAllLevels',
};

export const HistoricalUploadsActions = {
  GET: 'requestHistoricalUploads',
  GET_UNSUCCESSFUL_ITEMS: 'requestHistoricalUploadsUnsuccessful',
  GET_MORE_UNSUCCESSFUL_ITEMS: 'requestMoreHistoricalUploadsUnsuccessful',
  GET_ERRORS: 'requestHistoricalUploadsErrors',
  GET_MORE_ERRORS: 'requestMoreHistoricalUploadsErrors',
  UPDATE: 'requestHistoricalUploadsUpdate',
  UPDATE_BILLING: 'requestHistoricalUploadsUpdateBilling',
};

export enum LineItemsTransfersActions {
  GET_LINE_ITEM = 'requestLineItemForTransfer',
  CREATE = 'requestCreateLineItemTransfer',
  CREATE_LINE_ITEM = 'requestCreateLineItemForTransfer',
  AUTOCOMPLETE = 'requestLineItemTransferAutocomplete',
}

export const UiScenariosActions = {
  GET_FORM: 'requestFormScenario',
};

// TODO: rename methods as above
export const MainProjectActions = {
  GET: 'getMainProjects',
  GET_MORE: 'getMainProjectsMore',
  SINGLE: 'getMainProject',
  CREATE: 'createMainProject',
  UPDATE: 'updateMainProject',
};

// TODO: rename methods as above
export const MainContractActions = {
  GET: 'getMainContract',
  UPDATE: 'updateMainContract',
};

export const BillingActions = {
  UPDATE: 'requestBillingPatch',
};

export const OnboardingActions = {
  CREATE: 'requestOnboardingPost',
};
