import { Ability } from '@casl/ability';

import {
  IdLabelPair,
  Maybe,
  PageTab,
  RecordObject,
  RecordWithId,
  Scenario,
  Section,
  ShowScenario,
  ShowScenarioCard,
  ShowScenarioDropdown,
  ShowScenarioHeader,
  ShowScenarioSection,
} from 'src/models';
import { ifTrue } from 'src/tools/logic.tools';

// Permission subjects format on show pages:
// {name}_section (view/edit) - display/edit top level section
// {name}_tab (view) - display tab or not
// {name}_tab_section (view/edit) - display/edit tab's section
// {name}_card (view/edit) - display/edit card
// {name}_dropdown (view/edit) - display/edit dropdown
// totals - for totals

// Permission subjects format on index pages:
// {name}_column (view) - display column or not

const makeCan = (action: string, suffix: string) => (abilities: Ability) => (i: RecordWithId) =>
  abilities.can(action, `${i.id}_${suffix}`);

const canReadDropdown = makeCan('read', 'dropdown');

const canUpdateDropdown = makeCan('update', 'dropdown');

const canReadSection = makeCan('read', 'section');

const canUpdateSection = makeCan('update', 'section');

const canReadCard = makeCan('read', 'card');

const canUpdateCard = makeCan('update', 'card');

const canReadTab = makeCan('read', 'tab');

const canReadTabSection = makeCan('read', 'tab_section');

const canUpdateTabSection = makeCan('update', 'tab_section');

const canReadTotals = makeCan('read', 'totals');

const getShowScenarioDropdown = (dropdown: Maybe<ShowScenarioDropdown>, abilities: Ability) => {
  if (dropdown && canReadDropdown(abilities)(dropdown))
    return { ...dropdown, isEditable: canUpdateDropdown(abilities)(dropdown) };
};

function getShowScenarioHeader(header: Maybe<ShowScenarioHeader>, abilities: Ability) {
  return ifTrue(header, {
    ...header,
    dropdown: getShowScenarioDropdown(header?.dropdown, abilities),
  });
}

function getShowScenarioCards(cards: Maybe<ShowScenarioCard[]>, abilities: Ability) {
  function filterFn(c: ShowScenarioCard) {
    return !c.id || canReadCard(abilities)(c);
  }

  function mapperFn(c: ShowScenarioCard) {
    return { ...c, isEditable: c.isEditable && canUpdateCard(abilities)(c) };
  }

  return cards?.filter(filterFn).map(mapperFn);
}

function getShowScenarioSections(sections: ShowScenarioSection[], abilities: Ability) {
  return sections?.filter(canReadSection(abilities)).map(s => ({
    ...s,
    isEditable: canUpdateSection(abilities)(s),
    cards: getShowScenarioCards(s.cards, abilities),
    dropdown: getShowScenarioDropdown(s.dropdown, abilities),
  }));
}

function getShowScenarioTabs(tabs: Maybe<PageTab[]>, abilities: Ability) {
  return tabs?.filter(canReadTab(abilities));
}

function getShowScenarioTabsContent(allTabsContent: Maybe<RecordObject<Section[]>>, abilities: Ability) {
  if (!allTabsContent) return;

  const tabsContent: RecordObject<Section[]> = {};

  function mapperFn(s: Section) {
    return {
      ...s,
      isEditable: s.isEditable && canUpdateTabSection(abilities)(s),
    };
  }

  Object.keys(allTabsContent).forEach(tab => {
    const data = allTabsContent[tab]?.filter(canReadTabSection(abilities)).map(mapperFn);

    if (data?.length) tabsContent[tab] = data;
  });

  return tabsContent;
}

function getShowScenarioCustomCards(allCustomCards: Maybe<RecordObject<ShowScenarioCard[]>>, abilities: Ability) {
  if (!allCustomCards) return;

  const customCards: RecordObject<ShowScenarioCard[]> = {};

  Object.keys(allCustomCards).forEach(tab => {
    const cards = getShowScenarioCards(allCustomCards?.[tab], abilities);

    if (cards?.length) customCards[tab] = cards;
  });

  return customCards;
}

function getShowScenarioTotals(totals: Maybe<IdLabelPair>, abilities: Ability) {
  if (totals && canReadTotals(abilities)) return totals;
}

export function getShowScenarioWithPermissions(scenario: Maybe<ShowScenario>, abilities: Ability): Maybe<ShowScenario> {
  if (!scenario) return;

  return {
    ...scenario,
    header: getShowScenarioHeader(scenario.header, abilities),
    sections: getShowScenarioSections(scenario.sections, abilities),
    tabs: getShowScenarioTabs(scenario.tabs, abilities),
    tabsContent: getShowScenarioTabsContent(scenario.tabsContent, abilities),
    customCards: getShowScenarioCustomCards(scenario.customCards, abilities),
    totals: getShowScenarioTotals(scenario.totals, abilities),
  };
}

export function getTableScenarioWithPermissions(fullScenario: Maybe<Scenario>, abilities: Ability): Maybe<Scenario> {
  if (!fullScenario) return;

  const scenario: Scenario = {};

  Object.keys(fullScenario).forEach(column => {
    if (abilities.can('read', `${column}_column`)) scenario[column] = fullScenario[column];
  });

  return scenario;
}
