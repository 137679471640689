import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { Maybe } from 'src/models/general.model';
import {
  PageBalance,
  ReqDetailChangeOrder,
  ReqDetailPageData,
  ReqDetailPageDataExtended,
  ReqDetailBaseContract,
} from 'src/models/reqDetail.model';
import { Meta, Results, VersionedPeriod } from 'src/models/results.model';
import { LineItemChangeIndex } from 'src/models/lineItem.model';

import { INITIAL_REQ_DETAIL_STATE } from './reqDetail.model';
import { Scenario, Sidebar } from 'src/models';
import { History } from 'src/models/history.model';
import { Permission } from 'src/models/permissions.model';
import { insertData, updateData } from 'src/tools/array.tools';
import { ReqPeriod } from 'src/models/reqPeriod.model';

export const slice = createSlice({
  name: 'reqDetail',
  initialState: INITIAL_REQ_DETAIL_STATE,
  reducers: {
    clearReqDetailState: () => INITIAL_REQ_DETAIL_STATE,
    setReqDetailPageData: (state, action: PayloadAction<Maybe<ReqDetailPageDataExtended>>) => {
      state.pageData = omit(
        // TODO: remove last two after new endpoints starts working
        ['current_period', 'selected_period', 'trade_contract_id'],
        action.payload,
      ) as ReqDetailPageData;
      state.currentPeriod = action.payload?.current_period;
      state.selectedPeriod = action.payload?.selected_period;
    },
    setReqDetailPagePermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.pagePermissions = action.payload;
    },
    setReqDetailPageBalance: (state, action: PayloadAction<Maybe<PageBalance>>) => {
      state.pageBalance = action.payload;
    },
    setReqDetailSelectedPeriod: (state, action: PayloadAction<Maybe<VersionedPeriod>>) => {
      state.selectedPeriod = action.payload;
    },
    setReqDetailCurrentPeriod: (state, action: PayloadAction<Maybe<VersionedPeriod>>) => {
      state.currentPeriod = action.payload;
    },
    setReqDetailPageTotals: (state, action: PayloadAction<Maybe<Record<string, number>>>) => {
      state.pageTotals = action.payload;
    },
    setReqDetailPageTotalsScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.pageTotalsScenario = action.payload;
    },
    setReqDetailPageMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.pageMeta = action.payload;
    },
    setReqDetailSidebar: (state, action: PayloadAction<Maybe<Sidebar>>) => {
      state.sidebar = action.payload;
    },
    setReqDetailBaseContractTotals: (state, action: PayloadAction<Maybe<Record<string, number>>>) => {
      if (state.baseContract) state.baseContract.totals = action.payload;
    },
    setReqDetailBaseContract: (state, action: PayloadAction<Maybe<Results<ReqDetailBaseContract[]>>>) => {
      const result = action.payload;

      if (result) {
        state.baseContract = result;
        state.baseContractPages = [result.meta?.page || 1];
      }
    },
    addReqDetailBaseContract: (state, action: PayloadAction<Maybe<Results<ReqDetailBaseContract[]>>>) => {
      if (state.baseContract && action.payload) {
        const { data, meta, info } = action.payload;

        const pages = state.baseContractPages ?? [];

        const isNewPage = !meta?.page || !pages.includes(meta.page);

        state.baseContract.data = !isNewPage
          ? updateData(state.baseContract.data, data)
          : insertData(state.baseContract.data, data);

        state.baseContract.meta = meta;
        state.baseContract.info = info;

        state.baseContractPages = [...pages, meta?.page ?? 1];
      } else {
        state.baseContract = action.payload;
      }
    },
    updateReqDetailLineItem: (state, { payload: item }: PayloadAction<ReqDetailBaseContract>) => {
      if (state.baseContract?.data)
        state.baseContract.data = state.baseContract?.data.map(i => (i.id === item.id ? item : i));
    },
    updateReqDetailLineItemIndex: (
      state,
      { payload: { target_row_id, next_row_id } }: PayloadAction<LineItemChangeIndex>,
    ) => {
      if (state.baseContract) {
        const targetLineItem = state.baseContract.data.find(i => i.id === target_row_id);

        const nextLineItem = state.baseContract.data.find(i => i.id === next_row_id);

        const targetReqIndex = targetLineItem?.req_index;

        if (targetLineItem && nextLineItem) {
          const targetLineItemIndex = state.baseContract.data.indexOf(targetLineItem);

          const nextLineItemIndex = state.baseContract.data.indexOf(nextLineItem);

          // swap lineItems indexes in state
          [state.baseContract.data[nextLineItemIndex], state.baseContract.data[targetLineItemIndex]] = [
            state.baseContract.data[targetLineItemIndex],
            state.baseContract.data[nextLineItemIndex],
          ];

          // swap req_inexes
          targetLineItem.req_index = nextLineItem.req_index;
          nextLineItem.req_index = targetReqIndex;

          state.baseContract.data = state.baseContract.data.map(i => (i.id === next_row_id ? nextLineItem : i));
          state.baseContract.data = state.baseContract.data.map(i => (i.id === target_row_id ? targetLineItem : i));
        }
      }
    },
    setInitialReqDetailBaseContract: (state, action: PayloadAction<ReqDetailBaseContract[]>) => {
      if (state.baseContract) state.baseContract.data = action.payload;
    },
    setReqDetailChangeOrders: (state, action: PayloadAction<Maybe<Results<ReqDetailChangeOrder[]>>>) => {
      state.changeOrders = action.payload;
      state.changeOrdersPages = [action.payload?.meta?.page ?? 1];
    },
    setReqDetailChangeOrdersTotals: (state, action: PayloadAction<Maybe<Record<string, number>>>) => {
      if (state.changeOrders) {
        state.changeOrders.totals = action.payload;
      }
    },
    updateReqDetailChangeOrders: (state, action: PayloadAction<Maybe<Results<ReqDetailChangeOrder[]>>>) => {
      if (state.changeOrders && action.payload) {
        const { data, meta, totals } = action.payload;

        const pages = state.changeOrdersPages ?? [];

        const isNewPage = !meta?.page || !pages.includes(meta.page);

        state.changeOrders = {
          ...action.payload,
          data: !isNewPage ? updateData(state.changeOrders.data, data) : insertData(state.changeOrders.data, data),
          totals: totals ? totals : state.changeOrders.totals,
        };

        state.changeOrdersPages = [...pages, meta?.page ?? 1];
      } else {
        state.changeOrders = action.payload;
      }
    },
    setReqDetailHistory: (state, action: PayloadAction<Maybe<History[]>>) => {
      state.history = action.payload;
    },

    updateReqDetailHistory: (state, action: PayloadAction<History>) => {
      state.history = state.history?.map(h => (h.id === action.payload.id ? action.payload : h));
    },
    setReqDetailReqPeriods: (state, action: PayloadAction<Maybe<ReqPeriod[]>>) => {
      state.reqPeriods = action.payload;
    },
    setReqDetailUploadInProgress: (state, action: PayloadAction<Maybe<boolean>>) => {
      state.uploadInProgress = Boolean(action.payload);
    },
  },
});

export const {
  clearReqDetailState,
  setReqDetailPageData,
  setReqDetailCurrentPeriod,
  setReqDetailSelectedPeriod,
  setReqDetailChangeOrders,
  updateReqDetailChangeOrders,
  setReqDetailChangeOrdersTotals,
  setReqDetailHistory,
  setReqDetailPageMeta,
  setReqDetailPageTotals,
  setReqDetailPageTotalsScenario,
  setReqDetailBaseContract,
  addReqDetailBaseContract,
  setReqDetailBaseContractTotals,
  updateReqDetailLineItem,
  setReqDetailPageBalance,
  setReqDetailPagePermissions,
  setReqDetailSidebar,
  setReqDetailReqPeriods,
  updateReqDetailHistory,
  setReqDetailUploadInProgress,
  updateReqDetailLineItemIndex,
  setInitialReqDetailBaseContract,
} = slice.actions;

export default slice.reducer;
