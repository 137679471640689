import { CancelToken } from 'axios';

import { post, patch } from './api_old.service';

import { Result, Response, QueryAction, ID, ResponsePayload, RecordObject, VoidPromise } from 'src/models';
import { logError } from 'src/tools/log.tools';

import { QueryClass } from './query.service';
import { deserialize } from 'src/tools/string.tools';

interface UploadProps {
  data: FormData;
  onUploadProgress?: (arg0: number) => void;
  cancelToken?: CancelToken;
  respond?: (arg0: Result) => void;
  service: string;
  index?: string[];
  params?: RecordObject;
  method?: 'POST' | 'PATCH';
}

export async function upload({ method = 'POST', respond, ...props }: UploadProps): Promise<Response<never>> {
  try {
    const result = await (method === 'POST' ? post : patch)({
      headers: { 'Content-Type': 'multipart/form-data;' },
      ...props,
    });

    if (respond)
      respond({ isOK: true, payload: result.data.data.message ? result.data.data.message : result.data.data });

    return { isOK: true, payload: result.data.data };
  } catch (err) {
    const responseObject = {
      isOK: false,
      message: err.response?.data?.error?.message || err.message || 'Error in upload',
      status: err.response?.status,
    };

    if (respond) respond(responseObject);

    return responseObject;
  }
}

export async function filePath(
  domain: string,
  resource_id: ID,
  sendUrlFn: (arg: string) => void,
  preview?: boolean,
): VoidPromise {
  return QueryClass.instance()?.request(
    { [domain]: preview ? QueryAction.PREVIEW : QueryAction.LINK, payload: { resource_id } },
    (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<string>>(response.payload);

        if (!result?.data) {
          sendUrlFn('');
          return;
        }

        if (result.data === 'https://source.unsplash.com/random') sendUrlFn(result.data);

        /** split the address */
        const split = result.data.split('/');

        let base = window.location.origin;

        /** on localhost app and server, which is use as proxy for retrieving the files
         * are sitting on different ports
         */
        if (RegExp(/localhost/).test(base)) base = 'http://localhost:8080';

        /** remove 'https' and server name, join back and add base server */
        sendUrlFn(base + '/' + split.splice(3, split.length).join('/'));
      } else {
        /** show the error, but continue to operate */
        logError('Retrieving the file link (filePath)', { response });
        sendUrlFn('');
      }
    },
  );
}

export function urlToFilePath(url: string, sendUrlFn: (arg: string) => void): void {
  /** split the address */
  const split = url.split('/');

  let base = window.location.origin;

  /** on localhost app and server, which is use as proxy for retrieving the files
   * are sitting on different ports
   */
  if (RegExp(/localhost/).test(base)) base = 'http://localhost:8080';

  /** remove 'https' and server name, join back and add base server */
  return sendUrlFn(base + '/' + split.splice(3, split.length).join('/'));
}
