import { Maybe } from 'src/models/general.model';
import { Meta } from 'src/models/results.model';

export function hasNextPage(meta: Maybe<Meta>): boolean {
  if (!meta) return false;

  return meta.page !== meta.last;
}

export function metaForNextPage(meta: Maybe<Meta>): Maybe<Meta> {
  return meta && { ...meta, page: meta.next };
}
