import { Comment } from 'src/models/comment.model';
import { ReqPayDocument } from 'src/models/document.model';
import { ID, Maybe } from 'src/models/general.model';
import { Picture, PictureDrawing } from 'src/models/pictures.model';
import { CommentThread } from 'src/models/commentThread.model';

export interface AttachmentsState {
  documents?: Maybe<ReqPayDocument[]>;
  submissionsDocuments?: Maybe<ReqPayDocument[]>;
  backupDocuments?: Maybe<ReqPayDocument[]>;
  comments?: Maybe<Comment[]>;
  commentThreads?: Maybe<CommentThread[]>;
  pictures?: Maybe<Picture[]>;
  pictureDrawings?: Maybe<PictureDrawing>;
  pictureUrls: Record<ID, string>;
}

export const INITIAL_ATTACHMENTS_STATE: AttachmentsState = {
  pictureUrls: {},
};
