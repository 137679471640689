import * as React from 'react';
import clsx from 'clsx';
import { VariableSizeList } from 'react-window';
import { ID } from 'src/models/general.model';

import styles from './ScrollableRow.module.scss';
import { useCallback } from 'react';

interface Props<T> {
  items: T[];
  renderItem: (arg0: T, arg1: ID) => JSX.Element;
  itemWidth: number;
  width: number;
  idToPass: ID;
  classNames?: string;
}

export function ScrollableRow<T>({ items, renderItem, itemWidth, width, idToPass, classNames }: Props<T>) {
  const getItemSize = useCallback(() => itemWidth, [itemWidth]);

  const Item = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      let content;

      if (index >= items.length) {
        content = 'Loading...';
      } else {
        content = renderItem(items[index], idToPass);
      }

      return <div style={style}>{content}</div>;
    },
    [idToPass, items, renderItem],
  );

  return (
    <div>
      <VariableSizeList
        layout='horizontal'
        itemCount={items.length}
        itemSize={getItemSize}
        height='inherit'
        width={width}
        className={clsx(styles.container, classNames)}
      >
        {Item}
      </VariableSizeList>
    </div>
  );
}
