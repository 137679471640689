import { Dispatch } from '@reduxjs/toolkit';
import { Action, ActionMeta, Maybe } from 'src/models/general.model';
import {
  setCustomIsLoading,
  setCustomIsUpdating,
  setIsLoading,
  setIsUpdating,
} from 'src/store/loading/loading.reducer';

export interface IsBusyOptions {
  state: boolean;
  meta?: Maybe<ActionMeta>;
  id?: string;
}

export function setIsLoadingOrUpdating(dispatch: Dispatch<Action>, { state, meta, id }: IsBusyOptions) {
  if (!id) {
    if (meta?.isUpdate) dispatch(setIsUpdating(state));
    else dispatch(setIsLoading(state));
  } else {
    if (meta?.isUpdate) dispatch(setCustomIsUpdating({ id, state }));
    else dispatch(setCustomIsLoading({ id, state }));
  }
}

export function isLoadingOrUpdating(dispatch: Dispatch<Action>) {
  return function call({ state, meta, id }: IsBusyOptions) {
    if (!id) {
      if (meta?.isUpdate) dispatch(setIsUpdating(state));
      else dispatch(setIsLoading(state));
    } else {
      if (meta?.isUpdate) dispatch(setCustomIsUpdating({ id, state }));
      else dispatch(setCustomIsLoading({ id, state }));
    }
  };
}
