import * as React from 'react';
import clsx from 'clsx';

import { Button, ButtonProps } from '../Button';

import classes from './PlainPrimaryButton.module.scss';

export type PlainPrimaryButtonProps = Omit<ButtonProps, 'classNames'> & {
  className?: string;
};

// new-ui
export function PlainPrimaryButton({ className, ...props }: PlainPrimaryButtonProps) {
  return <Button classNames={{ ...classes, container: clsx(classes.container, className) }} {...props} />;
}
