import { useRef, useEffect, useCallback } from 'react';

import { AnyValue } from 'src/models';

export function useDebouncedFn(fn: (...arg: AnyValue[]) => void, delay = 1000) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  useEffect(() => clearTimer, []);

  return useCallback(
    (...arg: AnyValue[]) => {
      clearTimer();
      timeoutRef.current = setTimeout(() => fn(...arg), delay);
    },
    [fn, delay],
  );
}
