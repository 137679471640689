import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { INITIAL_COMPANIES_STATE } from './companies.model';
import { Company } from 'src/models/company.model';
import { Maybe } from 'src/models/general.model';
import { Meta, PageQuery } from 'src/models/results.model';
import { checkIfIsNewById } from 'src/tools/object.tools';
import { Permission } from 'src/models/permissions.model';

export const slice = createSlice({
  name: 'companies',
  initialState: INITIAL_COMPANIES_STATE,
  reducers: {
    clearCompaniesState: state => ({ ...INITIAL_COMPANIES_STATE, current: state.current }),
    setCompany: (state, action: PayloadAction<Maybe<Company>>) => {
      state.company = action.payload;
    },
    setUserCompany: (state, action: PayloadAction<Maybe<Company>>) => {
      state.userCompany = action.payload;
    },
    setCompanies: (state, action: PayloadAction<Maybe<Company[]>>) => {
      state.companies = action.payload;
    },
    addCompanies: (state, action: PayloadAction<Company[]>) => {
      state.companies = [...(state.companies || []), ...action.payload];
    },
    addCompany: (state, action: PayloadAction<Company>) => {
      if (checkIfIsNewById(state.companies, action.payload.id)) {
        state.companies = [...(state.companies || []), action.payload];
      }
    },
    setCompaniesQuery: (state, action: PayloadAction<Maybe<PageQuery>>) => {
      state.query = action.payload;
    },
    setCompaniesMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.meta = action.payload;
    },
    setCompaniesPermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.permissions = action.payload;
    },
  },
});

export const {
  addCompanies,
  addCompany,
  clearCompaniesState,
  setCompanies,
  setCompaniesPermissions,
  setCompaniesMeta,
  setCompaniesQuery,
  setCompany,
  setUserCompany,
} = slice.actions;

export default slice.reducer;
