import * as React from 'react';
import clsx from 'clsx';

import { ifTrue } from 'src/tools/logic.tools';
import { ButtonBaseType, ID } from 'src/models';
import { InputWithIcons } from 'src/components/Inputs';
import { Dropdown, BaseComponentProps } from 'src/components/Dropdown';
import { Button } from 'src/components/Button';
import { Menu } from 'src/components/Menu';

import classes from './SearchableButtonDropdown.module.scss';

interface Props {
  className?: string;
  inputValue?: string;
  isLoading?: boolean;
  isOpen?: boolean;
  items?: ButtonBaseType[];
  onChange?: (id: ID) => void;
  onInputChange: (value: string) => void;
  onRequestOpenClose?: (isOpen: boolean) => void;
  placeholder?: string;
  renderDropdownLabel?: (item: ButtonBaseType) => string | JSX.Element; // fn to render JSX.Element to display in dropdown
  label: string;
}

export function SearchableButtonDropdown({
  renderDropdownLabel,
  className,
  isLoading,
  isOpen: isOpenProp,
  items = [],
  onChange,
  onInputChange,
  onRequestOpenClose,
  placeholder,
  label,
}: Props) {
  if (typeof isOpenProp !== 'undefined' && !onRequestOpenClose) {
    throw new Error('SearchableDropdown: isOpen prop be defined together with onRequestOpenClose prop');
  }

  const [isOpenState, setIsOpenState] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');

  const isOpen = typeof isOpenProp !== 'undefined' ? isOpenProp : isOpenState;

  const handleRequestOpenClose = React.useCallback(
    (value: boolean) => {
      if (onRequestOpenClose) {
        onRequestOpenClose(value);
      }

      setIsOpenState(value);

      if (!value) {
        setInputValue('');
      }
    },
    [onRequestOpenClose],
  );

  function handleInputOnChange(value: string) {
    setInputValue(value);
    onInputChange(value);
  }

  function handleItemOnClick(id: ID) {
    handleRequestOpenClose(false);

    if (onChange) {
      onChange(id);
    }
  }

  function renderBaseComponent(buttonProps: BaseComponentProps) {
    return (
      <Button
        classNames={{
          label: clsx(classes['button-label'], 'o-hidden t-ellipsis'),
          container: isOpen ? classes['button-open'] : '',
        }}
        label={label}
        rightIconId={isOpen ? 'chevron-up' : 'chevron-down'}
        isSmall
        {...buttonProps}
      />
    );
  }

  return (
    <Dropdown
      classNames={{
        container: clsx(classes.container, className),
        dropdown: ifTrue(!items.length, classes['border-none']),
      }}
      renderBaseComponent={renderBaseComponent}
      onRequestOpenClose={handleRequestOpenClose}
      isOpen={isOpen}
      dropdownMinWidth='container-width'
    >
      <InputWithIcons
        className={classes.input}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputOnChange}
        isLoading={isLoading}
        autoFocus
      />
      <Menu
        className={classes.menu}
        items={items}
        renderItemLabel={renderDropdownLabel}
        onItemClick={handleItemOnClick}
      />
    </Dropdown>
  );
}
