import * as React from 'react';

import { Button, ButtonProps } from '../Button';

import classes from './SidebarXlPrimaryButton.module.scss';

type Props = Omit<ButtonProps, 'classNames'>;

// new-ui
export function SidebarXlPrimaryButton(props: Props) {
  return <Button classNames={classes} {...props} />;
}
