import { Maybe } from 'src/models/general.model';
import { Permission } from 'src/models/permissions.model';
import { Level, LevelsData, LevelType } from 'src/models/levels.model';
import { Scenario } from 'src/models';

export interface LevelsState {
  levels?: Maybe<Record<LevelType, Maybe<LevelsData>>>;
  allLevels?: Level[];
  pagePermissions?: Maybe<Permission[]>;
  scenario?: Maybe<Scenario>;
}

export const INITIAL_LEVELS_STATE: LevelsState = {};
