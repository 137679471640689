import { createContext } from 'react';
import { Ability } from '@casl/ability';

import {
  AttachmentsContext,
  Period,
  VersionedPeriod,
  Sidebar,
  ReqPeriod,
  ReqDetalStateStatuses,
  ManagerReqPageStatuses,
  ProjectTypes,
} from 'src/models';
import { ID } from 'src/models/general.model';

export interface SidebarApp {
  resource_type: string;
  resource_id: ID;
  period?: Period | VersionedPeriod;
  context?: AttachmentsContext;
  settings?: Sidebar;
  abilities: Ability;
  isEditableSubmissions?: boolean;
  submissionsTooltip?: string;
  isLockedPeriod?: boolean;
  reqPeriods?: ReqPeriod[];
  isLatestDraft?: boolean;
  pageStatus?: ReqDetalStateStatuses | ManagerReqPageStatuses;
  projectType?: ProjectTypes;
  isLoading?: boolean;
}

export const SidebarAppContext = createContext({} as SidebarApp);
