import * as React from 'react';
import clsx from 'clsx';

import { Button, ButtonProps } from '../Button';

import classes from './XlPrimaryButton.module.scss';

type Props = Omit<ButtonProps, 'classNames'> & { className?: string };

// new-ui
export function XlPrimaryButton({ className, ...props }: Props) {
  return (
    <Button
      classNames={{ ...classes, container: clsx(classes.container, className, { [classes.small]: props.isSmall }) }}
      {...props}
    />
  );
}
