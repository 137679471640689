import add from './icon_add';
import addCircle from './icon_add_circle';
import addUser from './icon_add_user';
import alert from './icon_alert';
import alertError from './icon_alert_error';
import alertTriangle from './icon_alert_triangle';
import architect from './icon_architect';
import arrowDown from './icon_arrow_down';
import arrowLeft from './icon_arrow_left';
import arrowRight from './icon_arrow_right';
import arrowUp from './icon_arrow_up';
import binderOpen from './icon_binder_open';
import binderClosed from './icon_binder_closed';
import briefcase from './icon_briefcase';
import calendar from './icon_calendar';
import camera from './icon_camera';
import chat from './icon_chat';
import check from './icon_check';
import checkCirceSuccess from './icon_check_circle_success';
import checkCircle from './icon_check_circle';
import checkOutline from './icon_check_outline';
import chevronDown from './icon_chevron_down';
import chevronLeft from './icon_chevron_left';
import chevronRight from './icon_chevron_right';
import chevronUp from './icon_chevron_up';
import chevronsLeft from './icon_chevrons_left';
import chevronsRight from './icon_chevrons_right';
import close from './icon_close';
import circle from './icon_circle';
import cmOrPm from './icon_cm_or_pm';
import consultant from './icon_consultant';
import dollar from './icon_dollar';
import download from './icon_download';
import edit from './icon_edit';
import editLine from './icon_edit_line';
import ellipsis from './icon_ellipsis';
import engineer from './icon_engineer';
import envelope from './icon_envelope';
import externalLink from './icon_external_link';
import fileDownload from './icon_file_download';
import fileEditAlt from './icon_file_edit_alt';
import filePlus from './icon_file_plus';
import fileText from './icon_file_text';
import filter from './icon_filter';
import folder from './icon_folder';
import government from './icon_government';
import governmentBuilding from './icon_form_government';
import grid from './icon_grid';
import history from './icon_history';
import home from './icon_home';
import hourglass from './icon_hourglass';
import house from './icon_form_house';
import infoFill from './icon_info_fill';
import infoStroke from './icon_info_stroke';
import link from './icon_link';
import linkAdd from './icon_link_add';
import linkBroken from './icon_link_broken';
import list from './icon_list';
import load from './icon_load';
import loadPrimary from './icon_load_primary';
import lockClosed from './icon_lock_closed';
import lockOpen from './icon_lock_open';
import logo from './logo';
import mapPin from './icon_map_pin';
import mapPinEmpty from './icon_map_pin_empty';
import mapPinNew from './icon_map_pin_new';
import menu from './icon_menu';
import minus from './icon_minus';
import moreHorizontal from './icon_more_horizontal';
import moreVertical from './icon_more_vertical';
import notification from './icon_notification';
import officeBldg from './icon_form_office';
import office from './icon_office';
import owner from './icon_owner';
import paperclip from './icon_paperclip';
import pdf from './icon_pdf';
import penTool from './icon_pen_tool';
import percent from './icon_percent';
import phone from './icon_phone';
import photo from './icon_photo';
import refresh from './icon_refresh';
import school from './icon_form_school';
import search from './icon_search';
import settings from './icon_settings';
import share from './icon_share';
import stamp from './icon_stamp';
import subcontractor from './icon_subcontractor';
import supplier from './icon_supplier';
import theatre from './icon_form_theatre';
import trade from './icon_trade';
import upload from './icon_upload';
import uploadCloud from './icon_upload_cloud';
import uploadCloudDefault from './icon_upload_cloud_default';
import user from './icon_user';
import userPlus from './icon_user_plus';
import vendor from './icon_vendor';
import visibilityOff from './icon_visibility_off';
import visibilityOn from './icon_visibility_on';
import zoomIn from './icon_zoom_in';
import zoomOut from './icon_zoom_out';
import dollarFill from './icon_dollar_fill';
import sortAmountDown from './icon_sort_amount_down';
import trash from './icon_trash';
import csv from './icon_csv';
import xls from './icon_xls';
import unknownFile from './icon_unknown_file';
import userFill from './icon_user_fill';
import expandedArrows from './icon_expanded_arrows';
import doodle from './icon_doodle';
import refreshCCW from './icon_refresh-ccw';
import building from './icon_building';
import logoTrademark from './icon_logo_trademark';
import exclamationFill from './icon_exclamation_fill';
import questionFill from './icon_question_fill';

export const ICONS = {
  'add-circle': addCircle,
  'alert-error': alertError,
  'alert-triangle': alertTriangle,
  'arrow-down': arrowDown,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  'binder-open': binderOpen,
  'binder-closed': binderClosed,
  'check-circle': checkCircle,
  'check-circle-success': checkCirceSuccess,
  'check-outline': checkOutline,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'chevron-up': chevronUp,
  'chevrons-left': chevronsLeft,
  'chevrons-right': chevronsRight,
  'cm-or-pm': cmOrPm,
  'edit-line': editLine,
  'external-link': externalLink,
  'file-download': fileDownload,
  'file-edit-alt': fileEditAlt,
  'file-plus': filePlus,
  'file-text': fileText,
  'government-building': governmentBuilding,
  'info-fill': infoFill,
  'info-stroke': infoStroke,
  'link-add': linkAdd,
  'link-broken': linkBroken,
  'load-primary': loadPrimary,
  'lock-closed': lockClosed,
  'lock-open': lockOpen,
  'map-pin': mapPin,
  'map-pin-empty': mapPinEmpty,
  'map-pin-new': mapPinNew,
  'more-horizontal': moreHorizontal,
  'more-vertical': moreVertical,
  'pen-tool': penTool,
  'upload-cloud': uploadCloud,
  'upload-cloud-default': uploadCloudDefault,
  'user-plus': userPlus,
  'visibility-off': visibilityOff,
  'visibility-on': visibilityOn,
  'zoom-in': zoomIn,
  'zoom-out': zoomOut,
  'dollar-fill': dollarFill,
  'sort-amount-down': sortAmountDown,
  'unknown-file': unknownFile,
  'user-fill': userFill,
  'expanded-arrows': expandedArrows,
  'refresh-ccw': refreshCCW,
  'logo-trademark': logoTrademark,
  'exclamation-fill': exclamationFill,
  'question-fill': questionFill,
  add,
  addUser,
  alert,
  architect,
  briefcase,
  calendar,
  camera,
  chat,
  check,
  circle,
  close,
  consultant,
  dollar,
  download,
  edit,
  ellipsis,
  engineer,
  envelope,
  filter,
  folder,
  government,
  grid,
  history,
  home,
  hourglass,
  house,
  link,
  list,
  load,
  logo,
  menu,
  minus,
  notification,
  office,
  officeBldg,
  owner,
  paperclip,
  pdf,
  percent,
  phone,
  photo,
  refresh,
  school,
  search,
  settings,
  share,
  stamp,
  subcontractor,
  supplier,
  theatre,
  trade,
  trash,
  upload,
  user,
  vendor,
  csv,
  xls,
  doodle,
  building,
};
