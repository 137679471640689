import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { ID, Maybe, Signature } from 'src/models';
import { INITIAL_SIGNATURES_STATE } from './signatures.model';

export const slice = createSlice({
  name: 'signatures',
  initialState: INITIAL_SIGNATURES_STATE,
  reducers: {
    clearState: state => {
      state.signatures = INITIAL_SIGNATURES_STATE.signatures;
    },
    setSignatures: (state, action: PayloadAction<Maybe<Signature[]>>) => {
      state.signatures = action.payload;
    },
    addSignature: (state, action: PayloadAction<Signature>) => {
      state.signatures = [...(state.signatures || []), action.payload];
    },
    deleteSignature: (state, action: PayloadAction<ID>) => {
      if (state.signatures) {
        state.signatures = state.signatures.filter(signature => signature.id !== action.payload);
      }
    },
  },
});

export const getSignatures = (state: RootState) => state.signatures.signatures;

export const { clearState, setSignatures, addSignature, deleteSignature } = slice.actions;

export default slice.reducer;
