import currencyToSymbolMap from 'currency-symbol-map/map';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import currencyCodes from 'currency-codes';

import { CurrencyItem } from 'src/models/currency.model';
import { Maybe } from 'src/models/general.model';

import { MATCH_CURRENCY_KEY_FLAG_URL } from 'src/data/currency.data';

let currencies: CurrencyItem[] = [];

export function makeCurrencyList() {
  Object.keys(currencyToSymbolMap).forEach(currencyKey => {
    // add all available data
    let tempResult: CurrencyItem = {
      id: currencyKey,
      symbol: currencyToSymbolMap[currencyKey],
      name: currencyCodes.code(currencyKey)?.currency ?? '',
      label: `${currencyKey} ${currencyToSymbolMap[currencyKey]} ${currencyCodes.code(currencyKey)?.currency ?? ''}`,
    };

    // find country
    const country = currencyCodes.code(currencyKey)?.countries[0];

    try {
      // if we have predefined flags -> use them
      // eslint-disable-next-line no-prototype-builtins
      if (MATCH_CURRENCY_KEY_FLAG_URL.hasOwnProperty(currencyKey))
        tempResult = { ...tempResult, flag: MATCH_CURRENCY_KEY_FLAG_URL[currencyKey] };
      else tempResult = { ...tempResult, flag: findFlagUrlByCountryName(country) };
    } catch {
      // just ignore
    }

    currencies = [...currencies, tempResult];
  });
}

export function getCurrencyList() {
  return currencies;
}

export function findCurrencyItemByValue(value: Maybe<string>) {
  if (!value) return;

  return currencies.find(currency => currency.label === value);
}

export function getCurrencySymbol(id: string) {
  return currencies.find(currency => currency.id === id)?.symbol ?? '$';
}
