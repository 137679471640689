import clsx from 'clsx';
import { useMemo } from 'react';

import { render } from 'src/tools/render.tools';
import { P5 } from 'src/components/Typography';
import { UserPreview } from 'src/models';
import { ifTrue } from 'src/tools/logic.tools';
import { Avatar } from 'src/components/Avatar';
import { CONFIG } from 'src/config';

import classes from './TeamMembersPreview.module.scss';

interface Props {
  userPreviews: UserPreview[];
  totalCount: number;
  onClick?: () => void;
}

export function TeamMembersPreview({ userPreviews, totalCount, onClick }: Props) {
  const itemsToDisplay = useMemo(() => userPreviews.slice(0, CONFIG.teamMembersPreviewCount), [userPreviews]);

  const restCount = totalCount - itemsToDisplay.length;

  function renderAvatar({ name, picture }: UserPreview, index: number) {
    return <Avatar key={name + index.toString()} author={{ photo_url: picture, full_name: name }} />;
  }

  function renderRestCount() {
    return <P5>+{restCount}</P5>;
  }

  if (!itemsToDisplay.length) return <></>;

  return (
    <div
      data-testid='team-members-preview'
      className={clsx(classes.container, 'row items-p4 a-center', { 'c-pointer': !!onClick })}
      onClick={onClick}
    >
      {render(itemsToDisplay, renderAvatar)}
      {ifTrue(restCount > 0, renderRestCount)}
    </div>
  );
}
