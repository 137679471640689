import { processError } from 'src/tools/events.tools';

import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  ResponsePayload,
  Action,
  Scenario,
  UserAdmin,
  ID,
} from 'src/models';
import { setIsLoading, setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { setUserAdmin, setUserAdminScenario, updateUserAdminInfo } from 'src/store/user/user.reducer';
import { deserialize } from 'src/tools/string.tools';
import { toType } from 'src/tools/type.tools';
import { sendInfo } from 'src/store/app/app.actions';

function processUserAdmin({ dispatch, getService }: ProcessProps) {
  const processGetUserAdmin = (action: Action) => () => {
    dispatch(setIsLoading(true));

    getService().request({ [QueryDomain.USER]: QueryAction.GET_USER_ADMIN }, (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<UserAdmin[]>>(response.payload);

        dispatch(setUserAdmin(result?.data));
        dispatch(setUserAdminScenario(toType<Scenario>(result?.scenario)));
      } else {
        processError({
          activityName: 'Request user admin',
          isLoadingFn: setIsLoading,
          response,
        });
      }

      if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

      dispatch(setIsLoading(false));
    });
  };

  type PatchUserAdminPayload = {
    user_id: ID;
    data: Record<string, unknown>;
  };

  const processUpdateUserAdmin = (action: Action<PatchUserAdminPayload>) => () => {
    dispatch(setCustomIsLoading({ id: 'user', state: true }));

    const user_id = action.payload?.user_id;

    const data = action.payload?.data;

    getService().request(
      {
        [QueryDomain.USER]: QueryAction.UPDATE_USER_ADMIN,
        payload: {
          user_id,
          ...data,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<UserAdmin>>(response.payload);

          if (result) dispatch(updateUserAdminInfo(result.data));

          dispatch(sendInfo('User information was updated'));
        } else {
          processError({
            activityName: 'Request update user admin',
            isLoadingFn: setIsLoading,
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setIsLoading(false));
      },
    );
  };

  return {
    processGetUserAdmin,
    processUpdateUserAdmin,
  };
}

export { processUserAdmin };
