import {
  PredefinedListsMatches,
  PredefinedListsObject,
  PredefinedNestedListsObject,
} from 'src/models/predefined_lists.model';
import { ID, PredefinedLists, PredefinedNestedLists } from 'src/models';

export function getMatchersFromLists(lists?: PredefinedListsObject) {
  if (!lists) return;

  const result: PredefinedListsMatches = {};

  [
    PredefinedLists.MAIN_CONTRACT_STATUSES,
    PredefinedLists.TRADE_CONTRACT_STATUSES,
    PredefinedLists.REQ_DETAIL_STATUSES,
    PredefinedLists.CHANGE_ORDER_STATUSES,
    PredefinedLists.REQ_DETAIL_SECTIONS,
  ].forEach(s => {
    const list = lists[s];

    const match: Record<ID, string> = {};

    if (list) {
      list.forEach(i => {
        match[i.id] = i.label;
      });

      result[s] = match;
    }
  });

  return result;
}

export function getMatchersFromNestedLists(lists?: PredefinedNestedListsObject) {
  if (!lists) return;

  const result: PredefinedListsMatches = {};

  [PredefinedNestedLists.REQ_DETAIL_CATEGORIES].forEach(s => {
    const nestedList = lists[s];

    const match: Record<ID, string> = {};

    if (nestedList)
      Object.keys(nestedList).forEach(k => {
        nestedList[k].forEach(i => {
          match[i.id] = i.label;
        });

        result[s] = match;
      });
  });

  return result;
}
