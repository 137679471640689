import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
      data-testid='icon_doodle'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M7.33 14.67h4.636c1.043 0 1.39-1.397.468-1.884l-.523-.276a1 1 0 01.096-1.813L15 9.5'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M11.334 2a1.885 1.885 0 112.667 2.667l-9 9-3.667 1 1-3.667 9-9z'
      />
    </svg>
  );
}

export default Icon;
