import * as React from 'react';
import { useThrottledFn } from './useThrottledFn';

export function useViewportSize() {
  const [width, setWidth] = React.useState(0);

  const [height, setHeight] = React.useState(0);

  const handleChange = React.useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  const handleChangeThrottled300 = useThrottledFn(handleChange, 300);

  React.useLayoutEffect(handleChange, [handleChange]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleChangeThrottled300);

    return () => {
      window.removeEventListener('resize', handleChangeThrottled300);
    };
  }, [handleChangeThrottled300]);

  return [width, height];
}
