import { ApiWsMessage, BehaviorMessage } from 'src/models/connection.model';
import { Action, ApiProcessProps, SingleOrArray } from 'src/models/general.model';
import { AppModule, NotificationMessage } from 'src/models/notification.model';
import {
  addMessage,
  addPageSubscription,
  removePageSubscription,
  setMessages,
} from 'src/store/notifications/notifications.reducer';
import { setReqDetailUploadInProgress } from 'src/store/reqDetail/reqDetail.reducer';
import { logAlpha, productionLog } from 'src/tools/log.tools';
import { convertMessage } from 'src/tools/message.tools';
import { setManagerReqUploadInProgress } from 'src/store/managerReq/managerReq.reducer';
import {
  setCurrentSubProjectSubmissionOpen,
  setSubProjectLockAndRollInProgress,
} from 'src/store/subProject/subProject.reducer';

const isTest = process.env.NODE_ENV === 'test';

export function processApiSocketNotifications({ dispatch, getService }: ApiProcessProps) {
  function processReqDetailSubscription(incoming: BehaviorMessage) {
    logAlpha('processReqDetailSubscription::incoming', incoming);

    const { data } = incoming;

    if ('upload_in_progress' in data) dispatch(setReqDetailUploadInProgress(Boolean(data.upload_in_progress)));
  }

  function processReqManagerSubscription(incoming: BehaviorMessage) {
    logAlpha('processReqManagerSubscription::incoming', incoming);

    const { data } = incoming;

    if ('upload_in_progress' in data) dispatch(setManagerReqUploadInProgress(Boolean(data.upload_in_progress)));
  }

  function processSubProjectSubscription(incoming: BehaviorMessage) {
    logAlpha('processSubProjectSubscription::incoming', incoming);

    const { data } = incoming;

    if ('lock_and_roll_in_progress' in data)
      dispatch(setSubProjectLockAndRollInProgress(Boolean(data.lock_and_roll_in_progress)));

    if ('submission_open' in data) dispatch(setCurrentSubProjectSubmissionOpen(Boolean(data.submission_open)));
  }

  function processIncomingNotifications(incoming: ApiWsMessage<NotificationMessage>) {
    let data: SingleOrArray<NotificationMessage>;

    if ('data' in incoming) data = incoming.data.map(convertMessage);
    else data = convertMessage(incoming);

    dispatch(Array.isArray(data) ? setMessages(data) : addMessage(data));
    productionLog('notifications', data);
  }

  function processManagerReqAddPageSubscription({ payload }: Action) {
    if (!isTest)
      getService().addSubscription({
        channel: 'ReqManagersChannel',
        payload: { main_contract_id: payload },
        fn: processReqManagerSubscription,
      });

    dispatch(addPageSubscription({ module: AppModule.MANAGER_REQ, id: payload }));
  }

  function processReqDetailAddPageSubscription({ payload }: Action) {
    if (!isTest)
      getService().addSubscription({
        channel: 'ReqDetailChannel',
        payload: { trade_contract_id: payload },
        fn: processReqDetailSubscription,
      });

    dispatch(addPageSubscription({ module: AppModule.REQ_DETAIL, id: payload }));
  }

  function processManagerReqRemovePageSubscription({ payload }: Action) {
    if (!isTest) getService()?.removeSubscription('ReqManagersChannel');

    dispatch(removePageSubscription({ module: AppModule.MANAGER_REQ, id: payload }));
  }

  function processReqDetailRemovePageSubscription({ payload }: Action) {
    if (!isTest) getService()?.removeSubscription('ReqDetailChannel');

    dispatch(removePageSubscription({ module: AppModule.REQ_DETAIL, id: payload }));
  }

  function processProjectsAddPageSubscription({ payload }: Action) {
    if (!isTest)
      getService().addSubscription({
        channel: 'ProjectsChannel',
        payload: { project_id: payload },
        fn: processSubProjectSubscription,
      });

    dispatch(addPageSubscription({ module: AppModule.PROJECTS, id: payload }));
  }

  function processProjectsRemovePageSubscription({ payload }: Action) {
    if (!isTest) getService()?.removeSubscription('ProjectsChannel');

    dispatch(removePageSubscription({ module: AppModule.MANAGER_REQ, id: payload }));
  }

  return {
    processProjectsAddPageSubscription,
    processProjectsRemovePageSubscription,
    processReqDetailRemovePageSubscription,
    processManagerReqRemovePageSubscription,
    processReqDetailAddPageSubscription,
    processIncomingNotifications,
    processManagerReqAddPageSubscription,
    processReqManagerSubscription,
    processReqDetailSubscription,
  };
}
