import { processError } from 'src/tools/events.tools';

import {
  Response,
  ProcessProps,
  QueryAction,
  QueryDomain,
  ReqPayDocument,
  ResponsePayload,
  ID,
  Period,
  Action,
  RequiredDocument,
  AnyValue,
  AttachmentsContext,
} from 'src/models';
import { addDocument, deleteDocument } from 'src/store/attachments/attachments.reducer';
import {
  setRequiredDocuments,
  updateRequiredDocument,
  addRequiredDocument,
  deleteRequiredDocument,
  setTemplateContent,
} from 'src/store/requiredDocuments/requiredDocuments.reducer';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { checkIsValid } from 'src/tools/object.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { sendInfo } from 'src/store/app/app.actions';
import { TemplateContent } from 'src/models/documentTemplate.model';

function processRequiredDocuments({ dispatch, getService }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processRequiredDocumentsRequest = (action: Action<string>) => () => {
    // early return if we left the page
    if (!checkIsValid(action.meta?.isValid)) return;

    setIsBusy({ meta: action.meta, id: 'required_documents', state: true });

    // resource_type is hardcoded since there is only required documents for Project
    getService().request(
      {
        [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.GET,
        payload: {
          params: action.payload,
        },
      },
      (response: Response<string>) => {
        if (checkIsValid(action.meta?.isValid)) {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<RequiredDocument[]>>(response.payload);

            if (result?.data) dispatch(setRequiredDocuments(result.data));
          } else {
            processError({
              activityName: 'Request required documents',
              response,
            });
          }
        }

        setIsBusy({ meta: action.meta, id: 'required_documents', state: false });
      },
    );
  };

  const processUpdateDocumentsRequest =
    ({ required_document_id, ...data }: AnyValue) =>
    () => {
      setIsBusy({ meta: { isUpdate: true }, id: 'required_documents', state: true });

      getService().request(
        {
          [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.UPDATE,
          payload: {
            index: [required_document_id],
            data,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<RequiredDocument>>(response.payload);

            if (result?.data) {
              dispatch(updateRequiredDocument(result.data));
              dispatch(sendInfo('Submission has been updated'));
            }
          } else {
            processError({
              activityName: 'Request update required documents',
              response,
            });
          }

          setIsBusy({ meta: { isUpdate: true }, id: 'required_documents', state: false });
        },
      );
    };

  const processCreateDocumentsRequest = (action: Action) => () => {
    setIsBusy({ meta: { isUpdate: true }, id: 'required_documents', state: true });

    getService().request(
      {
        [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.POST,
        payload: {
          data: action.payload,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<RequiredDocument>>(response.payload);

          if (result?.data) {
            dispatch(addRequiredDocument(result.data));
          }
        } else {
          processError({
            activityName: 'Request create required documents',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        setIsBusy({ meta: { isUpdate: true }, id: 'required_documents', state: false });
      },
    );
  };

  const processDeleteDocumentsRequest = (required_document_id: string) => () => {
    setIsBusy({ meta: { isUpdate: true }, id: 'required_documents', state: true });

    getService().request(
      {
        [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.DELETE,
        payload: { index: [required_document_id] },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(deleteRequiredDocument(required_document_id));
          dispatch(sendInfo('Submission has been deleted'));
        } else {
          processError({
            activityName: 'Request delete required documents',
            response,
          });
        }

        setIsBusy({ meta: { isUpdate: true }, id: 'required_documents', state: false });
      },
    );
  };

  interface GenerateRequiredDocumentParams {
    id?: ID;
    context?: AttachmentsContext;
    resource_id: ID;
    resource_type: string;
    required_document_id: ID;
    period: Period;
  }

  const processRequiredDocumentGenerateRequest = (action: Action<GenerateRequiredDocumentParams>) => () => {
    dispatch(setCustomIsUpdating({ id: 'required_documents', state: true }));

    const { id, ...data } = action.payload as GenerateRequiredDocumentParams;

    getService().request(
      {
        [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.GENERATE,
        payload: { data },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqPayDocument>>(response.payload);

          if (result?.data) {
            if (id) {
              dispatch(deleteDocument(id));
            }

            dispatch(addDocument(result.data));
          }
        } else {
          processError({
            activityName: 'Request required document generate',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'required_documents', state: false }));

        action.meta?.callback?.({ isOK: true });
      },
    );
  };

  const processTemplateContentRequest = (required_document_id: ID) => () => {
    dispatch(setCustomIsLoading({ id: 'requiredDocumentTemplate', state: true }));

    getService().request(
      {
        [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.GET_TEMPLATE_CONTENT,
        payload: { index: [required_document_id] },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<TemplateContent>>(response.payload);

          if (result?.data) dispatch(setTemplateContent(result?.data));
        } else {
          processError({
            activityName: 'Request required document template content',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'requiredDocumentTemplate', state: false }));
      },
    );
  };

  const processUpdateTemplateContentRequest =
    ({ payload, meta }: Action) =>
    () => {
      dispatch(setCustomIsUpdating({ id: 'requiredDocumentTemplate', state: true }));

      getService().request(
        {
          [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.UPDATE_TEMPLATE_CONTENT,
          payload: { index: [payload.required_document_id], data: { content: payload.content } },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<TemplateContent>>(response.payload);

            if (result?.data) dispatch(setTemplateContent(result?.data));

            dispatch(sendInfo('Submission template has been updated'));

            meta?.callback?.();
          } else {
            processError({
              activityName: 'Request to update required document template content',
              response,
            });
          }

          dispatch(setCustomIsUpdating({ id: 'requiredDocumentTemplate', state: false }));
        },
      );
    };

  const processResetTemplateContentRequest = (required_document_id: ID) => () => {
    dispatch(setCustomIsUpdating({ id: 'requiredDocumentTemplate', state: true }));

    getService().request(
      {
        [QueryDomain.REQUIRED_DOCUMENTS]: QueryAction.RESET_TEMPLATE_CONTENT,
        payload: { index: [required_document_id] },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<TemplateContent>>(response.payload);

          if (result?.data) dispatch(setTemplateContent(result?.data));

          dispatch(sendInfo('Submission template has been updated'));
        } else {
          processError({
            activityName: 'Request to reset required document template content',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'requiredDocumentTemplate', state: false }));
      },
    );
  };

  return {
    processRequiredDocumentGenerateRequest,
    processRequiredDocumentsRequest,
    processUpdateDocumentsRequest,
    processCreateDocumentsRequest,
    processDeleteDocumentsRequest,
    processTemplateContentRequest,
    processUpdateTemplateContentRequest,
    processResetTemplateContentRequest,
  };
}

export { processRequiredDocuments };
