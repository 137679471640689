import { isNil, not } from 'ramda';

import { AnyValue } from 'src/models';
import { isNumber, isString } from 'src/validators/simple';

export function parseFloatIfPossible(num: AnyValue) {
  if (typeof num === 'number') return num;

  function tryToParse() {
    try {
      if (not(isNaN(parseFloat(num)))) return parseFloat(num);

      return 0;
    } catch (err) {
      return 0;
    }
  }

  if (typeof num === 'string') return tryToParse();

  return 0;
}

// TODO: to Intl
export function makeNumberString(
  value: string | number,
  options?: {
    min?: number;
    max?: number;
    decimal?: boolean;
    suffix?: string;
    group?: boolean;
  },
): string {
  if (isNil(value)) return '0.00';

  let result: string | number = value;

  if (isString(value)) {
    result = parseFloat(result as string);
  }

  if (isNaN(result as number)) result = 0;

  return (
    result.toLocaleString('en-US', {
      useGrouping: true,
      minimumFractionDigits: getNumber({ value: options?.min, alt: 2, limit: 5 }),
      maximumFractionDigits: getNumber({ value: options?.max, alt: 2, limit: 5 }),
    }) + (options?.suffix || '')
  );
}

function getNumber({ value, limit, alt }: { value: unknown; limit?: number; alt?: number }): number {
  if (isNil(value)) return alt || 0;

  if (isNumber('number')) return alt || 0;

  if (limit && (value as number) > limit) return limit;

  return value as number;
}

// new-ui

export function showInCurrency(value: number | string, options?: Partial<Intl.NumberFormatOptions>): string {
  if (typeof value !== 'number' && typeof value !== 'string') return '';

  let data = value as number;

  if (typeof data === 'string') data = parseFloatIfPossible(data);

  if (isNaN(data)) return '';

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
    ...options,
  }).format(data);
}

export function makePercent(value?: number, of?: number, round = false): number {
  if (!of || !value) return 0;

  const result = (value / of) * 100;

  if (round) return Math.round(result);

  return result;
}

export function makeFromPercent(value?: number, of?: number, round = false): number {
  if (!of || !value) return 0;

  const result = (value / 100) * of;

  if (round) return Math.round(result);

  return result;
}
