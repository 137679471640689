import { Redirect, useParams } from 'react-router-dom';

import { PATHS } from 'src/data/routes.data';
import { useDomainPermissions } from 'src/hooks/useDomainPermissions';
import { resolvePathWithParams } from 'src/tools/url.tools';
import { PathParams } from 'src/models';

export function ReqIndex() {
  const { mainId, subId } = useParams<PathParams>();

  const { isAllowedPath } = useDomainPermissions();

  let availableReqPath = PATHS.PORTFOLIO;

  if (isAllowedPath(PATHS.REQ_SUMMARY)) availableReqPath = PATHS.REQ_SUMMARY;
  else if (isAllowedPath(PATHS.MANAGER_REQ)) availableReqPath = PATHS.MANAGER_REQ;
  else if (isAllowedPath(PATHS.REQ_DETAIL)) availableReqPath = PATHS.REQ_DETAIL;
  else if (isAllowedPath(PATHS.CHANGE_ORDERS)) availableReqPath = PATHS.CHANGE_ORDERS;

  return <Redirect to={resolvePathWithParams(availableReqPath, { mainId, subId })} />;
}
