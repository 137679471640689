import React from 'react';

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M19 4H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 10h18' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M16 2v4' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M8 2v4' />
    </svg>
  );
}

export default Icon;
