import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
      data-testid='icon_expanded-arrows'
    >
      <path
        stroke='none'
        d='M14 5.333a.667.667 0 00.667-.666V2A.667.667 0 0014 1.333h-2.666a.667.667 0 100 1.334h1.06L8 7.06 3.607 2.667h1.06a.667.667 0 100-1.334H2A.667.667 0 001.333 2v2.667a.667.667 0 101.334 0v-1.06L7.06 8l-4.393 4.393v-1.06a.667.667 0 10-1.333 0V14a.667.667 0 00.667.667h2.666a.667.667 0 000-1.334h-1.06L8 8.94l4.393 4.393h-1.06a.667.667 0 100 1.334H14a.667.667 0 00.667-.667v-2.667a.667.667 0 10-1.333 0v1.06L8.94 8l4.394-4.393v1.06a.666.666 0 00.666.666z'
      />
    </svg>
  );
}

export default Icon;
