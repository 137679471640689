import { Maybe, RecordObject } from 'src/models/general.model';
import { InputAddUser, InputInviteUser, InvitedMember, Member, UserRole } from 'src/models/team.model';

export interface TeamState {
  members?: Maybe<Member[]>;
  invitedMembers?: Maybe<InvitedMember[]>;
  inputAddedUsers?: Maybe<InputAddUser[]>;
  inputInvitedUsers?: Maybe<InputInviteUser[]>;
  roles: RecordObject<Maybe<UserRole[]>>;
  isShowAll?: boolean;
  search?: string;
}

export const INITIAL_TEAM_STATE: TeamState = {
  roles: {},
};
