import { forwardRef } from 'react';
import clsx from 'clsx';

import { Maybe, UseIcons } from 'src/models';
import { UseIcon } from 'src/components/UseIcon';
import { Input, InputProps } from '../Input';
import { ifTrue } from 'src/tools/logic.tools';
import { LoadingInline } from 'src/components/LoadingInline';

import classes from './InputWithIcons.module.scss';

interface Props extends InputProps {
  isLoading?: boolean;
  rightIconId?: UseIcons;
  leftIconId?: UseIcons;
  onRightIconClick?: () => void;
  onLeftIconClick?: () => void;
}

// new-ui
export const InputWithIcons = forwardRef<HTMLInputElement, Props>(
  (
    { className, isLoading, rightIconId, leftIconId, onClick, onRightIconClick, onLeftIconClick, ...inputProps },
    ref,
  ) => {
    const isIconsExist = !!rightIconId || !!leftIconId;

    function handleOnClick(e: React.MouseEvent) {
      if (onClick && !inputProps.isDisabled) {
        e.stopPropagation();
        onClick(e);
      }
    }

    function renderIcon(iconId: Maybe<UseIcons>, position: 'left' | 'right') {
      if (!iconId) return;

      const onClickHandler = position === 'left' ? onLeftIconClick : onRightIconClick;

      return (
        <UseIcon
          className={classes.icon}
          iconId={iconId}
          wrapElement={ifTrue(onClickHandler, 'button')}
          onClick={onClickHandler}
          tabIndex={-1}
        />
      );
    }

    function renderLoading() {
      return (
        <div className={classes.loading}>
          <LoadingInline />
        </div>
      );
    }

    return (
      <div
        data-testid='input-with-icons'
        className={clsx(classes.container, 'row a-center', className, {
          [classes.small]: inputProps.isSmall,
          [classes.xsmall]: inputProps.isXSmall,
          [classes['with-loading']]: isLoading,
          [classes['with-left-icon']]: !!leftIconId,
          [classes['with-right-icon']]: !!rightIconId,
          [classes['without-icon']]: !isIconsExist,
        })}
        onClick={handleOnClick}
      >
        {renderIcon(leftIconId, 'left')}
        <Input ref={ref} {...inputProps} />
        {ifTrue(isLoading, renderLoading)}
        {renderIcon(rightIconId, 'right')}
      </div>
    );
  },
);
