import clsx from 'clsx';

import { UseIcons } from 'src/models';
import { Switch } from 'src/components/Switch';
import { UseIcon } from 'src/components/UseIcon';
import { ifTrue } from 'src/tools/logic.tools';
import { useId } from 'src/hooks';

import { TextTooltip } from '../TextTooltip';

import classes from './IconsSwitch.module.scss';

interface Props {
  className?: string;
  onChange: (isLeft: boolean) => void;
  isLeft?: boolean;
  leftIconId?: UseIcons;
  rightIconId?: UseIcons;
  rightIconString?: string;
  leftIconString?: string;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom';
  tooltipClassName?: string;
}

// new-ui
export function IconsSwitch({
  className,
  onChange,
  isLeft,
  leftIconId,
  rightIconId,
  rightIconString,
  leftIconString,
  tooltip,
  tooltipPosition,
  tooltipClassName,
}: Props) {
  const tooltipId = useId();

  function handleOnToggle() {
    onChange(!isLeft);
  }

  function getHandleOnClickIcon(isLeftIcon: boolean) {
    return function call() {
      if (isLeft !== isLeftIcon) handleOnToggle();
    };
  }

  function renderLeftIcon() {
    if (leftIconId)
      return (
        <UseIcon
          wrapElement='button'
          className={ifTrue(isLeft, classes.active)}
          iconId={leftIconId}
          onClick={getHandleOnClickIcon(true)}
        />
      );
  }

  function renderRightIcon() {
    if (rightIconId)
      return (
        <UseIcon
          wrapElement='button'
          className={ifTrue(!isLeft, classes.active)}
          iconId={rightIconId}
          onClick={getHandleOnClickIcon(false)}
        />
      );
  }

  function renderLeftIconString() {
    if (leftIconString)
      return (
        <button
          className={clsx(classes['string-icon'], classes.left, ifTrue(isLeft, classes.activeStringIcon), 'c-pointer')}
          onClick={getHandleOnClickIcon(true)}
        >
          {leftIconString}
        </button>
      );
  }

  function renderRightIconString() {
    if (rightIconString)
      return (
        <button
          className={clsx(
            classes['string-icon'],
            classes.right,
            ifTrue(!isLeft, classes['active-string-icon']),
            'c-pointer',
          )}
          onClick={getHandleOnClickIcon(false)}
        >
          {rightIconString}
        </button>
      );
  }

  function renderTooltip() {
    return <TextTooltip id={tooltipId} place={tooltipPosition} className={tooltipClassName} />;
  }

  return (
    <div
      data-testid='icons-switch'
      className={clsx(classes.container, className, 'row j-center')}
      data-tip={tooltip}
      data-for={tooltipId}
    >
      {ifTrue(leftIconId, renderLeftIcon)}
      {ifTrue(leftIconString, renderLeftIconString)}
      <Switch
        className={classes.switch}
        color={ifTrue(!isLeft, 'var(--accent-1-default)', 'var(--black-lighter)')}
        onClick={handleOnToggle}
        isChecked={!isLeft}
        isDouble
      />
      {ifTrue(rightIconId, renderRightIcon)}
      {ifTrue(rightIconString, renderRightIconString)}
      {ifTrue(tooltip, renderTooltip)}
    </div>
  );
}
