import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_alert-triangle'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='var(--error-default)'
        d='M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z'
      ></path>
      <path
        fill='var(--white-default)'
        fillOpacity='0.75'
        d='M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z'
      ></path>
      <path
        stroke='var(--error-default)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z'
      ></path>
      <path
        stroke='var(--white-default)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeOpacity='0.5'
        strokeWidth='2'
        d='M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z'
      ></path>
      <path
        stroke='var(--error-default)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 9v4M12 17h.01'
      ></path>
    </svg>
  );
}

export default Icon;
