import { processError } from 'src/tools/events.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { patch, post } from 'src/services/api_old.service';

import { MainProject } from 'src/models/main_project.model';
import { QueryAction, QueryDomain } from 'src/models/query.model';
import { Action, ID, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { setIsLoading, setIsUpdating } from 'src/store/loading/loading.reducer';
import { updateMainProject } from 'src/store/portfolio/portfolio.reducer';

import { ServiceRequests } from 'src/data/api_endpoints';
import { sendInfo } from 'src/store/app/app.actions';
import { deserialize } from 'src/tools/string.tools';

function processMainProject({ dispatch, getService }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processSingleRequest = (main_project_id: ID) => () => {
    setIsBusy({ state: true, meta: { isUpdate: true } });

    getService().request(
      {
        [QueryDomain.MAIN_PROJECT]: QueryAction.GET_ONE,
        payload: { main_project_id },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<MainProject>>(response.payload);

          dispatch(updateMainProject(result?.data));
        } else {
          processError({ activityName: 'Request main project', response });
        }

        setIsBusy({ state: false, meta: { isUpdate: true } });
      },
    );
  };

  // FIXME: revise
  const processCreateRequest = (action: Action) => async () => {
    dispatch(setIsLoading(true));

    try {
      await post({
        service: ServiceRequests.MAIN_PROJECTS,
        data: action.payload,
        headers: { 'Content-Type': 'multipart/form-data;' },
      });

      // dispatch(addMainProject(result.data.data));
      dispatch(sendInfo('New main project has been created'));

      if (action.meta?.callback) action.meta.callback({ isOK: true });
    } catch (err) {
      processError({
        activityName: 'Request to create main project',
        callbackFn: action.meta?.callback,
        response: {
          isOK: false,
          status: err.response.status,
          payload: err.response?.data,
        },
      });
    }

    dispatch(setIsLoading(false));
  };

  const processUpdateRequest = (action: Action) => async () => {
    dispatch(setIsUpdating(true));

    try {
      const result = await patch({
        service: ServiceRequests.MAIN_PROJECT,
        data: action.payload.data,
        index: [action.payload.main_project_id],
        headers: { 'Content-Type': 'multipart/form-data;' },
      });

      dispatch(updateMainProject(result.data.data));
      dispatch(sendInfo('Main project has been saved'));

      if (action.meta?.callback) action.meta.callback({ isOK: true });
    } catch (err) {
      processError({
        activityName: 'Request to update main project',
        callbackFn: action.meta?.callback,
        response: {
          isOK: false,
          status: err.response.status,
          payload: err.response?.data,
        },
      });
    }

    dispatch(setIsUpdating(false));
  };

  return { processSingleRequest, processCreateRequest, processUpdateRequest };
}

export { processMainProject };
