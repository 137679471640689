import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Maybe } from 'src/models/general.model';
import { MainProject } from 'src/models/main_project.model';
import { Permission } from 'src/models/permissions.model';
import { Meta, PageQuery } from 'src/models/results.model';
import { INITIAL_PORTFOLIO_STATE } from './portfolio.model';

export const slice = createSlice({
  name: 'portfolio',
  initialState: INITIAL_PORTFOLIO_STATE,
  reducers: {
    clearPortfolioState: () => INITIAL_PORTFOLIO_STATE,
    setMainProjectsMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.meta = action.payload;
    },
    setMainProjects: (state, action: PayloadAction<Maybe<MainProject[]>>) => {
      state.mainProjects = action.payload ?? [];
    },
    addMainProjects: (state, action: PayloadAction<Maybe<MainProject[]>>) => {
      state.mainProjects = [...(state.mainProjects ?? []), ...(action.payload || [])];
    },
    updateMainProject: (state, action: PayloadAction<Maybe<MainProject>>) => {
      state.mainProjects = state.mainProjects?.map(p => (p.id === action.payload?.id ? action.payload : p));
    },
    setMainProjectsQuery: (state, action: PayloadAction<Maybe<PageQuery>>) => {
      state.query = action.payload;
    },
    setMainProjectsPermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.permissions = action.payload;
    },
  },
});

export const {
  setMainProjectsPermissions,
  setMainProjects,
  setMainProjectsMeta,
  setMainProjectsQuery,
  addMainProjects,
  updateMainProject,
  clearPortfolioState,
} = slice.actions;

export default slice.reducer;
