import { useEffect, useState } from 'react';

// TODO: add documentation - why it has been created, what does it do, how & etc.
export const useImageEncoder = (file?: File | string): string | null => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    if (typeof file === 'string') {
      setSrc(file);

      return;
    }

    if (!file || !file.type.includes('image/') || !(file instanceof File)) {
      setSrc(null);

      return;
    }

    const reader = new FileReader();

    let isUnmount = false;

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (!isUnmount) {
        setSrc(reader.result as string);
      }
    };

    return () => {
      isUnmount = true;
    };
  }, [file]);

  return src;
};
