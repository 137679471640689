import { Picture } from 'src/models/pictures.model';
import { QueryDomain } from 'src/models/query.model';
import { filePath } from 'src/services/file.service';
import { logError } from './log.tools';
import { ID, Maybe, RecordObject } from 'src/models';

export function resolveUrl(picture: Picture, sendUrlFn: (url: string) => void, preview = false) {
  filePath(QueryDomain.PICTURES, picture.id, sendUrlFn, preview).catch(err =>
    logError('Picture resolveUrl error:', err),
  );
}

export function resolvePathWithParams(path: string, params?: RecordObject<Maybe<ID>>) {
  if (!params) return path;

  let result = path;

  Object.keys(params).forEach(k => {
    if (params[k]) result = result.replace(`:${k}`, String(params[k]));
  });

  return result;
}

export function unifyPath(path: string) {
  // eslint-disable-next-line no-useless-escape
  const pathVariableRegEx = new RegExp(/(?:\:\w+)/g);

  const pathNumberRegEx = new RegExp(/[0-9]+/g);

  const result = path.replaceAll(pathVariableRegEx, 'N').replaceAll(pathNumberRegEx, 'N');

  return result;
}
