import React from 'react';

function Icon() {
  return (
    <svg
      data-testid='icon_file-edit-alt'
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M14.77 11.768l-1.882-1.936a.777.777 0 00-.552-.237.759.759 0 00-.552.237l-2.783 2.864a.801.801 0 00-.226.568V15.2c0 .212.082.416.228.566.146.15.344.234.55.234h1.881a.759.759 0 00.553-.232l2.783-2.864a.803.803 0 00.23-.568.82.82 0 00-.23-.568zM11.108 14.4h-.778v-.8l2.006-2.064.778.8-2.006 2.064zm-4.665 0h-3.11a.767.767 0 01-.55-.234.812.812 0 01-.228-.566V2.4c0-.212.082-.416.228-.566a.767.767 0 01.55-.234H7.22V4c0 .637.246 1.247.683 1.697a2.3 2.3 0 001.65.703h2.332v.8c0 .212.082.416.228.566a.767.767 0 001.1 0 .812.812 0 00.227-.566V5.6v-.048a1.075 1.075 0 00-.046-.216v-.072a.86.86 0 00-.148-.224L8.581.24a.831.831 0 00-.218-.152.242.242 0 00-.07 0L8.044 0H3.333a2.3 2.3 0 00-1.65.703A2.436 2.436 0 001 2.4v11.2c0 .636.246 1.247.683 1.697a2.3 2.3 0 001.65.703h3.11a.767.767 0 00.55-.234.812.812 0 00.227-.566.812.812 0 00-.228-.566.767.767 0 00-.55-.234zM8.775 2.728L10.79 4.8H9.553a.767.767 0 01-.55-.234A.812.812 0 018.775 4V2.728zM4.888 9.6h4.665a.767.767 0 00.55-.234.812.812 0 00.227-.566.812.812 0 00-.227-.566.767.767 0 00-.55-.234H4.888a.767.767 0 00-.55.234.812.812 0 00-.228.566c0 .212.082.416.228.566.146.15.343.234.55.234zm0-3.2h.777a.767.767 0 00.55-.234.812.812 0 00.228-.566.812.812 0 00-.228-.566.767.767 0 00-.55-.234h-.777a.767.767 0 00-.55.234.812.812 0 00-.228.566c0 .212.082.416.228.566.146.15.343.234.55.234zm1.555 4.8H4.888a.767.767 0 00-.55.234.812.812 0 00-.228.566c0 .212.082.416.228.566.146.15.343.234.55.234h1.555a.767.767 0 00.55-.234A.812.812 0 007.22 12a.812.812 0 00-.228-.566.767.767 0 00-.55-.234z'
      />
    </svg>
  );
}

export default Icon;
