import { useEffect, RefObject } from 'react';
import { useThrottledFn } from './useThrottledFn';

export function useResizeDetector<T extends HTMLElement>(ref: RefObject<T>, action: () => void) {
  const throttledAction = useThrottledFn(action, 300);

  const element = ref.current;

  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver(throttledAction);

      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, [element, throttledAction]);
}
