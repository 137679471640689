import { Suspense } from 'react';

import { ifTrue } from 'src/tools/logic.tools';
import { P4 } from '../Typography';
import { Loading } from '../Loading';

interface Props {
  component?: React.ReactNode;
  children?: JSX.Element;
  fallbackClassName?: string;
  isShowLoading?: boolean;
}

export function Factory({ component, children, fallbackClassName, isShowLoading }: Props) {
  function renderLoading() {
    return <Loading isFull />;
  }

  function renderText() {
    return <P4 className={fallbackClassName}>Loading...</P4>;
  }

  return <Suspense fallback={ifTrue(isShowLoading, renderLoading, renderText)}>{component ?? children}</Suspense>;
}
