import { VerificationModel } from 'src/models/general.model';
import { PATHS } from './routes.data';

export const PERMISSIONS_VERIFICATION_MODEL: VerificationModel = {
  action: { type: 'string', isRequired: true },
  subject: { type: 'string', isRequired: true },
  reason: { type: 'string' },
};

export const PATH_DOMAIN = {
  [PATHS.REQ_SUMMARY]: 'req_summary',
  [PATHS.MANAGER_REQ]: 'req_managers',
  [PATHS.REQ_DETAIL]: 'req_detail',
  [PATHS.REQ_DETAIL_SHOW]: 'req_detail',
  [PATHS.PROJECT_SHOW]: 'project',
  [PATHS.MAIN_CONTRACT_SHOW]: 'main_contract',
  [PATHS.TRADES]: 'trade_contracts_index',
  [PATHS.TRADE_SHOW]: 'trade_contracts_show',
  [PATHS.COMPANIES]: 'companies_index',
  [PATHS.USERS]: 'users_index',
  [PATHS.ROLES]: 'roles_index',
  [PATHS.CHANGE_ORDERS]: 'change_orders_index',
  [PATHS.CHANGE_ORDERS_SHOW]: 'change_orders_show',
  [PATHS.USER_SETTINGS_IN_PROJECT]: 'user_settings',
  [PATHS.TECHNICAL]: 'technical',
};
