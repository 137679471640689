import { AxiosRequestConfig } from 'axios';
import { RecordObject } from './general.model';
import { Permission } from './permissions.model';
import { Meta, PageQuery } from './results.model';

export type ApiResultTrue<Payload> = { isOK: true; payload: ApiResponse<Payload> };

export type ApiResultFalse = { isOK: false; payload: unknown };

export interface ApiError {
  name: string;
  message: string;
  trace: string;
}

export interface ApiErrorResponse {
  config: AxiosRequestConfig;
  data: {
    error: ApiError;
  };
  headers: RecordObject<string>;
  request: XMLHttpRequest;
  status: number;
  statusText: string;
}

export interface ApiRequest {
  endpoint: ApiEndpoints;
  indexes?: string[];
  data?: unknown;
  headers?: RecordObject<unknown>;
  params?: RecordObject<string | number>;
}

export enum ApiMethods {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface ApiResponse<Data> {
  data: Data;
  meta?: Meta;
  permissions?: Permission[];
  query?: PageQuery;
  //   scenario?: Scenario;
  //   totals?: RecordObject<number>;
  //   info?: PageInfo;
}

export enum ApiEndpoints {
  CHECK_TOKEN = '/api/v1/users/check_token',
  MAIN_PROJECTS = '/api/v1/main_projects',
  PROJECTS = '/api/v1/projects',
}
