import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ID, Maybe, RecordObject } from 'src/models/general.model';
import { PATHS } from 'src/data/routes.data';
import { resolvePathWithParams } from 'src/tools/url.tools';

// new-ui
export function useNavigation() {
  const { push, goBack, replace } = useHistory();

  const { pathname, search } = useLocation();

  const pathnameAndSearch = pathname + search;

  const go = useCallback(
    (path = PATHS.HOME) => {
      if (path !== pathnameAndSearch) push(path);
    },
    [pathnameAndSearch, push],
  );

  const isAdministrative = useMemo(() => pathname.includes('/administrative'), [pathname]);

  const goTo = (newPath: string, params: RecordObject<Maybe<ID>>) => go(resolvePathWithParams(newPath, params));

  return { go, goBack, replace, isAdministrative, goTo };
}
