import { path } from 'ramda';

import { Maybe, VoidFn } from 'src/models';
import { ApiErrorResponse } from 'src/models/api.model';
import { store } from 'src/store';
import { sendError } from 'src/store/app/app.actions';
import { setIsAuthed } from 'src/store/app/app.reducer';
import { capitalize, capitalizeFirst } from './string.tools';

export interface Props {
  activityName: string;
  callbackFn?: Maybe<VoidFn>;
  err: unknown;
  // if true, no toast message will be shown. Use for manual error handling
  isSkipError?: boolean;
}

/**
 * New version of function to process the error event - takes whole error object of unknown type
 */
export function processErrorEvent({ activityName, callbackFn, err, isSkipError }: Props) {
  const response = path(['response'], err) as ApiErrorResponse;

  // if 'not authed' error
  if (response?.status === 401) store.dispatch(setIsAuthed(false));

  if (process.env.NODE_ENV !== 'test')
    console.error(`${capitalize(activityName) ?? 'Unknown'} caught error: `, response?.data?.error ?? 'unknown error');

  // set default message
  let messageToDisplay = 'Unknown error';

  const message = response?.data?.error?.message;

  // if data is provided in error -> display it
  if (message) messageToDisplay = message;

  // show toast message
  if (!isSkipError) store.dispatch(sendError(capitalizeFirst(messageToDisplay)));

  // if need to trigger something -> do it
  if (callbackFn) callbackFn({ isOK: false, message });
}
