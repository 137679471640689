import { ID } from './general.model';
import { ResourceType } from './query.model';

export interface Threshold {
  start: number;
  end: number;
  percentage: number;
  freeze: boolean;
}

export enum RetainageThresholdType {
  BASE = 'base_thresholds',
  CHANGE_ORDERS = 'change_orders_thresholds',
}

export interface Retainage {
  id: ID;
  resource_id: ID;
  resource_type: ResourceType;
  applied: boolean;
  calculate_on_trade: boolean;
  [RetainageThresholdType.BASE]: Threshold[];
  [RetainageThresholdType.CHANGE_ORDERS]: Threshold[];
}

export type NewRetainage = Partial<Omit<Retainage, 'id'>>;
