import { isNil, not, reject } from 'ramda';
import { AttachmentFile, Picture, Progress, QueueItem, ShowScenarioCard } from 'src/models';

import { AnyValue, CustomState, Either, ID, Maybe, RecordObject, TextObject } from 'src/models/general.model';

import { isObject, validateArrayOf } from './validate.tools';
import { logUnableToProceed } from './log.tools';
import { toType } from './type.tools';
import { RefObject } from 'react';

export function disableEditOnSectionCards(cardsData: Maybe<ShowScenarioCard[]>): ShowScenarioCard[] {
  let result: ShowScenarioCard[] = [];

  if (!cardsData || !cardsData.length) return result;

  cardsData.forEach(card => (result = [...result, { ...card, isEditable: false }]));

  return result;
}

export function getTextByPath(obj: TextObject, keys: string[]): string {
  if (not(isObject(obj))) {
    logUnableToProceed('getElementByPath', 'Parameter (obj) is not an object: ', obj);
    return '';
  }

  if (!Array.isArray(keys) || !validateArrayOf(keys, ['string'])) {
    logUnableToProceed('getElementByPath', 'Parameter (keys) is not an array or not array of strings: ', keys);
    return '';
  }

  const d = obj[keys[0]];

  if (keys.length === 1) {
    if (typeof d === 'string') return d;
    else {
      logUnableToProceed('getElementByPath', 'End element in the path is not string', { obj, keys });
      return '';
    }
  }

  return getTextByPath(obj[keys[0]], keys.slice(1, keys.length));
}

export function checkIfIsNewById(data: unknown, id: ID) {
  if (!Array.isArray(data) || !data.length) return false;

  return toType<{ id: ID }[]>(data).filter(sub => String(sub.id) === String(id)).length === 0;
}

export function mapArrayElementsById<T>(data: T, element: unknown) {
  if (!Array.isArray(data) || !data.length || typeof element !== 'object' || !toType<RecordObject<string>>(element).id)
    return data;

  return data.map(el => (el.id === toType<RecordObject<string>>(element).id ? element : el));
}

export function omitById<T>(data: T, id: ID) {
  if (!Array.isArray(data) || !data.length || !id) return data;

  return data.filter(el => String(el.id) !== String(id));
}

export function updateFileProgress(data: Maybe<QueueItem<AttachmentFile>[]>, update: Progress) {
  if (!Array.isArray(data) || !data.length || !update || !update.id || !update.progress) return data;

  return data.map(file => {
    if (file.id === update.id) return { ...file, progress: update.progress };

    return file;
  });
}

export function addOrRemoveKey(data: Record<string, boolean>, update: CustomState) {
  if (!isObject(data) || !isObject(update) || !update.id || typeof update.state !== 'boolean') return data;

  if (update.state) return { ...data, [update.id]: update.state };

  const newData = data;

  if (hasKey(update.id, newData)) delete newData[update.id];

  return newData;
}

export function selectNextPictureId(pictures: Maybe<Picture[]>, selectedPictureId: Maybe<ID>) {
  if (!pictures || !pictures.length || !selectedPictureId) return selectedPictureId;

  const indexOfCurrentSelected = pictures.map(el => el.id, pictures).indexOf(selectedPictureId);

  if (indexOfCurrentSelected + 1 === pictures!.length) return pictures[0].id;
  else return pictures[indexOfCurrentSelected + 1].id;
}

export function selectPreviousPictureId(pictures: Maybe<Picture[]>, selectedPictureId: Maybe<ID>) {
  if (!pictures || !pictures.length || !selectedPictureId) return selectedPictureId;

  const indexOfCurrentSelected = pictures.map(el => el.id, pictures).indexOf(selectedPictureId);

  if (indexOfCurrentSelected - 1 < 0) return pictures.last().id;
  else return pictures[indexOfCurrentSelected - 1].id;
}

export function getKey(key: string, data: AnyValue): AnyValue {
  try {
    return data[key];
  } catch {
    return undefined;
  }
}

export function getKeyCurr(key: string) {
  return function call(data: AnyValue): AnyValue {
    try {
      return data[key];
    } catch {
      return undefined;
    }
  };
}

// doesn't work for Records
export function hasKey(key: string, data: AnyValue): boolean {
  if (!data) return false;

  // eslint-disable-next-line no-prototype-builtins
  return Object(data).hasOwnProperty(key);
}

export function recordHasKey(key: string, data: AnyValue): boolean {
  if (!data || typeof data !== 'object') return false;

  return key in data;
}

export function isReactElement(obj: AnyValue): boolean {
  return String(getKey('$$typeof', obj)) === 'Symbol(react.element)';
}

export function checkIsValid(isValid: Maybe<Either<boolean, RefObject<boolean>>>): boolean {
  if (!isValid) return false;

  if (typeof isValid === 'object' && !(isValid as RefObject<boolean>).current) return false;

  return true;
}

export function makeGenericObject<T, K>(object: Record<string, T>, defaultReturn: K) {
  return new Proxy(object, {
    get(target, prop) {
      if (prop in target) {
        return target[prop.toString()];
      } else return defaultReturn;
    },
  });
}

export function makeMatchObject<T = unknown, K = T>(object: RecordObject<T>, defaultReturn: K) {
  return new Proxy(object, {
    get(target, prop) {
      if (prop in target) {
        return target[prop.toString()];
      } else return defaultReturn;
    },
  });
}

export function removeNilKeys<T>(object: RecordObject<T>) {
  return reject(isNil)(object);
}
