import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      data-testid='icon_refresh-ccw'
      viewBox='0 0 16 16'
    >
      <g strokeLinecap='round' strokeLinejoin='round' clipPath='url(#refresh-ccw-clip0)'>
        <path strokeWidth='1.5' d='M15.333 13.334v-4h-4' />
        <path strokeWidth='1.5' d='M.667 2.667v4h4' />
        <path strokeWidth='1.5' d='M13.66 6a6 6 0 00-9.9-2.24L.667 6.667m14.666 2.666L12.24 12.24A6 6 0 012.34 10' />
        <g clipPath='url(#refresh-ccw-clip1)'>
          <path d='M8 4.944v6.111' />
          <path d='M9.389 6.055H7.306a.972.972 0 000 1.945h1.389a.972.972 0 110 1.944H6.333' />
        </g>
      </g>
      <defs>
        <clipPath id='refresh-ccw-clip0'>
          <path fill='#fff' d='M0 0H16V16H0z' />
        </clipPath>
        <clipPath id='refresh-ccw-clip1'>
          <path fill='#fff' d='M0 0H6.667V6.667H0z' transform='translate(4.667 4.667)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
