import { ApiErrorData } from 'src/models/error.model';
import { Action, AnyValue, Either, Maybe, Response, VoidFn } from 'src/models/general.model';

import { sendError } from 'src/store/app/app.actions';
import { isObject } from './validate.tools';
import { store } from 'src/store';
import { setIsAuthed } from 'src/store/app/app.reducer';
import { capitalize, capitalizeFirst, deserialize } from './string.tools';
import { logError } from './log.tools';

const defaultMessage = (activityName: string) => `Error in ${activityName ?? 'unknown'}`;

export function getMessage(err: Maybe<Either<Error, string>>, activityName: string): string {
  if (!err) return defaultMessage(activityName);

  if (typeof err === 'string') return err;

  if (isObject(err) && err.message) return err.message ?? defaultMessage(activityName);

  return defaultMessage(activityName);
}

interface Props {
  activityName: string;
  isLoadingFn?: (arg0: false) => Action;
  callbackFn?: Maybe<VoidFn>;
  response?: Response<AnyValue>;
  isSkipError?: boolean; // if true, no toast message will be shown. Use for manual error handling
}

export function processError({ activityName, isLoadingFn, callbackFn, response, isSkipError }: Props) {
  let skipError = isSkipError;

  // cancel isLoading
  if (isLoadingFn) store.dispatch(isLoadingFn(false));

  if (response?.status === 401) store.dispatch(setIsAuthed(false));

  // skip notification in case of error related to permission
  if (response?.status === 403) skipError = true;

  if (response?.payload?.error) {
    logError(`${capitalize(activityName)} caught error.`, { data: response.payload.error });

    if (!skipError) store.dispatch(sendError(response.payload.error.message));

    return;
  }

  // if payload is provided and in string -> hydrate it and try to show data, otherwise -> show default
  if (response?.payload && typeof response.payload === 'string') {
    const data = deserialize<Maybe<ApiErrorData>>(response.payload);

    if (isObject(data)) logError(`${capitalize(activityName)} caught error.`, data);
  } else logError(`${capitalize(activityName) ?? 'Unknown'} caught error.`, response?.message);

  // show toast message
  if (!skipError) store.dispatch(sendError(capitalizeFirst(getMessage(response?.message, activityName))));

  // if need to trigger something -> do it
  if (callbackFn) callbackFn({ isOK: false, message: response?.message });
}
