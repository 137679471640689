import * as React from 'react';
import { useSelector } from 'react-redux';
import { Ability } from '@casl/ability';

import { RootState } from 'src/store';
import { Maybe } from 'src/models';
import { Permission } from 'src/models/permissions.model';
import { makeAbilities } from 'src/tools/abilities.tools';

export const useAbilities = (selector: (state: RootState) => Maybe<Permission[]>): Ability => {
  const permissions = useSelector(selector);

  return React.useMemo(() => makeAbilities(permissions), [permissions]);
};
