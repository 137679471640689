import { Store } from 'redux';

import { SocketConnection } from 'src/services/api_socket.service';

import { Action, ActionsObject, VoidPromise } from 'src/models/general.model';
import { ManagerReqActions, ProjectActions, ReqDetailActions } from 'src/models';
import { processApiSocketNotifications } from 'src/process/apiSocket.process';
import { makeMatchObject } from 'src/tools/object.tools';
import { removeSubProjectPageSubscription, requestSubProjectPageSubscription } from '../subProject/subProject.actions';
import { getCurrentSubProject } from 'src/store/subProject/subProject.getters';

let service: SocketConnection | undefined;

let ACTIONS: ActionsObject;

const getService = () => service as SocketConnection;

export const socketMiddleware =
  ({ dispatch, getState }: Store) =>
  (next: (arg0: Action) => void) =>
  async (action: Action): VoidPromise => {
    next(action);

    const {
      processIncomingNotifications,
      processReqDetailAddPageSubscription,
      processManagerReqAddPageSubscription,
      processManagerReqRemovePageSubscription,
      processReqDetailRemovePageSubscription,
      processProjectsAddPageSubscription,
      processProjectsRemovePageSubscription,
    } = processApiSocketNotifications({ dispatch, getState, getService });

    // TODO: add disconnection of page subscription
    if (!ACTIONS)
      ACTIONS = makeMatchObject(
        {
          'app/setIsAuthed': ({ payload }: Action) => {
            if (payload && !service) {
              service = SocketConnection.createInstance(getState().app.serverConfig?.mode ?? 'development', [
                {
                  channel: 'NotificationsChannel',
                  fn: processIncomingNotifications,
                },
              ]);
            } else if (!payload && service) {
              service.disconnect();
              service = undefined;
            }
          },
          [ProjectActions.ADD_PAGE_SUBSCRIPTION]: processProjectsAddPageSubscription,
          [ProjectActions.REMOVE_PAGE_SUBSCRIPTION]: processProjectsRemovePageSubscription,
          [ReqDetailActions.REMOVE_PAGE_SUBSCRIPTION]: processReqDetailRemovePageSubscription,
          [ManagerReqActions.REMOVE_PAGE_SUBSCRIPTION]: processManagerReqRemovePageSubscription,
          [ReqDetailActions.ADD_PAGE_SUBSCRIPTION]: processReqDetailAddPageSubscription,
          [ManagerReqActions.ADD_PAGE_SUBSCRIPTION]: processManagerReqAddPageSubscription,
        },
        undefined,
      );

    if (ACTIONS) {
      const fn = ACTIONS[action.type];

      if (fn) fn(action);
    }

    switch (action.type) {
      case 'subProject/setCurrentSubProject':
        if (action.payload?.id) {
          const currentSubProjectId = getCurrentSubProject(getState())?.id;

          if (currentSubProjectId) dispatch(removeSubProjectPageSubscription(currentSubProjectId));

          dispatch(requestSubProjectPageSubscription(action.payload?.id));
        }

        break;
      case 'subProject/clearSubProjectState':
        dispatch(removeSubProjectPageSubscription(getState().subProject.currentSubProject?.id));

        break;

      default:
    }
  };
