import { Picture, ProcessProps, QueryAction, Response, QueryDomain, ResponsePayload, ID, Action } from 'src/models';
import {
  deletePicture,
  setPictures,
  updatePicture,
  updatePictureTags,
} from 'src/store/attachments/attachments.reducer';
import { setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import { deserialize } from 'src/tools/string.tools';
import { logUnableToProceed } from 'src/tools/log.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { RequestAttachmentsProps } from 'src/store/attachments/attachments.action';

function processPictures({ dispatch, getService }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processPicturesRequest =
    ({ payload, meta }: Action<RequestAttachmentsProps>) =>
    () => {
      setIsBusy({ id: 'pictures', state: true, meta });

      getService().request({ [QueryDomain.PICTURES]: QueryAction.GET, payload }, (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Picture[]>>(response.payload);

          dispatch(setPictures(result?.data));
        } else {
          processError({
            activityName: 'Request to get pictures',
            response,
          });
        }

        setIsBusy({ id: 'pictures', state: false, meta });
      });
    };

  interface UpdatePictureParams {
    picture_id: ID;
    included_in_pay_application?: boolean;
    title?: string;
  }

  const processPictureUpdateRequest = (payload: UpdatePictureParams) => () => {
    dispatch(setCustomIsUpdating({ id: 'pictures', state: true }));

    getService().request({ [QueryDomain.PICTURES]: QueryAction.UPDATE, payload }, (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<Picture>>(response.payload);

        if (result?.data) {
          dispatch(updatePicture(result.data));
        }
      } else {
        processError({
          activityName: 'Request to update picture',
          response,
        });
      }

      dispatch(setCustomIsUpdating({ id: 'pictures', state: false }));
    });
  };

  type PictureTagsPayload = { picture_id: ID; tags: string[] };

  const processPictureTagsAddRequest =
    ({ picture_id: resource_id, tags }: PictureTagsPayload) =>
    () => {
      dispatch(setCustomIsUpdating({ id: 'pictures', state: true }));
      getService().request(
        {
          [QueryDomain.TAGS]: QueryAction.POST,
          payload: { resource_id, resource_type: 'Attachments::Picture', tags },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<string[]>>(response.payload);

            dispatch(updatePictureTags({ pictureId: resource_id, tags: result?.data ?? [] }));
          } else {
            processError({
              activityName: 'Request to add tags',
              response,
            });
          }

          dispatch(setCustomIsUpdating({ id: 'pictures', state: false }));
        },
      );
    };

  const processPictureTagsDeleteRequest =
    ({ picture_id: resource_id, tags }: PictureTagsPayload) =>
    () => {
      dispatch(setCustomIsUpdating({ id: 'pictures', state: true }));
      getService().request(
        {
          [QueryDomain.TAGS]: QueryAction.DELETE,
          payload: { resource_id, resource_type: 'Attachments::Picture', tags },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<string[]>>(response.payload);

            dispatch(updatePictureTags({ pictureId: resource_id, tags: result?.data ?? [] }));
          } else {
            processError({
              activityName: 'Request to delete tags',
              response,
            });
          }

          dispatch(setCustomIsUpdating({ id: 'pictures', state: false }));
        },
      );
    };

  const processPictureDeleteRequest = (action: Action<ID>) => () => {
    const { payload: picture_id } = action;

    if (!picture_id) {
      logUnableToProceed('processPictureDeleteRequest', { picture_id });
      return;
    }

    dispatch(setCustomIsUpdating({ id: 'pictures', state: true }));

    getService().request(
      { [QueryDomain.PICTURES]: QueryAction.DELETE, payload: { picture_id } },
      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(deletePicture(picture_id));

          if (action.meta?.callback) action.meta.callback();
        } else {
          processError({
            activityName: 'Request to delete picture',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'pictures', state: false }));
      },
    );
  };

  return {
    processPictureUpdateRequest,
    processPictureTagsAddRequest,
    processPictureTagsDeleteRequest,
    processPicturesRequest,
    processPictureDeleteRequest,
  };
}

export { processPictures };
