import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_check-circle-success'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <path
        fill='none'
        stroke='var(--success-default)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M29.333 14.773V16a13.334 13.334 0 11-7.907-12.187'
      />
      <path
        fill='none'
        stroke='var(--success-default)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M29.333 5.333L16 18.68l-4-4'
      />
    </svg>
  );
}

export default Icon;
