import { AnyValue, ButtonBaseType, RecordObject } from './general.model';
import { UseIcons } from './interface.model';
import { PredefinedLists, PredefinedNestedLists } from './query.model';

export type FormType = RecordObject<AnyValue>;

export enum FormShowAs {
  TEXT_SWITCH = 'textSwitch',
  PRIMARY_BUTTON = 'primaryButton',
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkboxGroup',
  COMPANIES_AUTO_COMPLETE = 'companiesAutoComplete',
  CSI_AUTO_COMPLETE = 'csiAutoComplete',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  SEARCH_NESTED_DROPDOWN = 'searchNestedDropdown',
  NESTED_CONTINGENCY_DROPDOWN = 'searchNestedContingencyDropdown',
  LEVELS_TREE_DROPDOWN = 'levelsTreeDropdown',
  LEVEL_SEARCHABLE_DROPDOWN = 'levelSearchableDropdown',
  FIN = 'fin',
  HEADING = 'heading',
  LARGE_SELECT = 'largeSelect',
  NUMBER = 'number',
  INLINE_NUMBER = 'inlineNumber',
  PROJ_SUBCONTRACTOR_AUTO_COMPLETE = 'projSubcontractorAutoComplete',
  PROJ_COMPANY_TRADE_AUTO_COMPLETE = 'projCompanyAutoComplete',
  PERCENT = 'percent',
  PHONE_NUMBER = 'phoneNumber',
  PLACES = 'places',
  RADIO = 'radio',
  SEARCH_DROPDOWN = 'searchDropdown',
  SWITCH = 'switch',
  TAGS_INPUT = 'tagsInput',
  TEXT = 'text',
  TRADE_GROUP = 'tradeGroup',
  MAIN_GROUP = 'mainGroup',
  UPLOAD_DOCUMENT = 'uploadDocument',
  UPLOAD_XLS_CSV_DOCUMENT = 'uploadXlsCsvDocument',
  UPLOAD_PICTURE = 'uploadPicture',
  UPLOAD_PICTURE_BUTTON = 'uploadPictureButton',
  UPLOAD_PROFILE_PICTURE = 'uploadProfilePicture',
  INLINE_TEXTAREA_BUTTON = 'inlineTextareaButton',
  PLACEHOLDER_TEXT = 'placeholderText',
}

// FIXME: noRequired is a duplicate of isRequired
// _init fields
export interface FormConfig {
  noRequired?: boolean; // don't display '* required' marks
  validationDebounceDelay?: number;
  useFormData?: boolean; // onFormChange will send FormData object
  formKey?: string;
}

export enum AddToLabel {
  SUB_NAME = 'subName',
}

export interface FormElementScript extends FormConfig {
  addNew?: string; // if results from autocomplete should contain line with this title
  addToLabel?: AddToLabel; // add variable data to label; i.e. label = 'Some %1'; addToLabel=[AddToLabel.SUB_NAME]  => 'Some Project Name
  autofocus?: boolean; // change to autoFocus
  autoComplete?: boolean | string;
  autoCorrect?: boolean;
  spellCheck?: boolean;
  buttonLabel?: string;
  isRequired?: boolean;
  isSection?: boolean;
  items?: ButtonBaseType[];
  label?: string;
  topLabel?: string; // the blue label before the actual label
  bottomLabel?: string; // text to show under the input, e.g. some warning
  placeholder?: string;
  showAs?: FormShowAs;
  defaultValue?: AnyValue;
  type?: string;
  min?: number;
  max?: number;
  multiSelect?: boolean;
  isDisabled?: boolean;
  isPrivate?: boolean; // if "true" the field value will not be included in form object
  writeKeys?: string[]; // form[writeKey]: field can set any values to private/public form field
  readKey?: string; // form[readKey]: field can get value from private/public form field
  additionalReadKeys?: string[]; // if needed access to to reading more then one form fields
  isDisabledFromKey?: string; // field will get isDisabled value from form[isEnabledFromKey]
  isEnabledFromKey?: string; // field will get isDisabled value from !form[isEnabledFromKey]
  isVisibleFromKey?: string; // field will be hidden if !form[isEnabledFromKey]
  isHiddenFromKey?: string; // field will be hidden if form[isEnabledFromKey]
  group?: string; // fields can be grouped and rendered on a single line
  section?: string; // fields can be grouped and rendered as a section with some title
  flexInGroup?: string; // css flex value for group item
  rightIconId?: UseIcons; // right icon ID for button
  actionId?: string; // button action id
  customization?: string; // the same parser can look differently in different forms, this is a key for it
  decimals?: number; // number of decimals of number fields
  predefinedListName?: PredefinedLists; // items can be received from predefined lists
  predefinedNestedListName?: PredefinedNestedLists; // in case of Nested Dropdown we can specify the name of predefined nested list to use
  isSubmittable?: boolean; // field can trigger form submit
  placeholderText?: string; // for showing as FormShowAs.PlaceholderText component instead main component if readableKey condition
  tooltip?: string;
  tooltipPosition?: string;
}

export type FormScenario = Record<string, FormElementScript>;
