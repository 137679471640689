import { ButtonBaseType } from './general.model';

export enum AutoCompleteDomains {
  COMPANIES = 'companies',
  USERS = 'users',
  PROJ_COMPANY_TRADES = 'project_company_trades',
  CSI_CODES = 'csi_codes',
  TRADE_GROUP = 'trade_line_groups',
  MAIN_GROUP = 'main_line_groups',
  TRADES_NAVBAR = 'trades_navbar',
}

export interface AutoCompleteResult extends ButtonBaseType {
  url?: string;
  icon_url?: string;
  data?: unknown;
}
