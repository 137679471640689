import { Maybe } from 'src/models/general.model';
import {
  PredefinedListsMatches,
  PredefinedListsObject,
  PredefinedNestedListsObject,
  PredefinedStateMachinesObject,
} from 'src/models/predefined_lists.model';

export interface PredefinedListsState {
  lists?: Maybe<PredefinedListsObject>; // all the lists that are represented as ButtonBaseType[]
  nestedLists?: Maybe<PredefinedNestedListsObject>; // all the nested lists that are represented as Object with ButtonBaseType[] values
  listsMatches?: Maybe<PredefinedListsMatches>; // all the key-value objects generated from predefined lists
  stateMachines?: Maybe<PredefinedStateMachinesObject>; // all the lists that are represented as StateMachine
}

export const INITIAL_ATTRIBUTE_CHANGES_STATE: PredefinedListsState = {};
