import * as React from 'react';
import clsx from 'clsx';

import styles from 'src/components/Navbar/SubprojectButton/SubprojectButton.module.scss';

interface Props {
  name: string;
  disabled?: boolean;
  requestOpen: () => void;
  requestClose: () => void;
}

export function SubprojectButton({ name, disabled, requestOpen, requestClose }: Props) {
  return (
    <div
      data-testid='subproject-container'
      className={clsx(styles.container, { [styles.disabled]: disabled })}
      onMouseLeave={!disabled ? requestClose : undefined}
    >
      <button
        className='t-ellipsis'
        data-testid='subproject-button'
        disabled={disabled}
        onMouseEnter={!disabled ? requestOpen : undefined}
      >
        {name}
      </button>
    </div>
  );
}
