import * as React from 'react';
import clsx from 'clsx';

import { ID, Maybe } from 'src/models/general.model';

import style from './Image.module.scss';

interface Props {
  id: ID;
  src: Maybe<string>;
  placeholderSrc?: string;
  alt?: string;
  containerStyle?: { readonly [key: string]: string };
  onClick?: () => void;
}

export function Image({ id, src, placeholderSrc, alt = `Image_${id}`, containerStyle, ...containerProps }: Props) {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useLayoutEffect(() => {
    setIsLoading(true);
  }, [src]);

  function handleOnLoad() {
    setIsLoading(false);
  }

  function renderPlaceholder() {
    if (placeholderSrc && (isLoading || !src)) {
      return (
        <img
          data-testid='image-placeholder'
          className={clsx(style.image, style.placeholder, containerStyle?.image)}
          src={placeholderSrc}
          alt={src ? 'Loading...' : alt}
        />
      );
    }

    return null;
  }

  return (
    <div
      data-testid='image-container'
      className={clsx('row', containerStyle?.container, { [style.loading]: isLoading })}
      {...containerProps}
    >
      {renderPlaceholder()}
      {src && (
        <img
          data-testid='image-main'
          className={clsx(style.image, containerStyle?.image)}
          src={src}
          alt={alt}
          onLoad={handleOnLoad}
        />
      )}
    </div>
  );
}
