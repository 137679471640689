import * as React from 'react';

export default function IconSchool() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='41' viewBox='0 0 40 41' stroke='none'>
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' transform='translate(-596 -1306)'>
          <g transform='translate(596 1306)'>
            <path
              stroke='none'
              d='M1.252 11.519l2.162 1.283a3.519 3.519 0 011.848-.819l15.28-1.895a.713.713 0 01.793.625.717.717 0 01-.62.8L5.435 13.41a2.1 2.1 0 00-.66.201l15.847 9.428 19.371-11.52L20.623 0 1.251 11.519z'
            />
            <path
              stroke='none'
              d='M20.261 24.49L6.4 16.248v7.472c0 1.976 1.692 3.897 4.643 5.27a23.199 23.199 0 009.58 1.907c3.29.052 6.554-.598 9.578-1.907 2.951-1.373 4.643-3.294 4.643-5.27v-7.472l-13.86 8.242a.707.707 0 01-.723 0z'
            />
            <path fill='none' d='M4.26666667 39.509881L2.84444444 31.6149138 0 40.9453296z' />
            <path
              stroke='none'
              d='M4.978 29.462a2.145 2.145 0 00-1.422-2.021V15.545c.002-.302.067-.6.192-.875a2.145 2.145 0 011.017-1.06l-1.356-.807c-.368.315-.67.703-.885 1.14a3.58 3.58 0 00-.39 1.602v11.896a2.153 2.153 0 00-1.36 2.512 2.132 2.132 0 002.32 1.648 2.142 2.142 0 001.884-2.14z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
