import { dissoc } from 'ramda';

import { logAlpha } from 'src/tools/log.tools';
import { hasKey, makeMatchObject } from 'src/tools/object.tools';
import { upload } from 'src/services/file.service';

import { BatchUploadActions, ServiceInfo } from 'src/models/files.model';
import {
  Action,
  AnyValue,
  BatchItemState,
  FormDataBatchItem,
  PromiseRecords,
  RecordObject,
  Result,
  StateStore,
  VoidPromise,
} from 'src/models/general.model';
import { getAvailableUploadItems, getHasFailedUploads, getQtyOfUploads, getServiceInfo } from '../files/files.getters';
import {
  removeFromUploadsById,
  removeUploads,
  setServiceInfo,
  switchUploadToIdle,
  updateUploadProgressById,
  updateUploadsState,
} from '../files/files.reducer';
import { setIsUploadPopupIsOpen } from '../app/app.reducer';

import { CONFIG } from 'src/config';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { mockFn } from 'src/tools/fn.tools';
import { sendError, sendInfo } from '../app/app.actions';

let batch: PromiseRecords = {};
let cancelTokens: RecordObject<CancelTokenSource> = {};

function getCancelToken(id: string): CancelToken {
  const source = axios.CancelToken.source();

  cancelTokens = { ...cancelTokens, [id]: source };
  return source.token;
}

export const filesMiddleware =
  ({ dispatch, getState }: StateStore) =>
  (next: (arg0: Action) => void) =>
  async (action: Action): VoidPromise => {
    next(action);

    const availableItems = () => getAvailableUploadItems(getState());

    const hasFailedUploads = () => getHasFailedUploads(getState());

    const serviceInfo = () => getServiceInfo(getState());

    const isUploadPopupOpen = () => getState().app.isUploadPopupOpen;

    function clearUploads() {
      const isHasFailedUploads = hasFailedUploads();

      logAlpha('jobs is done');

      if (!isHasFailedUploads) {
        setTimeout(() => {
          if (isUploadPopupOpen()) dispatch(setIsUploadPopupIsOpen(false));

          dispatch(removeUploads());
        }, 2000);
      }

      if (action.payload?.callback && typeof action.payload.callback === 'function')
        action.payload.callback(!isHasFailedUploads);
    }

    function updateProgress(id: string) {
      return function call(progress: number) {
        dispatch(updateUploadProgressById({ id, progress }));
      };
    }

    async function uploadItem(item: FormDataBatchItem, cancelToken: CancelToken, service: ServiceInfo) {
      return upload({
        ...service,
        onUploadProgress: updateProgress(item.id),
        data: item.payload,
        respond: handleApiResponse(item.id),
        cancelToken,
      });
    }

    // remove item by id from batch and update the batch
    function removeId(id: string) {
      batch = dissoc(id, batch);
      cancelTokens = dissoc(id, cancelTokens);

      // batch is empty and no available items -> job is done;
      if (!Object.keys(batch).length && !availableItems.length) clearUploads();
      else updateBatch();
    }

    // process API response
    function handleApiResponse(id: string) {
      return function call(payload: Result) {
        if (payload.isOK) itemHasFinished(BatchItemState.SUCCESS)(id);
        else itemHasFinished(BatchItemState.FAIL)(id, payload.message, payload.status);
      };
    }

    // TODO: add logic for both success & fail
    function itemHasFinished(state: BatchItemState) {
      return function call(id: string, message?: string, status?: number) {
        // update state of item && update batch
        dispatch(
          updateUploadsState({
            ids: [id],
            state,
            message: status === 413 ? 'This upload failed because the file size exceeds 30MB' : message,
          }),
        );

        removeId(id);

        if (state === BatchItemState.FAIL) {
          if (status === 413) {
            dispatch(sendError('This upload failed because the file size exceeds 30MB'));
          } else {
            message ? dispatch(sendError(message)) : dispatch(sendError('An error occurred while loading this file'));
          }
        } else if (state === BatchItemState.SUCCESS) {
          dispatch(sendInfo('Submission was added successfully'));
        }
      };
    }

    // add item(s) to batch
    function addToBatch(toAdd: FormDataBatchItem[]) {
      let result: RecordObject<Promise<AnyValue>> = batch;

      const service = serviceInfo();

      if (!service) return;

      for (const item of toAdd) {
        result = { ...result, [item.id]: uploadItem(item, getCancelToken(item.id), service) };
      }

      batch = result;
      dispatch(updateUploadsState({ ids: Object.keys(result), state: BatchItemState.IN_PROGRESS }));
    }

    // update the batch with new uploads
    function updateBatch() {
      const availableSlots = CONFIG.batches.maxUpload - Object.keys(batch).length;

      // if no available slots
      if (!availableSlots) return;

      // if available items to upload
      if (!availableItems().length) return;

      if (action.type === UPDATE_BATCH)
        dispatch(
          setServiceInfo({
            service: action.payload.service,
            params: action.payload.params,
            index: action.payload.index,
          }),
        );

      const newItemsToAdd = availableItems().slice(0, availableSlots);

      if (newItemsToAdd.length) {
        if (!isUploadPopupOpen()) dispatch(setIsUploadPopupIsOpen(true));

        addToBatch(newItemsToAdd);
      }
    }

    function removeUploadAndClosePopupIfNeeded() {
      if (getQtyOfUploads(getState()) === 1 && isUploadPopupOpen()) {
        dispatch(setIsUploadPopupIsOpen(false));
      }

      dispatch(removeFromUploadsById(action.payload));

      if (hasKey(action.payload, cancelTokens)) cancelTokens[action.payload].cancel('Cancel process.');
    }

    function cancelAll() {
      dispatch(setIsUploadPopupIsOpen(false));
      dispatch(removeUploads());

      if (Object.keys(cancelTokens).length)
        Object.keys(cancelTokens).forEach(id => cancelTokens[id].cancel('Cancel process.'));
    }

    function reloadItem() {
      const itemToUpdate = getState().files.uploads?.find(upload => upload.id === action.payload);

      if (itemToUpdate?.state === BatchItemState.FAIL) {
        dispatch(switchUploadToIdle(action.payload));
        updateBatch();
      }
    }

    const { UPDATE_BATCH, RELOAD, REMOVE_UPLOAD, CANCEL_ALL } = BatchUploadActions;

    // TODO: add actions: remove, re-try
    const ACTIONS = makeMatchObject(
      {
        [UPDATE_BATCH]: updateBatch,
        [REMOVE_UPLOAD]: removeUploadAndClosePopupIfNeeded,
        [RELOAD]: reloadItem,
        [CANCEL_ALL]: cancelAll,
      },
      mockFn,
    );

    ACTIONS[action.type]();
  };
