import * as React from 'react';
import { PrimaryButton, PrimaryButtonProps } from '../PrimaryButton';
import { t } from 'src/tools/text.tools';

const TXT = t(['buttons']);

type Props = Omit<PrimaryButtonProps, 'rightIconId' | 'label'>;

// new-ui
export function NextPrimaryButton(props: Props) {
  return <PrimaryButton dataTestId='next-button' isSmall {...props} label={TXT('next')} rightIconId='arrow-right' />;
}
