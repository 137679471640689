import { P3 } from 'src/components/Typography';
import classes from './SectionHeader.module.scss';

interface Props {
  title: string;
}

export function SectionHeader({ title }: Props) {
  return <P3 className={classes.container}>{title}</P3>;
}
