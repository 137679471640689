/// <reference types="resize-observer-browser" />
import * as React from 'react';
import { MaybeNull } from 'src/models';
import { useThrottledFn } from './useThrottledFn';

export function useBoundingClientRects(ref: React.RefObject<HTMLElement>, forceUpdate?: boolean) {
  const [clientRects, setClientRects] = React.useState<MaybeNull<DOMRect>>(null);

  const handleChange = React.useCallback(() => {
    if (ref.current) {
      setClientRects(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  const handleChangeThrottled = useThrottledFn(handleChange, 15);

  const handleChangeThrottled300 = useThrottledFn(handleChange, 300);

  React.useEffect(() => {
    const { current } = ref;

    if (!current) {
      return;
    }

    document.addEventListener('scroll', handleChangeThrottled, true);
    window.addEventListener('resize', handleChangeThrottled300);

    const resizeObserver = new ResizeObserver(handleChangeThrottled);

    resizeObserver.observe(current);

    return () => {
      resizeObserver.unobserve(current);
      document.removeEventListener('scroll', handleChangeThrottled);
      window.removeEventListener('resize', handleChangeThrottled300);
    };
  }, [ref, handleChangeThrottled, handleChangeThrottled300]);

  React.useLayoutEffect(() => {
    if (forceUpdate) {
      handleChange();
    }
  }, [handleChange, forceUpdate]);

  return clientRects;
}
