import { useCallback, useEffect, useState } from 'react';
import { useResizeDetector } from 'src/hooks/useResizeDetector';

export const useDimensions = (ref: React.MutableRefObject<HTMLElement | null>): [number, number] => {
  const [height, setHeight] = useState(0);

  const [width, setWidth] = useState(0);

  const updateDimensions = useCallback(() => {
    const element = ref.current;

    if (element) {
      setHeight(element.offsetHeight);
      setWidth(element.offsetWidth);
    }
  }, [ref]);

  useResizeDetector(ref, updateDimensions);

  useEffect(updateDimensions, [updateDimensions]);

  return [width, height];
};
