import clsx from 'clsx';

import { Button, ButtonProps } from '../Button';

import classes from './SecondaryButton.module.scss';

// new-ui
export function SecondaryButton({ classNames, ...props }: ButtonProps) {
  return (
    <Button
      {...props}
      classNames={{
        ...classes,
        container: clsx(classes.container, classNames?.container),
        label: clsx(classes.label, classNames?.label),
      }}
    />
  );
}
