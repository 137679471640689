import * as React from 'react';

export default function IconTheater() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='35' viewBox='0 0 40 35' stroke='none'>
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' transform='translate(-455 -1306)'>
          <g transform='translate(455 1306)'>
            <path
              stroke='none'
              d='M39.635.015H0v30.991h3.431a8.49 8.49 0 01.958-3.483c.304-.572.67-1.09 1.086-1.543a5.768 5.768 0 01-.833-2.994c0-1.387.487-2.655 1.288-3.628a34.8 34.8 0 00-3.139-3.605 9.663 9.663 0 002.215-.844c2.902-1.518 5.49-4.442 7.708-8.703 2.247.303 4.698.47 7.264.47s5.017-.167 7.264-.47c2.219 4.261 4.806 7.185 7.708 8.703.823.43 1.572.69 2.214.844a34.828 34.828 0 00-3.139 3.605 5.693 5.693 0 011.289 3.628 5.768 5.768 0 01-.833 2.994 7.44 7.44 0 011.086 1.543 8.49 8.49 0 01.958 3.483h3.43V.014h-.32zM4.566 13.86a9.403 9.403 0 01-.79.378c2.041-2.213 4.36-5.274 5.45-8.647.731.162 1.496.308 2.29.437-2.522 4.694-5.115 6.847-6.95 7.832zM28.44 6.029c.795-.129 1.56-.275 2.29-.437 1.091 3.373 3.41 6.434 5.45 8.647-2.091-.886-4.971-3.044-7.74-8.21z'
            />
            <path
              stroke='none'
              d='M11.892 26.177c.942-.693 1.561-1.863 1.561-3.191 0-2.127-1.589-3.85-3.548-3.85-1.96 0-3.549 1.723-3.549 3.85 0 1.328.62 2.498 1.562 3.19-1.645.93-2.788 2.977-2.788 5.354 0 3.25 2.138 3.412 4.775 3.412 2.637 0 4.774-.161 4.774-3.412 0-2.377-1.143-4.424-2.787-5.353zM21.973 26.177c.942-.693 1.561-1.863 1.561-3.191 0-2.127-1.588-3.85-3.548-3.85s-3.548 1.723-3.548 3.85c0 1.328.619 2.498 1.561 3.19-1.645.93-2.788 2.977-2.788 5.354 0 3.25 2.138 3.412 4.775 3.412 2.637 0 4.774-.161 4.774-3.412 0-2.377-1.143-4.424-2.787-5.353zM32.038 26.177c.942-.693 1.561-1.863 1.561-3.191 0-2.127-1.588-3.85-3.548-3.85s-3.548 1.723-3.548 3.85c0 1.328.619 2.498 1.561 3.19-1.645.93-2.788 2.977-2.788 5.354 0 3.25 2.138 3.412 4.775 3.412 2.637 0 4.775-.161 4.775-3.412 0-2.377-1.143-4.424-2.788-5.353z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
