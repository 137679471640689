import { useSelector } from 'react-redux';

import { getIsAuthed } from 'src/store/app/app.reducer';
import { PATH_DOMAIN } from 'src/data/permissions.data';
import { getCurrentSubprojectAllowedDomains } from 'src/store/subProject/subProject.getters';

export function useDomainPermissions() {
  const isAuthed = useSelector(getIsAuthed);

  const allowedDomains = useSelector(getCurrentSubprojectAllowedDomains);

  function isIncluded(path: string): boolean {
    const pathDomain = PATH_DOMAIN[path];

    return !pathDomain || allowedDomains.includes(pathDomain);
  }

  function isAllowedPath(path: string): boolean {
    return isAuthed && isIncluded(path);
  }

  return { isAllowedPath };
}
