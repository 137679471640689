import React from 'react';

function Icon() {
  return (
    <svg
      data-testid='icon_chevrons-left'
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      fill='none'
      viewBox='0 0 21 20'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M9.667 5.833L5.5 10l4.167 4.167'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M15.5 5.833L11.333 10l4.167 4.167'
      />
    </svg>
  );
}

export default Icon;
