import clsx from 'clsx';
import * as React from 'react';

import styles from './Heading.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
}

// new-ui
export function H1({ children, className, ...opts }: Props) {
  return (
    <h1 className={clsx(styles.h1, className)} {...opts}>
      {children}
    </h1>
  );
}

export function H2({ children, className, ...opts }: Props) {
  return (
    <h2 className={clsx(styles.h2, className)} {...opts}>
      {children}
    </h2>
  );
}

export function H3({ children, className, ...opts }: Props) {
  return (
    <h3 className={clsx(styles.h3, className)} {...opts}>
      {children}
    </h3>
  );
}

export function H4({ children, className, ...opts }: Props) {
  return (
    <h4 className={clsx(styles.h4, className)} {...opts}>
      {children}
    </h4>
  );
}

export function H5({ children, className, ...opts }: Props) {
  return (
    <h5 className={clsx(styles.h5, className)} {...opts}>
      {children}
    </h5>
  );
}

export function H6({ children, className, ...opts }: Props) {
  return (
    <h6 className={clsx(styles.h6, className)} {...opts}>
      {children}
    </h6>
  );
}

export function H7({ children, className, ...opts }: Props) {
  return (
    <h6 className={clsx(styles.h7, className)} {...opts}>
      {children}
    </h6>
  );
}

export function H8({ children, className, ...opts }: Props) {
  return (
    <h6 className={clsx(styles.h8, className)} {...opts}>
      {children}
    </h6>
  );
}
