import * as React from 'react';
import clsx from 'clsx';

import { P3 } from 'src/components/Typography';

import classes from './ProgressBar.module.scss';

interface Props {
  className?: string;
  value: number;
  isHiddenOnZero?: boolean;
  hideValue?: boolean;
}

// new-ui
export function ProgressBar({ className, value, hideValue, isHiddenOnZero }: Props) {
  const displayValue = Math.max(0, Math.min(100, Math.floor(value)));

  if (!displayValue && isHiddenOnZero) {
    return null;
  }

  return (
    <div data-testid='progress-bar' className={clsx(classes.container, className)}>
      {!hideValue && <P3>{displayValue}%</P3>}
      <div className={classes.progress}>
        <div className='progress-bar-value-line' style={{ width: `${value}%` }} />
      </div>
    </div>
  );
}
