import * as React from 'react';

import { Button } from '../Button';

import classes from './DashboardOpenButton.module.scss';

interface Props {
  onClick: () => void;
  isOpen?: boolean;
}

export function DashboardOpenButton({ onClick, isOpen }: Props) {
  return (
    <Button
      classNames={classes}
      leftIconId={isOpen ? 'chevron-up' : 'chevron-down'}
      onClick={onClick}
      tooltip={isOpen ? 'Click to hide Dashboard' : 'Click to show Dashboard'}
      isSmall
    />
  );
}
