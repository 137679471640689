import * as React from 'react';
import clsx from 'clsx';

import { LogoSymbol } from 'src/assets';

import classes from './Loading.module.scss';

interface Props {
  isSmall?: boolean;
  isTiny?: boolean;
  isFull?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export function Loading({ isSmall, isTiny, isFull, className, style }: Props) {
  return (
    <div
      data-testid='loading'
      className={clsx(
        classes.container,
        { [classes.full]: isFull, [classes.small]: isSmall, [classes.tiny]: isTiny },
        className,
      )}
      style={style}
    >
      <LogoSymbol />
    </div>
  );
}
