import { ID } from 'src/models/general.model';
import { NotificationsActions } from 'src/models/query.model';

export interface NotificationsUpdateProps {
  notification_id: ID;
  read: boolean;
}

export function requestNotificationsUpdate(payload: NotificationsUpdateProps) {
  return {
    type: NotificationsActions.UPDATE,
    payload,
  };
}

export function requestNotificationsDelete(notification_id: ID) {
  return {
    type: NotificationsActions.DELETE,
    payload: { notification_id },
  };
}
