import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_arrow-up'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 19V5' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5 12l7-7 7 7' />
    </svg>
  );
}

export default Icon;
