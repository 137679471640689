import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_REQUIRED_DOCUMENTS_STATE } from './requiredDocuments.model';
import { ID, Maybe } from 'src/models/general.model';
import { RequiredDocument } from 'src/models';
import { TemplateContent } from 'src/models/documentTemplate.model';

export const slice = createSlice({
  name: 'requiredDocuments',
  initialState: INITIAL_REQUIRED_DOCUMENTS_STATE,
  reducers: {
    clearRequiredDocumentsState: () => INITIAL_REQUIRED_DOCUMENTS_STATE,
    setRequiredDocuments: (state, action: PayloadAction<Maybe<RequiredDocument[]>>) => {
      state.buildInDocuments = action.payload?.filter(r => r.built_in);
      state.customDocuments = action.payload?.filter(r => !r.built_in);
    },
    updateRequiredDocument: (state, action: PayloadAction<Maybe<RequiredDocument>>) => {
      if (!action.payload) return;

      const field = action.payload.built_in ? 'buildInDocuments' : 'customDocuments';

      state[field] = state[field]?.map(r => (r.id === action.payload?.id ? action.payload : r));
    },
    addRequiredDocument: (state, action: PayloadAction<Maybe<RequiredDocument>>) => {
      if (!action.payload) return;

      if (action.payload.built_in) {
        state.buildInDocuments = state.buildInDocuments && [...state.buildInDocuments, action.payload];
      } else {
        state.customDocuments = state.customDocuments && [...state.customDocuments, action.payload];
      }
    },
    deleteRequiredDocument: (state, action: PayloadAction<ID>) => {
      state.buildInDocuments = state.buildInDocuments?.filter(r => r.id !== action.payload);
      state.customDocuments = state.customDocuments?.filter(r => r.id !== action.payload);
    },
    setTemplateContent: (state, action: PayloadAction<Maybe<TemplateContent>>) => {
      state.templateContent = action.payload;
    },
  },
});

export const {
  clearRequiredDocumentsState,
  setRequiredDocuments,
  updateRequiredDocument,
  addRequiredDocument,
  deleteRequiredDocument,
  setTemplateContent,
} = slice.actions;

export default slice.reducer;
