import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { PredefinedLists, ResourceType } from 'src/models';
import { getPredefinedList } from 'src/store/predefinedLists/predefinedLists.getters';
import { getAppParams } from 'src/store/app/app.getters';
import { requestPredefinedLists } from 'src/store/predefinedLists/predefinedLists.actions';
import { PROJECT_PREDEFINED_LISTS, TRADE_PREDEFINED_LISTS } from 'src/data/predefinedLists.data';
import { removePredefinedList } from 'src/store/predefinedLists/predefinedLists.reducer';

/**
 * hook for using some predefined list on the page that is not part of initial predefined lists
 * @param name - name of the predefined list
 */
export const usePredefinedList = (name?: PredefinedLists) => {
  const dispatch = useDispatch();

  const { subId, tradeId } = useSelector(getAppParams);

  const data = useSelector(getPredefinedList)(name);

  useEffect(() => {
    if (data || !name) return;

    if (PROJECT_PREDEFINED_LISTS.includes(name) && subId) {
      dispatch(
        requestPredefinedLists({
          lists: [name],
          resource_id: subId,
          resource_type: ResourceType.PROJECT,
        }),
      );
    } else if (TRADE_PREDEFINED_LISTS.includes(name) && tradeId) {
      dispatch(
        requestPredefinedLists({
          lists: [name],
          resource_id: tradeId,
          resource_type: ResourceType.TRADE_CONTRACT,
        }),
      );
    }
  }, [dispatch, data, name, subId, tradeId]);

  useEffect(
    () => () => {
      if (name) dispatch(removePredefinedList(name));
    },
    [dispatch, name, subId, tradeId],
  );
};
