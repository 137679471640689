import * as React from 'react';
import clsx from 'clsx';

import { CloseButton } from 'src/components/Button';

import classes from './Badge.module.scss';
import { ifTrue } from 'src/tools/logic.tools';

export interface Props {
  classNames?: {
    container?: string;
    close?: string;
  };
  label: string;
  isSmall?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  dataTestId?: string;
  isHiddenTitle?: boolean;
}

// new-ui
export function Badge({
  classNames = {},
  label,
  isSmall,
  onClose,
  onClick,
  isHiddenTitle,
  dataTestId = 'badge',
}: Props) {
  function handleOnClose(e: React.MouseEvent) {
    e.stopPropagation();
    onClose?.();
  }

  return (
    <div
      data-testid={dataTestId}
      className={clsx(
        classes.container,
        { [classes.small]: isSmall, 'c-pointer': !!onClick },
        classNames.container,
        'row',
      )}
      onClick={onClick}
    >
      <span className='t-ellipsis' title={ifTrue(!isHiddenTitle, label)}>
        {label}
      </span>
      {onClose && (
        <CloseButton
          dataTestId={`${dataTestId}-close`}
          className={clsx('row a-center', classes.close, classNames.close)}
          onClick={handleOnClose}
          isSmall
        />
      )}
    </div>
  );
}
