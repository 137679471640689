import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='72'
      height='72'
      fill='none'
      viewBox='0 0 72 72'
      data-testid='icon_unknown-file'
    >
      <g clipPath='url(#clip0)'>
        <path
          fill='#DDD'
          d='M63.41 72H15.177c-2.246 0-4.088-1.756-4.088-3.93V3.93C11.09 1.756 12.916 0 15.178 0h35.531c.162 0 .307.031.453.109l.113.062.097.109c.161.17 5.687 6.184 8.046 8.763 1.972 2.144 3.927 4.288 5.397 5.888 2.537 2.766 2.537 2.766 2.602 2.906v.015c.032.11.064.218.064.327v49.937C67.497 70.244 65.655 72 63.41 72zM15.177 1.554c-1.358 0-2.472 1.056-2.472 2.377v64.154c0 1.305 1.098 2.377 2.472 2.377h48.248c1.357 0 2.472-1.057 2.472-2.377V18.396a17988.367 17988.367 0 00-15.48-16.842h-35.24z'
        />
        <path
          fill='#F4F3F2'
          d='M66.69 18.148v49.937c0 1.74-1.47 3.154-3.28 3.154H15.18c-1.81 0-3.28-1.414-3.28-3.154V3.931c0-1.74 1.47-3.154 3.28-3.154h35.53c.017 0 .05 0 .066.016l15.899 17.293c0 .031.016.047.016.062z'
        />
        <path
          fill='#DDD'
          d='M66.835 18.1H53.973c-1.761 0-3.2-1.382-3.2-3.076V.637c.259.28 16.062 17.448 16.062 17.464zM58.731 30.298h-38.86V33h38.86v-2.703zM58.731 37.196h-38.86V39.9h38.86v-2.704zM58.731 44.095h-38.86v2.703h38.86v-2.703zM58.731 50.993h-38.86v2.704h38.86v-2.704zM58.731 57.908h-38.86v2.704h38.86v-2.704zM42.358 24.425H4.5V6.666h37.858c.372 0 .663.28.663.637v16.485c0 .357-.291.637-.663.637z'
        />
        <path fill='#BEBEBE' d='M4.5 24.425l6.592 6.836v-6.836H4.5z' />
        <path
          fill='#000'
          stroke='#000'
          strokeWidth='0.05'
          d='M15.01 18.024a13.966 13.966 0 01-.006-.367c0-.48.071-.894.214-1.242.104-.263.273-.528.506-.795.172-.194.478-.477.92-.849.448-.375.738-.674.871-.896.133-.222.2-.464.2-.727 0-.475-.195-.891-.585-1.25-.39-.361-.868-.542-1.434-.542-.547 0-1.004.163-1.37.489-.366.325-.607.835-.72 1.527l-1.32-.149c.118-.928.47-1.639 1.055-2.132.59-.494 1.368-.74 2.333-.74 1.023 0 1.839.264 2.448.794s.913 1.17.913 1.922c0 .434-.107.835-.32 1.202-.215.367-.634.813-1.257 1.338-.418.353-.692.613-.82.78-.129.168-.224.36-.286.578-.062.217-.097.57-.107 1.06h-1.234zm-.078 2.452v-1.392h1.463v1.392h-1.463zM23.116 18.024a13.966 13.966 0 01-.007-.367c0-.48.071-.894.214-1.242.105-.263.274-.528.507-.795.171-.194.478-.477.92-.849.447-.375.738-.674.87-.896.134-.222.2-.464.2-.727 0-.475-.194-.891-.585-1.25-.39-.361-.868-.542-1.434-.542-.547 0-1.003.163-1.37.489-.366.325-.606.835-.72 1.527l-1.32-.149c.119-.928.47-1.639 1.056-2.132.59-.494 1.367-.74 2.333-.74 1.023 0 1.838.264 2.447.794.61.53.914 1.17.914 1.922 0 .434-.107.835-.321 1.202-.215.367-.633.813-1.256 1.338-.419.353-.692.613-.82.78-.13.168-.224.36-.286.578-.062.217-.098.57-.107 1.06h-1.235zm-.078 2.452v-1.392H24.5v1.392h-1.463zM31.218 18.024a13.627 13.627 0 01-.007-.367c0-.48.071-.894.214-1.242.104-.263.273-.528.506-.795.172-.194.479-.477.92-.849.448-.375.738-.674.871-.896.134-.222.2-.464.2-.727 0-.475-.195-.891-.585-1.25-.39-.361-.868-.542-1.434-.542-.547 0-1.004.163-1.37.489-.366.325-.607.835-.72 1.527l-1.32-.149c.118-.928.47-1.639 1.055-2.132.59-.494 1.368-.74 2.334-.74 1.022 0 1.838.264 2.447.794s.913 1.17.913 1.922c0 .434-.107.835-.32 1.202-.215.367-.634.813-1.257 1.338-.418.353-.692.613-.82.78-.129.168-.224.36-.286.578-.061.217-.097.57-.107 1.06h-1.234zm-.079 2.452v-1.392h1.463v1.392H31.14z'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0H63V72H0z' transform='translate(4.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
