import { RootState } from 'src/store';
import { Maybe, Meta } from 'src/models';

export function isAllowedToUpdate(state: RootState, meta: Maybe<Meta>, id?: string) {
  const isLoading = id ? state.loading.customIsLoading[id] : state.loading.isLoading;

  const isUpdating = id ? state.loading.customIsUpdating[id] : state.loading.isUpdating;

  return !isLoading && !isUpdating && typeof meta?.next === 'number';
}

export function makeIsAllowedToUpdate(getMeta: (state: RootState) => Maybe<Meta>, id?: string) {
  return function call(state: RootState) {
    return isAllowedToUpdate(state, getMeta(state), id);
  };
}
