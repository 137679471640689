import {
  Action,
  AutoCompleteResult,
  Config,
  ProcessProps,
  QueryAction,
  QueryDomain,
  Response,
  ResponsePayload,
  Timezone,
} from 'src/models';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import { setServerConfig, setTimezones } from 'src/store/app/app.reducer';
import { deserialize } from 'src/tools/string.tools';
import { getSignUpInviteId } from 'src/store/app/app.getters';

import { AutocompleteEmail } from 'src/models/autocomplete.model';

function generalProcess({ dispatch, getService, getState }: ProcessProps) {
  function processServerConfigRequest() {
    return () =>
      getService().request({ [QueryDomain.SERVER]: QueryAction.CONFIG }, (response: Response<unknown>) => {
        if (response.isOK) dispatch(setServerConfig(response.payload as Config));
      });
  }

  function processAutocompleteRequest(action: Action) {
    return () => {
      dispatch(setCustomIsLoading({ id: action.meta?.id ?? 'autocomplete', state: true }));

      getService().request(
        { [QueryDomain.AUTOCOMPLETE]: QueryAction.GET, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<AutoCompleteResult[]>>(response.payload);

            if (result) action.meta?.callback?.({ isOK: true, payload: result.data });
          } else {
            processError({ activityName: 'Request autocomplete', response, callbackFn: action.meta?.callback });
          }

          dispatch(setCustomIsLoading({ id: action.meta?.id ?? 'autocomplete', state: false }));
        },
      );
    };
  }

  function processPlacesAutocompleteRequest(action: Action) {
    return () => {
      const invite_id = getSignUpInviteId(getState());

      dispatch(setCustomIsLoading({ id: 'placesAutocomplete', state: true }));

      getService().request(
        {
          [QueryDomain.AUTOCOMPLETE]: QueryAction.PLACES,
          payload: { query: action.payload.trim().split(' ').join('+'), invite_id },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<string[]>>(response.payload);

            if (result) action.meta?.callback?.({ isOK: true, payload: result.data });
          } else {
            processError({
              activityName: 'Request places autocomplete',
              callbackFn: action.meta?.callback,
              response,
            });
          }

          dispatch(setCustomIsLoading({ id: 'placesAutocomplete', state: false }));
        },
      );
    };
  }

  function processEmailAutocompleteRequest(action: Action) {
    return () => {
      dispatch(setCustomIsLoading({ id: 'emailAutocomplete', state: true }));

      getService().request(
        {
          [QueryDomain.AUTOCOMPLETE]: QueryAction.EMAIL,
          payload: action.payload,
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<AutocompleteEmail[]>>(response.payload);

            if (result && action.meta?.callback)
              action.meta.callback({ isOK: true, message: action.payload.query, payload: result.data });
          } else {
            processError({
              activityName: 'Request email autocomplete',
              response,
            });
          }

          dispatch(setCustomIsLoading({ id: 'emailAutocomplete', state: false }));
        },
      );
    };
  }

  function processTimezonesRequest(action: Action) {
    return () => {
      dispatch(setCustomIsLoading({ id: 'timezones', state: true }));

      getService().request({ [QueryDomain.TIMEZONES]: QueryAction.GET }, (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Timezone[]>>(response.payload);

          if (result?.data) dispatch(setTimezones(result.data));

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({ activityName: 'Request timezones', response, callbackFn: action?.meta?.callback });
        }

        dispatch(setCustomIsLoading({ id: 'timezones', state: false }));
      });
    };
  }

  return {
    processServerConfigRequest,
    processAutocompleteRequest,
    processPlacesAutocompleteRequest,
    processEmailAutocompleteRequest,
    processTimezonesRequest,
  };
}

export { generalProcess };
