import * as React from 'react';
import clsx from 'clsx';

import { Button } from '../Button';

import classes from './AccordionButton.module.scss';

interface Props {
  label: string;
  className?: string;
  isOpen?: boolean;
  isSmall?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  isSecondary?: boolean;
  style?: React.CSSProperties;
}

// new-ui
export function AccordionButton({ className, isOpen, isSecondary, ...rest }: Props) {
  return (
    <Button
      classNames={{ ...classes, container: clsx(classes.container, className, { [classes.secondary]: isSecondary }) }}
      rightIconId={isOpen ? 'chevron-down' : 'chevron-up'}
      {...rest}
    />
  );
}
