import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_vendor'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M14.666 3.333H1.333A.667.667 0 00.666 4v2.667a2 2 0 001.333 1.88v6.12a.667.667 0 00.667.666h10.667a.667.667 0 00.666-.666v-6.12a2 2 0 001.334-1.88V4a.667.667 0 00-.667-.667zM9.999 4.667h1.334v2a.667.667 0 01-1.334 0v-2zm-2.666 0h1.333v2a.667.667 0 11-1.333 0v-2zm-2.667 0h1.333v2a.667.667 0 01-1.333 0v-2zm-2 2.666a.667.667 0 01-.667-.666v-2h1.334v2a.667.667 0 01-.667.666zM9.333 14H6.666v-1.333a1.333 1.333 0 112.667 0V14zm3.333 0h-2v-1.333a2.666 2.666 0 10-5.333 0V14h-2V8.547c.245-.091.47-.227.666-.4a2 2 0 002.667 0 2 2 0 002.667 0 2 2 0 002.666 0c.196.173.422.309.667.4V14zm1.333-7.333a.667.667 0 11-1.333 0v-2h1.333v2zM2.866 2h10.467a.667.667 0 100-1.333H2.866a.667.667 0 100 1.333z'
      />
    </svg>
  );
}

export default Icon;
