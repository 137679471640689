import { ButtonBaseType, PageTab } from 'src/models';
import { ReqSummaryTabs } from 'src/models/reqSummary.model';

export const REQ_SUMMARY_TABS: PageTab[] = [
  { id: ReqSummaryTabs.SUMMARY, label: 'Summary' },
  { id: ReqSummaryTabs.CONSTRUCTION, label: 'Trades: Construction' },
  { id: ReqSummaryTabs.PRE_CONSTRUCTION, label: 'Trades: Pre-construction' },
  { id: ReqSummaryTabs.GR, label: 'General Requirements' },
  { id: ReqSummaryTabs.UNAWARDED, label: 'Trades: Unawarded' },
  { id: ReqSummaryTabs.GC, label: 'General Conditions' },
  { id: ReqSummaryTabs.OTHER, label: 'Indirect Work' },
];

export const REQ_SUMMARY_ACTIONS_DROPDOWN_ITEMS: ButtonBaseType[] = [
  { id: 'open_submission', label: 'Open submission period' },
  { id: 'lock_and_roll', label: 'Lock & Roll' },
];

export const REQ_SUMMARY_PP_ACTIONS_DROPDOWN_ITEMS: ButtonBaseType[] = [
  { id: 'open_submission', label: 'Setup dates for prior period' },
  { id: 'lock_and_roll', label: 'Lock & Roll' },
];
