import { Place, Effect } from 'react-tooltip';
import { ManagerReqTabs, PictureViewMode } from './models';
import { DrawColor, DrawSize } from './models/pictureControlButton.model';
import { DocumentTemplateTab } from 'src/models/documentTemplate.model';

export const CONFIG = {
  font: {
    primary: 'Be Vietnam, Helvetica Neue, Arial, sans-serif',
  },
  desktop: 1600,
  closeTimeout: 200,
  toastInfoTimeout: 4000,
  toastDefaultTimeout: 6000,
  retainageInitialThresholds: [
    { start: 0, end: 50, percentage: 10, freeze: true },
    { start: 50, end: 100, percentage: 5, freeze: true },
  ],
  trade: {
    companyFields: ['name', 'email', 'website', 'phone_number', 'address'],
  },
  reqSections: ['main_contracts', 'manager_req', 'req_detail', 'req_summary'],
  pictureWidget: {
    tabs: [
      { id: 'tags', label: 'TAGS' },
      { id: 'comments', label: 'COMMENTS' },
    ],
  },
  spreadsheet: {
    groupLabelHeight: 37, // 36 + 1 border-top
    rowHeight: 48,
    headerHeight: 36,
    accordionHeight: 37, // 36 + 1 border-top
  },
  resizableWindow: {
    // we need to get latest window height => fn
    defaultOptions: () => ({
      x: 10,
      y: 10,
      width: 530,
      height: window.innerHeight * 0.9,
    }),
  },
  batches: {
    maxUpload: 2,
  },
  pictures: {
    tagMaxLength: 30,
    tagsMaxCount: 20,
  },
  comments: {
    commentMaxLength: 700,
    pictureCommentMaxLength: 140,
  },
  sidebarPictures: {
    headerHeight: 48,
    footerHeight: 40,
    cardRowHeight: 176,
    rowPadding: 16,
  },
  team: {
    addMembersMax: 50,
  },
  checkboxGroup: {
    maxItemsInGroup: 10,
  },
  projectCOSeparator: ' ',
  categoriesSeparator: '; ',
  validationDebounceDelay: 300,
  maxNotificationsCounter: 99,
  teamMembersPreviewCount: 5,
  maxAvatarSize: 25 * 1024 * 1024,
  maxAvatarResolution: 6000,
  minAvatarResolution: 72,
  minPhoneSize: 4,
  maxPhoneSize: 13,
  resendEmailTimeout: 60 * 1000, // 60 seconds
  defaultPictureMode: PictureViewMode.COMMENT,
  defaultDrawColor: DrawColor.WARNING_DEFAULT,
  defaultDrawSize: DrawSize.SIZE_2,
  maxLineItemTransfersCount: 5,
  changeOrderDisableEditStatuses: ['approved', 'superseded', 'void', 'rejected'],
  managerReqDefaultTab: ManagerReqTabs.GC,
  documentTemplateDefaultTab: DocumentTemplateTab.VIEW,
  maxIconBadgeCount: 9,
  maxTabNotificationsCounter: 999,
  reqPerodsDropdownTooltipTimeout: 3000,
  defaultPillBackgroundColor: 'var(--primary-default)',
};

interface TooltipProps {
  place: Place;
  className: string;
  effect: Effect;
  multiline: boolean;
  textColor: string;
  backgroundColor: string;
}

export const TOOLTIP: TooltipProps = {
  className: 'tooltip',
  place: 'top',
  effect: 'solid',
  multiline: true,
  textColor: 'var(--grey-dark)',
  backgroundColor: 'var(--grey-lighter)',
};
