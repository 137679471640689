import { VersionedPeriod } from 'src/models';
import { Action, ActionMeta, ID } from 'src/models/general.model';
import { GeneralActions, ResourceType } from 'src/models/query.model';

// new-ui
export function requestPlacesAutocomplete(payload: string, meta?: ActionMeta): Action {
  return { type: GeneralActions.GET_PLACES_AUTOCOMPLETE, payload, meta };
}

interface RequestEmailAutocompleteProps {
  resource_id: ID;
  resource_type: ResourceType;
  query: string;
}

export function requestEmailAutocomplete(payload: RequestEmailAutocompleteProps, meta?: ActionMeta): Action {
  return { type: GeneralActions.GET_EMAIL_AUTOCOMPLETE, payload, meta };
}

interface RequestAutocompleteProps {
  domain?: string;
  ids?: string[];
  query: string;
  period?: VersionedPeriod;
  invite_id?: string;
}

export function requestAutocomplete(payload: RequestAutocompleteProps, meta?: ActionMeta): Action {
  return { type: GeneralActions.GET_AUTOCOMPLETE, payload, meta };
}
