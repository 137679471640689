import * as React from 'react';
import clsx from 'clsx';

import { Button, ButtonProps } from '../Button';
import { NotificationsCounter } from '../../NotificationsCounter';

import classes from './TabButton.module.scss';
import { ifTrue } from 'src/tools/logic.tools';
import { CONFIG } from 'src/config';

type Props = Omit<ButtonProps, 'classNames' | 'isSmall'> & {
  isFolder?: boolean;
  notificationCount?: number;
};

// new-ui
export function TabButton({ isFolder, notificationCount, label, ...buttonProps }: Props) {
  function renderNotificationsCouner() {
    return (
      <div className='row centered items-p8'>
        <span>{label ?? ''}</span>
        <NotificationsCounter
          className={classes.counter}
          value={notificationCount ?? 0}
          max={CONFIG.maxTabNotificationsCounter}
        />
      </div>
    );
  }

  return (
    <Button
      classNames={{ ...classes, container: clsx(classes.container, { [classes.folder]: isFolder }) }}
      label={ifTrue(notificationCount, renderNotificationsCouner, label)}
      {...buttonProps}
    />
  );
}
