/* eslint-disable max-lines */
import { pick } from 'ramda';

import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  ChangeOrder,
  AnyValue,
  Totals,
  Maybe,
  Action,
  PageQuery,
  ResponsePayload,
  ChangeOrderParams,
  RequestMeta,
  ShowScenario,
  ChangeOrderHistory,
  AutoCompleteResult,
  ContingencyLineItem,
} from 'src/models';
import { sendInfo } from 'src/store/app/app.actions';
import { DEFAULT_QUERY } from 'src/data';
import { fetchTotals } from 'src/store/change_orders/change_orders.action';
import {
  setChangeOrders,
  setChangeOrderQuery,
  setChangeOrdersMeta,
  addChangeOrders,
  setChangeOrder,
  setChangeOrderTotals,
  setChangeOrderHistory,
  setChangeOrderScenario,
  setShowScenario,
  setChangeOrdersPagePermissions,
  updateChangeOrders,
  setChangeOrderPeriod,
} from 'src/store/change_orders/change_orders.reducer';
import {
  getChangeOrderQuery,
  getChangeOrderMeta,
  getChangeOrders,
} from 'src/store/change_orders/change_orders.getters';
import { processError } from 'src/tools/events.tools';
import { setCustomIsLoading, setIsLoading, setIsUpdating } from 'src/store/loading/loading.reducer';
import { logUnableToProceed } from 'src/tools/log.tools';
import { getCurrentSubprojectId } from 'src/store/app/app.getters';
import { deserialize } from 'src/tools/string.tools';
import { toType } from 'src/tools/type.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';

function processChangeOrders({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  function makeQuery(queryUpdate: Maybe<Partial<PageQuery>> = {}): PageQuery {
    return {
      ...DEFAULT_QUERY,
      ...queryUpdate,
    };
  }

  function makeQueryFromState(queryUpdate: Maybe<Partial<PageQuery>> = {}): PageQuery {
    const query = getChangeOrderQuery(getState()) ?? {};

    return {
      ...DEFAULT_QUERY,
      ...query,
      ...queryUpdate,
    };
  }

  function makeMetaFromState(metaUpdate: Maybe<Partial<RequestMeta>> = {}): RequestMeta {
    const meta = getChangeOrderMeta(getState()) ?? {};

    return pick(['page', 'per_page'], {
      ...meta,
      ...metaUpdate,
    });
  }

  const processRequest = (action: Action<ChangeOrderParams>) => () => {
    const { payload: params = {}, meta } = action;

    const project_id = getCurrentSubprojectId(getState());

    if (!project_id) {
      logUnableToProceed('processChangeOrders::processRequest', { project_id });
      return;
    }

    const isInitial = !getChangeOrders(getState());

    const query = isInitial ? params.query : makeQueryFromState(params.query);

    setIsBusy({ state: true, meta });

    getService().request(
      {
        [QueryDomain.CHANGE_ORDER]: QueryAction.GET,
        payload: {
          project_id,
          query,
          meta: makeMetaFromState(params.meta),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ChangeOrder[]>>(response.payload);

          if (result?.data && result.data.length) dispatch(fetchTotals());

          if (action.meta?.isUpdate && !isInitial) dispatch(updateChangeOrders(result?.data ?? []));
          else {
            dispatch(setChangeOrderScenario(result?.scenario));
            dispatch(setChangeOrders(result?.data ?? []));
            dispatch(setChangeOrderQuery(makeQuery(result?.query)));
            dispatch(setChangeOrdersMeta(result?.meta));
            dispatch(setChangeOrdersPagePermissions(result?.permissions));
            dispatch(setChangeOrderPeriod(result?.period));
          }
        } else {
          processError({ activityName: 'Request change orders', response });
        }

        setIsBusy({ state: false, meta });
      },
    );
  };

  function processMoreRequest() {
    return () => {
      const pagination = getChangeOrderMeta(getState());

      if (typeof pagination?.next !== 'number') {
        logUnableToProceed('processMoreRequest', { pagination });
        return;
      }

      dispatch(setIsUpdating(true));
      getService().request(
        {
          [QueryDomain.CHANGE_ORDER]: QueryAction.GET,
          payload: {
            project_id: getState().app.params.subId,
            query: makeQueryFromState(),
            meta: makeMetaFromState({ page: pagination.next }),
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ChangeOrder[]>>(response.payload);

            dispatch(addChangeOrders(result?.data ?? []));
            dispatch(setChangeOrdersMeta(result?.meta));
          } else {
            processError({
              activityName: 'Request more change orders',
              response,
            });
          }

          dispatch(setIsUpdating(false));
        },
      );
    };
  }

  function processSingleRequest(payload: AnyValue) {
    return () => {
      dispatch(setIsLoading(true));

      getService().request(
        { [QueryDomain.CHANGE_ORDER]: QueryAction.GET_ONE, payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ChangeOrder>>(response.payload);

            dispatch(setChangeOrder(result?.data));
            dispatch(setChangeOrderPeriod(result?.period));
            dispatch(setShowScenario(toType<ShowScenario>(result?.scenario)));
            dispatch(setChangeOrdersPagePermissions(result?.permissions));
          } else {
            processError({
              activityName: 'Request to get single change order',
              response,
            });
          }

          dispatch(setIsLoading(false));
        },
      );
    };
  }

  function processTotalsRequest() {
    return () => {
      dispatch(setCustomIsLoading({ id: 'totals', state: true }));
      getService().request(
        {
          [QueryDomain.CHANGE_ORDER]: QueryAction.GET_TOTALS,
          payload: {
            projectId: getState().app.params.subId,
          },
        },

        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<{ data: Record<string, Totals> | undefined }>(response.payload, {
              data: undefined,
            });

            dispatch(setChangeOrderTotals(result?.data));
          } else {
            processError({
              activityName: 'Request change order totals',
              response,
            });
          }

          dispatch(setCustomIsLoading({ id: 'totals', state: false }));
        },
      );
    };
  }

  function processHistoryRequest(action: Action) {
    return () => {
      dispatch(setCustomIsLoading({ id: 'history', state: true }));
      getService().request(
        {
          [QueryDomain.CHANGE_ORDER]: QueryAction.GET_HISTORY,
          payload: {
            project_id: getState().app.params.subId,
            change_order_id: getState().app.params.coId,
          },
        },

        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ChangeOrderHistory[]>>(response.payload);

            dispatch(setChangeOrderHistory(result?.data));
          } else {
            processError({
              activityName: 'Request change order history',
              response,
            });
          }

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

          dispatch(setCustomIsLoading({ id: 'history', state: false }));
        },
      );
    };
  }

  function processAutocompleteRequest(action: Action) {
    return () => {
      dispatch(setCustomIsLoading({ id: 'autocomplete', state: true }));
      getService().request(
        {
          [QueryDomain.CHANGE_ORDER]: QueryAction.AUTOCOMPLETE,
          payload: {
            params: action.payload,
            index: [getState().app.params.subId],
          },
        },

        (response: Response<string>) => {
          let result;

          if (response.isOK) {
            result = deserialize<ResponsePayload<AutoCompleteResult[]>>(response.payload);
          } else {
            processError({
              activityName: 'Request change order autocomplete',
              response,
            });
          }

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK, payload: result?.data });

          dispatch(setCustomIsLoading({ id: 'autocomplete', state: false }));
        },
      );
    };
  }

  const processAddRequest = (action: Action) => () => {
    dispatch(setIsLoading(true));

    getService().request(
      {
        [QueryDomain.CHANGE_ORDER]: QueryAction.POST,
        payload: {
          project_id: getCurrentSubprojectId(getState()),
          data: action.payload,
        },
      },

      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(sendInfo('New Change Order Created'));
        } else {
          processError({
            activityName: 'Request to create change order',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback(response);

        dispatch(setIsLoading(false));
      },
    );
  };

  function processPatchRequest(action: Action) {
    return () => {
      dispatch(setIsUpdating(true));
      getService().request(
        { [QueryDomain.CHANGE_ORDER]: QueryAction.PATCH, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ChangeOrder>>(response.payload);

            dispatch(setChangeOrderHistory(undefined));
            dispatch(setChangeOrder(result?.data));
            dispatch(sendInfo('Change order has been updated'));

            if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result });
          } else {
            processError({
              activityName: 'Request to update change order',
              response,
              callbackFn: action.meta?.callback,
            });
          }

          dispatch(setIsUpdating(false));
        },
      );
    };
  }

  function processGetContingencyLineItemsList(action: Action) {
    return () => {
      const project_id = getCurrentSubprojectId(getState());

      if (!project_id) {
        logUnableToProceed('processChangeOrders::processRequest', { project_id });
        return;
      }

      getService().request(
        {
          [QueryDomain.CHANGE_ORDER]: QueryAction.GET_CONTINGENCY_LIST,
          payload: {
            project_id,
            trade_contract_id: action.payload.trade_contract_id,
            period: action.payload.period,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ContingencyLineItem[]>>(response.payload);

            if (result) action.meta?.callback?.({ isOK: true, payload: result.data });
          } else {
            processError({ activityName: 'Request Contingency line items list', response });
            action.meta?.callback?.({ isOK: false });
          }
        },
      );
    };
  }

  return {
    processRequest,
    processMoreRequest,
    processSingleRequest,
    processTotalsRequest,
    processAddRequest,
    processPatchRequest,
    processHistoryRequest,
    processAutocompleteRequest,
    processGetContingencyLineItemsList,
  };
}

export { processChangeOrders };
