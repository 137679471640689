import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { RootState } from 'src/store';
import { Maybe, Scenario } from 'src/models';
import { Permission } from 'src/models/permissions.model';
import { useAbilities } from 'src/hooks/useAbilities';
import { getTableScenarioWithPermissions } from 'src/tools/scenarioPermission.tools';

/**
 * hook for patching table scenario with permission
 * @param getScenario - table scenario getter
 * @param getPermissions - page permissions getter
 * @return TableScenario - patched scenario
 */
export const useTableScenarioWithPermissions = (
  getScenario: (state: RootState) => Maybe<Scenario>,
  getPermissions: (state: RootState) => Maybe<Permission[]>,
): Maybe<Scenario> => {
  const scenario = useSelector(getScenario);

  const abilities = useAbilities(getPermissions);

  return useMemo(() => getTableScenarioWithPermissions(scenario, abilities), [scenario, abilities]);
};
