import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { useDebouncedFn } from 'src/hooks';
import { requestAutocomplete } from 'src/store/autocomplete/autocomplete.actions';
import { AutoCompleteDomains, AutoCompleteResult, ID, Result } from 'src/models';
import { getCustomIsLoading } from 'src/store/loading/loading.reducer';
import { DropdownMenu } from 'src/components/Dropdown/DropdownMenu';
import { InputWithIcons } from 'src/components/Inputs';
import { Avatar } from 'src/components/Avatar';
import { P3, P4 } from 'src/components/Typography';
import { getInputDiff } from 'src/tools/string.tools';

import classes from './TagUserInput.module.scss';
import { ALLOWED_SEARCH_REGEX } from 'src/data/users.data';

const domain = AutoCompleteDomains.USERS;

interface Props {
  className?: string;
  placeholder?: string;
  onChange: (fullValue: string) => void;
  onSubmit: () => void;
  onDiscard: () => void;
  maxLength?: number;
  isAutoFocus?: boolean;
  isSmall?: boolean;
  isReset?: boolean;
}

interface Tag {
  startPosition: number;
  endPosition: number;
  value: ID;
}

export function TagUserInput({
  onChange,
  onSubmit,
  maxLength,
  className,
  isAutoFocus,
  placeholder,
  isSmall,
  onDiscard,
  isReset,
}: Props) {
  const [results, setResults] = React.useState<AutoCompleteResult[]>([]);

  const [isOpen, setIsOpen] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const inputElement = inputRef.current;

  const [searchStartPosition, setSearchStartPosition] = React.useState(-1);

  const [tags, setTags] = React.useState<Tag[]>([]);

  const isSearching = searchStartPosition !== -1;

  const [inputValue, setInputValue] = React.useState('');

  const skipResponse = React.useRef(false);

  const dispatch = useDispatch();

  const isLoading = useSelector(getCustomIsLoading)(domain);

  const sendRequest = React.useCallback(
    (query: string) => {
      if (!skipResponse.current) {
        dispatch(requestAutocomplete({ domain, query }, { callback: handleResults, id: domain }));
      }
    },
    [dispatch],
  );

  const request = useDebouncedFn(sendRequest, 600);

  React.useEffect(() => {
    if (!isSearching) {
      setIsOpen(false);
      setResults([]);
      skipResponse.current = true;
    }
  }, [isSearching]);

  React.useEffect(() => {
    if (isReset) {
      setInputValue('');
      setTags([]);
      setSearchStartPosition(-1);
    }
  }, [isReset]);

  const searchStr = getSearchString();

  React.useEffect(() => {
    if (searchStr.length > 1) {
      request(searchStr);
      skipResponse.current = false;
    } else {
      setResults([]);
      skipResponse.current = true;
    }
  }, [request, searchStr]);

  function getSearchString(userInput = inputValue) {
    if (!userInput || searchStartPosition === -1) {
      return '';
    }

    // looking for the end position
    let i = searchStartPosition + 1;

    while (userInput[i] && ALLOWED_SEARCH_REGEX.test(userInput[i])) {
      i += 1;
    }

    return userInput.slice(searchStartPosition + 1, i);
  }

  function getFullValue(value = inputValue, currentTags = tags) {
    if (!value || !currentTags.length) {
      return value;
    }

    const sortedTags = currentTags.sort((a, b) => a.startPosition - b.endPosition);

    let result = '';

    for (let i = 0; i < sortedTags.length; i += 1) {
      const tag = sortedTags[i];

      const prevTagEnd = sortedTags[i - 1]?.endPosition ?? -1;

      result += value.slice(prevTagEnd + 1, tag.startPosition);
      result += `%{${tag.value}}`;

      if (i === sortedTags.length - 1) {
        result += value.slice(tag.endPosition + 1);
      }
    }

    return result;
  }

  function handleInputOnChange(userInput: string) {
    if (!inputElement || inputElement.selectionStart === null) {
      return;
    }

    const diff = getInputDiff(inputValue, userInput, inputElement.selectionStart);

    if (!diff) {
      return;
    }

    const diffStartPosition = diff.position;

    const diffEndPosition = diffStartPosition + diff.size;

    const updatedTags = tags
      .filter(t => {
        return !(
          (diffStartPosition >= t.startPosition && diffStartPosition < t.endPosition) ||
          (diffEndPosition >= t.startPosition && diffEndPosition < t.endPosition) ||
          (diffStartPosition <= t.startPosition && diffEndPosition >= t.endPosition)
        );
      })
      .map(t => {
        const shift = diff.value.length - diff.size;

        if (t.startPosition > diffStartPosition && shift) {
          return {
            ...t,
            startPosition: t.startPosition + shift,
            endPosition: t.endPosition + shift,
          };
        }

        return t;
      });

    setTags(updatedTags);

    if (searchStartPosition !== -1 && diffStartPosition < searchStartPosition) {
      stopSearching();
    }

    setInputValue(userInput);
    onChange(getFullValue(userInput, updatedTags));
  }

  function stopSearching() {
    setSearchStartPosition(-1);
  }

  function handleInputOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        // disable ArrowUp/ArrowDown for input when dropdown is opened
        if (isOpen) e.preventDefault();

        break;
      case 'Enter':
        if (!isOpen) {
          onSubmit();
          setInputValue('');
          setTags([]);
          stopSearching();
        }

        break;
      case 'Escape':
        if (!isOpen) {
          onDiscard();
        }

        break;
      default:
    }
  }

  function handleInputOnKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    const { selectionStart, value } = e.currentTarget;

    const caretPosition = selectionStart ?? -1;

    switch (e.key) {
      case '@': {
        // check for the space before "@" to make sure it's not an email
        if (!isSearching && (value === '@' || value[caretPosition - 2] === ' ')) {
          setSearchStartPosition(caretPosition - 1);
        } else {
          stopSearching();
        }

        break;
      }
      default:
        if (isSearching && e.key.length === 1 && !ALLOWED_SEARCH_REGEX.test(e.key)) {
          stopSearching();
        }
    }

    if (isSearching && (value[searchStartPosition] !== '@' || !caretPosition || caretPosition < searchStartPosition)) {
      stopSearching();
    }
  }

  function handleOnUserSelect(id: ID) {
    const userFullName = results.find(i => i.id === id)?.sub_label;

    if (isSearching && userFullName) {
      const searchString = getSearchString();

      const inputStart = inputValue.slice(0, searchStartPosition);

      const inputEnd = inputValue.slice(searchStartPosition + searchString.length + 1);

      const tag = {
        startPosition: searchStartPosition,
        endPosition: searchStartPosition + userFullName.length,
        value: id,
      };

      const updatedTags = [...tags, tag];

      const updatedInputValue = `${inputStart}@${userFullName}${inputEnd}`;

      setInputValue(updatedInputValue);
      setTags(updatedTags);
      stopSearching();
      onChange(getFullValue(updatedInputValue, updatedTags));

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }

  function handleResults(response: Result) {
    if (skipResponse.current || !response.isOK) {
      return;
    }

    setResults(response.payload as AutoCompleteResult[]);
    setIsOpen(true);
  }

  function renderItemLabel(
    { label: email, sub_label: full_name = '', icon_url: photo_url }: AutoCompleteResult,
    isSelected: boolean,
    isActive: boolean,
  ) {
    return (
      <div
        className={clsx(classes.item, 'row items-1p2 a-center w-100p h-100p', {
          [classes.active]: isActive,
          [classes.selected]: isSelected,
        })}
      >
        <Avatar author={{ photo_url, full_name }} />
        <P3 className={classes.name}>{full_name}</P3>
        <P4 className={classes.name}>{email}</P4>
      </div>
    );
  }

  function renderInput() {
    return (
      <InputWithIcons
        ref={inputRef}
        className='w-100p'
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputOnChange}
        onKeyUp={handleInputOnKeyUp}
        onKeyDown={handleInputOnKeyDown}
        autoFocus={isAutoFocus}
        maxLength={maxLength}
        isLoading={isLoading}
      />
    );
  }

  return (
    <DropdownMenu
      classNames={{
        container: clsx('w-100p', className),
      }}
      isOpen={isOpen}
      isSmall={isSmall}
      items={results}
      onItemClick={handleOnUserSelect}
      onRequestOpenClose={setIsOpen}
      renderBaseComponent={renderInput}
      renderItemLabel={renderItemLabel}
      position='top'
    />
  );
}
