import { Maybe } from 'src/models';
import { Permission } from 'src/models/permissions.model';

export function makeAllowedDomains(permissions: Maybe<Permission[]>) {
  if (!permissions) return [];

  const domains: string[] = [];

  for (const domain of permissions) {
    domains.push(domain.subject);
  }

  return domains;
}
