import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ID, Maybe, Meta, Scenario } from 'src/models';
import { INITIAL_HISTORICAL_UPLOADS_STATE } from './historicalUploads.model';
import {
  HistoricalUploadsError,
  HistoricalUploadsInfo,
  HistoricalUploadsQuery,
  HistoricalUploadsUnsuccessful,
} from 'src/models/historicalUploads.model';

export const slice = createSlice({
  name: 'historicalUploads',
  initialState: INITIAL_HISTORICAL_UPLOADS_STATE,
  reducers: {
    clearHistoricalUploadsState: () => INITIAL_HISTORICAL_UPLOADS_STATE,
    setHistoricalUploadsInfo: (state, action: PayloadAction<Maybe<HistoricalUploadsInfo>>) => {
      state.info = action.payload;
    },
    setHistoricalUploadsUnsuccessfulItems: (state, action: PayloadAction<HistoricalUploadsUnsuccessful[]>) => {
      state.unsuccessfulItems = action.payload;
    },
    setHistoricalUploadsUnsuccessfulItemsMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.unsuccessfulItemsMeta = action.payload;
    },
    setHistoricalUploadsUnsuccessfulItemsQuery: (state, action: PayloadAction<HistoricalUploadsQuery>) => {
      state.unsuccessfulItemsQuery = action.payload;
    },
    setHistoricalUploadsUnsuccessfulItemsScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.unsuccessfulItemsScenario = action.payload;
    },
    setHistoricalUploadsErrorItemsMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.errorItemsMeta = action.payload;
    },
    setHistoricalUploadsErrorItemsQuery: (state, action: PayloadAction<HistoricalUploadsQuery>) => {
      state.errorItemsQuery = action.payload;
    },
    setHistoricalUploadsErrorItemsScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.errorItemsScenario = action.payload;
    },
    addHistoricalUploadsUnsuccessfulItems: (state, action: PayloadAction<HistoricalUploadsUnsuccessful[]>) => {
      if (state.unsuccessfulItems) state.unsuccessfulItems = [...state.unsuccessfulItems, ...action.payload];
    },
    removeHistoricalUploadsUnsuccessfulItems: (state, action: PayloadAction<ID>) => {
      if (state.unsuccessfulItems)
        state.unsuccessfulItems = state.unsuccessfulItems.filter(item => item.id !== action.payload);

      if (state.info) state.info.unsuccessful_records = Math.max(state.info.unsuccessful_records - 1, 0);
    },
    updateHistoricalUploadsUnsuccessfulItem: (state, action: PayloadAction<HistoricalUploadsUnsuccessful>) => {
      if (state.unsuccessfulItems)
        state.unsuccessfulItems = state.unsuccessfulItems.map(item =>
          item.id !== action.payload.id ? item : action.payload,
        );
    },
    setHistoricalUploadsErrors: (state, action: PayloadAction<HistoricalUploadsError[]>) => {
      state.errorItems = action.payload;
    },
    addHistoricalUploadsErrors: (state, action: PayloadAction<HistoricalUploadsError[]>) => {
      if (state.errorItems) state.errorItems = [...state.errorItems, ...action.payload];
    },
    removeHistoricalUploadsErrors: (state, action: PayloadAction<ID>) => {
      if (state.errorItems) state.errorItems = state.errorItems.filter(item => item.id !== action.payload);

      if (state.info) state.info.errors_records = Math.max(state.info.errors_records - 1, 0);
    },
    updateHistoricalUploadsErrors: (state, action: PayloadAction<HistoricalUploadsError>) => {
      if (state.errorItems)
        state.errorItems = state.errorItems.map(item => (item.id !== action.payload.id ? item : action.payload));
    },
  },
});

export const {
  clearHistoricalUploadsState,
  setHistoricalUploadsInfo,
  setHistoricalUploadsUnsuccessfulItems,
  setHistoricalUploadsUnsuccessfulItemsMeta,
  setHistoricalUploadsUnsuccessfulItemsQuery,
  setHistoricalUploadsUnsuccessfulItemsScenario,
  setHistoricalUploadsErrorItemsMeta,
  setHistoricalUploadsErrorItemsQuery,
  setHistoricalUploadsErrorItemsScenario,
  addHistoricalUploadsUnsuccessfulItems,
  setHistoricalUploadsErrors,
  addHistoricalUploadsErrors,
  removeHistoricalUploadsUnsuccessfulItems,
  removeHistoricalUploadsErrors,
  updateHistoricalUploadsUnsuccessfulItem,
  updateHistoricalUploadsErrors,
} = slice.actions;

export default slice.reducer;
