import { CSSProperties, createContext } from 'react';
import { AnyValue, ID, RecordObject } from 'src/models';

export interface RowData extends Record<string, unknown> {
  id: ID; // ids often converted to numbers in parsing, thus ID (string|number) is preferable
  changes?: string[];
  document_search_result?: boolean;
}

export interface SpreadsheetRowType {
  editable?: boolean;
  isSearched?: boolean;
  isSelected?: boolean; // if the row is selected
  positionHasChanged?: boolean;
  row: RowData | RecordObject<AnyValue>; // row data with ID
  select?: (lineId: string) => void; // select row trigger
  showInfoButton?: boolean; // can be removed, moved to scenarios
  small?: boolean; // style adjustment for small tables // remove?
  styles?: CSSProperties;
  isLastRow?: boolean;
  rowKey?: string;
  isTotalsRow?: boolean;
  isOdd?: boolean; // is odd row index (for styling)
}

export const RowContext = createContext({} as SpreadsheetRowType);
