import { Action, ActionMeta, AnyValue, ChangeOrderParams, ID, QueryActions, VersionedPeriod } from 'src/models';

import { ChangeOrderActions } from 'src/models/query.model';

export const requestChangeOrders = (payload: ChangeOrderParams = {}, meta?: ActionMeta): Action => ({
  type: ChangeOrderActions.GET,
  payload,
  meta,
});

export function fetchAdditionalChangeOrders() {
  return { type: QueryActions.GET_CHANGE_ORDERS_MORE };
}

export function requestSingleChangeOrder(project_id: string, change_order_id: string) {
  return { type: QueryActions.GET_SINGLE_CHANGE_ORDER, payload: { project_id, change_order_id } };
}

export const requestChangeOrderCreate = (payload: Record<string, AnyValue>, meta: ActionMeta): Action => ({
  type: ChangeOrderActions.CREATE,
  payload,
  meta,
});

export const requestChangeOrderUpdate = (
  project_id: ID,
  change_order_id: ID,
  data: Record<string, unknown>,
  meta?: ActionMeta,
): Action => ({
  type: ChangeOrderActions.PATCH,
  payload: { project_id, change_order_id, data },
  meta,
});

export const requestChangeOrderHistory = (meta?: ActionMeta): Action => ({
  type: ChangeOrderActions.HISTORY,
  meta,
});

export function fetchTotals(projectId?: string) {
  return { type: QueryActions.CHANGE_ORDERS_TOTALS, payload: { projectId } };
}

export function requestAutocomplete(query: string, type: string, meta?: ActionMeta) {
  return { type: ChangeOrderActions.AUTOCOMPLETE, payload: { type, query }, meta };
}

export function requestContingencyLineItemsList(trade_contract_id: ID, period: VersionedPeriod, meta?: ActionMeta) {
  return {
    type: ChangeOrderActions.CONTINGENCY_LIST,
    payload: { trade_contract_id, period },
    meta,
  };
}
