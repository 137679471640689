import { Maybe } from 'src/models/general.model';
import {
  PageBalance,
  ReqDetailBaseContract,
  ReqDetailChangeOrder,
  ReqDetailPageData,
} from 'src/models/reqDetail.model';
import { Meta, PageQuery, Results, VersionedPeriod } from 'src/models/results.model';
import { History } from 'src/models/history.model';
import { Permission } from 'src/models/permissions.model';
import { Scenario } from 'src/models/scenario.model';
import { Sidebar } from 'src/models';
import { ReqPeriod } from 'src/models/reqPeriod.model';

interface ReqDetailState {
  baseContract?: Maybe<Results<ReqDetailBaseContract[]>>;
  changeOrders?: Maybe<Results<ReqDetailChangeOrder[]>>;
  baseContractPages?: Maybe<number[]>; // numbers of all requested pages, for updating
  changeOrdersPages?: Maybe<number[]>; // numbers of all requested pages, for updating
  currentPeriod?: Maybe<VersionedPeriod>;
  history?: Maybe<History[]>;
  pageBalance?: Maybe<PageBalance>;
  pageData?: Maybe<ReqDetailPageData>;
  pageMeta?: Maybe<Meta>;
  pagePermissions?: Maybe<Permission[]>;
  pageQuery?: Maybe<PageQuery>;
  pageTotals?: Maybe<Record<string, number>>;
  pageTotalsScenario?: Maybe<Scenario>;
  selectedPeriod?: Maybe<VersionedPeriod>;
  sidebar?: Maybe<Sidebar>;
  reqPeriods?: Maybe<ReqPeriod[]>;
  uploadInProgress?: boolean;
}

export const INITIAL_REQ_DETAIL_STATE: ReqDetailState = {};
