import * as React from 'react';
import clsx from 'clsx';

import { ifTrue } from 'src/tools/logic.tools';
import { useId } from 'src/hooks';

import { ICONS } from 'src/icons';
import { UseIcons } from 'src/models/interface.model';
import { TextTooltip } from '../TextTooltip';

import classes from './UseIcon.module.scss';

interface Props {
  wrapElement?: string;
  dataTestId?: string;
  isDisabled?: boolean;
  iconId: UseIcons;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void; // send event, if needed
  onFocus?: (e: React.MouseEvent<HTMLButtonElement>) => void; // send event, if needed
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void; // send event, if needed
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void; // send event, if needed
  title?: string;
  id?: string;
  href?: string;
  target?: string;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom';
  tooltipClassName?: string;
  style?: React.CSSProperties;
  tabIndex?: number;

  children?: React.ReactNode;
}

export function UseIcon({
  wrapElement = 'span',
  iconId,
  dataTestId = `${wrapElement}_${iconId}`,
  children,
  className,
  onClick,
  isDisabled,
  tooltip,
  tooltipPosition,
  tooltipClassName,
  ...props
}: Props) {
  if (wrapElement === 'button' && !onClick)
    throw new Error('UseItem: Set as "button", but no "onClick" function is provided.');

  if (wrapElement === 'a' && !props.href) throw new Error('UseItem: Set as "a", but no "href" field is provided.');

  const Icon = ICONS[iconId];

  const tooltipId = useId();

  if (!Icon) return null;

  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (onClick) {
      e.preventDefault();

      onClick(e);
    }
  }

  function renderTooltip() {
    return <TextTooltip id={tooltipId} place={tooltipPosition} className={tooltipClassName} />;
  }

  return React.createElement(
    wrapElement,
    {
      ...props,
      onClick: handleOnClick,
      className: clsx(
        classes.container,
        'row centered',
        { [classes.button]: wrapElement === 'button' || wrapElement === 'a' },
        className,
      ),
      'data-testid': dataTestId,
      disabled: isDisabled,
      rel: ifTrue(wrapElement === 'a', 'noopener noreferrer'),
      // type='button' is important here, so the button click won't be triggered on Enter press in the form
      type: ifTrue(wrapElement === 'button', 'button'),
    },
    <span className='row centered nowrap' data-tip={tooltip} data-for={tooltipId}>
      <Icon />
      {children}
      {ifTrue(tooltip, renderTooltip)}
    </span>,
  );
}
