/// <reference types="resize-observer-browser" />
import * as React from 'react';
import { useThrottledFn } from './useThrottledFn';

// This hook dynamically checks if the element is not hidden by overflow hidden/scroll
export function useIsIntersecting(ref: React.RefObject<HTMLElement>, forceUpdate?: boolean) {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const [item, setItem] = React.useState<IntersectionObserverEntry>();

  const handleChange = React.useCallback((value: boolean) => setIsIntersecting(value), []);

  const handleChangeThrottled = useThrottledFn(handleChange, 100);

  React.useEffect(() => {
    const { current } = ref;

    if (!current) {
      return;
    }

    const resizeObserver = new IntersectionObserver(entries => {
      const item = entries[0];

      if (item) {
        handleChangeThrottled(item.isIntersecting);
        setItem(item);
      }
    });

    resizeObserver.observe(current);

    return () => resizeObserver.unobserve(current);
  }, [ref, handleChangeThrottled, forceUpdate]);

  return { isIntersecting, item };
}
