import * as React from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import clsx from 'clsx';

import { CardConfirmation, Props as CardConfirmationProps } from 'src/components/CardConfirmation';

import classes from './Confirmation.module.scss';

// new-ui
export function Confirmation(props: CardConfirmationProps) {
  const containerRef = useOnclickOutside(props.onCancel, {
    eventTypes: ['click'],
  });

  return (
    <div className={clsx(classes.container, props.containerClassName ? props.containerClassName : 'column centered')}>
      <div ref={containerRef} className={clsx(classes.confirmation, 'card-item')}>
        <CardConfirmation {...props} />
      </div>
    </div>
  );
}
