import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getToken } from 'src/services/storage.service';
import { productionLog } from 'src/tools/log.tools';
import { ifTrue } from 'src/tools/logic.tools';
import { useUrlQuery } from 'src/hooks';

import { getAppIsLoading, setAppIsLoading, setUrlQuery } from 'src/store/app/app.reducer';
import { checkToken } from 'src/store/app/app.actions';

import { Routes } from 'src/routes/App.routes';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { Loading } from 'src/components/Loading';
import { Headers } from './Headers';

export function App() {
  const dispatch = useDispatch();

  const isLoading = useSelector(getAppIsLoading);

  const urlQuery = useUrlQuery();

  useEffect(() => {
    dispatch(setUrlQuery(urlQuery));
  }, [dispatch, urlQuery]);

  const cancelIsLoading = useCallback(() => {
    dispatch({ type: 'forceLogoutIfNeeded' });
  }, [dispatch]);

  useEffect(() => {
    productionLog('boot middlewares');
    dispatch({ type: 'boot middlewares' });

    let timer: NodeJS.Timeout;

    if (getToken()) {
      dispatch(checkToken());
      setTimeout(cancelIsLoading, 5000);
    } else dispatch(setAppIsLoading(false));

    return () => {
      clearTimeout(timer);
    };
  }, [cancelIsLoading, dispatch]);

  function renderIsLoading() {
    return (
      <div className='full-page'>
        <Loading isFull />
      </div>
    );
  }

  function renderRoutes() {
    return <Routes />;
  }

  return (
    <ErrorBoundary>
      <Headers />
      {ifTrue(isLoading, renderIsLoading, renderRoutes)}
    </ErrorBoundary>
  );
}
