import { path } from 'ramda';

import { CONTENT_TYPE } from 'src/data/api.data';
import { Action, StateStore, SubProject } from 'src/models';
import { ApiEndpoints } from 'src/models/api.model';
import { ApiService } from 'src/services/api.service';
import { sendInfo } from 'src/store/app/app.actions';
import { setIsLoading } from 'src/store/loading/loading.reducer';
import { SubProjectCreateRequest } from 'src/store/subProject/subProject.actions';
import { addSubProject } from 'src/store/subProject/subProject.reducer';
import { processErrorEvent } from 'src/tools/error.tools';

export async function processSubProjectCreateRequest(
  { dispatch }: StateStore,
  action: Action<SubProjectCreateRequest>,
) {
  if (!action.payload) return;

  dispatch(setIsLoading(true));

  const { data, main_project_id } = action.payload;

  const response = await ApiService.instance().post<SubProject>({
    endpoint: ApiEndpoints.PROJECTS,
    headers: CONTENT_TYPE.FormData,
    params: { main_project_id },
    data,
  });

  if (response.isOK) {
    dispatch(addSubProject(response.payload.data));
    dispatch(sendInfo('New Subproject Created'));

    if (action.meta?.callback) action.meta.callback({ isOK: true, payload: response.payload.data });
  } else {
    processErrorEvent({ activityName: 'processSubProjectCreateRequest', err: response.payload });

    if (action.meta?.callback) action.meta.callback({ isOK: false, message: path(['message'], response.payload) });
  }

  dispatch(setIsLoading(false));
}
