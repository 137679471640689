import { processError } from 'src/tools/events.tools';

import { ProcessProps, QueryDomain, QueryAction, Response, AnyValue, ResponsePayload, Action } from 'src/models';
import { Comment } from 'src/models/comment.model';
import { setComments, addCommentToThread, updateComments } from 'src/store/attachments/attachments.reducer';
import { setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { RequestAttachmentsProps } from 'src/store/attachments/attachments.action';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';

function processComments({ dispatch, getService }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processCommentsRequest =
    ({ payload, meta }: Action<RequestAttachmentsProps>) =>
    () => {
      setIsBusy({ id: 'comments', state: true, meta });

      getService().request({ [QueryDomain.COMMENTS]: QueryAction.GET, payload }, (response: Response<string>) => {
        if (response.isOK) {
          const data = deserialize<ResponsePayload<Comment[]>>(response.payload);

          if (data) {
            const reversedCommentsData = [...data.data].reverse();

            dispatch(setComments(reversedCommentsData));
          }
        } else {
          processError({ activityName: 'Request to get comments', response });
        }

        setIsBusy({ id: 'comments', state: false, meta });
      });
    };

  const processCommentsPost = (payload: AnyValue) => () => {
    dispatch(setCustomIsUpdating({ id: 'comments', state: true }));

    getService().request(
      {
        [QueryDomain.COMMENTS]: QueryAction.POST,
        payload,
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Comment>>(response.payload);

          if (result) {
            if (payload.comment_thread_id)
              dispatch(addCommentToThread({ id: payload.comment_thread_id, data: result.data }));
            else dispatch(updateComments(result.data));
          }
        } else {
          processError({ activityName: 'Request to post comment', response });
        }

        dispatch(setCustomIsUpdating({ id: 'comments', state: false }));
      },
    );
  };

  return { processCommentsRequest, processCommentsPost };
}

export { processComments };
