import * as React from 'react';
import { useRef, forwardRef } from 'react';
import clsx from 'clsx';

import { Maybe, UseIcons } from 'src/models';
import { InputNumber } from '../Number';
import { CloseButton } from 'src/components/Button';
import { IconsSwitch } from 'src/components/IconsSwitch';

import classes from './SpreadsheetInput.module.scss';

interface Props {
  onChange: (value: Maybe<number>) => void;
  onToggle: () => void;
  onSubmit: () => void;
  onClear?: () => void;
  value: Maybe<number>;
  autoFocus?: boolean;
  isCurrency?: boolean;
  className?: string;
  isLeft?: boolean;
  leftIconId?: UseIcons;
  rightIconId?: UseIcons;
  rightIconString?: string;
  leftIconString?: string;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom';
}

// new-ui
export const SpreadsheetInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      onToggle,
      onSubmit,
      onClear,
      isLeft,
      leftIconId,
      rightIconId,
      rightIconString,
      leftIconString,
      tooltip,
      tooltipPosition,
      ...inputProps
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    function handleOnClear() {
      if (onClear) onClear();
      else inputProps.onChange(0);

      if (inputRef.current) inputRef.current.focus();
    }

    return (
      <div ref={ref} className={clsx(classes.container, 'row', className)}>
        <div className={classes['input-wrap']}>
          <InputNumber ref={inputRef} {...inputProps} onSubmit={onSubmit} numeralDecimalScale={2} isSelectAllOnFocus />
          <CloseButton className={classes.close} onClick={handleOnClear} isSmall />
        </div>
        <IconsSwitch
          className={classes['switch-wrap']}
          leftIconId={leftIconId}
          rightIconId={rightIconId}
          rightIconString={rightIconString}
          leftIconString={leftIconString}
          isLeft={isLeft}
          onChange={onToggle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
        />
      </div>
    );
  },
);
