import { processError } from 'src/tools/events.tools';
import { deserialize } from 'src/tools/string.tools';
import { Action, Maybe, ProcessProps, Response } from 'src/models/general.model';
import { QueryAction, QueryDomain } from 'src/models/query.model';
import { Results } from 'src/models/results.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { updateManagerReqHistory } from 'src/store/managerReq/managerReq.reducer';
import { updateReqDetailHistory } from 'src/store/reqDetail/reqDetail.reducer';
import { History } from 'src/models/history.model';
import { requestReqDetailPageData } from 'src/store/reqDetail/reqDetail.actions';
import { requestManagerReq } from 'src/store/managerReq/managerReq.actions';
import { getReqDetailSelectedPeriod } from 'src/store/reqDetail/reqDetail.getters';
import { getManagerReqSelectedPeriod } from 'src/store/managerReq/managerReq.getters';

function processBillingVersion({ dispatch, getService, getState }: ProcessProps) {
  const processBillingVersionUpdateRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'billingVersion', state: true }));

    getService().request(
      { [QueryDomain.BILLING_VERSION]: QueryAction.PATCH, payload: action.payload },
      (response: Response<string>) => {
        dispatch(setCustomIsLoading({ id: 'billingVersion', state: false }));

        if (response.isOK) {
          const result = deserialize<Maybe<Results<History>>>(response.payload, undefined);

          if (result?.data) {
            switch (action.payload.resource_type) {
              case 'TradeContract':
                dispatch(updateReqDetailHistory(result.data));
                break;
              case 'MainContract':
                dispatch(updateManagerReqHistory(result.data));
                break;
            }
          }

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request to update billing version',
            callbackFn: action.meta?.callback,
            response,
          });
        }
      },
    );
  };

  const processBillingVersionMakeCurrentRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'billingVersion', state: true }));

    getService().request(
      { [QueryDomain.BILLING_VERSION]: QueryAction.POST, payload: action.payload },
      (response: Response<string>) => {
        dispatch(setCustomIsLoading({ id: 'billingVersion', state: false }));

        if (response.isOK) {
          const result = deserialize<Maybe<Results<History>>>(response.payload, undefined);

          if (result?.data.version) {
            switch (action.payload.resource_type) {
              case 'TradeContract': {
                const selectedPeriod = getReqDetailSelectedPeriod(getState());

                const period = selectedPeriod && { ...selectedPeriod, version: result?.data.version };

                dispatch(requestReqDetailPageData({ period }, { isValid: true }));
                break;
              }
              case 'MainContract': {
                const selectedPeriod = getManagerReqSelectedPeriod(getState());

                const period = selectedPeriod && { ...selectedPeriod, version: result?.data.version };

                dispatch(requestManagerReq({ period }, { isValid: true }));
                break;
              }
            }
          }

          if (action.meta?.callback) action.meta.callback({ isOK: true });
        } else {
          processError({
            activityName: 'Request to make current billing version',
            callbackFn: action.meta?.callback,
            response,
          });
        }
      },
    );
  };

  return { processBillingVersionUpdateRequest, processBillingVersionMakeCurrentRequest };
}

export { processBillingVersion };
