import { useRef, useEffect, useCallback } from 'react';

import { AnyValue } from 'src/models';

export function useThrottledFn(fn: (...arg: AnyValue[]) => void, delay = 1000, callLast = true) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const waitingRef = useRef(false);

  const lastCall = useRef(false);

  const lastCallArg = useRef<AnyValue[] | undefined>(undefined);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    },
    [],
  );

  return useCallback(
    (...arg: AnyValue[]) => {
      if (!waitingRef.current) {
        fn(...arg);

        waitingRef.current = true;

        timeoutRef.current = setTimeout(() => {
          waitingRef.current = false;

          if (lastCall.current) {
            if (lastCallArg.current) {
              fn(...lastCallArg.current);
            } else {
              fn();
            }

            lastCall.current = false;
            lastCallArg.current = undefined;
          }
        }, delay);
      } else {
        lastCall.current = callLast;
        lastCallArg.current = arg;
      }
    },
    [fn, delay, waitingRef, timeoutRef, callLast],
  );
}
