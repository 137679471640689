import { useRef, useEffect, useContext } from 'react';
import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';

import { InputContext } from 'src/contexts/input.context';
import { UseIcon } from 'src/components/UseIcon';
import { InputNumber, InputNumberProps } from '../Number';
import { CloseButton } from 'src/components/Button';
import { ifTrue } from 'src/tools/logic.tools';
import { LoadingInline } from 'src/components/LoadingInline';
import { UseIcons } from 'src/models';

import classes from './InlineInput.module.scss';

interface Props extends Omit<InputNumberProps, 'isSmall' | 'isXSmall'> {
  onSubmit: () => void;
  onDiscard: () => void;
  isLoading?: boolean;
  iconId?: UseIcons;
}

// new-ui
export function InlineNumberInput({ className, onClick, isLoading, iconId = 'check', ...inputProps }: Props) {
  const { storedValue } = useContext(InputContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const ref = useOnclickOutside(handleClickOudside, {
    disabled: inputProps.isReadonly,
  });

  function handleClickOudside() {
    if (storedValue === inputProps.value) inputProps.onDiscard();
    else inputProps.onSubmit();
  }

  useEffect(() => {
    if (!inputProps.isDisabled && inputProps.autoFocus) inputRef.current?.focus();
  }, [inputProps.autoFocus, inputProps.isDisabled]);

  function handleSubmitButtonOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    inputProps.onSubmit();
  }

  function handleDiscardButtonOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    inputProps.onDiscard();
  }

  function renderLoading() {
    return (
      <div className={classes.loading}>
        <LoadingInline />
      </div>
    );
  }

  function renderButtons() {
    return (
      <>
        <UseIcon wrapElement='button' className={classes.submit} onClick={handleSubmitButtonOnClick} iconId={iconId} />
        <CloseButton className={classes.close} onClick={handleDiscardButtonOnClick} isSmall />
      </>
    );
  }

  return (
    <div
      ref={ref}
      data-testid='inline-number-input'
      className={clsx(classes.container, 'row a-center', className, {
        disabled: inputProps.isDisabled || inputProps.isReadonly,
        [classes['with-loading']]: isLoading,
      })}
      onClick={onClick}
    >
      <InputNumber ref={inputRef} {...inputProps} />
      {ifTrue(isLoading, renderLoading)}
      {ifTrue(!inputProps.isDisabled && !inputProps.isReadonly, renderButtons)}
    </div>
  );
}
