import clsx from 'clsx';
import * as React from 'react';

import styles from './ContentSlider.module.scss';
import { CONFIG } from 'src/config';

interface Props {
  children?: React.ReactNode;
  show: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export function ContentSlider({ children, show, onMouseEnter, onMouseLeave }: Props) {
  const [visible, setVisible] = React.useState(show);

  const [animation, setAnimation] = React.useState('slideInDown');

  const divEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (show && !visible) {
      setVisible(true);
      setAnimation('slideInDown');
    } else if (visible && !show) {
      const timeoutId = setTimeout(() => {
        setAnimation('slideOutUp');

        if (divEl.current) {
          divEl.current.addEventListener('animationend', () => setVisible(false));
        }
      }, CONFIG.closeTimeout);

      return () => clearTimeout(timeoutId);
    }
  }, [show, visible]);

  return visible ? (
    <div
      ref={divEl}
      className={clsx(styles.container, 'animate__animated', `animate__${animation}`)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  ) : null;
}
