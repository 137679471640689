import { Action, ID, PictureDrawing, ProcessProps, QueryAction, QueryDomain, Response } from 'src/models';
import { setPicturesDrawings } from 'src/store/attachments/attachments.reducer';
import { logError } from 'src/tools/log.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { deserialize } from 'src/tools/string.tools';

function processDrawings({ dispatch, getService }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, { id: 'pictureDrawings', ...options });

  const processGetDrawingsRequest = (picture_id: ID) => () => {
    setIsBusy({ state: true });
    getService().request(
      { [QueryDomain.PICTURES]: QueryAction.DRAWING, payload: { picture_id } },
      (response: Response<string>) => {
        if (response.isOK) {
          const content = deserialize<string>(response.payload);

          if (content) {
            dispatch(setPicturesDrawings({ picture_id, content }));
          }
        } else {
          logError('getDrawings query caught error.', response.message);
          dispatch(setPicturesDrawings({ picture_id: '', content: '' }));
        }

        setIsBusy({ state: false });
      },
    );
  };

  const processPostDrawingsRequest = (action: Action<PictureDrawing>) => () => {
    setIsBusy({ state: true });
    getService().request(
      { [QueryDomain.PICTURES]: QueryAction.CREATE_DRAWING, payload: action.payload },
      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(setPicturesDrawings(action.payload));
        } else {
          logError('getDrawings query caught error.', response.message);
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        setIsBusy({ state: false });
      },
    );
  };

  return { processGetDrawingsRequest, processPostDrawingsRequest };
}

export { processDrawings };
