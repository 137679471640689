import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { path } from 'ramda';

import { Maybe, RecordObject, StateStore } from 'src/models';
import { ApiMethods, ApiRequest, ApiResponse, ApiResultFalse, ApiResultTrue } from 'src/models/api.model';
import { failure, success } from 'src/tools/api.tools';
import { logAlpha } from 'src/tools/log.tools';
import { makeStringFromTemplate } from 'src/tools/string.tools';
import { getToken } from './storage.service';

let instance: Maybe<ApiService> = undefined;

export class ApiService {
  #token = getToken();

  #state: Maybe<StateStore> = undefined;

  static instance(state?: Maybe<StateStore>) {
    if (!instance) instance = new ApiService(state);

    return instance;
  }

  static apiUrl(): string {
    const baseUrl = window.__ENVIRONMENT__.REACT_APP_API_BASE_URL;

    const isSsl = baseUrl && !baseUrl.includes('localhost');

    return `${isSsl ? 'https' : 'http'}://${baseUrl}` || '';
  }

  constructor(state?: Maybe<StateStore>) {
    if (!this.#state && state) this.#state = state;
  }

  updateToken(token?: Maybe<string>): void {
    if (this.#token !== token) this.#token = token ?? '';
  }

  #makeHeaders(headers: Maybe<RecordObject<unknown>>): RecordObject<unknown> {
    return { Authorization: this.#token, ...headers };
  }

  #makeRequest<Payload>(config: AxiosRequestConfig): AxiosPromise<Payload> {
    return axios(config) as AxiosPromise<Payload>;
  }

  async get<Payload = unknown>({
    endpoint,
    headers,
    indexes,
  }: ApiRequest): Promise<ApiResultTrue<Payload> | ApiResultFalse> {
    try {
      const response = await this.#makeRequest<ApiResponse<Payload>>({
        method: ApiMethods.GET,
        url: ApiService.apiUrl() + makeStringFromTemplate(endpoint, indexes ?? []),
        headers: this.#makeHeaders(headers),
      });

      return success<Payload>(response.data);
    } catch (err) {
      logAlpha('ApiService::get > ', path(['message'], err) ?? 'unknown error');
      return failure(err);
    }
  }

  async post<Payload = unknown>({
    endpoint,
    headers,
    indexes,
    params,
    data,
  }: ApiRequest): Promise<ApiResultTrue<Payload> | ApiResultFalse> {
    try {
      const response = await this.#makeRequest<ApiResponse<Payload>>({
        method: ApiMethods.POST,
        url: ApiService.apiUrl() + makeStringFromTemplate(endpoint, indexes ?? []),
        headers: this.#makeHeaders(headers),
        params,
        data,
      });

      return success<Payload>(response.data);
    } catch (err) {
      logAlpha('ApiService::get > ', path(['message'], err) ?? 'unknown error');
      return failure(err);
    }
  }
}
