import { UserProfile, Invitation, Maybe, User, ShowScenario, Scenario, UserAdmin, LoginData } from 'src/models';
import { NotificationMessage } from 'src/models/notification.model';

export interface UserState {
  current?: Maybe<User>;
  profile?: Maybe<UserProfile>;
  profileScenario?: Maybe<ShowScenario>;
  notifications?: NotificationMessage[];
  invitation?: Maybe<Invitation>;
  lastTimeCodeSent?: Maybe<Date>;
  loginData?: Maybe<LoginData>;
  token?: Maybe<string>;
  userAdmin?: Maybe<UserAdmin[]>;
  userAdminScenario?: Maybe<Scenario>;
}

export const INITIAL_USER_STATE: UserState = {};
