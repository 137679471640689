import { PredefinedLists, PredefinedStateMachines, ResourceType } from 'src/models/query.model';
import { AppActions, ID } from 'src/models';
import { ifTrue } from 'src/tools/logic.tools';

interface RequestPredefinedListsProps {
  lists: (PredefinedLists | PredefinedStateMachines)[];
  resource_id?: ID;
  resource_type?: ResourceType;
}

export const requestPredefinedLists = (payload?: RequestPredefinedListsProps) => ({
  type: AppActions.PREDEFINED_LISTS,
  payload: {
    ...payload,
    lists: ifTrue(payload?.lists, payload?.lists?.join(','), 'initial'),
  },
});
