import React from 'react';

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 404.04 87.74' data-testid='icon_logo'>
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.03'
          x2='70.21'
          y1='277.12'
          y2='277.12'
          gradientTransform='matrix(1 0 0 -1 0 329.77)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#0021db'></stop>
          <stop offset='1' stopColor='#008dff'></stop>
        </linearGradient>
        <linearGradient
          id='linear-gradient-2'
          x1='6.37'
          x2='59.02'
          y1='283.65'
          y2='314.05'
          gradientTransform='matrix(1 0 0 -1 0 329.77)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#ff7b32'></stop>
          <stop offset='1' stopColor='#ffc456'></stop>
        </linearGradient>
        <linearGradient
          id='linear-gradient-3'
          x1='11.18'
          x2='63.83'
          y1='257.76'
          y2='288.16'
          gradientTransform='matrix(1 0 0 -1 0 329.77)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#0550ff'></stop>
          <stop offset='1' stopColor='#12c6ff'></stop>
        </linearGradient>
      </defs>
      <path
        fill='url(#linear-gradient)'
        fillRule='evenodd'
        strokeWidth='0'
        d='M0.03 52.65L35.12 87.74 70.21 52.65 35.12 17.56 0.03 52.65z'
      ></path>
      <path
        fill='#00ff82'
        fillRule='evenodd'
        strokeWidth='0'
        d='M52.64 35.08L35.12 52.64 26.34 43.86 17.56 52.64 35.12 70.15 61.42 43.86 52.64 35.08z'
      ></path>
      <path
        fill='url(#linear-gradient-2)'
        fillRule='evenodd'
        strokeWidth='0'
        d='M35.12 0L35.12 0 0 35.08 8.78 43.86 35.12 17.56 61.42 43.86 70.2 35.08 35.12 0z'
      ></path>
      <path
        fill='url(#linear-gradient-3)'
        fillRule='evenodd'
        strokeWidth='0'
        d='M35.12 87.72L35.12 87.72 70.2 52.64 61.42 43.86 35.12 70.15 8.78 43.86 0 52.64 35.12 87.72z'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        strokeWidth='0'
        d='M292.54 31.06h-10.66c-3.1 0-4.98 1.31-6.25 2.45-.74.74-1.31 1.49-1.83 2.45l-.13.22h-.92l-1.83-4.41h-7.34v49.01h10.13V68.29h18.78c5.59 0 9.83-4.24 9.83-9.83v-17.6c.09-5.55-4.15-9.79-9.78-9.79zM130.7 50.76c1.84-.35 3.41-.96 4.76-1.79 3.19-1.92 4.76-4.98 4.76-9.17v-8.82c0-7.08-4.59-11.66-11.66-11.66H91.95v49.01h10.88V51.47h14.2l13.15 16.86h13.41L130 50.9l.7-.13zm-1.31-13.85c0 3.41-1.79 5.2-5.2 5.2h-21.36V28.79h21.36c3.41 0 5.2 1.79 5.2 5.2v2.93zm33.59 11.27h24.2v-9.04h-24.2v-10.4h32.28v-9.39H152.1v49.01h43.16v-9.39h-32.28V48.18zM336.92 31.8h-23.41v7.56h18.65c3.19 0 4.45 1.31 4.45 4.46v2.62h-16.55c-5.59 0-9.83 4.24-9.83 9.83v2.93c0 5.59 4.24 9.83 9.83 9.83h8.43c2.53 0 4.59-.79 6.2-2.45.7-.61 1.27-1.4 1.83-2.45l.13-.22h.92l1.83 4.41h7.34V41.59c.04-5.55-4.19-9.79-9.83-9.79zm45.08 0l-8.43 24.94h-1.35L362.3 31.8h-11.62l15.99 35.78v.92l-.04.09c-1 2.97-2.75 3.58-4.06 3.58h-6.16v8.69h8.74c6.68 0 10.7-3.19 13.54-10.75l14.59-38.31h-11.27zM241.21 18.61h-26.43c-7.08 0-11.66 4.59-11.66 11.66V57.4c0 7.08 4.59 11.66 11.66 11.66h7.43l9.17 11.75h11.31l-9.17-11.75h7.64c7.08 0 11.66-4.59 11.66-11.66V30.27c.04-7.08-4.54-11.66-11.62-11.66zm.79 35.86c0 3.41-1.79 5.2-5.2 5.2h-17.6c-3.41 0-5.2-1.79-5.2-5.2V33.2c0-3.41 1.79-5.2 5.2-5.2h17.6c3.41 0 5.2 1.79 5.2 5.2v21.27zm94.66.75c0 4.11-2.18 6.29-6.29 6.29h-5.85c-2.66 0-4.11-1.44-4.11-4.11v-.35c0-2.66 1.44-4.11 4.11-4.11h12.19v2.27h-.04zm-44.42 0c0 3.19-1.31 4.46-4.46 4.46h-14.02V46.05c0-4.11 2.18-6.29 6.29-6.29h7.69c3.19 0 4.46 1.31 4.46 4.46v11.01h.04z'
      ></path>
      <path
        fill='#041e42'
        fillRule='evenodd'
        strokeWidth='0'
        d='M398.65 19.66c2.39 0 4.33 1.94 4.33 4.33s-1.94 4.33-4.33 4.33-4.33-1.94-4.33-4.33 1.94-4.33 4.33-4.33zm0-1.05c2.97 0 5.38 2.41 5.38 5.38s-2.41 5.38-5.38 5.38-5.38-2.41-5.38-5.38 2.41-5.38 5.38-5.38zm.77 5.73h.03c.53-.1 1.13-.38 1.34-.92.24-.61.14-1.31-.31-1.79-.47-.5-1.2-.49-1.85-.49-.15 0-2.23-.01-2.33 0v5.71h1.15v-2.34c.85-.02 1.1.11 1.56.81.18.28.47.84.63 1.15.07.13.13.25.2.38h1.42c-.02-.06-.79-1.36-.8-1.38-.29-.46-.52-.92-1.06-1.11l.03-.02zm-.22-.83l-.37.08c-.07 0-.24.01-.33.02h-1.06v-1.56h1.05c.32 0 .64.04.87.17.28.16.34.41.32.72 0 .12-.09.31-.16.37-.04.04-.1.08-.15.11-.15.08-.1.04-.17.08z'
      ></path>
    </svg>
  );
}

export default Icon;
