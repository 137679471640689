import { RootState } from '..';
import { makeIsAllowedToUpdate } from 'src/tools/selectors.tools';

export const getHistoricalUploadsInfo = (state: RootState) => state.historicalUploads.info;

export const getHistoricalUploadsUnsuccessfulItems = (state: RootState) => state.historicalUploads.unsuccessfulItems;

export const getHistoricalUploadsUnsuccessfulItemsMeta = (state: RootState) =>
  state.historicalUploads.unsuccessfulItemsMeta;

export const getHistoricalUploadsUnsuccessfulItemsQuery = (state: RootState) =>
  state.historicalUploads.unsuccessfulItemsQuery;

export const getUnsuccessfulItemsAllowedToUpdate = makeIsAllowedToUpdate(getHistoricalUploadsUnsuccessfulItemsMeta);

export const getUnsuccessfulItemsScenario = (state: RootState) => state.historicalUploads.unsuccessfulItemsScenario;

export const getHistoricalUploadsErrorItems = (state: RootState) => state.historicalUploads.errorItems;

export const getHistoricalUploadsErrorItemsMeta = (state: RootState) => state.historicalUploads.errorItemsMeta;

export const getHistoricalUploadsErrorItemsQuery = (state: RootState) => state.historicalUploads.errorItemsQuery;

export const getErrorsItemsAllowedToUpdate = makeIsAllowedToUpdate(getHistoricalUploadsErrorItemsMeta);

export const getErrorsItemsScenario = (state: RootState) => state.historicalUploads.errorItemsScenario;
