import { RootState } from 'src/store';
import { makeIsAllowedToUpdate } from 'src/tools/selectors.tools';

export const getShowTotals = (state: RootState) => state.changeOrders.showTotals;

export const getChangeOrders = (state: RootState) => state.changeOrders.orders;

export const getChangeOrdersTotals = (state: RootState) => state.changeOrders.totals;

export const getShowChangeOrder = (state: RootState) => state.changeOrders.order;

export const getChangeOrderQuery = (state: RootState) => state.changeOrders.query;

export const getChangeOrderMeta = (state: RootState) => state.changeOrders.meta;

export const getChangeOrdersAllowedToUpdate = makeIsAllowedToUpdate(getChangeOrderMeta);

export const getChangeOrdersScenario = (state: RootState) => state.changeOrders.scenario;

export const getChangeOrderShowScenario = (state: RootState) => state.changeOrders.showScenario;

export const getChangeOrderHistory = (state: RootState) => state.changeOrders.history;

export const getChangeOrderPagePermissions = (state: RootState) => state.changeOrders.pagePermissions;

export const getChangeOrderCurrentPeriod = (state: RootState) => state.changeOrders.currentPeriod;

export const getTradeContractId = (state: RootState) => state.changeOrders.order?.trade_contract_id;
