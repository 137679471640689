import clsx from 'clsx';

import { ButtonBaseType, ID } from 'src/models/general.model';

import { BaseComponentProps } from '../Dropdown';
import { DropdownMenu } from '../DropdownMenu';
import { UseIcon } from 'src/components/UseIcon';

import classes from './MoreButtonDropdown.module.scss';

interface Props {
  className?: string;
  items?: ButtonBaseType[];
  isDisabled?: boolean;
  onItemClick: (id: ID, report?: string, context?: string) => void;
}

export function MoreButtonDropdown({ className, items, onItemClick, isDisabled }: Props) {
  function renderDropdownButton({ onClick }: BaseComponentProps) {
    return (
      <UseIcon
        isDisabled={isDisabled}
        iconId='more-vertical'
        className={clsx(classes.button, className)}
        onClick={onClick}
        wrapElement='button'
      />
    );
  }

  return (
    <DropdownMenu
      isSmall
      isDisabled={isDisabled}
      classNames={{ dropdown: classes.dropdown }}
      renderBaseComponent={renderDropdownButton}
      items={items}
      onItemClick={onItemClick}
      position='bottom-right'
    />
  );
}
