import { useState } from 'react';

import { ButtonBaseType, ID } from 'src/models';
import { Menu } from 'src/components/Menu';
import { Dropdown, Props as DropdownProps } from '../Dropdown';

interface Props extends Omit<DropdownProps, 'children'> {
  isSmall?: boolean;
  isHideSplits?: boolean;
  currentItemId?: ID;
  items?: ButtonBaseType[];
  onItemClick?: (id: ID, report?: string, context?: string) => void;
  renderItemLabel?: (item: ButtonBaseType, isSelected: boolean, isActive: boolean) => string | JSX.Element;
}

export function DropdownMenu({
  currentItemId,
  isSmall,
  isHideSplits,
  items = [],
  onItemClick,
  renderItemLabel,
  isOpen: isOpenProp,
  onRequestOpenClose,
  ...dropdownProps
}: Props) {
  if (typeof isOpenProp !== 'undefined' && !onRequestOpenClose) {
    throw new Error('Dropdown: isOpen prop be defined together with onRequestOpenClose prop');
  }

  const [isOpenState, setIsOpenState] = useState(false);

  const isOpen = typeof isOpenProp !== 'undefined' ? isOpenProp : isOpenState;

  function handleOnRequestOpenClose(state: boolean) {
    if (onRequestOpenClose) onRequestOpenClose(state);

    setIsOpenState(state);
  }

  function handleClose() {
    handleOnRequestOpenClose(false);
  }

  function handleItemOnClick(id: ID, report?: string, context?: string) {
    handleClose();

    if (onItemClick) {
      onItemClick(id, report, context);
    }
  }

  return (
    <Dropdown {...dropdownProps} isOpen={isOpen} onRequestOpenClose={handleOnRequestOpenClose}>
      <Menu
        items={items}
        currentItemId={currentItemId}
        renderItemLabel={renderItemLabel}
        onItemClick={handleItemOnClick}
        onEnterPressed={handleClose}
        isSmall={isSmall}
        isHideSplits={isHideSplits}
      />
    </Dropdown>
  );
}
