import { clearContractTermsState } from '../contract_terms/contract_terms.reducer';
import { clearFilesState } from '../files/files.reducer';
import { clearManagerReqState } from '../managerReq/managerReq.reducer';
import { clearReqDetailState } from '../reqDetail/reqDetail.reducer';
import { clearRetainageState } from '../retainage/retainage.reducer';
// TODO: verify clear state fns and convert them to auto;
import {
  clearAllRnds,
  clearState as clearAppState,
  setAppIsLoading,
  setIsApiOnline,
  removeResizableWindowId,
  getActiveResizableWindowId,
  clearClosedRequiredDocumentMessage,
} from '../app/app.reducer';
import { clearState as clearChangeOrdersState } from '../change_orders/change_orders.reducer';
import { clearState as clearUserState } from '../user/user.reducer';
import { clearCompaniesState, setCompanies, setCompany } from '../companies/companies.reducer';
import { clearMainContractState } from '../mainContract/mainContract.reducer';
import { clearTradesState } from '../trades/trades.reducer';
import { clearTeamState } from '../team/team.reducer';
import { clearAttachmentsState } from '../attachments/attachments.reducer';
import { clearNotificationsState } from '../notifications/notifications.reducer';
import { clearSubProjectState, setCurrentSubProject } from '../subProject/subProject.reducer';

import { Action, AppActions, Maybe, RecordObject, StateStore, VoidPromise } from 'src/models';
import { clearModals, removeById, removeElement } from 'src/tools/dom.tools';
import { productionLog } from 'src/tools/log.tools';
import { clearToken } from 'src/services/storage.service';
import { sendWarning } from '../app/app.actions';
import { clearReqSummaryState } from '../reqSummary/reqSummary.reducer';
import { clearState as clearSignaturesState } from '../signatures/signatures.reducer';
import { makeGenericObject } from 'src/tools/object.tools';
import { mockFn } from 'src/tools/fn.tools';
import { requestPredefinedLists } from '../predefinedLists/predefinedLists.actions';
import { clearPredefinedListsState } from '../predefinedLists/predefinedLists.reducer';
import { FormScenarioType } from 'src/models/uiScenarios.model';
import { removeFormScenario } from 'src/store/uiScenarios/uiScenarios.reducer';
import { getCurrentSubProject, getSubProjects } from 'src/store/subProject/subProject.getters';
import { clearLevelsState } from 'src/store/levels/levels.reducer';

let displayed = false;

let ACTIONS: Maybe<RecordObject<() => void>> = undefined;

export const appMiddleware =
  ({ dispatch, getState }: StateStore) =>
  (next: (arg0: Action) => void) =>
  async (action: Action): VoidPromise => {
    next(action);

    function onAuthedActions() {
      if (getState().app.isAuthed) dispatch(requestPredefinedLists());
      else dispatch({ type: 'wipeout' });
    }

    if (!ACTIONS)
      ACTIONS = makeGenericObject(
        {
          'app/setIsAuthed': onAuthedActions,
        },
        mockFn,
      );

    if (ACTIONS) ACTIONS[action.type]();

    // TODO: revise below to the above
    switch (action.type) {
      case 'forceLogoutIfNeeded':
        if (getState().app.isLoading) {
          clearToken();

          if (action.meta?.callback) action.meta.callback();

          dispatch(setAppIsLoading(false));
          dispatch({ type: 'wipeout' });
          dispatch(sendWarning('You need to login first'));
        }

        break;
      case 'wipeout':
        dispatch(clearAllRnds());
        dispatch(clearAppState());
        dispatch(clearChangeOrdersState());
        dispatch(clearCompaniesState());
        dispatch(clearContractTermsState());
        dispatch(clearFilesState());
        dispatch(clearMainContractState());
        dispatch(clearManagerReqState());
        dispatch(clearReqDetailState());
        dispatch(clearClosedRequiredDocumentMessage());
        dispatch(clearReqSummaryState());
        dispatch(clearRetainageState());
        dispatch(clearTeamState());
        dispatch(clearTradesState());
        dispatch(clearUserState());
        dispatch(clearAttachmentsState());
        dispatch(clearNotificationsState());
        dispatch(clearSubProjectState());
        dispatch(clearPredefinedListsState());

        clearModals();
        break;

      case 'app/setServerConfig':
        if (!displayed) {
          productionLog(
            `⊙ app version is ${action.payload.version}, server is running in ${action.payload.mode} mode on ${
              action.payload.stage
            } stage. API is ${action.payload.apiIsOnline ? 'online' : 'offline'}.`,
          );
          displayed = true;
        }

        if (!action.payload.apiIsOnline && getState().app.isApiOnline) dispatch({ type: 'lost_api_connection' });

        if (action.payload.apiIsOnline && !getState().app.isApiOnline) dispatch(setIsApiOnline(true));

        break;
      case 'app/clearSingleRnd':
        removeById(action.payload);
        break;
      case 'app/setParams': {
        const subId = Number(action.payload.subId);

        const mainId = Number(action.payload.mainId);

        const currProject = getCurrentSubProject(getState());

        if (currProject && (currProject.id !== subId || currProject.main_project_id !== mainId)) {
          const subProjects = getSubProjects(getState());

          const newProject = subProjects?.find(p => p.id === subId && p.main_project_id === mainId);

          if (newProject) dispatch(setCurrentSubProject(newProject));
          else dispatch(clearSubProjectState());
        }

        break;
      }
      case 'subProject/setCurrentSubProject': {
        dispatch(clearLevelsState());
        break;
      }
      case AppActions.CLOSE_RESIZABLE_WINDOW:
        removeById(action.payload);
        dispatch(removeResizableWindowId(action.payload));

        // clear signatures after the last window is closed
        if (!getActiveResizableWindowId(getState())) {
          dispatch(clearSignaturesState());
        }

        break;
      case 'app/clearAllRnds':
        // FIXME: revise
        // eslint-disable-next-line no-case-declarations
        const elements = document.getElementById('rnd')?.children;

        if (elements && elements.length) Array.from(elements).forEach(removeElement);

        break;
      // TODO: review below
      case 'cleanupAfterBillingModal':
        dispatch(clearAttachmentsState());
        break;
      case 'cleanUpAfterTrades':
        dispatch(clearTradesState());
        dispatch(setCompanies());
        dispatch(setCompany());
        dispatch(clearAttachmentsState());
        dispatch(clearContractTermsState());
        dispatch(clearTeamState());
        dispatch(removeFormScenario(FormScenarioType.TRADE_CONTRACT_CREATE));
        break;
      case 'mainContract/clearMainContractState':
        dispatch(setCompanies());
        dispatch(setCompany());
        dispatch(clearAttachmentsState());
        // dispatch(clearContractTermsState());
        dispatch(clearTeamState());
        break;
    }
  };
