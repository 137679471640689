import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='72'
      height='72'
      fill='none'
      viewBox='0 0 72 72'
      data-testid='icon_csv'
    >
      <path
        fill='#DDD'
        d='M59.496 69.75H18.155c-1.925 0-3.504-1.646-3.504-3.685V5.935c0-2.039 1.565-3.685 3.504-3.685H48.61c.139 0 .263.03.388.102l.097.058.083.102c.139.16 4.875 5.798 6.897 8.216 1.69 2.01 3.366 4.02 4.626 5.52 2.174 2.593 2.174 2.593 2.23 2.724v.015c.027.102.055.204.055.306v46.815c.014 1.996-1.565 3.642-3.49 3.642zM18.155 3.707c-1.164 0-2.12.99-2.12 2.228V66.08c0 1.224.942 2.229 2.12 2.229H59.51c1.163 0 2.119-.99 2.119-2.229V19.496A16427.065 16427.065 0 0048.36 3.706H18.155z'
      />
      <path
        fill='#F4F3F2'
        d='M62.302 19.263V66.08c0 1.631-1.26 2.957-2.812 2.957H18.15c-1.552 0-2.812-1.326-2.812-2.957V5.935c0-1.631 1.26-2.957 2.811-2.957h30.456c.014 0 .041 0 .055.015.222.262 13.628 16.212 13.628 16.212 0 .029.014.044.014.058z'
      />
      <path
        fill='#DDD'
        d='M62.432 19.22H51.407c-1.51 0-2.742-1.297-2.742-2.884V2.846c.222.262 13.767 16.358 13.767 16.373zM55.483 30.654H22.174v2.535h33.309v-2.535zM55.483 37.122H22.174v2.534h33.309v-2.534zM55.483 43.589H22.174v2.534h33.309V43.59zM55.483 50.057H22.174v2.534h33.309v-2.534zM55.483 56.539H22.174v2.534h33.309V56.54z'
      />
      <path fill='#F0D725' d='M41.45 25.148H9V8.5h32.45c.318 0 .568.262.568.597v15.455c0 .335-.25.597-.568.597z' />
      <path fill='#B3A01D' d='M9 25.148l5.65 6.41v-6.41H9z' />
      <path
        fill='#fff'
        d='M20.87 18.023l1.261.146c-.083.787-.374 1.442-.873 1.952-.498.51-1.163.772-1.966.772-.693 0-1.247-.175-1.676-.539-.43-.364-.762-.86-.983-1.471-.236-.627-.347-1.311-.347-2.054 0-1.209.277-2.185.817-2.942.54-.758 1.288-1.136 2.244-1.136.748 0 1.357.247 1.856.728.499.48.803 1.165.942 2.068l-1.26.146c-.25-1.195-.748-1.792-1.538-1.792-.512 0-.9.248-1.177.743s-.416 1.21-.416 2.156c0 .961.153 1.675.457 2.17.305.48.693.729 1.192.729.401 0 .734-.16 1.01-.496.264-.32.416-.713.458-1.18zM22.412 18.87l1.316-.291c.152.787.761 1.18 1.814 1.18.485 0 .845-.102 1.108-.306.263-.204.388-.452.388-.743 0-.219-.056-.422-.18-.597-.111-.19-.374-.32-.748-.437l-1.44-.393c-.485-.132-.86-.292-1.095-.467a1.74 1.74 0 01-.568-.728 2.408 2.408 0 01-.207-1.005c0-.7.249-1.267.747-1.704.5-.423 1.136-.641 1.912-.641.65 0 1.219.146 1.731.451.512.292.831.714.97 1.268l-1.288.335c-.194-.598-.665-.903-1.386-.903-.415 0-.747.087-.969.276a.885.885 0 00-.332.7c0 .451.346.771 1.038.946l1.274.335c.776.204 1.288.51 1.552.903.263.394.401.845.401 1.326 0 .772-.277 1.384-.83 1.835-.555.452-1.275.67-2.161.67-.748 0-1.399-.16-1.953-.495-.54-.306-.9-.816-1.094-1.515zM32.398 20.778h-1.372l-2.312-7.895h1.426l1.718 6.045 1.758-6.045h1.122l-2.34 7.895z'
      />
    </svg>
  );
}

export default Icon;
