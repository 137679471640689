import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DisplayAs, Maybe } from 'src/models/general.model';
import { Meta, PageQuery } from 'src/models/results.model';
import { Trade } from 'src/models/trade.model';

import { RootState } from 'src/store';
import { INITIAL_TRADES_STATE } from './trades.model';
import { ShowScenario } from 'src/models';
import { Permission } from 'src/models/permissions.model';

export const slice = createSlice({
  name: 'trades',
  initialState: INITIAL_TRADES_STATE,
  reducers: {
    clearTradesState: () => INITIAL_TRADES_STATE,
    setTrades: (state, action: PayloadAction<Trade[]>) => {
      state.trades = action.payload;
    },
    addTrade: (state, action: PayloadAction<Trade>) => {
      if (state.trades) state.trades = [...state.trades, action.payload];
    },
    addTrades: (state, action: PayloadAction<Trade[]>) => {
      if (state.trades) state.trades = [...state.trades, ...action.payload];
    },
    setTrade: (state, action: PayloadAction<Maybe<Trade>>) => {
      state.trade = action.payload;
    },
    setTradeScenario: (state, action: PayloadAction<Maybe<ShowScenario>>) => {
      state.scenario = action.payload;
    },
    setTradesDisplayAs: (state, action: PayloadAction<DisplayAs>) => {
      if (state.displayAs !== action.payload) state.displayAs = action.payload;
    },
    setTradesQuery: (state, action: PayloadAction<Maybe<PageQuery>>) => {
      state.query = action.payload;
    },
    setTradesMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.meta = action.payload;
    },
    setTradesPermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.permissions = action.payload;
    },
  },
});

export const getTradesQuery = (state: RootState) => state.trades.query;

export const {
  clearTradesState,
  setTrades,
  setTrade,
  setTradeScenario,
  setTradesDisplayAs,
  setTradesQuery,
  setTradesMeta,
  addTrade,
  addTrades,
  setTradesPermissions,
} = slice.actions;

export default slice.reducer;
