import * as React from 'react';

import { t } from 'src/tools/text.tools';
import { SidebarAppContext } from 'src/contexts';

import { Button } from '../Button';

import periodOpenClasses from './SidebarOpenButtonPeriodOpen.module.scss';
import periodClosedClasses from './SidebarOpenButtonPeriodClosed.module.scss';

interface Props {
  onClick: () => void;
  isOpen?: boolean;
}

const TXT = t(['sidebar']);

// new-ui
export function SidebarOpenButton({ onClick, isOpen }: Props) {
  const { isLoading, isLockedPeriod } = React.useContext(SidebarAppContext);

  const [animationName, setAnimationName] = React.useState('waiting-animation');

  React.useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!isLoading) setAnimationName('peek-out');
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [isLoading]);

  return (
    <Button
      classNames={isLockedPeriod ? periodClosedClasses : periodOpenClasses}
      leftIconId={isOpen ? 'chevron-right' : isLockedPeriod ? 'binder-closed' : 'binder-open'}
      label={TXT(isOpen ? 'closeButton' : 'openButton')}
      onClick={onClick}
      animationName={animationName}
    />
  );
}
