import * as React from 'react';
import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';

import { P1, P3 } from 'src/components/Typography';
import { t } from 'src/tools/text.tools';

import { PlainButton, PrimaryButton } from 'src/components/Button';

import classes from './CardConfirmation.module.scss';

const TXT = t(['cardConfirmation']);

export interface Props {
  title: string;
  className?: string;
  confirmButtonTitle?: string;
  onConfirm: () => void;
  onCancel: () => void;
  onDiscard?: () => void;
  isXSmall?: boolean;
  containerClassName?: string;
}

// new-ui
export function CardConfirmation({
  title,
  className,
  confirmButtonTitle = TXT('save'),
  onConfirm,
  onCancel,
  onDiscard,
  isXSmall,
}: Props) {
  const containerRef = useOnclickOutside(onCancel, {
    eventTypes: ['click'],
  });

  function handleContainerOnClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const P = isXSmall ? P3 : P1;

  return (
    <div
      data-testid='card-confirmation'
      ref={containerRef}
      className={clsx(classes.container, 'column j-between h-100p', className)}
      onClick={handleContainerOnClick}
    >
      <P title={title}>{title}</P>
      <div className='row items-p4'>
        <PlainButton
          dataTestId='cancel-button'
          onClick={onCancel}
          label={TXT('cancel')}
          isSmall={!isXSmall}
          isXSmall={isXSmall}
        />
        {onDiscard && (
          <PlainButton
            dataTestId='discard-button'
            onClick={onDiscard}
            label={TXT('discard')}
            isSmall={!isXSmall}
            isXSmall={isXSmall}
          />
        )}
        <PrimaryButton
          dataTestId='confirm-button'
          onClick={onConfirm}
          label={confirmButtonTitle}
          isSmall={!isXSmall}
          isXSmall={isXSmall}
        />
      </div>
    </div>
  );
}
