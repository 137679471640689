import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_load'
      width='24'
      height='24'
      stroke='none'
      viewBox='0 0 24 24'
    >
      <path
        stroke='none'
        d='M11.8 4.2a2 2 0 110-4 2 2 0 010 4zm7.071 2.829a1.9 1.9 0 110-3.8 1.9 1.9 0 010 3.8zM21.801 14a1.8 1.8 0 110-3.6 1.8 1.8 0 010 3.6zm-2.93 6.971a1.7 1.7 0 110-3.4 1.7 1.7 0 010 3.4zm-7.07 2.829a1.6 1.6 0 110-3.2 1.6 1.6 0 010 3.2zM4.728 20.77a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM1.8 13.6a1.4 1.4 0 110-2.8 1.4 1.4 0 010 2.8zm2.93-7.171a1.3 1.3 0 110-2.6 1.3 1.3 0 010 2.6z'
      />
    </svg>
  );
}

export default Icon;
