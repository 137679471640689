import { Action, ActionMeta, ID, RecordObject } from 'src/models/general.model';
import { ProjectActions } from 'src/models/query.model';

export const requestSubProjectPageSubscription = (payload: ID): Action => ({
  type: ProjectActions.ADD_PAGE_SUBSCRIPTION,
  payload,
});

export const removeSubProjectPageSubscription = (payload: ID): Action => ({
  type: ProjectActions.REMOVE_PAGE_SUBSCRIPTION,
  payload,
});

export const requestSubProjects = (mainProjectId: ID, meta?: ActionMeta): Action => ({
  type: ProjectActions.GET,
  payload: mainProjectId,
  meta,
});

export type SubProjectCreateRequest = { data: FormData; main_project_id: ID };

export const requestCreateSubProject = (payload: SubProjectCreateRequest, meta?: ActionMeta): Action => ({
  type: ProjectActions.CREATE,
  payload,
  meta,
});

export const requestUpdateSubProject = (payload: RecordObject, meta?: ActionMeta): Action => ({
  type: ProjectActions.UPDATE,
  payload,
  meta,
});

export const requestSubProjectTeam = (): Action => ({
  type: ProjectActions.GET_TEAM,
});

export const requestSubProjectTeamMember = (team_member_id: ID): Action => ({
  type: ProjectActions.GET_TEAM_MEMBER,
  payload: team_member_id,
});

export const requestSubProjectTeamInvite = (email: string): Action => ({
  type: ProjectActions.GET_TEAM_INVITE,
  payload: email,
});

export const requestCurrentSubProject = (): Action => ({
  type: ProjectActions.SHOW,
});
