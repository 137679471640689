import * as React from 'react';
import { PlainButton, PlainButtonProps } from '../PlainButton';
import { t } from 'src/tools/text.tools';

const TXT = t(['subNavbar', 'buttons']);

type Props = Omit<PlainButtonProps, 'label' | 'isSmall'>;

// new-ui
export function DocumentationPlainButton(props: Props) {
  return (
    <PlainButton isSmall {...props} label={TXT('documentation')} leftIconId='file-text' rightIconId='chevron-down' />
  );
}
