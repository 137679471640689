import { RootState } from 'src/store';
import { Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { QueryClass } from 'src/services/query.service';
import { CSSProperties } from 'react';
import { UseIcons } from './interface.model';
import { PageQuery, Results } from './results.model';
import { Scenario } from './scenario.model';
import { SocketConnection } from 'src/services/api_socket.service';

// new-ui

export type SingleOrArray<Type> = Type | Type[];

export type VoidPromise = Promise<void>;

export type VoidFn = (...args: AnyValue[]) => void;

export type ResponsePayload<T = unknown, S = Scenario> = Maybe<Results<T, Record<string, number>, PageQuery, S>>;

export enum BatchItemState {
  IDLE = 'idle',
  SUCCESS = 'success',
  FAIL = 'fail',
  IN_PROGRESS = 'inProgress',
}

export interface RecordWithId {
  id: string;
}

export interface BatchItem<T> extends RecordWithId {
  id: string;
  state: BatchItemState;
  payload: T;
  progress?: number; // upload progress
  message?: string; // available on FAIL status
}

export type FormDataBatchItem = BatchItem<FormData>;

export type PromiseRecords = RecordObject<Promise<AnyValue>>;
// end-of new-ui

// TODO: remove below
export interface Response<T> {
  isOK: boolean;
  message?: string;
  status?: number;
  payload?: T;
}

// export type MonthYear = [number, number];

export interface Query {
  [index: string]: string | number | string[] | Query;
}

export interface CustomState {
  id: string;
  state: boolean;
}

export enum DisplayAs {
  CARDS = 'cards',
  LIST = 'list',
}

type Maybe<T> = T | undefined;

type MaybeNull<T> = T | null;

type Either<T, K> = T | K;

export type ID = Either<number, string>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyValue = any;

interface ProcessProps {
  dispatch: Dispatch<Action>;
  getService: () => QueryClass;
  getState: () => RootState;
}

export type ActionsObject = Record<string, (arg0: Action) => void>;

export interface ApiProcessProps {
  dispatch: Dispatch<Action>;
  getState: () => RootState;
  getService: () => SocketConnection;
}

type Result<T = AnyValue> = { isOK: boolean; message?: string; payload?: T; status?: number };

type IsValid = Maybe<Either<boolean, React.RefObject<boolean>>>;

export interface ActionMeta {
  callback?: (data?: AnyValue) => void;
  isValid?: IsValid;
  isUpdate?: boolean;
  isInitial?: boolean;
  nextStep?: boolean;
  id?: string;
}

interface Action<T = AnyValue> {
  type: string;
  payload?: T;
  meta?: Maybe<ActionMeta>;
}

export type TotalByStatus = Record<string, number>;

interface Totals {
  total: number;
  total_by_status: TotalByStatus[];
}

interface PageTab {
  id: AnyValue;
  label: string;
  to?: string;
  style?: CSSProperties;
  options?: Record<string, unknown>;
  notificationCount?: number;
}

type StateStore = MiddlewareAPI<Dispatch<Action>, RootState>;

interface Tab {
  url: string;
  title: string;
  id: ID;
}

type RecordObject<T = unknown> = Record<string, T>;

export type RecordStringObject = RecordObject<string>;

export interface ButtonBaseType<T = ID> {
  label: string;
  id: T;
  report?: string;
  context?: string;
  iconId?: UseIcons;
  isDisabled?: boolean;
  isChecked?: boolean;
  isSplitAfter?: boolean;
  isHighlightedText?: boolean;
  className?: string;
  sub_label?: string;
  items?: ButtonBaseType<T>[];
  color?: string;
  isEditable?: boolean;
}

export type StateMachine = RecordObject<string[]>;

// @ts-ignore - Circular reference
export type TextObject = Record<string, Either<TextObject, string>>;

export enum ParagraphIds {
  PERCENT = 'percent',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  FIXED = 'fixed',
  FLOAT = 'float',
  PHONE = 'phone',
  STRING = 'string',
  NUMBERS_ONLY = 'numbersOnly',
  REQ_NUMBER = 'reqNumber',
}

export enum ParseMode {
  VIEW = 'view',
  EDIT = 'edit',
}

export type RenderFunctions = Record<string, () => JSX.Element>;

export type DecoratorRenderFunctions = Record<string, (children: JSX.Element | null) => JSX.Element>;

// new-ui
export interface PathParams extends RecordObject<Maybe<string>> {
  mainId?: string;
  subId?: string;
  tradeId?: string;
  coId?: string;
}

export interface VerificationModel {
  [index: string]: { type: string; isRequired?: boolean };
}

export enum MessageCode {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface ToastMessage {
  id: string;
  code: MessageCode;
  content: string;
}

export interface AlertMessageContent {
  content: string;
  buttonLabel?: string;
  title?: string;
}

export interface AlertMessage extends AlertMessageContent {
  code: MessageCode;
}

export type ErrorMessage = AlertMessageContent;

export enum AttachmentsContext {
  REQ_DETAIL = 'req_detail',
  REQ_MANAGERS = 'req_managers',
  REQ_SUMMARY = 'req_summary',
}

export interface Timezone {
  id: ID;
  label: string;
}

export interface Group<T extends RecordObject<AnyValue>> {
  data: T[];
  label: string;
}

export type GroupedData<T extends RecordObject<AnyValue>> = {
  [group: string]: Group<T>;
} & { '#data'?: T[] };

export interface FileInfo {
  url: string;
  name: string;
  type: string;
}

export interface ActionResult {
  data: string;
}

export enum TitleDocumentType {
  REQUIRED_SUBMISSION = 'requiredSubmission',
  CUSTOM_SUBMISSION = 'customSubmisison',
  SIDEBAR_SUBMISSION = 'sidebarSubmission',
  BACKUP = 'backup',
}

// end-of new-ui
export type {
  IsValid,
  Action,
  AnyValue,
  Either,
  Maybe,
  MaybeNull,
  PageTab,
  ProcessProps,
  RecordObject,
  Result,
  StateStore,
  Tab,
  Totals,
};
