import { AnyValue, AttachmentFile, FormDataBatchItem, Maybe, QueueItem, ServiceInfo } from 'src/models';

export interface FilesState {
  files?: Maybe<QueueItem<AttachmentFile>[]>;
  annotations?: Maybe<Record<string, AnyValue>>;
  uploads?: Maybe<FormDataBatchItem[]>;
  serviceInfo?: Maybe<ServiceInfo>;
}

export const INITIAL_FILES_STATE: FilesState = {};
