import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_engineer'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M14 1.333H2A.667.667 0 001.335 2v12a.667.667 0 00.667.667h4A.667.667 0 006.667 14V6.667h7.334A.667.667 0 0014.667 6V2a.667.667 0 00-.666-.667zm-.666 4h-1.333v-.666a.667.667 0 00-1.334 0v.666H9.334v-.666a.667.667 0 10-1.333 0v.666H6.667v-.666a.667.667 0 00-1.333 0v.666h-.667a.667.667 0 000 1.334h.667V8h-.667a.667.667 0 000 1.333h.667v1.334h-.667a.667.667 0 000 1.333h.667v1.333H2.667V2.667h10.667v2.666z'
      />
    </svg>
  );
}

export default Icon;
