import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_architect'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M15.073 4.7L11.333.927a.666.666 0 00-.94 0L.926 10.36a.667.667 0 000 .94L4.7 15.073a.667.667 0 00.94 0l1.886-1.886 5.66-5.66 1.887-1.887a.667.667 0 000-.94zm-2.36 1.413l-.473-.473a.667.667 0 10-.94.947l.473.473-.947.94-1.413-1.413a.667.667 0 00-.94.94L9.886 8.94l-.946.947-.467-.474a.67.67 0 00-.947.947l.474.467-.94.946-1.42-1.413a.666.666 0 00-1.134.47c0 .176.07.345.194.47l1.413 1.413-.94.947-2.833-2.833 8.486-8.487 2.834 2.833-.947.94z'
      />
      <path
        stroke='none'
        fillOpacity='0.25'
        d='M15.073 4.7L11.333.927a.666.666 0 00-.94 0L.926 10.36a.667.667 0 000 .94L4.7 15.073a.667.667 0 00.94 0l1.886-1.886 5.66-5.66 1.887-1.887a.667.667 0 000-.94zm-2.36 1.413l-.473-.473a.667.667 0 10-.94.947l.473.473-.947.94-1.413-1.413a.667.667 0 00-.94.94L9.886 8.94l-.946.947-.467-.474a.67.67 0 00-.947.947l.474.467-.94.946-1.42-1.413a.666.666 0 00-1.134.47c0 .176.07.345.194.47l1.413 1.413-.94.947-2.833-2.833 8.486-8.487 2.834 2.833-.947.94z'
      />
    </svg>
  );
}

export default Icon;
