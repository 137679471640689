import * as React from 'react';
import { Router } from 'react-router-dom';
import { v4 as generateId } from 'uuid';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from 'src/store';
import { setActiveResizableWindowId } from 'src/store/app/app.reducer';
import { history } from 'src/routes/history';

import { Window, WindowParameters } from './Window';

interface Options extends WindowParameters {
  title?: string;
  onClose?: () => void;
}

export function createResizableWindow(content: React.ReactElement, { title, onClose, ...windowOptions }: Options) {
  const windowId = generateId();

  const root = document.getElementById('rnd');

  if (!root) return;

  const child = document.createElement('div');

  function handleSetVisibility(isVisible: boolean) {
    if (!child) return;

    if (isVisible) child.classList.remove('d-none');
    else child.classList.add('d-none');
  }

  child.setAttribute('id', windowId);
  root.appendChild(child);

  store.dispatch(setActiveResizableWindowId(windowId));

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Window
          Content={content}
          options={windowOptions}
          windowId={windowId}
          title={title}
          onClose={onClose}
          setVisibility={handleSetVisibility}
        />
      </Router>
    </Provider>,
    document.getElementById(windowId) as HTMLElement,
  );
}
