import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_pdf'
      width='16'
      height='16'
      fill='none'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        fill='#FF2116'
        d='M3.454.5a1.55 1.55 0 00-1.548 1.55v11.9c0 .854.695 1.55 1.548 1.55h9.09a1.55 1.55 0 001.548-1.55V4.165s.024-.287-.1-.566c-.117-.261-.306-.443-.306-.443l-.002-.002L11.429.94a.106.106 0 00-.004-.004s-.193-.184-.478-.306c-.336-.145-.682-.13-.682-.13h.005-6.816z'
      />
      <path
        stroke='none'
        fill='#F5F5F5'
        d='M3.453 1.009h6.821s.273.003.472.088c.12.053.23.123.329.208v.001l2.25 2.21s.136.143.202.29a1.108 1.108 0 01.056.348v9.796c0 .581-.46 1.041-1.04 1.041h-9.09c-.58 0-1.039-.46-1.039-1.04V2.05c0-.581.459-1.041 1.039-1.041z'
      />
      <path
        stroke='none'
        fill='#FF2116'
        d='M4.947 9.431c-.35-.35.03-.831 1.056-1.342l.647-.322.252-.552c.163-.362.316-.729.459-1.1l.207-.548-.143-.406c-.175-.499-.238-1.249-.127-1.518.152-.365.646-.328.843.064.153.305.137.859-.044 1.557l-.15.573.132.223c.072.122.282.413.467.646l.349.433.433-.056c1.376-.18 1.847.125 1.847.563 0 .553-1.08.599-1.988-.04-.204-.143-.344-.285-.344-.285s-.568.115-.848.191c-.29.078-.433.127-.856.27 0 0-.149.216-.246.372-.36.584-.78 1.068-1.08 1.244-.336.197-.688.21-.866.033zm.55-.196c.196-.122.594-.593.87-1.03l.111-.178-.508.256c-.784.395-1.143.767-.957.992.105.126.23.116.483-.04zm5.095-1.432c.193-.135.165-.407-.053-.517-.169-.085-.305-.102-.745-.096a9.55 9.55 0 00-.778.09s.239.165.344.226c.142.08.484.23.735.307.246.075.39.067.497-.01zM8.544 6.95a5.021 5.021 0 01-.44-.568c-.165-.215-.247-.368-.247-.368s-.12.387-.218.62l-.307.76-.09.172s.474-.156.715-.219c.255-.066.773-.168.773-.168l-.186-.229zm-.66-2.65c.029-.25.041-.5-.039-.625-.222-.243-.49-.04-.444.538.015.194.063.526.128.731l.117.372.082-.28c.046-.154.115-.486.155-.737z'
      />
      <path
        stroke='none'
        fill='#2C2C2C'
        d='M5.543 11.053h.568c.182 0 .329.017.442.052.113.034.208.11.286.227a.747.747 0 01.116.422.768.768 0 01-.094.39.553.553 0 01-.254.236c-.105.048-.268.072-.487.072h-.197v.897h-.38v-2.296zm.38.294v.801h.188c.168 0 .284-.031.348-.094.065-.063.097-.165.097-.306a.46.46 0 00-.063-.256c-.043-.066-.09-.107-.142-.121a.883.883 0 00-.24-.024h-.188zm1.323-.294h.515c.25 0 .45.044.599.133.15.088.263.22.34.395.076.175.115.369.115.582 0 .224-.035.425-.104.6a.955.955 0 01-.316.425c-.141.107-.343.161-.605.161h-.544v-2.296zm.38.304v1.688h.157c.22 0 .381-.077.48-.23.1-.153.15-.357.15-.613 0-.563-.21-.845-.63-.845h-.158zm1.554-.304h1.274v.304H9.56v.687h.715v.304H9.56v1.001h-.38v-2.296z'
      />
    </svg>
  );
}

export default Icon;
