import { ICONS } from 'src/icons';

export type UseIcons = keyof typeof ICONS;

export enum AppActions {
  CLOSE_RESIZABLE_WINDOW = 'closeResizableWindow',
  CLEANUP = 'cleanUpAfterTrades',
  GET_TIMEZONES = 'getTimezones',
  PREDEFINED_LISTS = 'requestPredefinedLists',
}
