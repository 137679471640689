import { AnyValue } from 'src/models';

const { info, error, warn } = console;

const isDev = () => process.env.NODE_ENV === 'development';

const isTest = () => process.env.NODE_ENV === 'test';

export const productionLog = (message: string, ...data: unknown[]) => !isTest() && info('❏', message, ...data);

export function logUnableToProceed(fnName: string, ...payload: AnyValue[]) {
  info('⇲', `[${fnName}] unable to proceed`, ...payload);
}

export function logIncorrectData(fnName: string, description: string, ...payload: AnyValue[]) {
  info('⇲', `[${fnName}] got incorrect data${description ? '; ' + description : ''}`, ...payload);
}

// DEV-only logs
export function logError(message: string, ...payload: unknown[]) {
  isDev() &&
    error(`%c${message}`, 'color:red; background-color: pink; padding: 3px 8px; border-radius: 4px;', ...payload);
}

export function logInfo(message: string, ...payload: unknown[]) {
  isDev() &&
    info(
      `%c${message}`,
      'color: green; background-color: lightgreen; padding: 3px 8px; border-radius: 2px;',
      ...payload,
    );
}

export function logWarn(message: string, ...payload: unknown[]) {
  isDev() &&
    warn(`%c${message}`, 'color: sienna; background-color: bisque; padding: 3px 8px; border-radius: 4px;', ...payload);
}

export function logBeta(message: string, ...payloads: unknown[]) {
  isDev() &&
    info(
      `%c[β] ${message}`,
      'color: midnightblue; background-color: lightblue; padding: 3px 10px 2px 10px; border-radius: 2px;',
      ...payloads,
    );
}

export function logAlpha(message: string, ...payloads: unknown[]) {
  isDev() &&
    info(
      `%c[α] ${message || ''}`,
      'color: maroon; background-color: mistyrose; padding: 3px 10px 2px 10px; border-radius: 2px;',
      ...payloads,
    );
}
