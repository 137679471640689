import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_hourglass'
      width='24'
      height='24'
      stroke='none'
      viewBox='0 0 24 24'
    >
      <path
        stroke='none'
        d='M18.992 21.002h-1v-1.667a5.001 5.001 0 00-.3-1.678.956.956 0 00-.035-.084 4.976 4.976 0 00-.665-1.237l-1.4-1.867a3.019 3.019 0 01-.6-1.801v-1.01a3.021 3.021 0 01.879-2.12l.657-.658a4.945 4.945 0 001.396-2.839c0-.013.008-.025.008-.04l-.003-.013c.035-.213.056-.428.063-.643V3.002h1a1 1 0 100-2h-14a1 1 0 000 2h1v2.343c.007.215.028.43.063.643l-.003.014c0 .014.008.026.008.04A4.946 4.946 0 007.457 8.88l.657.657c.56.564.876 1.326.878 2.121v1.01a3.022 3.022 0 01-.6 1.8l-1.4 1.868a4.983 4.983 0 00-.665 1.237.968.968 0 00-.035.084 5.002 5.002 0 00-.3 1.678v1.667h-1a1 1 0 100 2h14a1 1 0 000-2zm-11-16v-2h8v2h-8zm.879 2.464a2.97 2.97 0 01-.377-.464h6.997c-.11.167-.236.322-.377.464l-.658.657a4.96 4.96 0 00-1.421 2.879H10.95a4.96 4.96 0 00-1.422-2.88l-.657-.656zm1.122 8.202c.578-.775.921-1.7.988-2.666h2.022c.067.965.41 1.89.99 2.666l1 1.334H8.991l1-1.334zm6 5.334h-8v-1.667c.002-.111.011-.223.026-.333h7.946c.015.11.024.222.027.333v1.667z'
      />
    </svg>
  );
}

export default Icon;
