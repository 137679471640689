import {
  QueryActions,
  MainProjectActions,
  ReqDetailActions,
  SocketQueryActions,
  ReqSummaryActions,
  CommentsActions,
  CommentThreadActions,
  CompaniesActions,
  DocumentsActions,
  PicturesActions,
  ContractTermsActions,
  ManagerReqActions,
  MainContractActions,
  RequiredDocumentsActions,
  ProjectActions,
  TeamActions,
  NotificationsActions,
  UiScenariosActions,
} from 'src/models';

export const PAYLOAD_TYPE: Record<string, string> = {
  [CommentsActions.POST]: 'payload',
  [CommentThreadActions.GET]: 'payload',
  [CommentThreadActions.SINGLE]: 'payload',
  [CompaniesActions.GET]: 'payload',
  [CompaniesActions.MORE]: 'payload',
  [CompaniesActions.SINGLE]: 'payload',
  [CompaniesActions.USER]: 'payload',
  [ContractTermsActions.GET]: 'payload',
  [DocumentsActions.SEARCH]: 'payload',
  [RequiredDocumentsActions.UPDATE]: 'payload',
  [RequiredDocumentsActions.DELETE]: 'payload',
  [RequiredDocumentsActions.GET_TEMPLATE_CONTENT]: 'payload',
  [RequiredDocumentsActions.RESET_TEMPLATE_CONTENT]: 'payload',
  [PicturesActions.UPDATE]: 'payload',
  [MainContractActions.GET]: 'payload',
  [MainProjectActions.GET_MORE]: 'payload',
  [MainProjectActions.SINGLE]: 'payload',
  [ManagerReqActions.BASE_CONTRACT]: 'action',
  [ManagerReqActions.CHANGE_ORDERS]: 'action',
  [ManagerReqActions.CHANGE_ORDERS_MORE]: 'payload',
  [ManagerReqActions.BASE_CONTRACT_MORE]: 'payload',
  [ManagerReqActions.HISTORY]: 'payload',
  [ManagerReqActions.PERFORM_ACTION]: 'payload',
  [ManagerReqActions.TOTALS]: 'payload',
  [NotificationsActions.UPDATE]: 'payload',
  [NotificationsActions.DELETE]: 'payload',
  [PicturesActions.ADD_TAGS]: 'payload',
  [PicturesActions.DELETE_TAGS]: 'payload',
  [ProjectActions.GET_TEAM_MEMBER]: 'payload',
  [ProjectActions.GET_TEAM_INVITE]: 'payload',
  [QueryActions.ASSIGN_CONTACT]: 'payload',
  [QueryActions.CHANGE_ORDERS_TOTALS]: 'payload',
  [QueryActions.DELETE_CONTACT]: 'payload',
  [QueryActions.GET_CHANGE_ORDERS_MORE]: 'payload',
  [QueryActions.GET_DRAWING]: 'payload',
  [QueryActions.GET_SINGLE_CHANGE_ORDER]: 'payload',
  [ReqDetailActions.HISTORY]: 'payload',
  [ReqDetailActions.PERFORM_ACTION]: 'payload',
  [ReqDetailActions.STATS]: 'payload',
  [ReqSummaryActions.GET_TRADES_MORE]: 'payload',
  [ReqSummaryActions.SHOW]: 'payload',
  // [ReqSummaryActions.STATISTICS]: 'payload',
  [ReqSummaryActions.TRADES]: 'payload',
  [ReqSummaryActions.TERMS]: 'payload',
  [SocketQueryActions.GET_SERVER_CONFIG]: 'payload',
  [SocketQueryActions.USER_LOGOUT]: 'payload',
  [TeamActions.UPDATE_MEMBER]: 'payload',
  [TeamActions.RESEND_INVITE]: 'payload',
  [TeamActions.UPDATE_INVITE]: 'payload',
  [UiScenariosActions.GET_FORM]: 'payload',
};
