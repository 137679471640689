import { Action, ActionMeta, ID, RecordObject } from 'src/models/general.model';
import { SocketQueryActions, UserActions } from 'src/models/query.model';

export function loginUser(payload: RecordObject<string>, meta?: ActionMeta): Action {
  return { type: SocketQueryActions.USER_LOGIN, payload, meta };
}

export function requestInvite(team_invite_id: ID, meta?: ActionMeta): Action {
  return { type: SocketQueryActions.GET_INVITE, payload: { team_invite_id }, meta };
}

export function signUpUser(payload: FormData, meta?: ActionMeta): Action {
  return { type: SocketQueryActions.SIGN_UP, payload, meta };
}

export function requestUserProfile(meta?: ActionMeta): Action {
  return { type: UserActions.GET, meta };
}

interface UpdateUserProfileProps {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  title?: string;
  picture?: File;
  company_id?: ID;
  timezone_id?: ID;
}

interface UpdateUserAdminProps {
  user_id: ID;
  data: {
    email?: string;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    title?: string;
    company_id?: ID;
    timezone_id?: ID;
    two_factor_enabled?: boolean;
    enabled?: boolean;
  };
}

export function updateUserProfile(payload: UpdateUserProfileProps, meta?: ActionMeta): Action {
  return { type: UserActions.UPDATE, payload, meta };
}

export function requestSendUpdatePasswordCode(meta?: ActionMeta): Action {
  return { type: UserActions.SEND_UPDATE_PASSWORD_TOKEN, meta };
}

export function requestSendResetPasswordCode(email: string, meta?: ActionMeta): Action {
  return { type: UserActions.SEND_RESET_PASSWORD_TOKEN, payload: { email }, meta };
}

export function requestCheckUpdatePasswordCode(token: string, meta?: ActionMeta): Action {
  return { type: UserActions.CHECK_UPDATE_PASSWORD_TOKEN, payload: { token }, meta };
}

export interface RequestUpdatePasswordProps {
  token: string;
  password: string;
  password_confirmation: string;
}

export function requestUpdatePassword(payload: RequestUpdatePasswordProps, meta?: ActionMeta): Action {
  return { type: UserActions.UPDATE_PASSWORD, payload, meta };
}

export interface RequestResetPasswordProps {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export function requestResetPassword(payload: RequestResetPasswordProps, meta?: ActionMeta): Action {
  return { type: UserActions.RESET_PASSWORD, payload, meta };
}

export function logout() {
  return { type: SocketQueryActions.USER_LOGOUT };
}

export function requestUserAdmin(meta?: ActionMeta): Action {
  return { type: UserActions.GET_USER_ADMIN, meta };
}

export function updateUserAdmin(payload: UpdateUserAdminProps, meta?: ActionMeta): Action {
  return { type: UserActions.UPDATE_USER_ADMIN, payload, meta };
}
