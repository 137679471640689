import { append } from 'ramda';
import { TEXT } from 'src/data/text.data';
import { Either } from 'src/models/general.model';
import { logUnableToProceed } from './log.tools';
import { getTextByPath } from './object.tools';
import { validateArrayOf } from './validate.tools';

// new-ui
export function t(section: string[], obj = TEXT) {
  return function call(key: Either<string, string[]>) {
    try {
      if (typeof key === 'string') return getTextByPath(obj, append(key, section));

      if (Array.isArray(key) && validateArrayOf(key, ['string'])) return getTextByPath(obj, [...section, ...key]);
    } catch (err) {
      logUnableToProceed('t', { err });
      return '';
    }

    return '';
  };
}
