/* eslint-disable max-lines */
import { assoc, omit } from 'ramda';

import {
  Action,
  AnyValue,
  ManagerReqDetailData,
  ManagerReqLine,
  ManagerReqTotals,
  ProcessProps,
  QueryAction,
  QueryDomain,
  ResourceType,
  Response,
  ResponsePayload,
  Maybe,
  ActionResult,
  UpdateManagerReqPagePayload,
  ManagerReqPageStatuses,
} from 'src/models';
import { sendInfo, alertError } from 'src/store/app/app.actions';
import {
  setManagerReqBaseContract,
  setManagerReqBaseContractMeta,
  setManagerReqBaseContractQuery,
  setManagerReqBaseContractScenario,
  setManagerReqBaseContractTotals,
  setManagerReqBaseContractInfo,
  setManagerReqChangeOrders,
  setManagerReqChangeOrdersMeta,
  setManagerReqChangeOrdersQuery,
  setManagerReqChangeOrdersScenario,
  setManagerReqChangeOrdersTotals,
  setManagerReqCurrentPeriod,
  setManagerReqData,
  setManagerReqHistory,
  setManagerReqMeta,
  setManagerReqSelectedPeriod,
  setManagerReqTotals,
  setManagerReqTotalsScenario,
  setManagerReqSidebar,
  setManagerReqReqPeriods,
  clearManagerReqBaseContract,
  updateManagerReqBaseContract,
  updateManagerReqChangeOrders,
  setManagerReqPagePermissions,
  updateManagerReqLineItem,
  setManagerReqUploadInProgress,
  removeManagerReqBaseContractLineItem,
} from 'src/store/managerReq/managerReq.reducer';
import { setPageStatusFromManagerReq } from 'src/store/reqSummary/reqSummary.reducer';
import {
  getManagerReqBaseContract,
  getManagerReqBaseContractItem,
  getManagerReqBaseContractMeta,
  getManagerReqBaseContractQuery,
  getManagerReqChangeOrders,
  getManagerReqChangeOrdersMeta,
  getManagerReqChangeOrdersQuery,
  getManagerReqSelectedPeriod,
  getManagerReqSelectedTab,
} from 'src/store/managerReq/managerReq.getters';
import {
  BaseContractDataParams,
  ManagerReqShowParams,
  RequestChangeOrdersProps,
  requestManagerReqHistory,
} from 'src/store/managerReq/managerReq.actions';
import { actionWrapper, makeUpdatedAction } from 'src/tools';
import { checkIsValid } from 'src/tools/object.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { MANAGER_REQ_TABS_BY_TABID } from 'src/data';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import { logUnableToProceed } from 'src/tools/log.tools';
import { getCurrentMainContractId } from '../store/subProject/subProject.getters';
import { History } from 'src/models/history.model';
import { deserialize, capitalize } from 'src/tools/string.tools';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { ifTrue } from 'src/tools/logic.tools';
import { ReqDetailChangeOrder } from 'src/models/reqDetail.model';

export function processManagerReq({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const getSection = (options: 'baseContract' | 'changeOrders' | 'totals') => {
    const selectedTab = getManagerReqSelectedTab(getState());

    return MANAGER_REQ_TABS_BY_TABID[selectedTab]?.options?.[options];
  };

  const makeManagerReqVersionedPeriod = (payload: ManagerReqShowParams = {}) =>
    'period' in payload ? payload.period : getManagerReqSelectedPeriod(getState());

  const processPageDataRequest = (action: Action<ManagerReqShowParams>) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    if (!mainContractId) {
      logUnableToProceed('processPageDataRequest', { mainContractId });
      return;
    }

    setIsBusy({ meta: action.meta, state: true });

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.SHOW,
        payload: {
          main_contract_id: mainContractId,
          period: makeManagerReqVersionedPeriod(action.payload),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ManagerReqDetailData>>(response.payload);

          dispatch(
            setManagerReqData(
              result?.data ? omit(['current_period', 'selected_period', 'sidebar'], result?.data) : undefined,
            ),
          );

          setIsBusy({ meta: action.meta, state: false });

          dispatch(setManagerReqUploadInProgress(Boolean(result?.data.upload_in_progress)));
          dispatch(setManagerReqCurrentPeriod(result?.data?.current_period));
          dispatch(setManagerReqSelectedPeriod(result?.data?.selected_period));
          dispatch(setManagerReqSidebar(result?.data?.sidebar));
          dispatch(setManagerReqMeta(result?.meta));
          dispatch(setManagerReqPagePermissions(result?.permissions));

          processGetReqPeriods()();
          processHistoryRequest()();
          processBaseContractDataRequest(actionWrapper({ meta: { page: 1 } }, action.meta))();
          processChangeOrdersDataRequest(actionWrapper({ meta: { page: 1 } }, action.meta))();
          processTotalsRequest(actionWrapper({}, action.meta))();
        } else {
          processError({ activityName: 'Request manager req data', response });

          setIsBusy({ meta: action.meta, state: false });
        }
      },
    );
  };

  const processBaseContractDataRequest = (action: Action<Partial<BaseContractDataParams>>) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const section = getSection('baseContract');

    if (!mainContractId || !section) {
      logUnableToProceed('processBaseContractDataRequest', { mainContractId, section });
      return;
    }

    const isNewRequest = !getManagerReqBaseContract(getState()) || action.meta?.isInitial;

    const currentQuery = getManagerReqBaseContractQuery(getState());

    const query = ifTrue(!isNewRequest, { ...currentQuery, ...action.payload?.query });

    const meta = getManagerReqBaseContractMeta(getState());

    if (action.meta?.isUpdate) {
      dispatch(setCustomIsUpdating({ id: 'baseContract', state: true }));
    } else {
      dispatch(setCustomIsLoading({ id: 'baseContract', state: true }));
      dispatch(clearManagerReqBaseContract());
    }

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.GET,
        payload: {
          main_contract_id: mainContractId,
          section,
          period: makeManagerReqVersionedPeriod(action.payload),
          meta: { ...meta, ...action.payload?.meta },
          query,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ManagerReqLine[]>>(response.payload);

          if (action.meta?.isUpdate && !isNewRequest) dispatch(updateManagerReqBaseContract(result));
          else dispatch(setManagerReqBaseContract(result));

          dispatch(setManagerReqBaseContractQuery(result?.query));
          dispatch(setManagerReqBaseContractMeta(result?.meta));
          dispatch(setManagerReqBaseContractScenario(result?.scenario));
          dispatch(setManagerReqBaseContractTotals(result?.totals));
          dispatch(setManagerReqBaseContractInfo(result?.info));
        } else {
          processError({
            activityName: 'Request base contract items',
            response,
          });
        }

        if (action.meta?.isUpdate) {
          dispatch(setCustomIsUpdating({ id: 'baseContract', state: false }));
        } else {
          dispatch(setCustomIsLoading({ id: 'baseContract', state: false }));
        }
      },
    );
  };

  const processBaseContractMoreRequest = (action: Action) => {
    return function call() {
      const pagination = getManagerReqBaseContractMeta(getState());

      if (typeof pagination?.next !== 'number') {
        logUnableToProceed('processBaseContractMoreRequest', { pagination });
        return;
      }

      processBaseContractDataRequest({
        ...action,
        payload: { meta: { page: pagination.next } },
        meta: { isUpdate: true },
      })();
    };
  };

  const processChangeOrdersDataRequest = (action: Action<RequestChangeOrdersProps>) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const section = getSection('changeOrders');

    // some sections doesn't have change orders
    if (!section) return;

    if (!mainContractId) {
      logUnableToProceed('processChangeOrdersDataRequest', { mainContractId });
      return;
    }

    const isNewRequest = !getManagerReqChangeOrders(getState());

    const currentQuery = getManagerReqChangeOrdersQuery(getState());

    const query = ifTrue(!isNewRequest, { ...currentQuery, ...action.payload?.query });

    if (action.meta?.isUpdate) {
      dispatch(setCustomIsUpdating({ id: 'changeOrder', state: true }));
    } else {
      dispatch(setCustomIsLoading({ id: 'changeOrder', state: true }));
      dispatch(setManagerReqChangeOrders());
    }

    const meta = getManagerReqChangeOrdersMeta(getState());

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.GET_CHANGE_ORDERS,
        payload: {
          main_contract_id: mainContractId,
          section,
          period: makeManagerReqVersionedPeriod(action.payload),
          query,
          meta: {
            ...meta,
            ...action.payload?.meta,
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqDetailChangeOrder[]>>(response.payload);

          if (action.meta?.isUpdate && !isNewRequest) dispatch(updateManagerReqChangeOrders(result));
          else dispatch(setManagerReqChangeOrders(result));

          dispatch(setManagerReqChangeOrdersQuery(result?.query));
          dispatch(setManagerReqChangeOrdersMeta(result?.meta));
          dispatch(setManagerReqChangeOrdersScenario(result?.scenario));
          dispatch(setManagerReqChangeOrdersTotals(result?.totals));
        } else {
          processError({ activityName: 'Request change orders', response });
        }

        if (action.meta?.isUpdate) {
          dispatch(setCustomIsUpdating({ id: 'changeOrder', state: false }));
        } else {
          dispatch(setCustomIsLoading({ id: 'changeOrder', state: false }));
        }
      },
    );
  };

  const processActions = (payload: AnyValue) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const period = getManagerReqSelectedPeriod(getState());

    if (!mainContractId || !period) {
      logUnableToProceed('processActions', { mainContractId, period });
      return;
    }

    dispatch(setCustomIsLoading({ id: 'actions', state: true }));

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.ACTION,
        payload: {
          data: {
            main_contract_id: mainContractId,
            selected_action: payload,
            period,
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<Maybe<ActionResult>>(response.payload);

          dispatch(sendInfo(result?.data ?? 'Action was performed'));
          processPageDataRequest(makeUpdatedAction(actionWrapper({}, { isInitial: true })))();
          dispatch(requestManagerReqHistory());
        } else {
          processError({
            activityName: 'Request manager req actions',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'actions', state: false }));
      },
    );
  };

  const processHistoryRequest = (payload?: ManagerReqShowParams) => () => {
    dispatch(setCustomIsLoading({ id: 'history', state: true }));
    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.HISTORY,
        payload: {
          main_contract_id: getCurrentMainContractId(getState()),
          period: makeManagerReqVersionedPeriod(payload),
        },
      },
      (response: Response<unknown>) => {
        if (response.isOK) {
          // TODO: remove Set
          const result = deserialize<Set<History>>(response.payload as string, new Set<History>());

          dispatch(setManagerReqHistory(Array.from(result ?? [])));
        } else {
          processError({
            activityName: 'Request manager req history',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'history', state: false }));
      },
    );
  };

  const processTotalsRequest = (action: Action<ManagerReqShowParams>) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const section = getSection('totals');

    if (!mainContractId || !section) {
      logUnableToProceed('processTotalsRequest', { mainContractId, section });
      return;
    }

    if (action.meta?.isUpdate) dispatch(setCustomIsUpdating({ id: 'totals', state: true }));
    else dispatch(setCustomIsLoading({ id: 'totals', state: true }));

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.GET_TOTALS,
        payload: {
          main_contract_id: mainContractId,
          section,
          period: makeManagerReqVersionedPeriod(action.payload),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ManagerReqTotals>>(response.payload);

          dispatch(setManagerReqTotals(result?.data));
          dispatch(setManagerReqBaseContractTotals(result?.data));
          dispatch(setManagerReqTotalsScenario(result?.scenario));
        } else {
          processError({ activityName: 'Request totals', response });
        }

        if (action.meta?.isUpdate) dispatch(setCustomIsUpdating({ id: 'totals', state: false }));
        else dispatch(setCustomIsLoading({ id: 'totals', state: false }));
      },
    );
  };

  const processPostLineRequest = (action: Action) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const period = getManagerReqSelectedPeriod(getState());

    if (!mainContractId || !period) {
      logUnableToProceed('processPostLineRequest', { mainContractId, period });
      return;
    }

    dispatch(setCustomIsLoading({ id: 'form', state: true }));
    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.POST,
        payload: {
          data: { ...action.payload, period, resource_id: mainContractId, resource_type: ResourceType.MAIN_CONTRACT },
        },
      },
      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(sendInfo('Line Item was created.'));

          processBaseContractDataRequest(makeUpdatedAction(actionWrapper({}, { isInitial: true })))();
          processTotalsRequest(makeUpdatedAction(actionWrapper({})))();
        } else {
          processError({ activityName: 'Request line item create', response });
        }

        if (action.meta?.callback) action.meta.callback(response);

        dispatch(setCustomIsLoading({ id: 'form', state: false }));
      },
    );
  };

  const processPatchLineRequest = (action: Action) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const period = getManagerReqSelectedPeriod(getState());

    if (!mainContractId || !period) {
      logUnableToProceed('processPatchLineRequest', { mainContractId, period });
      return;
    }

    const { line_item_id, data } = action.payload;

    const group = data.group ?? null;

    dispatch(setCustomIsLoading({ id: 'form', state: true }));
    getService().request(
      {
        [QueryDomain.LINE_ITEMS]: QueryAction.PATCH,
        payload: {
          line_item_id,
          data: {
            ...{ group, ...data },
            period,
            resource_id: mainContractId,
            resource_type: ResourceType.MAIN_CONTRACT,
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ManagerReqLine>>(response.payload);

          const oldItem = getManagerReqBaseContractItem(getState())(line_item_id);

          const isGroupChanged = oldItem?.group !== result?.data.group;

          // if group wasn't changed, no need to update the whole page data
          if (result?.data && !isGroupChanged) dispatch(updateManagerReqLineItem(result.data));
          else processBaseContractDataRequest(makeUpdatedAction(actionWrapper({}, { isInitial: true })))();

          processTotalsRequest(makeUpdatedAction(actionWrapper({})))();

          dispatch(sendInfo('Line Item was updated.'));

          if (action.meta?.callback) action.meta.callback(response);
        } else {
          processError({ activityName: 'Request line item update', response });

          if (action.meta?.callback) action.meta.callback(response);
        }

        dispatch(setCustomIsLoading({ id: 'form', state: false }));
      },
    );
  };

  const processDeleteLineRequest = (action: Action) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const period = getManagerReqSelectedPeriod(getState());

    if (!mainContractId || !period) {
      logUnableToProceed('processDeleteLineRequest', { mainContractId, period });
      return;
    }

    const { line_item_id } = action.payload;

    dispatch(setCustomIsLoading({ id: 'form', state: true }));
    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.DELETE_LINE_ITEM,
        payload: {
          line_item_id,
          data: {
            period,
            resource_id: mainContractId,
            resource_type: ResourceType.MAIN_CONTRACT,
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(removeManagerReqBaseContractLineItem(line_item_id));

          processBaseContractDataRequest(makeUpdatedAction(actionWrapper({}, { isInitial: false })))();

          processTotalsRequest(makeUpdatedAction(actionWrapper({})))();

          dispatch(sendInfo('Line item was deleted.'));

          if (action.meta?.callback) action.meta.callback(response);
        } else {
          dispatch(
            alertError({
              title: 'Request line item delete',
              content: capitalize(String(response?.message)) ?? 'Failed to perform the action',
              buttonLabel: 'Got it',
            }),
          );
        }

        dispatch(setCustomIsLoading({ id: 'form', state: false }));
      },
    );
  };

  const processOrdersMoreRequest = () => () => {
    const mainContractId = getCurrentMainContractId(getState());

    const section = getSection('changeOrders');

    if (!mainContractId || !section) {
      logUnableToProceed('processOrdersMoreRequest', { mainContractId, section });
      return;
    }

    const query = getManagerReqChangeOrdersQuery(getState());

    const meta = getManagerReqChangeOrdersMeta(getState());

    if (!meta || (meta?.page || 0) + 1 > (meta?.last || 0)) return;

    dispatch(setCustomIsUpdating({ id: 'changeOrder', state: true }));

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.GET_CHANGE_ORDERS,
        payload: {
          main_contract_id: mainContractId,
          section,
          period: getManagerReqSelectedPeriod(getState()),
          meta: assoc('page', meta.next, meta),
          query,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqDetailChangeOrder[]>>(response.payload);

          dispatch(updateManagerReqChangeOrders(result));
          dispatch(setManagerReqChangeOrdersQuery(result?.query));
          dispatch(setManagerReqChangeOrdersMeta(result?.meta));
        } else {
          processError({
            activityName: 'Request more change orders',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'changeOrder', state: false }));
      },
    );
  };

  const processGetReqPeriods = () => () => {
    const main_contract_id = getCurrentMainContractId(getState());

    if (!main_contract_id) {
      logUnableToProceed('processGetReqPeriods', { main_contract_id });

      return;
    }

    dispatch(setCustomIsUpdating({ id: 'reqPeriods', state: true }));

    getService().request(
      { [QueryDomain.MANAGER_REQ]: QueryAction.GET_PERIODS, payload: { main_contract_id } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqPeriod[]>>(response.payload);

          dispatch(setManagerReqReqPeriods(result?.data));
        } else {
          processError({
            activityName: 'Request REQ periods',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'reqPeriods', state: false }));
      },
    );
  };

  const processUpdatePage = (action: Action<UpdateManagerReqPagePayload>) => () => {
    if (!checkIsValid(action.meta?.isValid)) return;

    const main_contract_id = getCurrentMainContractId(getState());

    if (!main_contract_id) {
      logUnableToProceed('processUdateManagerReqPage', { main_contract_id });

      return;
    }

    setIsBusy({ id: 'status', state: true, meta: action.meta });

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.UPDATE_MANAGER_REQ_PAGE,
        payload: { main_contract_id, body: { ...action.payload, period: getManagerReqSelectedPeriod(getState()) } },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          processPageDataRequest(actionWrapper({ period: undefined }, action.meta))();

          setIsBusy({ id: 'status', state: false, meta: action.meta });
        } else {
          setIsBusy({ id: 'status', state: false, meta: action.meta });

          processError({
            activityName: 'Request Update ManagerReq page',
            response,
          });
        }
      },
    );
  };

  const processPageStatusRequest = (action: Action<ManagerReqShowParams>) => () => {
    const mainContractId = getCurrentMainContractId(getState());

    if (!mainContractId) {
      logUnableToProceed('processPageDataRequest', { mainContractId });
      return;
    }

    setIsBusy({ meta: action.meta, state: true });

    getService().request(
      {
        [QueryDomain.MANAGER_REQ]: QueryAction.SHOW,
        payload: {
          main_contract_id: mainContractId,
          period: makeManagerReqVersionedPeriod(action.payload),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ManagerReqDetailData>>(response.payload);

          if (result) dispatch(setPageStatusFromManagerReq(result.data.status as ManagerReqPageStatuses));

          setIsBusy({ meta: action.meta, state: false });
        } else {
          processError({ activityName: 'Request manager req page status', response });

          setIsBusy({ meta: action.meta, state: false });
        }
      },
    );
  };

  return {
    processActions,
    processHistoryRequest,
    processPageDataRequest,
    processPatchLineRequest,
    processPostLineRequest,
    processDeleteLineRequest,
    processBaseContractDataRequest,
    processChangeOrdersDataRequest,
    processTotalsRequest,
    processOrdersMoreRequest,
    processBaseContractMoreRequest,
    processGetReqPeriods,
    processUpdatePage,
    processPageStatusRequest,
  };
}
