import clsx from 'clsx';
import * as React from 'react';

import styles from './SubNavbar.module.scss';

interface Props {
  left?: React.ReactNode;
  right?: React.ReactNode;
  children?: React.ReactNode;
}

// new-ui
export function SubNavbar({ left, right, children }: Props) {
  return (
    <section className={clsx(styles.container, 'row j-between w-100p')}>
      {left ? <div className={clsx(styles.left, 'row j-start a-center o-hidden')}>{left}</div> : <div />}
      {!!children && children}
      {right ? <div className={clsx(styles.right, 'row j-end a-center')}>{right}</div> : <div />}
    </section>
  );
}
