import { prop } from 'ramda';

import { AnyValue } from 'src/models/general.model';

export function isObject(data: unknown): boolean {
  if (data !== Object(data) || Array.isArray(data) || typeof data === 'function') return false;

  return true;
}

// TODO: obsolete? use ajv?
export function validateLoginObject(data: AnyValue): boolean {
  if (!isObject(data)) return false;

  if (!prop('user', data) || !prop('token', data)) return false;

  if (!isObject(data.user) || typeof data.token !== 'string') return false;

  if (!prop('email', data.user) || !prop('full_name', data.user) || !prop('id', data.user)) return false;

  return true;
}

export function validateArrayOf(arr: unknown[], types: string[]): boolean {
  if (!Array.isArray(arr) || !Array.isArray(types)) return false;

  if (arr.length === 1) return types.includes(typeof arr[0]);

  return validateArrayOf(arr.slice(1, arr.length), types);
}

export function isEmpty(data: AnyValue, isNil = false): boolean {
  if (isNil && (typeof data === 'undefined' || data === null)) return true;

  if (Array.isArray(data) || typeof data === 'string' || data instanceof String) return !data.length;

  if (data instanceof Map || data instanceof Set) return !data.size;

  if ({}.toString.call(data) === '[object Object]') return !Object.keys(data).length;

  return false;
}
