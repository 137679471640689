import {
  QueryActions,
  CommentsActions,
  CommentThreadActions,
  Action,
  DocumentsActions,
  RequestDocumentsProps,
  PicturesActions,
  ID,
  ActionMeta,
  PictureDrawing,
  Period,
  AttachmentsContext,
  ResourceType,
} from 'src/models';

// new-ui
export interface RequestAttachmentsProps {
  resource_id: ID;
  resource_type: string;
  context?: AttachmentsContext;
  period?: Period;
}

interface PostCommentProps extends RequestAttachmentsProps {
  content: string;
  comment_thread_id?: ID;
}

export interface DeleteDocumentProps {
  document_id: ID;
}

export interface RequestCommentThreadProps {
  resource_id: ID;
  resource_type: ResourceType;
}

export interface PostCommentThreadProps extends RequestCommentThreadProps {
  content: string;
  location: string;
}

export const requestComments = (payload: RequestAttachmentsProps, meta?: ActionMeta): Action => ({
  type: CommentsActions.GET,
  payload,
  meta,
});

export const requestPictures = (payload: RequestAttachmentsProps, meta?: ActionMeta): Action => ({
  type: PicturesActions.GET,
  payload,
  meta,
});

export const postComment = (payload: PostCommentProps, meta?: ActionMeta) => ({
  type: CommentsActions.POST,
  payload,
  meta,
});

export const requestCommentThreads = (payload: RequestCommentThreadProps): Action => ({
  type: CommentThreadActions.GET,
  payload,
});

export const requestSingleCommentThread = (id: ID): Action => ({
  type: CommentThreadActions.SINGLE,
  payload: id,
});

export const postCommentThread = (payload: PostCommentThreadProps, meta?: ActionMeta) => ({
  type: CommentThreadActions.POST,
  payload,
  meta,
});

export const requestDocuments = (params: RequestDocumentsProps, meta?: ActionMeta): Action => ({
  type: DocumentsActions.GET,
  payload: params,
  meta,
});

export const requestDocument = (params: RequestDocumentsProps, meta?: ActionMeta): Action => ({
  type: DocumentsActions.GET_DOCUMENT,
  payload: params,
  meta,
});

interface UpdateDocumentParams {
  document_id: ID;
  included_in_pay_application?: boolean;
  title?: string;
  completed?: boolean;
}

export const updateDocument = (payload: UpdateDocumentParams, meta?: ActionMeta): Action => ({
  type: DocumentsActions.UPDATE,
  payload,
  meta,
});

export const deleteDocument = (document_id: ID, meta?: ActionMeta): Action => ({
  type: DocumentsActions.DELETE,
  payload: document_id,
  meta,
});

export const searchDocument = (document_id: ID, search: string): Action => ({
  type: DocumentsActions.SEARCH,
  payload: { document_id, search },
});

interface UpdatePictureParams {
  picture_id: ID;
  included_in_pay_application?: boolean;
  title?: string;
}

export const updatePicture = (payload: UpdatePictureParams, meta?: ActionMeta): Action => ({
  type: PicturesActions.UPDATE,
  payload,
  meta,
});

export const addPictureTags = (picture_id: ID, tags: string[]): Action => ({
  type: PicturesActions.ADD_TAGS,
  payload: { picture_id, tags },
});

export const deletePictureTags = (picture_id: ID, tags: string[]): Action => ({
  type: PicturesActions.DELETE_TAGS,
  payload: { picture_id, tags },
});

export const deletePicture = (picture_id: ID, meta?: ActionMeta): Action => ({
  type: PicturesActions.DELETE,
  payload: picture_id,
  meta,
});

export const getPictureDrawing = (picture_id: ID): Action => ({
  type: QueryActions.GET_DRAWING,
  payload: picture_id,
});

export const postPictureDrawing = (drawing: PictureDrawing, meta?: ActionMeta): Action => ({
  type: QueryActions.POST_DRAWING,
  payload: drawing,
  meta,
});

// end-of new-ui

export const wipeout = (): Action => ({ type: 'wipeout' });

export const requestPostComment = (resource_id: ID, resource_type: string, content: string): Action => ({
  type: CommentsActions.POST,
  payload: {
    resource_id,
    resource_type,
    content,
  },
});
