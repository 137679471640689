import { RootState } from '..';

export const getUser = (state: RootState) => state.user.current;

export const getUserNotifications = (state: RootState) => state.user.notifications;

export const getUserInvitation = (state: RootState) => state.user.invitation;

export const getUserProfile = (state: RootState) => state.user.profile;

export const getUserProfileScenario = (state: RootState) => state.user.profileScenario;

export const getUserLastTimeCodeSent = (state: RootState) => state.user.lastTimeCodeSent;

export const getLoginData = (state: RootState) => state.user.loginData;

export const getUserToken = (state: RootState) => state.user.token;

export const getUserAdminScenario = (state: RootState) => state.user.userAdminScenario;

export const getUserAdmin = (state: RootState) => state.user.userAdmin;

export const getUserTimezoneId = (state: RootState) => state.user.current?.timezone_id;
