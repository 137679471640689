import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='39'
      fill='none'
      stroke='none'
      viewBox='0 0 36 39'
      data-testid='icon_map-pin'
    >
      <path
        stroke='none'
        d='M31.5 16.582C31.5 27.504 18 36.867 18 36.867S4.5 27.504 4.5 16.582c0-3.725 1.422-7.297 3.954-9.93C10.986 4.017 14.42 2.537 18 2.537c3.58 0 7.014 1.48 9.546 4.113 2.532 2.634 3.954 6.206 3.954 9.93z'
      />
      <path
        fill='#fff'
        stroke='none'
        d='M18 19.703c1.657 0 3-1.398 3-3.121 0-1.724-1.343-3.121-3-3.121s-3 1.397-3 3.12c0 1.724 1.343 3.122 3 3.122z'
      />
    </svg>
  );
}

export default Icon;
