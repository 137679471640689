import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_notification'
      width='20'
      height='22'
      fill='none'
      viewBox='0 0 20 22'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M16 7A6 6 0 104 7c0 7-3 9-3 9h18s-3-2-3-9zM11.73 20a1.999 1.999 0 01-3.46 0'
      />
    </svg>
  );
}

export default Icon;
