import { StateStore, User } from 'src/models';
import { ApiEndpoints } from 'src/models/api.model';
import { ApiService } from 'src/services/api.service';
import { clearToken } from 'src/services/storage.service';
import { setIsAuthed, setAppIsLoading } from 'src/store/app/app.reducer';
import { setUser, updateUserToken } from 'src/store/user/user.reducer';
import { processErrorEvent } from 'src/tools/error.tools';

export async function processUserCheckTokenRequest({ dispatch }: StateStore) {
  const response = await ApiService.instance().get<User>({ endpoint: ApiEndpoints.CHECK_TOKEN });

  if (response.isOK) {
    dispatch(setUser(response.payload.data));
    dispatch(setIsAuthed(true));
  } else {
    clearToken();
    processErrorEvent({ activityName: 'processUserCheckTokenRequest', err: response.payload });
    dispatch(setUser());
    dispatch(updateUserToken());
  }

  dispatch(setAppIsLoading(false));
}
