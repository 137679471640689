import axios, { AxiosPromise, CancelToken } from 'axios';
import { AnyValue, RecordObject } from 'src/models';

import { getBaseUrlWithHttp } from 'src/tools/global.tools';
import { makeStringFromTemplate } from 'src/tools/string.tools';
import { getToken } from './storage.service';

axios.defaults.baseURL = getBaseUrlWithHttp();

interface RequestProps {
  service: string;
  token?: string;
  params?: RecordObject;
  index?: string[];
  data?: unknown;
  headers?: RecordObject;
  responseType?: 'arraybuffer';
  onUploadProgress?: (arg0: number) => void;
  cancelToken?: CancelToken;
}

export function post({ service, index, headers, onUploadProgress, ...props }: RequestProps): AxiosPromise<AnyValue> {
  return axios({
    headers: { ...headers, Authorization: getToken() },
    method: 'post',
    url: makeStringFromTemplate(service, index as string[]),
    onUploadProgress: progressEvent => {
      if (onUploadProgress) {
        onUploadProgress(Math.ceil((progressEvent.loaded / progressEvent.total) * 100));
      }
    },
    ...props,
  });
}

export function patch({ service, index, headers, onUploadProgress, ...props }: RequestProps): AxiosPromise<AnyValue> {
  return axios({
    headers: { ...headers, Authorization: getToken() },
    method: 'patch',
    url: makeStringFromTemplate(service, index as string[]),
    onUploadProgress: progressEvent => {
      if (onUploadProgress) {
        onUploadProgress(Math.ceil((progressEvent.loaded / progressEvent.total) * 100));
      }
    },
    ...props,
  });
}
