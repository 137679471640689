import { FileInfo, ID } from './general.model';

export interface NotificationMessageType {
  title: string;
  background_color?: string;
}

export interface NotificationMessage {
  content: string;
  file?: FileInfo;
  read: boolean;
  created_at: Date;
  notification_type?: NotificationMessageType;
  id: ID;
}

export enum AppModule {
  MANAGER_REQ = 'manager_req',
  REQ_DETAIL = 'req_detail',
  PROJECTS = 'subProject',
}

export interface PageSubscription {
  module: AppModule;
  id: ID;
}
