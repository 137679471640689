import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_dollar'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 1v22' />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M17 5H9.5a3.5 3.5 0 100 7h5a3.5 3.5 0 110 7H6'
      />
    </svg>
  );
}

export default Icon;
