import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteItem } from 'src/models/routes.model';

import { useDomainPermissions } from 'src/hooks/useDomainPermissions';
import { PATHS } from 'src/data/routes.data';
import { getCurrentSubProject, getIsLockAndRollInProgress } from '../store/subProject/subProject.getters';
import { getIsLoading } from '../store/loading/loading.reducer';
import { Loading } from '../components/Loading';
import { SyncParamsWithStore } from './SyncParamsWithStore';
import { UIBlocker } from 'src/components/UIBlocker';
import { t } from 'src/tools/text.tools';

const TXT = t(['protectedRoute']);

export function ProtectedRoute({ component, path, exact, isNotProtected, isBlockedDuringLockAndRoll }: RouteItem) {
  const isFetchedSubProject = !!useSelector(getCurrentSubProject);

  const isLockAndRollInProgress = useSelector(getIsLockAndRollInProgress);

  const isLoading = useSelector(getIsLoading);

  const { isAllowedPath } = useDomainPermissions();

  const isAllowed = isAllowedPath(path);

  function renderComponent() {
    if (isBlockedDuringLockAndRoll && isLockAndRollInProgress)
      return <UIBlocker className='page-without-submenu' text={TXT('lockAndRollInProgress')} isTransparent />;

    if (isFetchedSubProject || isNotProtected) return component;

    if (isLoading) return <Loading isFull />;

    return <></>;
  }

  // check if user has access to path
  if (!isNotProtected && isFetchedSubProject && !isAllowed) return <Redirect from={path} to={PATHS.LOGIN} />;

  return (
    <Route path={path} exact={exact}>
      <SyncParamsWithStore>{renderComponent()}</SyncParamsWithStore>
    </Route>
  );
}
