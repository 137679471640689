import * as React from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { VariableSizeList } from 'react-window';

import { AnyValue } from 'src/models/general.model';

interface Props<T> {
  hasNextPage: boolean; // Are there more items to load?
  isNextPageLoading: boolean; // Are we currently loading a page of items?
  items: T[]; // Array of items loaded so far.
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<AnyValue>; // Callback function responsible for loading the next page of items.
  limit?: number;
  height: number;
  renderItem: (data: T) => JSX.Element;
  itemHeight: number;
}

export function RenderInfiniteList<T>({
  hasNextPage,
  isNextPageLoading,
  height,
  items,
  loadNextPage,
  renderItem,
  itemHeight,
}: Props<T>) {
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  const loadMoreItems = (startIndex: number, stopIndex: number) => {
    return isNextPageLoading ? null : loadNextPage(startIndex, stopIndex);
  };

  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Item = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    let content;

    if (!isItemLoaded(index)) {
      content = 'Loading...';
    } else {
      content = renderItem(items[index]);
    }

    return <div style={style}>{content}</div>;
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems} threshold={3}>
      {({ onItemsRendered, ref }) => (
        <VariableSizeList
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
          itemSize={(_index: number) => itemHeight}
          height={height}
          width='100%'
        >
          {Item}
        </VariableSizeList>
      )}
    </InfiniteLoader>
  );
}
