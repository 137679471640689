import * as React from 'react';
import clsx from 'clsx';

import { AnyValue } from 'src/models';
import { UseIcon } from 'src/components/UseIcon';
import { Input } from '../Input';
import { ifTrue } from 'src/tools/logic.tools';
import { t } from 'src/tools/text.tools';

import classes from './SearchInput.module.scss';

const TXT = t(['searchInput']);

interface Props {
  className?: string;
  onSubmit?: () => void;
  onChange: (value: AnyValue) => void;
  value: string;
}

// new-ui
export function SearchInput({ className, onSubmit, onChange, value }: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  function handleOnKeyUp(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && onSubmit) {
      e.stopPropagation();
      onSubmit();
    }
  }

  return (
    <div className={clsx(classes.container, 'row a-center items-p8', className)}>
      <Input
        ref={inputRef}
        onKeyUp={handleOnKeyUp}
        className={clsx(classes.input, 'f-1')}
        onChange={onChange}
        value={value}
        placeholder={TXT('search')}
        isSmall
      />
      <UseIcon wrapElement={ifTrue(onSubmit, 'button')} className={classes.button} onClick={onSubmit} iconId='search' />
    </div>
  );
}
