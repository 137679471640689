import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'ramda';

import { INITIAL_LEVELS_STATE } from 'src/store/levels/levels.model';
import { Maybe } from 'src/models/general.model';
import { Level, LevelsData, LevelType } from 'src/models/levels.model';
import { Results } from 'src/models';

export const slice = createSlice({
  name: 'levels',
  initialState: INITIAL_LEVELS_STATE,
  reducers: {
    clearLevelsState: () => INITIAL_LEVELS_STATE,
    setLevels: (state, action: PayloadAction<{ level_type: LevelType; data: Maybe<Results<Level[]>> }>) => {
      state.levels = state.levels ?? ({} as Record<LevelType, Maybe<LevelsData>>);

      state.levels[action.payload.level_type] = pick(['data', 'query'], action.payload.data) as LevelsData;

      if (!state.scenario || !action.payload.data?.scenario) state.scenario = action.payload.data?.scenario;

      if (!state.pagePermissions || !action.payload.data?.permissions)
        state.pagePermissions = action.payload.data?.permissions;
    },
    clearLevels: state => ({
      ...INITIAL_LEVELS_STATE,
      allLevels: state.allLevels,
    }),
    updateLevel: (state, action: PayloadAction<Level>) => {
      const { level_type } = action.payload;

      const level = state.levels?.[level_type];

      if (level) level.data = level.data.map(i => (i.id === action.payload.id ? action.payload : i));
    },
    setAllLevels: (state, action: PayloadAction<Maybe<Level[]>>) => {
      state.allLevels = action.payload;
    },
  },
});

export const { clearLevelsState, setLevels, clearLevels, updateLevel, setAllLevels } = slice.actions;

export default slice.reducer;
