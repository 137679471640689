import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { INITIAL_CONTRACT_TERMS_STATE } from './contract_terms.model';

import { RootState } from 'src/store';
import { ContractTerms } from 'src/models/contract_terms.model';
import { ID } from 'src/models/general.model';
import { mapArrayElementsById, omitById } from 'src/tools/object.tools';

export const slice = createSlice({
  name: 'contractTerms',
  initialState: INITIAL_CONTRACT_TERMS_STATE,
  reducers: {
    clearContractTermsState: () => INITIAL_CONTRACT_TERMS_STATE,
    setContractTerms: (state, action: PayloadAction<ContractTerms[]>) => {
      state.contractTerms = action.payload;
    },
    changeSingleContractTerm: (state, action: PayloadAction<ContractTerms>) => {
      if (state.contractTerms) state.contractTerms = mapArrayElementsById(state.contractTerms, action.payload);
    },
    addSingleContractTerm: (state, action: PayloadAction<ContractTerms>) => {
      state.contractTerms = [...(state.contractTerms ?? []), action.payload];
    },
    deleteSingleContractTerm: (state, action: PayloadAction<ID>) => {
      if (state.contractTerms) state.contractTerms = omitById(state.contractTerms, action.payload);
    },
  },
});

export const getContractTerms = (state: RootState) => state.contractTerms.contractTerms;

export const getContractTermsById = (state: RootState) => (id: ID) =>
  state.contractTerms.contractTerms?.filter(el => String(el.id) === String(id))[0];

export const {
  clearContractTermsState,
  setContractTerms,
  changeSingleContractTerm,
  deleteSingleContractTerm,
  addSingleContractTerm,
} = slice.actions;

export default slice.reducer;
