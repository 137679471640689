import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
      data-testid='icon_envelope'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2.666 2.667h10.667c.733 0 1.333.6 1.333 1.333v8c0 .733-.6 1.333-1.333 1.333H2.666c-.733 0-1.333-.6-1.333-1.333V4c0-.733.6-1.333 1.333-1.333z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M14.666 4L8 8.667 1.333 4' />
    </svg>
  );
}

export default Icon;
