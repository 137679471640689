import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { CONFIG } from 'src/config';
import { closeResizableWindow } from 'src/store/app/app.actions';
import { setActiveResizableWindowId, getActiveResizableWindowId } from 'src/store/app/app.reducer';
import { ResizableWindowContext, ConfirmationProps } from 'src/contexts/resizableWindow.context';

import { Backdrop } from '../Backdrop';
import { Maybe } from 'src/models';
import { Confirmation } from 'src/components/Confirmation';

import classes from './Window.module.scss';

export interface WindowParameters {
  x?: number;
  y?: number;
  width?: number | string;
  height?: number | string;
}

interface Props {
  Content: React.ReactElement;
  options?: WindowParameters;
  title?: string;
  windowId: string;
  onClose?: () => void;
  setVisibility: (isVisible: boolean) => void;
}

export function Window({
  Content,
  options = CONFIG.resizableWindow.defaultOptions(),
  windowId,
  title,
  onClose,
  setVisibility,
}: Props) {
  const [confirmationProps, setConfirmationProps] = useState<Maybe<ConfirmationProps>>();

  const [onCloseConfirmationFn, setOnCloseConfirmationFn] = useState<Maybe<() => void>>();

  const [width, setWidth] = useState(options.width ?? 610);

  const [height, setHeight] = useState(options.height ?? 'auto');

  const [positionX, setPositionX] = useState(options.x ?? (window.innerWidth - parseInt(String(width), 10)) / 2);

  const [positionY, setPositionY] = useState(options.y ?? window.innerHeight * 0.3);

  const dispatch = useDispatch();

  const activeWindowId = useSelector(getActiveResizableWindowId);

  const isActive = windowId === activeWindowId;

  const isDisabledClosing = !!confirmationProps;

  function closeConfirmation() {
    setOnCloseConfirmationFn(undefined);
  }

  function removeConfirmation() {
    setOnCloseConfirmationFn(undefined);
    setConfirmationProps(undefined);
  }

  function closeWindow() {
    dispatch(closeResizableWindow(windowId));
    onClose?.();
  }

  function showConfirmation(onCloseFn: () => void) {
    if (confirmationProps) {
      setOnCloseConfirmationFn(() => onCloseFn);
    }
  }

  function handleCloseWindow(isSkipConfirmation?: boolean) {
    if (isDisabledClosing && !isSkipConfirmation) setOnCloseConfirmationFn(() => closeWindow);
    else closeWindow();
  }

  function handleOnDragStart() {
    dispatch(setActiveResizableWindowId(windowId));
  }

  function handleOnConfirm() {
    if (confirmationProps && onCloseConfirmationFn) {
      confirmationProps.onConfirm(onCloseConfirmationFn);
      removeConfirmation();
    }
  }

  function handleOnDiscard() {
    if (onCloseConfirmationFn) {
      removeConfirmation();
      onCloseConfirmationFn();
    }
  }

  function renderConfirmation() {
    if (onCloseConfirmationFn && confirmationProps) {
      return (
        <Confirmation
          {...confirmationProps}
          onConfirm={handleOnConfirm}
          onCancel={closeConfirmation}
          onDiscard={handleOnDiscard}
        />
      );
    }
  }

  return (
    <ResizableWindowContext.Provider
      value={{
        closeWindow: handleCloseWindow,
        setConfirmationProps,
        showConfirmation,
        isDisabledClosing,
        windowId,
        title,
        isActive,
        setVisibility,
        renderConfirmation,
      }}
    >
      <Rnd
        disableDragging={false}
        enableUserSelectHack={true}
        size={{ width: width, height: height }}
        position={{ x: positionX, y: positionY }}
        onDragStop={(e, d) => (setPositionX(d.x), setPositionY(d.y))}
        onResizeStop={(e, direction, ref, delta, position) => {
          return {
            width: setWidth(ref.style.width),
            height: setHeight(ref.style.height),
            ...position,
          };
        }}
        className={clsx(classes.dialog, 'row centered o-hidden', { [classes.active]: isActive })}
        onDragStart={handleOnDragStart}
        dragHandleClassName='rnd-header-draggable'
      >
        {React.cloneElement(Content)}
        {renderConfirmation()}
      </Rnd>
      <Backdrop />
    </ResizableWindowContext.Provider>
  );
}
