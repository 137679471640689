import {
  ManagerReqDetailPage,
  ManagerReqLine,
  ManagerReqTotals,
  Maybe,
  Meta,
  PageInfo,
  PageQuery,
  RecordObject,
  Scenario,
  Sidebar,
  VersionedPeriod,
} from 'src/models';
import { History } from 'src/models/history.model';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { Permission } from 'src/models/permissions.model';
import { ReqDetailChangeOrder } from 'src/models/reqDetail.model';

export interface ManagerReqState extends RecordObject {
  baseContractData?: Maybe<ManagerReqLine[]>;
  baseContractMeta?: Maybe<Meta>;
  baseContractQuery?: Maybe<PageQuery>;
  baseContractInfo?: Maybe<PageInfo>;
  baseContractScenario?: Maybe<Scenario>;
  baseContractTotals?: Maybe<ManagerReqTotals>;
  baseContractPages?: Maybe<number[]>; // numbers of all requested pages, for updating
  changeOrdersPages?: Maybe<number[]>; // numbers of all requested pages, for updating
  changeOrdersData?: Maybe<ReqDetailChangeOrder[]>;
  changeOrdersMeta?: Maybe<Meta>;
  changeOrdersQuery?: Maybe<PageQuery>;
  changeOrdersScenario?: Maybe<Scenario>;
  changeOrdersTotals?: Maybe<ManagerReqTotals>;
  currentPeriod?: Maybe<VersionedPeriod>;
  pagePermissions?: Maybe<Permission[]>;
  // TODO: switch to maybe
  history: History[];
  meta?: Maybe<Meta>;
  page?: Maybe<ManagerReqDetailPage>;
  selectedPeriod?: Maybe<VersionedPeriod>;
  sidebar?: Maybe<Sidebar>;
  tabTotals?: Maybe<ManagerReqTotals>;
  tabTotalsScenario?: Maybe<Scenario>;
  reqPeriods?: Maybe<ReqPeriod[]>;
  uploadInProgress?: boolean;
}

export const INITIAL_MANAGE_REQ_STATE: ManagerReqState = {
  history: [],
};
