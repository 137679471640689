import { Maybe } from 'src/models/general.model';
import { ShowScenario } from 'src/models/scenario.model';
import { SubProjectTeam, SubProject, SubProjectTeamSortBy } from 'src/models/sub_project.model';
import { InvitedMember, Member } from 'src/models/team.model';
import { Permission } from 'src/models/permissions.model';

export interface SubProjectStateModel {
  currentSubProject?: Maybe<SubProject>;
  subProjects?: Maybe<SubProject[]>;
  scenario?: Maybe<ShowScenario>;
  subProjectTeam?: Maybe<SubProjectTeam>;
  teamPagePermissions?: Maybe<Permission[]>;
  subProjectTeamMember?: Maybe<Member[]>;
  subProjectTeamInvite?: Maybe<InvitedMember[]>;
  subProjectTeamSearch: string;
  subProjectTeamSortBy: SubProjectTeamSortBy;
  permissions?: Maybe<Permission[]>;
}

export const INITIAL_SUBPROJECT_STATE: SubProjectStateModel = {
  subProjectTeamSearch: '',
  subProjectTeamSortBy: 'LastName',
};
