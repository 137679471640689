import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_minus'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4.167 10h11.666' />
    </svg>
  );
}

export default Icon;
