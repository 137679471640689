import clsx from 'clsx';
import { CSSProperties, ReactNode, useRef, useState, MouseEvent, useCallback } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useBoundingClientRects } from 'src/hooks/useBoundingClientRects';
import { useIsIntersecting, useViewportSize, useDebouncedFn, useKeyPress } from 'src/hooks';
import { ifTrue } from 'src/tools/logic.tools';
import { Portal } from 'src/components/Portal';
import { P4 } from 'src/components/Typography';

import classes from './Dropdown.module.scss';

export interface BaseComponentProps {
  isOpen: boolean;
  isDisabled?: boolean;
  onClick: (e?: MouseEvent) => void;
  onFocus: () => void;
  onBlur: () => void;
  isFocused?: boolean;
}

export type Position = 'left' | 'right' | 'top' | 'bottom' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface Props {
  children: ReactNode;
  classNames?: {
    container?: string;
    dropdown?: string;
  };
  dropdownMinWidth?: 'container-width' | string | number;
  dropdownWidth?: 'container-width' | string | number;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  onRequestOpenClose?: (isOpen: boolean) => void;
  position?: Position;
  renderBaseComponent: (props: BaseComponentProps) => JSX.Element;
  isInline?: boolean; // dropdown content will be rendered next to the input
  tooltip?: string;
}

export function Dropdown({
  children,
  classNames,
  dropdownMinWidth,
  dropdownWidth,
  isDisabled,
  isLoading,
  isOpen: isOpenProp,
  onRequestOpenClose,
  position = 'bottom-left',
  renderBaseComponent,
  isInline,
  tooltip,
}: Props) {
  if (typeof isOpenProp !== 'undefined' && !onRequestOpenClose) {
    throw new Error('Dropdown: isOpen prop be defined together with onRequestOpenClose prop');
  }

  const [isOpenState, setIsOpenState] = useState(false);

  const isOpen = typeof isOpenProp !== 'undefined' ? isOpenProp : isOpenState;

  const [isMousePressed, setIsMousePressed] = useState(false);

  const setIsMousePressedDebounced = useDebouncedFn(setIsMousePressed, 100);

  const [isFocused, setIsFocused] = useState(false);

  const handleRequestOpenClose = useCallback(
    (value: boolean) => {
      if (onRequestOpenClose) {
        onRequestOpenClose(value);
      }

      setIsOpenState(value);
    },
    [onRequestOpenClose],
  );

  const handleArrowUpDown = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();

      handleRequestOpenClose(true);
    },
    [handleRequestOpenClose],
  );

  useKeyPress(
    {
      onArrowUp: handleArrowUpDown,
      onArrowDown: handleArrowUpDown,
    },
    isFocused,
  );

  function handleClose(e?: KeyboardEvent) {
    e?.stopPropagation();

    if (onRequestOpenClose) {
      onRequestOpenClose(false);
    }

    setIsOpenState(false);
  }

  function handleOpen() {
    if (onRequestOpenClose) onRequestOpenClose(true);

    setIsOpenState(true);
  }

  function handleMouseDown() {
    setIsMousePressed(true);
  }

  function handleMouseUp() {
    setIsMousePressedDebounced(false);
  }

  function handleOnClickOutside() {
    if (!isMousePressed) {
      handleClose();
    }
  }

  function handleFocus() {
    handleOpen();
    setIsFocused(true);
  }

  function handleBlur() {
    handleOnClickOutside();
    setIsFocused(false);
  }

  function handleClick() {
    if (isFocused) return;

    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  }

  const containerRef = useRef<HTMLDivElement>(null);

  useKeyPress({ onEscape: handleClose }, isOpen);

  const [viewportWidth, viewportHeight] = useViewportSize();

  const wrapperRef = useRef(null);

  const rects = useBoundingClientRects(wrapperRef, isOpen);

  const { isIntersecting } = useIsIntersecting(wrapperRef, isOpen);

  useOnclickOutside(handleOnClickOutside, {
    disabled: !isOpen,
    refs: [containerRef],
  });

  function getStyle(): CSSProperties {
    const width = dropdownWidth === 'container-width' ? rects?.width : dropdownWidth;

    const minWidth = dropdownMinWidth === 'container-width' ? rects?.width : dropdownMinWidth;

    if (!rects) {
      return {
        top: 0,
        left: 0,
        width,
        minWidth,
        maxHeight: 'auto',
      };
    }

    const isRight = position.includes('right');

    const isAbove = position.includes('top');

    let marginTop = 0;

    let marginLeft = 0;

    if (containerRef?.current) {
      const marginTopValue = parseInt(getComputedStyle(containerRef.current).marginTop);

      const marginLeftValue = parseInt(getComputedStyle(containerRef.current).marginLeft);

      if (!Number.isNaN(marginTopValue)) marginTop = marginTopValue;

      if (!Number.isNaN(marginLeftValue)) marginLeft = marginLeftValue;
    }

    let top;

    let bottom;

    let right;

    let left;

    let maxHeight;

    let maxWidth;

    if (isAbove) {
      bottom = viewportHeight - rects.y;
      maxHeight = viewportHeight - bottom - (marginTop + 8);
    } else {
      top = position.includes('bottom') ? rects.y + rects.height : rects.y;
      maxHeight = viewportHeight - rects.bottom - (marginTop + 8);
    }

    if (position === 'right') {
      left = rects.x + rects.width;
      maxWidth = viewportWidth - left - (marginLeft + 8);
    } else if (position === 'left') {
      right = viewportWidth - rects.x;
      maxWidth = viewportWidth - right - (marginLeft + 8);
    } else if (isRight) {
      right = viewportWidth - rects.x - rects.width;
      maxWidth = viewportWidth - right - (marginLeft + 8);
    } else {
      left = rects.x;
      maxWidth = viewportWidth - left - (marginLeft + 8);
    }

    return {
      top: ifTrue(!Number.isNaN(top), top),
      bottom: ifTrue(!Number.isNaN(bottom), bottom),
      left: ifTrue(!Number.isNaN(left), left),
      right: ifTrue(!Number.isNaN(right), right),
      width,
      minWidth,
      maxHeight: ifTrue(!Number.isNaN(maxHeight), maxHeight),
      maxWidth: ifTrue(!Number.isNaN(maxWidth), maxWidth),
    };
  }

  function renderWrapper() {
    return (
      <div ref={wrapperRef} className={classes.wrapper} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        {renderBaseComponent({
          onClick: handleClick,
          onFocus: handleFocus,
          onBlur: handleBlur,
          isOpen,
          isDisabled,
          isFocused,
        })}
      </div>
    );
  }

  function renderLoading() {
    return <P4 className={clsx(classes.loading, 'w-100p')}>Loading...</P4>;
  }

  function containerDropdownContainer() {
    return (
      <div
        ref={containerRef}
        data-testid='dropdown-dropdown'
        className={clsx(classes.dropdown, classes[position], 'ignore-onclick-outside', classNames?.dropdown, {
          [classes.inline]: isInline,
        })}
        style={ifTrue(!isInline, getStyle())}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {children}
        {ifTrue(isLoading, renderLoading)}
      </div>
    );
  }

  if (isInline)
    return (
      <>
        <div className={clsx(classes.container, 'row', classNames?.container)}>{renderWrapper()}</div>
        {ifTrue(isOpen && !isDisabled, containerDropdownContainer)}
      </>
    );

  return (
    <div
      data-testid='dropdown-container'
      className={clsx(classes.container, 'row', classNames?.container)}
      title={tooltip}
    >
      {renderWrapper()}
      <Portal parentId='dropdown'>{ifTrue(isOpen && isIntersecting && !isDisabled, containerDropdownContainer)}</Portal>
    </div>
  );
}
