import React from 'react';

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' data-testid='icon_question-fill'>
      <rect width='24' height='24' opacity='0' />
      <path d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 17a1.2 1.3 0 1 1 1-1 1 1 0 0 1-1 1zm1-5.16V14a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1 1.2 1.2 0 1 0-1.2-1.2 1 1 0 0 1-2 0 3.3 3.5 0 1 1 4.3 3.34z' />
    </svg>
  );
}

export default Icon;
