import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_ATTRIBUTE_CHANGES_STATE } from 'src/store/uiScenarios/uiScenarios.model';
import { FormScenarioType, FormsScenarioPatch } from 'src/models/uiScenarios.model';
import { Maybe } from 'src/models';

export const slice = createSlice({
  name: 'uiScenarios',
  initialState: INITIAL_ATTRIBUTE_CHANGES_STATE,
  reducers: {
    clearUiScenariosState: () => INITIAL_ATTRIBUTE_CHANGES_STATE,
    addFormScenario: (
      state,
      action: PayloadAction<{ type: FormScenarioType; scenario: Maybe<FormsScenarioPatch> }>,
    ) => {
      state.forms = state.forms ?? ({} as Record<FormScenarioType, FormsScenarioPatch>);

      state.forms[action.payload.type] = action.payload.scenario;
    },
    removeFormScenario: (state, action: PayloadAction<FormScenarioType>) => {
      if (state.forms) delete state.forms[action.payload];
    },
  },
});

export const { clearUiScenariosState, addFormScenario, removeFormScenario } = slice.actions;

export default slice.reducer;
