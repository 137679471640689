const REQUISITIONS_PATH = '/portfolio/:mainId/projects/:subId/requisitions';

const ADMINISTRATIVE_PATH = '/portfolio/:mainId/projects/:subId/administrative';

const CHANGE_ORDERS_PATH = '/portfolio/:mainId/projects/:subId/change_orders';

export const PATHS = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password',
  TWO_FACTOR_AUTHENTICATION: '/two-factor-authentication',
  PORTFOLIO: '/portfolio',
  REQ_INDEX: REQUISITIONS_PATH,
  REQ_SUMMARY: `${REQUISITIONS_PATH}/req_summary`,
  MANAGER_REQ: `${REQUISITIONS_PATH}/manager_req`,
  REQ_DETAIL: `${REQUISITIONS_PATH}/req_detail`,
  REQ_DETAIL_SHOW: `${REQUISITIONS_PATH}/req_detail/:tradeId`,
  PROJECT_SHOW: `${ADMINISTRATIVE_PATH}/project`,
  MAIN_CONTRACT_SHOW: `${ADMINISTRATIVE_PATH}/main_contract`,
  TRADES: `${ADMINISTRATIVE_PATH}/trades`,
  TRADE_SHOW: `${ADMINISTRATIVE_PATH}/trades/:tradeId`,
  COMPANIES: `${ADMINISTRATIVE_PATH}/companies`,
  USERS: `${ADMINISTRATIVE_PATH}/users`,
  ROLES: `${ADMINISTRATIVE_PATH}/roles`,
  TECHNICAL: `${ADMINISTRATIVE_PATH}/technical`,
  CHANGE_ORDERS: `${CHANGE_ORDERS_PATH}`,
  CHANGE_ORDERS_SHOW: `${CHANGE_ORDERS_PATH}/:coId`,
  USER_ADMIN: '/portfolio/user_admin',
  USER_SETTINGS: '/portfolio/user_settings',
  USER_SETTINGS_IN_PROJECT: '/portfolio/:mainId/projects/:subId/user_settings',
};

export const DOCUMENTATION = 'https://help.reqpay.com';

export const CONNECT_TO_SUPPORT = 'mailto:help@reqpay.com';
