import { RootState } from '..';
import { LevelType } from 'src/models/levels.model';

export const getLevels = (state: RootState) => (levelType: LevelType) => state.levels.levels?.[levelType]?.data;

export const getLevelsQuery = (state: RootState) => (levelType: LevelType) => state.levels.levels?.[levelType]?.query;

export const getAllLevels = (state: RootState) => state.levels.allLevels;

export const getLevelsPagePermissions = (state: RootState) => state.levels.pagePermissions;

export const getLevelsScenario = (state: RootState) => state.levels.scenario;
