import { v4 as uuidv4 } from 'uuid';

import { addUpload, updateProgress, removeUpload } from '../files/files.reducer';
import { upload } from 'src/services/file.service';
import { addMessage } from '../app/app.reducer';
import { Action, AttachmentFile, MessageCode, QueueItem, Result, StateStore, UploadActions } from 'src/models';
import { makeStringFromTemplate } from 'src/tools/string.tools';
import { t } from 'src/tools/text.tools';

const TXT = t(['files']);

export const uploadMiddleware =
  ({ dispatch }: StateStore) =>
  (next: (arg0: Action) => void) =>
  async (action: Action) => {
    next(action);

    // TODO: to process
    function updateProgressData(id: string) {
      return function update(progress: number) {
        dispatch(updateProgress({ id, progress }));
      };
    }

    function respond({
      id,
      item,
      action,
      onSuccess,
      onError,
    }: {
      id: string;
      item: string;
      action: string;
      onSuccess?: () => void;
      onError?: () => void;
    }) {
      return function res(result: Result) {
        dispatch(removeUpload(id));

        if (result.isOK) {
          onSuccess && onSuccess();
        } else {
          dispatch(
            addMessage({
              code: MessageCode.ERROR,
              content: makeStringFromTemplate(TXT('error'), [item, `${action}ed`]),
            }),
          );

          onError && onError();
        }
      };
    }

    switch (action.type) {
      case UploadActions.UPLOAD: {
        const { file, service, data, onSuccess, onError } = action.payload;

        const uploadItem: QueueItem<AttachmentFile> = {
          id: uuidv4(),
          payload: {
            title: file.name,
            type: file.type,
          },
        };

        dispatch(addUpload(uploadItem));

        upload({
          service,
          onUploadProgress: updateProgressData(uploadItem.id),
          data,
          respond: respond({
            id: uploadItem.id,
            item: uploadItem.payload.title,
            action: 'upload',
            onSuccess,
            onError,
          }),
        });
        break;
      }
    }
  };
