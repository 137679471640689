import { createContext } from 'react';
import { AnyValue, Maybe } from 'src/models';

export interface ConfirmationProps {
  title: string;
  className?: string;
  confirmButtonTitle?: string;
  onConfirm: (callback: () => void, prop?: AnyValue) => void;
  isXSmall?: boolean;
}

interface ResizableWindow {
  closeWindow: (isSkipConfirmation?: boolean) => void;
  setConfirmationProps: (props?: ConfirmationProps) => void;
  showConfirmation: (onCloseFn: () => void) => void;
  isDisabledClosing?: boolean;
  windowId: string;
  title?: string;
  isActive: boolean;
  setVisibility: (isVisible: boolean) => void;
  renderConfirmation?: () => Maybe<JSX.Element>;
  isHideCloseButton?: boolean;
}

export const ResizableWindowContext = createContext({} as ResizableWindow);
