import { RootState } from '..';
import { ID } from 'src/models';

export const getComments = (state: RootState) => state.attachments.comments;

export const getDocuments = (state: RootState) => state.attachments.documents;

export const getSubmissionsDocuments = (state: RootState) => state.attachments.submissionsDocuments;

export const getBackupDocuments = (state: RootState) => state.attachments.backupDocuments;

export const getDocumentItem = (state: RootState) => (id?: ID) => {
  if (state.attachments.documents) {
    return state.attachments.documents.find(doc => doc.id === id);
  }
};

export const getPictures = (state: RootState) => state.attachments.pictures;

export const getPicturesCount = (state: RootState) => state.attachments.pictures?.length || 0;

export const getPictureIndex = (state: RootState) => (id: ID) => {
  if (state.attachments.pictures) {
    return state.attachments.pictures.findIndex(pic => pic.id === id);
  }

  return -1;
};

export const getPictureItem = (state: RootState) => (id?: ID) => {
  if (state.attachments.pictures && id) {
    return state.attachments.pictures.find(pic => pic.id === id);
  }
};

export const getCommentThreads = (state: RootState) => state.attachments.commentThreads;

export const getCommentThread = (state: RootState) => (id?: ID) => {
  if (state.attachments.commentThreads && id) {
    return state.attachments.commentThreads.find(pic => pic.id === id);
  }
};

export const getPrevNextPictureIds = (state: RootState) => (id: ID) => {
  const { pictures } = state.attachments;

  const currIndex = getPictureIndex(state)(id);

  if (pictures && typeof currIndex === 'number') {
    return [pictures[currIndex - 1]?.id, pictures[currIndex + 1]?.id];
  }

  return [];
};

export const getPictureDrawings = (state: RootState) => state.attachments.pictureDrawings;

export const getPictureUrls = (state: RootState) => state.attachments.pictureUrls;
