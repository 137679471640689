import { addIndex, assoc, map } from 'ramda';
import { AnyValue, ID, Maybe } from 'src/models/general.model';

export const indexedCur =
  <T>(mapperFn: (el: AnyValue, idx: number) => T) =>
  <K>(arr: K[]) =>
    addIndex(map)(mapperFn, arr) as T[];

export function insertData<T extends { id: ID }>(currentData: Maybe<T[]>, newData: T[] = []): T[] {
  return currentData ? [...currentData, ...newData] : newData;
}

export function updateData<T extends { id: ID }>(currentData: Maybe<T[]>, newData: T[] = []): T[] {
  if (!currentData) {
    return newData;
  }

  let newDataObj: Record<string, T> = {};

  newData.forEach(item => {
    newDataObj = assoc(String(item.id), item, newDataObj);
  });

  const result: T[] = [];

  currentData.forEach(item => {
    const newItem = newDataObj[String(item.id)];

    if (newItem) {
      result.push(newItem);

      delete newDataObj[String(item.id)];
    } else {
      result.push(item);
    }
  });

  const leftItems: T[] = Object.values(newDataObj);

  return [...result, ...leftItems];
}
