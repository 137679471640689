import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
      data-testid='icon_map-pin-empty'
    >
      <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' clipPath='url(#clip-map-pin-empty)'>
        <path fill='none' d='M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666a6 6 0 1112 0z' />
        <path fill='none' d='M8 8.667a2 2 0 100-4 2 2 0 000 4z' />
      </g>
      <defs>
        <clipPath id='clip-map-pin-empty'>
          <path fill='#fff' d='M0 0H16V16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
