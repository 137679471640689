import { RouteItem } from 'src/models/routes.model';

import { Factory } from 'src/components/Factory';
import { Portfolio, ReqIndex } from 'src/pages/Portfolio';
import {
  ManagerReq,
  ReqSummary,
  Trades,
  ChangeOrders,
  Companies,
  ReqDetail,
  TradeShow,
  MainContract,
  ChangeOrderShow,
  ProjectShow,
  Roles,
  Users,
  UserSettings,
  TechnicalLayout,
  UserAdmin,
} from 'src/pages';
import { PATHS } from '../data/routes.data';

// just wrap lazy element into factory
function renderPage(component: React.ReactNode) {
  return <Factory component={component} isShowLoading />;
}

export const ROUTES: RouteItem[] = [
  { path: PATHS.PORTFOLIO, exact: true, isNotProtected: true, component: <Portfolio /> },
  { path: PATHS.REQ_INDEX, exact: true, component: <ReqIndex />, isBlockedDuringLockAndRoll: true },
  {
    path: PATHS.PROJECT_SHOW,
    exact: true,
    component: renderPage(<ProjectShow />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.TECHNICAL,
    exact: true,
    component: renderPage(<TechnicalLayout />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.REQ_SUMMARY,
    exact: true,
    component: renderPage(<ReqSummary />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.MANAGER_REQ,
    exact: true,
    component: renderPage(<ManagerReq />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.TRADE_SHOW,
    exact: true,
    component: renderPage(<TradeShow />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.REQ_DETAIL,
    exact: true,
    component: renderPage(<Trades />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.REQ_DETAIL_SHOW,
    exact: true,
    component: renderPage(<ReqDetail />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.MAIN_CONTRACT_SHOW,
    exact: true,
    component: renderPage(<MainContract />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.COMPANIES,
    exact: true,
    component: renderPage(<Companies />),
    isBlockedDuringLockAndRoll: true,
  },
  {
    path: PATHS.USERS,
    exact: true,
    component: renderPage(<Users />),
    isBlockedDuringLockAndRoll: true,
  },
  { path: PATHS.TRADES, exact: true, component: renderPage(<Trades />), isBlockedDuringLockAndRoll: true },
  { path: PATHS.ROLES, exact: true, component: renderPage(<Roles />), isBlockedDuringLockAndRoll: true },
  { path: PATHS.CHANGE_ORDERS, exact: true, component: renderPage(<ChangeOrders />), isBlockedDuringLockAndRoll: true },
  {
    path: PATHS.CHANGE_ORDERS_SHOW,
    exact: true,
    component: renderPage(<ChangeOrderShow />),
    isBlockedDuringLockAndRoll: true,
  },
  { path: PATHS.USER_ADMIN, exact: true, isNotProtected: true, component: renderPage(<UserAdmin />) },
  { path: PATHS.USER_SETTINGS, exact: true, isNotProtected: true, component: renderPage(<UserSettings />) },
  { path: PATHS.USER_SETTINGS_IN_PROJECT, exact: true, component: renderPage(<UserSettings />) },
];
