/* eslint-disable max-lines */
import { processError } from 'src/tools/events.tools';

import {
  Action,
  ActionResult,
  AutoCompleteResult,
  Maybe,
  Period,
  ProcessProps,
  QueryAction,
  QueryDomain,
  RecordObject,
  ReqSummaryTermsRequestArgs,
  ReqSummaryTermsType,
  ReqSummaryTradeRequestArgs,
  ReqSummaryTradeType,
  Response,
  ResponsePayload,
} from 'src/models';
import { setCustomIsLoading, setCustomIsUpdating, setIsLoading } from 'src/store/loading/loading.reducer';
import { alertError, sendInfo } from 'src/store/app/app.actions';

import {
  setReqSummaryGeneralConditions,
  setReqSummaryGeneralRequirements,
  setReqSummaryOtherTerms,
  setReqSummaryPageData,
  setReqSummaryPagePermissions,
  setReqSummaryPageTotals,
  setReqSummaryTradesConstruction,
  setReqSummaryTradesPreConstruction,
  setReqSummaryTradesUnawarded,
  setReqSummaryReqPeriods,
  setReqSummarySidebar,
  setReqSummaryPageBalance,
  setReqSummaryScenario,
  setReqSummarySummaryTable,
  setReqSummarySummaryTotals,
  setReqSummaryDashboardData,
  setReqSummaryDashboardScenario,
} from 'src/store/reqSummary/reqSummary.reducer';
import {
  MaybeReqSummaryTermsData,
  MaybeReqSummaryTradesData,
  ReqSummaryBalance,
  ReqSummaryDashboardData,
  ReqSummaryPageData,
  ReqSummarySummaries,
  ReqSummaryTabs,
} from 'src/models/reqSummary.model';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { getCurrentMainContractId, getSubProjectDescriptionType } from 'src/store/subProject/subProject.getters';
import {
  getReqSummarySelectedPeriod,
  getReqSummaryTradesByType,
  getReqSummaryTradesMetaByType,
  getReqSummaryTradesQueryByType,
  getReqSummaryTermsQueryByType,
  getReqSummaryTabsIds,
} from 'src/store/reqSummary/reqSummary.getters';
import { logUnableToProceed } from 'src/tools/log.tools';
import { deserialize } from 'src/tools/string.tools';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { ifTrue } from 'src/tools/logic.tools';
import { DashboardScenario } from 'src/models/dashboardScenario.model';
import { requestReqSummaryDashboard } from 'src/store/reqSummary/reqSummary.actions';
import { getManagerReqPageStatus } from 'src/store/managerReq/managerReq.actions';

const DEF_META = { page: 1, per_page: 50 };

const STORE_FUNCTIONS: Record<string, (payload: MaybeReqSummaryTradesData) => Action> = {
  construction: setReqSummaryTradesConstruction,
  pre_construction: setReqSummaryTradesPreConstruction,
  gr: setReqSummaryGeneralRequirements,
  unawarded: setReqSummaryTradesUnawarded,
};

const TERMS_STORE_FUNCTIONS: Record<string, (payload: MaybeReqSummaryTermsData) => Action> = {
  gc: setReqSummaryGeneralConditions,
  other: setReqSummaryOtherTerms,
};

function processReqSummary({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  function processPageDataRequest(period?: Period) {
    return function call() {
      dispatch(setIsLoading(true));

      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.SHOW,
          payload: {
            main_contract_id: getCurrentMainContractId(getState()),
            period,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqSummaryPageData>>(response.payload);

            dispatch(setReqSummaryPageData(result?.data));
            dispatch(setReqSummaryPagePermissions(result?.permissions));
            dispatch(setReqSummarySidebar(result?.data?.sidebar));

            const tabs = getReqSummaryTabsIds(getState());

            if (tabs.includes(ReqSummaryTabs.SUMMARY)) processSummaryRequest(result?.data.selected_period)();

            if (result?.data.selected_period) dispatch(getManagerReqPageStatus(result.data.selected_period));

            processPageBalanceRequest(result?.data.selected_period)();
            processGetReqPeriods()();

            processDashboardRequest(undefined, period)();
            processTradeRequest(ifTrue(period, { params: { period } }))();
            processTermsRequest(ifTrue(period, { params: { period } }))();

            if (result && result.data && result.data.selected_period?.id)
              processTotalsRequest(result?.data.selected_period)();
          } else {
            processError({
              activityName: 'Request req summary page',
              response,
            });
          }

          dispatch(setIsLoading(false));
        },
      );
    };
  }

  // new-ui
  function processPageBalanceRequest(period?: Period) {
    return function call() {
      setIsBusy({ id: 'balance', state: true });

      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.GET_BALANCE,
          payload: {
            main_contract_id: getCurrentMainContractId(getState()),
            period: period ?? getReqSummarySelectedPeriod(getState()),
          },
        },

        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqSummaryBalance>>(response.payload);

            dispatch(setReqSummaryPageBalance(result?.data));
          } else {
            processError({
              activityName: 'Request req summary page balance',
              response,
            });
          }

          setIsBusy({ id: 'balance', state: false });
        },
      );
    };
  }

  function processTotalsRequest(period?: Period) {
    return function call() {
      setIsBusy({ id: 'totals', state: true });
      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.TOTALS,
          payload: {
            main_contract_id: getCurrentMainContractId(getState()),
            period: period || getReqSummarySelectedPeriod(getState()),
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<RecordObject<number>>>(response.payload);

            dispatch(setReqSummaryPageTotals(result));
          } else {
            processError({
              activityName: 'Request Summary totals',
              response,
            });
          }

          setIsBusy({ id: 'totals', state: false });
        },
      );
    };
  }

  // new-ui
  function processSummaryRequest(period?: Period) {
    return function call() {
      setIsBusy({ id: 'summary', state: true });

      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.SUMMARY,
          payload: {
            params: {
              main_contract_id: getCurrentMainContractId(getState()),
              period: period ?? getReqSummarySelectedPeriod(getState()),
            },
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<Maybe<ReqSummarySummaries>>(response.payload);

            if (result) {
              const { data, totals, scenario } = result;

              dispatch(setReqSummarySummaryTable(data));
              dispatch(setReqSummarySummaryTotals(totals));
              dispatch(setReqSummaryScenario(scenario));
            }
          } else {
            processError({
              activityName: 'Request Summary summaries tables',
              response,
            });
          }

          setIsBusy({ id: 'summary', state: false });
        },
      );
    };
  }

  // new-ui
  function processTradeRequest(payload: ReqSummaryTradeRequestArgs) {
    return function call() {
      const projectType = getSubProjectDescriptionType(getState());

      const type = payload?.params?.type;

      if (!type) {
        const tabs = getReqSummaryTabsIds(getState());

        [
          ReqSummaryTradeType.CONSTRUCTION,
          ReqSummaryTradeType.PRE_CONSTRUCTION,
          ReqSummaryTradeType.GR,
          ReqSummaryTradeType.UNAWARDED,
        ].forEach(type => {
          if (tabs.includes(type)) processTradeRequest({ ...payload, params: { ...payload?.params, type } })();
        });
        return;
      }

      if (type === ReqSummaryTradeType.SUMMARY) {
        [
          ReqSummaryTradeType.CONSTRUCTION,
          ReqSummaryTradeType.PRE_CONSTRUCTION,
          ReqSummaryTradeType.GR,
          ReqSummaryTradeType.UNAWARDED,
        ].forEach(type => processTradeRequest({ ...payload, params: { ...payload?.params, type } })());
        return;
      }

      setIsBusy({ id: type, state: true });
      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: projectType === 'sub' ? QueryAction.TRADES_SUB_ONLY : QueryAction.TRADES,
          payload: {
            main_contract_id: getCurrentMainContractId(getState()),
            period: {
              ...getReqSummarySelectedPeriod(getState()),
              ...payload.params?.period,
            },
            type,
            meta: {
              ...DEF_META,
              ...getReqSummaryTradesMetaByType(getState())(type),
              ...payload.meta,
            },
            query: {
              ...getReqSummaryTradesQueryByType(getState())(type),
              ...payload.query,
            },
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<MaybeReqSummaryTradesData>(response.payload);

            dispatch(
              STORE_FUNCTIONS[type]({
                data: result?.data,
                totals: result?.totals,
                scenario: result?.scenario,
                meta: result?.meta,
                query: result?.query,
              }),
            );
          } else {
            processError({ activityName: 'Request trades', response });
          }

          setIsBusy({ id: type, state: false });
        },
      );
    };
  }

  // new-ui
  function processTradeMoreRequest(type: ReqSummaryTradeType) {
    return function call() {
      const meta = getReqSummaryTradesMetaByType(getState())(type);

      const query = getReqSummaryTradesQueryByType(getState())(type);

      if (typeof meta?.next !== 'number') {
        logUnableToProceed('processTradeMoreRequest', { meta });
        return;
      }

      setIsBusy({ id: type, state: true, meta: { isUpdate: true } });
      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.TRADES,
          payload: {
            main_contract_id: getCurrentMainContractId(getState()),
            period: getReqSummarySelectedPeriod(getState()),
            type,
            meta: {
              ...meta,
              page: meta.next,
            },
            query,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const oldTrades = getReqSummaryTradesByType(getState())(type);

            const result = deserialize<MaybeReqSummaryTradesData>(response.payload);

            dispatch(
              STORE_FUNCTIONS[type]({
                data: oldTrades?.data && result?.data ? [...oldTrades.data, ...result.data] : result?.data,
                totals: result?.totals,
                scenario: result?.scenario,
                meta: result?.meta,
                query: result?.query,
              }),
            );
          } else {
            processError({ activityName: 'Request trades more', response });
          }

          setIsBusy({ id: type, state: false, meta: { isUpdate: true } });
        },
      );
    };
  }

  // new-ui
  function processTermsRequest(payload: Partial<ReqSummaryTermsRequestArgs>) {
    return function call() {
      const type = payload?.params?.type;

      if (!type) {
        const tabs = getReqSummaryTabsIds(getState());

        [ReqSummaryTermsType.GC, ReqSummaryTermsType.OTHER].forEach(type => {
          if (tabs.includes(type)) processTermsRequest({ ...payload, params: { ...payload?.params, type } })();
        });
        return;
      }

      if (type === ReqSummaryTermsType.SUMMARY) {
        [ReqSummaryTermsType.GC, ReqSummaryTermsType.OTHER].forEach(type =>
          processTermsRequest({ ...payload, params: { ...payload?.params, type } })(),
        );
        return;
      }

      const query = getReqSummaryTermsQueryByType(getState())(type) ?? {};

      setIsBusy({ id: type, state: true });
      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.TERMS,
          payload: {
            main_contract_id: getCurrentMainContractId(getState()),
            period: payload.params?.period || getReqSummarySelectedPeriod(getState()),
            type,
            query: {
              ...query,
              ...payload.query,
            },
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<MaybeReqSummaryTermsData>(response.payload);

            dispatch(
              TERMS_STORE_FUNCTIONS[type]({
                data: result?.data,
                totals: result?.totals,
                query: result?.query,
                scenario: result?.scenario,
              }),
            );
          } else {
            processError({ activityName: 'Request terms', response });
          }

          setIsBusy({ id: type, state: false });
        },
      );
    };
  }

  const processBillingStatusRequest =
    ({ payload }: Action) =>
    () => {
      dispatch(setCustomIsLoading({ id: 'status', state: true }));
      getService().request(
        { [QueryDomain.REQ_SUMMARY]: QueryAction.STATISTICS, payload },
        (response: Response<string>) => {
          if (response.isOK) {
            // const result = deserialize<Maybe<StatisticsPeriod[]>>(response.payload, undefined);
            // dispatch(setReqSummaryBillingStatus(result));
          } else {
            processError({
              activityName: 'Request req summary billing status',
              response,
            });
          }

          dispatch(setCustomIsLoading({ id: 'status', state: false }));
        },
      );
    };

  const processActions = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'action', state: true }));

    getService().request(
      {
        [QueryDomain.REQ_SUMMARY]: QueryAction.ACTION,
        payload: {
          data: {
            ...action.payload,
            main_contract_id: getCurrentMainContractId(getState()),
            period: getReqSummarySelectedPeriod(getState()),
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<Maybe<ActionResult>>(response.payload);

          dispatch(sendInfo(result?.data ?? 'Action was performed'));
          processPageDataRequest()();

          if (['lock_and_roll', 'open_submission'].includes(action.payload.selected_action))
            dispatch(requestReqSummaryDashboard());
        } else if (action.payload.selected_action === 'lock_and_roll')
          dispatch(
            alertError({
              title: 'Lock & Roll',
              content: response?.message ?? 'Failed to perform the action',
              buttonLabel: 'Got it',
            }),
          );
        else
          processError({
            activityName: 'reqSummary action',
            response,
          });

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'action', state: false }));
      },
    );
  };

  const processGetReqPeriods = () => () => {
    const main_contract_id = getCurrentMainContractId(getState());

    if (!main_contract_id) {
      logUnableToProceed('processGetReqPeriods', { main_contract_id });

      return;
    }

    dispatch(setCustomIsUpdating({ id: 'reqPeriods', state: true }));

    getService().request(
      { [QueryDomain.REQ_SUMMARY]: QueryAction.GET_PERIODS, payload: { main_contract_id } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqPeriod[]>>(response.payload);

          dispatch(setReqSummaryReqPeriods(result?.data));
        } else {
          processError({
            activityName: 'Request REQ periods',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'reqPeriods', state: false }));
      },
    );
  };

  function processAutocompleteRequest(action: Action) {
    return () => {
      dispatch(setCustomIsLoading({ id: 'autocomplete', state: true }));
      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.AUTOCOMPLETE,
          payload: {
            params: {
              ...action.payload,
              main_contract_id: getCurrentMainContractId(getState()),
              period: getReqSummarySelectedPeriod(getState()),
            },
          },
        },

        (response: Response<string>) => {
          let result;

          if (response.isOK) {
            result = deserialize<ResponsePayload<AutoCompleteResult[]>>(response.payload);
          } else {
            processError({
              activityName: 'Request Summary autocomplete',
              response,
            });
          }

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK, payload: result?.data });

          dispatch(setCustomIsLoading({ id: 'autocomplete', state: false }));
        },
      );
    };
  }

  function processDashboardRequest(action?: Action, period?: Period) {
    return () => {
      dispatch(setCustomIsLoading({ id: 'dashboard', state: true }));
      getService().request(
        {
          [QueryDomain.REQ_SUMMARY]: QueryAction.DASHBOARD,
          payload: {
            params: {
              main_contract_id: getCurrentMainContractId(getState()),
              period: period ?? getReqSummarySelectedPeriod(getState()),
            },
          },
        },

        (response: Response<string>) => {
          let result;

          if (response.isOK) {
            result = deserialize<ResponsePayload<ReqSummaryDashboardData, DashboardScenario>>(response.payload);

            dispatch(setReqSummaryDashboardData(result?.data));
            dispatch(setReqSummaryDashboardScenario(result?.scenario));
          } else {
            processError({
              activityName: 'Request Summary dashboard',
              response,
            });
          }

          action?.meta?.callback?.({ isOK: response.isOK, payload: result?.data });

          dispatch(setCustomIsLoading({ id: 'dashboard', state: false }));
        },
      );
    };
  }

  return {
    processPageDataRequest,
    // processStatisticsRequest,
    processTradeRequest,
    processTotalsRequest,
    processActions,
    processBillingStatusRequest,
    processTradeMoreRequest,
    processGetReqPeriods,
    processTermsRequest,
    processPageBalanceRequest,
    processAutocompleteRequest,
    processDashboardRequest,
  };
}

export { processReqSummary };
