import { createContext } from 'react';
import { FormType } from 'src/models/form.model';

import { AnyValue, ButtonBaseType, Maybe, RecordObject, VoidFn } from 'src/models/general.model';
import { UseIcons } from 'src/models';

export interface InputParserType {
  classNames?: RecordObject<string>; // classes for styling
  value: AnyValue; // current value
  storedValue?: AnyValue; // value from data for camparing input changes
  data?: AnyValue; // some additional data, like name of the company in case when value is id
  onChange: (value: AnyValue, isSubmit?: boolean) => void; // parser changes the value, with or without submission
  onClick?: () => void; // click on element
  onSubmit?: () => void; // submit current value
  onDiscard?: () => void; // discard changes
  isDisabled?: boolean; // is disabled
  isReadonly?: boolean; // is readonly
  isAutoFocus?: boolean; // will be focused when is not disabled
  isLoading?: boolean; // is some data being fetched for the component
  placeholder?: string;
  min?: AnyValue;
  max?: AnyValue;
  actions?: RecordObject<Maybe<VoidFn>>; // some custom actions
  customization?: RecordObject<AnyValue>; // some custom options like isSmall, containerWidth, etc
  items?: ButtonBaseType[]; // nested items dropdown
  nestedItems?: RecordObject<ButtonBaseType[]>; // nested items for complex dropdown
  initialState?: FormType;
  commonData?: AnyValue;
  iconId?: UseIcons;
  isCloseButton?: boolean;
}

export const InputContext = createContext({} as InputParserType);
