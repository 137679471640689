import * as React from 'react';

import { Button } from '../Button';

import classes from './LogoButton.module.scss';

interface Props {
  onClick?: () => void;
  tooltip?: string;
  to?: string;
}

// new-ui
export function LogoButton(props: Props) {
  return <Button classNames={classes} rightIconId='logo' {...props} />;
}
