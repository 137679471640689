import { dissoc, reject, isNil } from 'ramda';
import { v4 as generateId } from 'uuid';

import { hasKey } from './object.tools';

import { AnyValue, BatchItemState, FormDataBatchItem, Maybe, RecordObject } from 'src/models/general.model';

export function convertToForm(dataObject: Record<string, AnyValue>, formKey?: Maybe<string>): FormData {
  const form_data = new FormData();

  for (const key of Object.keys(dataObject)) {
    const fieldName = formKey ? `${formKey}[${key}]` : key;

    if (dataObject[key] instanceof File) {
      form_data.append(fieldName, dataObject[key], dataObject[key].name);
    } else if (dataObject[key] === null) {
      form_data.append(`${fieldName}`, '');
    } else if (dataObject[key] instanceof Date) {
      form_data.append(`${fieldName}`, dataObject[key].toISOString());
    } else if (typeof dataObject[key] === 'object') {
      for (const innerKey of Object.keys(dataObject[key])) {
        form_data.append(`${fieldName}[${innerKey}]`, dataObject[key][innerKey] as string);
      }
    } else {
      form_data.append(fieldName, dataObject[key] as string);
    }
  }

  return form_data;
}

// split files and create multiple FormData object with one
// file and copy the other keys
export function convertFormWithFilesToFormDataArray(
  form: RecordObject<AnyValue>,
  fileField: string,
  nameField?: string,
): FormDataBatchItem[] {
  let result: FormDataBatchItem[] = [];

  if (!hasKey(fileField, form)) return [];

  const isArrayOfFiles = Array.isArray(form[fileField]);

  const formWithoutFiles: RecordObject<AnyValue> = isArrayOfFiles ? dissoc(fileField, form) : form;

  const files: File[] = !isArrayOfFiles ? [form[fileField]] : form[fileField];

  for (const file of files) {
    let title;

    if (nameField && formWithoutFiles[nameField]) title = formWithoutFiles[nameField];
    else if (file.name) title = file.name.replace('.pdf', '');

    result = [
      ...result,
      {
        id: generateId(),
        state: BatchItemState.IDLE,
        payload: convertToForm({ ...reject(isNil)(formWithoutFiles), file, title }),
      },
    ];
  }

  return result;
}

export function cloneFormData(formData: FormData) {
  const clone = new FormData();

  for (const entries of formData.entries()) {
    clone.append(entries[0], entries[1]);
  }

  return clone;
}
