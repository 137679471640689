import clsx from 'clsx';
import { useEffect, lazy, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCanBoundTo } from '@casl/react';

import { hasNextPage, metaForNextPage } from 'src/tools/paging.tools';
import { useDimensions } from 'src/hooks/useDimensions';
import { t } from 'src/tools/text.tools';

import { IncludedSubProject, MainProject } from 'src/models/main_project.model';
import {
  getMainProjects,
  getMainProjectsMeta,
  getMainProjectsPermissions,
} from 'src/store/portfolio/portfolio.getters';
import { ID } from 'src/models/general.model';
import { getIsLoading, getIsUpdating, getCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { requestMainProjects } from 'src/store/portfolio/portfolio.actions';
import { clearPortfolioState } from 'src/store/portfolio/portfolio.reducer';

import { SubNavbar } from 'src/components/SubNavbar';
import { AddPrimaryButton, PrimaryButton } from 'src/components/Button';
import { MainProjectCard } from 'src/components/MainProjectCard';
import { RenderInfiniteList } from 'src/components/RenderInfiniteList';
import { ScrollableRow } from 'src/components/ScrollableRow';
import { SubprojectCard } from 'src/components/SubprojectCard';
import { Factory } from 'src/components/Factory';
import { useAbilities, useNavigation } from 'src/hooks';
import { clearMainContractState } from 'src/store/mainContract/mainContract.reducer';
import { SubProject } from 'src/models';
import { PATHS } from 'src/data/routes.data';

import styles from './Portfolio.module.scss';
import { createResizableWindow } from 'src/components/ResizableWindow';

const MainProjectForm = lazy(() => import('./MainProjectForm'));

const SubProjectForm = lazy(() => import('./SubProjectForm'));

const ProjectMembersModal = lazy(() => import('./ProjectMembersModal/ProjectMembersModal'));

const OnboardingForm = lazy(() => import('./OnboardingForm'));

const TXT = t(['portfolio', 'buttons']);

export function Portfolio() {
  const dispatch = useDispatch();

  const mainRef = useRef<HTMLDivElement | null>(null);

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const isMembersUpdating = useSelector(getCustomIsUpdating)('team');

  const projects = useSelector(getMainProjects);

  const meta = useSelector(getMainProjectsMeta);

  const isUpdating = useSelector(getIsUpdating);

  const isLoading = useSelector(getIsLoading);

  const abilities = useAbilities(getMainProjectsPermissions);

  const Can = createCanBoundTo(abilities);

  const { goTo, go } = useNavigation();

  const [containerWidth, containerHeight] = useDimensions(mainRef);

  useEffect(() => {
    dispatch(requestMainProjects());
  }, [isMembersUpdating, dispatch]);

  useEffect(() => {
    dispatch(clearMainContractState());
    dispatch(requestMainProjects());

    return () => {
      dispatch(clearPortfolioState());
    };
  }, [dispatch]);

  function handleSubProjectOnCreate({ main_project_id: mainId, id: subId }: SubProject) {
    goTo(PATHS.PROJECT_SHOW, { mainId, subId });
  }

  function handleAddSubProject(id: ID) {
    return function call() {
      createResizableWindow(
        <Factory>
          <SubProjectForm id={id} onCreate={handleSubProjectOnCreate} />
        </Factory>,
        { y: window.innerHeight * 0.1, height: window.innerHeight - 200 },
      );
    };
  }

  function handleClickMembersPreview(id: ID) {
    return function call() {
      createResizableWindow(
        <Factory>
          <ProjectMembersModal mainProjectId={id} />
        </Factory>,
        { y: window.innerHeight * 0.3 },
      );
    };
  }

  function handleAddProject(project?: MainProject) {
    createResizableWindow(
      <Factory>
        <MainProjectForm project={project} />
      </Factory>,
      { y: window.innerHeight * 0.3 },
    );
  }

  function handleOnboarding() {
    createResizableWindow(
      <Factory>
        <OnboardingForm />
      </Factory>,
      { y: window.innerHeight * 0.2 },
    );
  }

  function handleUserAdmin() {
    go(PATHS.USER_ADMIN);
  }

  function getHandleEditProject(project?: MainProject) {
    return function call() {
      handleAddProject(project);
    };
  }

  function handleSubProjectOnClick(mainId: ID, { id: subId }: IncludedSubProject) {
    return function call() {
      goTo(PATHS.REQ_INDEX, { mainId, subId });
    };
  }

  function renderSubProject(prj: IncludedSubProject, mainProjectId: ID) {
    return (
      <SubprojectCard
        key={prj.id}
        id={prj.id}
        imageUrl={prj.picture}
        label={prj.name}
        onClick={handleSubProjectOnClick(mainProjectId, prj)}
        type={prj.subscription_type}
      />
    );
  }

  function renderMainProject(prj: MainProject) {
    return (
      <section ref={sectionRef} className={clsx('border-bottom row j-start w-100p', styles.section)}>
        <MainProjectCard
          onEdit={getHandleEditProject(prj)}
          onAddSubProject={handleAddSubProject(prj.id)}
          onClickMembersPreview={handleClickMembersPreview(prj.id)}
          {...prj}
        />
        <div className='divider-vertical' />
        <div className={clsx(styles['projects-line'], 'row nowrap w-100p')}>
          <ScrollableRow
            items={prj.projects}
            renderItem={renderSubProject}
            itemWidth={248}
            width={containerWidth - 316}
            idToPass={prj.id}
            classNames='w-100p o-scroll p-relative scroll-bar'
          />
        </div>
      </section>
    );
  }

  async function handleLoadNextPage(_startIndex: number, _stopIndex: number) {
    // TODO fix according to the API
    dispatch(requestMainProjects(metaForNextPage(meta), { isUpdate: true }));
  }

  function renderSubRight() {
    return (
      <>
        <Can I='create' a='main_project'>
          <AddPrimaryButton label={TXT('addMainProject')} onClick={handleAddProject} />
        </Can>
      </>
    );
  }

  function renderSubLeft() {
    return (
      <>
        <Can I='create' a='onboarding'>
          <PrimaryButton isSmall label={TXT('onboarding')} onClick={handleOnboarding} />
        </Can>
        <div className='space-horizontal' />
        <Can I='create' a='user_admin'>
          <PrimaryButton isSmall label={TXT('userAdmin')} onClick={handleUserAdmin} />
        </Can>
      </>
    );
  }

  return (
    <main ref={mainRef} className='page column w-100p h-100p j-start'>
      <SubNavbar right={renderSubRight()} left={renderSubLeft()} />
      <RenderInfiniteList
        items={projects || []}
        hasNextPage={hasNextPage(meta)}
        isNextPageLoading={isLoading || isUpdating}
        height={containerHeight - 52}
        loadNextPage={handleLoadNextPage}
        renderItem={renderMainProject}
        itemHeight={200}
        limit={5}
      />
    </main>
  );
}
