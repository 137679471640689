import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_cm-or-pm'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        fillRule='evenodd'
        d='M2.666 5.333A.667.667 0 001.999 6v6.667c0 .368.299.666.667.666h10.667a.667.667 0 00.666-.666V6a.667.667 0 00-.666-.667H2.666zM.666 6a2 2 0 012-2h10.667a2 2 0 012 2v6.667a2 2 0 01-2 2H2.666a2 2 0 01-2-2V6z'
        clipRule='evenodd'
      />
      <path
        stroke='none'
        fillRule='evenodd'
        d='M5.252 1.92a2 2 0 011.414-.587h2.667a2 2 0 012 2V14a.667.667 0 01-1.334 0V3.333a.667.667 0 00-.666-.666H6.666a.667.667 0 00-.667.666V14a.667.667 0 01-1.333 0V3.333a2 2 0 01.586-1.414z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default Icon;
