import { ActionMeta, ID } from 'src/models/general.model';
import { LevelsActions } from 'src/models/query.model';
import { PageQuery } from 'src/models';
import { LevelType } from 'src/models/levels.model';

export interface RequestLevelsProps {
  level_type: LevelType;
  query?: Partial<PageQuery>;
}

export function requestLevels(payload: RequestLevelsProps, meta?: ActionMeta) {
  return {
    type: LevelsActions.GET,
    payload,
    meta,
  };
}

export function requestCreateLevels(payload: Record<LevelType, number>, meta?: ActionMeta) {
  return {
    type: LevelsActions.CREATE,
    payload,
    meta,
  };
}

export interface UpdateLevelProps {
  level_id: ID;
  level_type: LevelType;
  name: string;
}

export function requestUpdateLevel(payload: UpdateLevelProps, meta?: ActionMeta) {
  return {
    type: LevelsActions.UPDATE,
    payload,
    meta,
  };
}

export interface DeleteLevelProps {
  level_id: ID;
  level_type: LevelType;
}

export function requestDeleteLevel(payload: DeleteLevelProps, meta?: ActionMeta) {
  return {
    type: LevelsActions.DELETE,
    payload,
    meta,
  };
}

export function requestAllLevels(meta?: ActionMeta) {
  return {
    type: LevelsActions.GET_ALL,
    meta,
  };
}
