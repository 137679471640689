import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_share'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M18 22a3 3 0 100-6 3 3 0 000 6z'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M6 15a3 3 0 100-6 3 3 0 000 6z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M8.59 13.51l6.83 3.98' />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M18 8a3 3 0 100-6 3 3 0 000 6z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15.41 6.51l-6.82 3.98' />
    </svg>
  );
}

export default Icon;
