import * as React from 'react';

export default function IconGovernment() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35' stroke='none'>
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' transform='translate(-737 -1307)'>
          <g transform='translate(737 1307)'>
            <g>
              <path stroke='none' d='M0 8.58449275L34.9728261 8.58449275 17.486413 0.0120289855z' />
              <path stroke='none' d='M3.39 14.029H6.232V28.165H3.39z' />
              <path stroke='none' d='M11.84 14.029H14.682V28.165H11.84z' />
              <path stroke='none' d='M20.291 14.029H23.133V28.165H20.291z' />
              <path stroke='none' d='M28.741 14.029H31.583V28.165H28.741z' />
              <path
                stroke='none'
                d='M33.0974638 12.760471L33.0974638 9.89543478 1.87532609 9.89543478 1.87532609 12.760471 3.38981884 12.760471 6.23141304 12.760471 11.8403261 12.760471 14.6819203 12.760471 20.2908696 12.760471 23.1324638 12.760471 28.7413768 12.760471 31.582971 12.760471z'
              />
              <path
                stroke='none'
                d='M32.522 31.426h-.939v-1.994H3.39v1.994h-.94c-.882 0-1.597.716-1.597 1.598v1.792H34.12v-1.792c0-.882-.715-1.598-1.597-1.598z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
