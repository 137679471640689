import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_percent'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12.667 3.333l-9.334 9.334' />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M11.667 13.333a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M4.333 6a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z'
      />
    </svg>
  );
}

export default Icon;
