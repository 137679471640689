import { ID } from 'src/models/general.model';
import { ResourceType } from 'src/models/query.model';
import { Meta, VersionedPeriod } from 'src/models/results.model';
import { ErrorsObject } from 'src/models/error.model';

export enum FailureRecordType {
  LINE_ITEM = 'line_item',
  CHANGE_ORDER = 'change_order',
}

export interface HistoricalUploadsQuery {
  resource_id: ID;
  resource_type: ResourceType;
  period: VersionedPeriod;
  section: string;
  failure_record_type: FailureRecordType;
  meta?: Meta;
}

export interface HistoricalUploadsInfo {
  period: ID;
  unsuccessful_records: number;
  errors_records: number;
  name: string;
  contract_value: number;
  left_to_schedule: number;
  trade_name?: string;
  company_name?: string;
}

export interface HistoricalUploadsUnsuccessful {
  id: ID;
  section: string;
  category: string;
  csi_code: string;
  description: string;
  group: string;
  amount: number;
  retainage_applied: boolean;
  _errors?: ErrorsObject;
}

export interface HistoricalUploadsError {
  id: ID;
  critical: boolean;
  description: string;
  section: string;
  category: string;
  section_and_category: string;
  amount: number;
  material_cost: number;
  total_completed: number;
  retainage_total: number;
  prior_periods: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_this_period: number;
  retainage_prior_periods: number;
  due_this_period: number;
  note: string;
  _errors?: ErrorsObject;
}
