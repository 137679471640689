import clsx from 'clsx';

import { ifTrue } from 'src/tools/logic.tools';
import { P1 } from 'src/components/Typography';
import { UseIcon } from '../UseIcon';

import classes from './UIBlocker.module.scss';

interface Props {
  className?: string;
  text?: string;
  isTransparent?: boolean;
}

export function UIBlocker({ className, text, isTransparent }: Props) {
  function renderText() {
    if (!text) return <></>;

    return <P1>{text}</P1>;
  }

  return (
    <div
      data-testid='ui-blocker'
      className={clsx(
        'column items-1p6 centered w-100p h-100p',
        classes.container,
        className,
        ifTrue(isTransparent, classes.transparent),
      )}
    >
      <UseIcon className={classes.icon} iconId='logo-trademark' />
      {renderText()}
    </div>
  );
}
