import clsx from 'clsx';
import * as React from 'react';

import { hasKey } from 'src/tools/object.tools';

import { ifTrue } from 'src/tools/logic.tools';

import classes from './Input.module.scss';
import { MATCH_AUTOCOMPLETE_WITH_TYPE } from 'src/data/misc.data';

export interface InputProps {
  autoComplete?: boolean | string;
  className?: string;
  onChange?: (value: string) => void;
  onSubmit?: () => void;
  onDiscard?: () => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  isSmall?: boolean;
  isXSmall?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  autoCorrect?: boolean;

  // all below are grouped into "opts"
  autoFocus?: boolean;
  onBlur?: (e: React.FocusEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  spellCheck?: boolean;
  type?: string;
  maxLength?: number;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      autoComplete,
      autoCorrect,
      onChange,
      onSubmit,
      onDiscard,
      onKeyUp,
      className,
      isDisabled,
      isReadonly,
      isSmall,
      isXSmall,
      ...opts
    },
    ref,
  ) => {
    if (isSmall && isXSmall) {
      throw new Error('Input can be either small or xsmall');
    }

    function handleOnKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Enter' && onSubmit) onSubmit();
      else if (e.key === 'Escape' && onDiscard) onDiscard();

      if (onKeyUp) onKeyUp(e);
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) onChange(e.target.value);
    }

    function getAutoComplete() {
      if (!autoComplete || !opts.type) return 'off';

      if (hasKey(opts.type, MATCH_AUTOCOMPLETE_WITH_TYPE)) return MATCH_AUTOCOMPLETE_WITH_TYPE[opts.type];

      return 'on';
    }

    return (
      <input
        ref={ref}
        autoComplete={getAutoComplete()}
        autoCorrect={ifTrue(!autoCorrect, 'off')}
        className={clsx(classes.container, className, { [classes.small]: isSmall, [classes.xsmall]: isXSmall })}
        onChange={handleOnChange}
        onKeyUp={handleOnKeyUp}
        value={value ?? ''}
        disabled={isDisabled}
        readOnly={isReadonly}
        role={ifTrue(opts.type === 'password', 'password')}
        {...opts}
      />
    );
  },
);
