import * as React from 'react';
import clsx from 'clsx';

import { Button, ButtonProps } from '../Button';

import classes from './Accent1Button.module.scss';

export type Accent1ButtonProps = Omit<ButtonProps, 'classNames'> & {
  className?: string;
};

// new-ui
export function Accent1Button({ className, ...props }: Accent1ButtonProps) {
  return <Button classNames={{ ...classes, container: clsx(classes.container, className) }} {...props} />;
}
