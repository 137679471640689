import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_upload-cloud'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <g fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' clipPath='url(#clip-upload-cloud)'>
        <path fill='none' d='M16 16l-4-4-4 4' />
        <path fill='none' d='M12 12v9' />
        <path fill='none' d='M20.39 18.39A5 5 0 0018 9h-1.26A8 8 0 103 16.3' />
        <path fill='none' d='M16 16l-4-4-4 4' />
      </g>
      <defs>
        <clipPath id='clip-upload-cloud'>
          <path fill='none' d='M0 0H24V24H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
