import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { ToastMessage } from 'src/models';
import { getMessages, removeMessage } from 'src/store/app/app.reducer';
import { render } from 'src/tools/render.tools';

import { Toast } from 'src/components/Toast';

import style from './Toasts.module.scss';

export function Toasts() {
  const dispatch = useDispatch();

  const messages = useSelector(getMessages);

  const handleRemoveMessage = React.useCallback((id: string) => dispatch(removeMessage(id)), [dispatch]);

  function renderMessage({ id, code, content }: ToastMessage) {
    return <Toast key={id} id={id} code={code} content={content} onClose={handleRemoveMessage} />;
  }

  if (!messages.length) return <></>;

  return <div className={clsx(style.container, 'column items-1')}>{render(messages, renderMessage)}</div>;
}
