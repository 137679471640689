import * as React from 'react';
import clsx from 'clsx';

import { Badge, Props } from './Badge';

import classes from './PredefinedBadge.module.scss';

export function Accent1Badge(props: Props) {
  return (
    <Badge
      {...props}
      classNames={{
        container: clsx(classes['accent-1'], props.classNames?.container),
        close: clsx(classes['accent-1-close'], props.classNames?.close),
      }}
    />
  );
}

export function Accent1TransparentBadge(props: Props) {
  return (
    <Badge
      {...props}
      classNames={{
        container: clsx(classes['accent-1-transparent'], props.classNames?.container),
        close: clsx(classes['accent-1-close'], props.classNames?.close),
      }}
    />
  );
}

export function Accent2Badge(props: Props) {
  return (
    <Badge
      {...props}
      classNames={{
        container: clsx(classes['accent-2'], props.classNames?.container),
        close: clsx(classes['accent-2-close'], props.classNames?.close),
      }}
    />
  );
}

export function Accent3Badge(props: Props) {
  return (
    <Badge
      {...props}
      classNames={{
        container: clsx(classes['accent-3'], props.classNames?.container),
        close: clsx(classes['accent-3-close'], props.classNames?.close),
      }}
    />
  );
}

export function ErrorBadge(props: Props) {
  return (
    <Badge
      {...props}
      classNames={{
        container: clsx(classes['error'], props.classNames?.container),
        close: clsx(classes['error-close'], props.classNames?.close),
      }}
    />
  );
}
