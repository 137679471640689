import { useCallback, useEffect, useRef } from 'react';
import { useThrottledFn } from './useThrottledFn';

export function usePaginationScroll<T extends HTMLElement>(callback: () => void, diff?: number) {
  const ref = useRef<T>(null);

  const handleOnScroll = useCallback(() => {
    const element = ref.current;

    if (!element) return;

    const { scrollTop, scrollHeight, offsetHeight } = element;

    const scrollBottom = scrollHeight - offsetHeight - scrollTop;

    if (scrollBottom <= (diff ?? offsetHeight)) callback();
  }, [ref, diff, callback]);

  const handleOnScrollThrottled = useThrottledFn(handleOnScroll, 100);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      element.addEventListener('scroll', handleOnScrollThrottled);

      return () => {
        element.removeEventListener('scroll', handleOnScrollThrottled);
      };
    }
  }, [handleOnScrollThrottled, ref]);

  return ref;
}
