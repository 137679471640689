import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { getCurrencySymbol } from 'src/tools/currency.tools';
import { Maybe, SubProjectSortedTeam, SubProjectTeamMember } from 'src/models';
import { makeAllowedDomains } from 'src/tools/permissions.tools';

export const getCurrentSubProject = (state: RootState) => state.subProject.currentSubProject;

export const getSubProjects = (state: RootState) => state.subProject.subProjects;

export const getSubProjectDescriptionType = (state: RootState) => state.subProject.currentSubProject?.subscription_type;

export const getCurrentMainContractId = (state: RootState) => state.subProject.currentSubProject?.main_contract_id;

export const getCurrentSubprojectName = (state: RootState) => state.subProject.currentSubProject?.name;

export const getCurrentSubprojectCompanyId = (state: RootState) => state.subProject.currentSubProject?.company_id;

export const getSubprojectPermissions = (state: RootState) => state.subProject.permissions;

export const getCurrentSubprojectDomains = (state: RootState) => state.subProject.currentSubProject?._domains;

export const getCurrentSubprojectCurrency = (state: RootState) => state.subProject.currentSubProject?.currency ?? 'USD';

export const getIsLockAndRollInProgress = (state: RootState) =>
  !!state.subProject.currentSubProject?.lock_and_roll_in_progress;

export const getIsSubmissionIsOpen = (state: RootState) => !!state.subProject.currentSubProject?.submission_open;

export const getCurrentSubprojectCurrencySymbol = createSelector(
  [getCurrentSubprojectCurrency],
  (currency = 'USD'): string => getCurrencySymbol(currency) ?? '$',
);

export const getSubProjectScenario = (state: RootState) => state.subProject.scenario;

export const getSubProjectTeam = (state: RootState) => state.subProject.subProjectTeam;

export const getSubProjectTeamPagePermissions = (state: RootState) => state.subProject.teamPagePermissions;

export const getSubProjectTeamMember = (state: RootState) => state.subProject.subProjectTeamMember;

export const getSubProjectTeamInvite = (state: RootState) => state.subProject.subProjectTeamInvite;

export const getSubProjectTeamSortBy = (state: RootState) => state.subProject.subProjectTeamSortBy;

export const getSubProjectTeamSearch = (state: RootState) => state.subProject.subProjectTeamSearch;

export const getSubProjectSortedTeam = createSelector(
  [getSubProjectTeam, getSubProjectTeamSortBy],
  (team, sortBy): Maybe<SubProjectSortedTeam> => {
    if (!team) return;

    function getLetter(member: SubProjectTeamMember) {
      const [firstName, lastName = ''] = member.full_name.split(' ');

      const name = sortBy === 'FirstName' ? firstName : lastName;

      return name[0]?.toUpperCase();
    }

    const result: SubProjectSortedTeam = {};

    team.team_members.forEach(m => {
      const firstLetter = getLetter(m);

      if (!firstLetter) return;

      result[firstLetter] = result[firstLetter] ?? { team_members: [], invites: [] };
      result[firstLetter].team_members.push(m);
    });

    team.invites.forEach(i => {
      const firstLetter = i.email[0]?.toUpperCase();

      if (!firstLetter) return;

      result[firstLetter] = result[firstLetter] ?? { team_members: [], invites: [] };
      result[firstLetter].invites.push(i);
    });

    return result;
  },
);

export const getSubProjectTeamLetters = createSelector([getSubProjectSortedTeam], (sortedTeam): string[] =>
  sortedTeam ? Object.keys(sortedTeam).sort() : [],
);

export const getCurrentSubprojectAllowedDomains = createSelector([getCurrentSubprojectDomains], makeAllowedDomains);
