import { useRef, useEffect, useContext } from 'react';
import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';

import { InputContext } from 'src/contexts/input.context';
import { UseIcon } from 'src/components/UseIcon';
import { Input, InputProps } from '../Input';
import { CloseButton } from 'src/components/Button';
import { ifTrue } from 'src/tools/logic.tools';
import { LoadingInline } from 'src/components/LoadingInline';
import { UseIcons } from 'src/models';

import classes from './InlineInput.module.scss';

interface Props extends Omit<InputProps, 'isSmall' | 'isXSmall'> {
  onSubmit: () => void;
  onDiscard: () => void;
  onIconSubmit?: () => void;
  isLoading?: boolean;
  iconId?: UseIcons;
}

// new-ui
export function InlineInput({ className, onClick, onIconSubmit, isLoading, iconId = 'check', ...inputProps }: Props) {
  const { storedValue } = useContext(InputContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const ref = useOnclickOutside(handleClickOudside, {
    disabled: inputProps.isReadonly,
    ignoreClass: 'ignore-onclick-outside',
  });

  function handleClickOudside() {
    if (storedValue === inputProps.value) {
      inputProps.onDiscard();
    } else {
      inputProps.onSubmit();
    }
  }

  useEffect(() => {
    if (!inputProps.isDisabled && inputProps.autoFocus) inputRef.current?.focus();
  }, [inputProps.autoFocus, inputProps.isDisabled]);

  function handleOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    if (onClick) onClick(e);
  }

  function handleSubmitOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    if (onIconSubmit) onIconSubmit();
    else inputProps.onSubmit();
  }

  function handleDiscardOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    inputProps.onDiscard();
  }

  function renderLoading() {
    return (
      <div className={classes.loading}>
        <LoadingInline />
      </div>
    );
  }

  function renderButtons() {
    return (
      <>
        <UseIcon wrapElement='button' className={classes.submit} onClick={handleSubmitOnClick} iconId={iconId} />
        <CloseButton className={classes.close} onClick={handleDiscardOnClick} isSmall />
      </>
    );
  }

  return (
    <div
      ref={ref}
      data-testid='inline-input'
      className={clsx(classes.container, 'row a-center', className, {
        disabled: inputProps.isDisabled || inputProps.isReadonly,
        [classes['with-loading']]: isLoading,
      })}
      title={inputProps.value}
      onClick={handleOnClick}
    >
      <Input ref={inputRef} {...inputProps} />
      {ifTrue(isLoading, renderLoading)}
      {ifTrue(!inputProps.isDisabled && !inputProps.isReadonly, renderButtons)}
    </div>
  );
}
