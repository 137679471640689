import {
  Action,
  ActionMeta,
  ID,
  ManagerReqActions,
  PageQuery,
  RequestMeta,
  VersionedPeriod,
  UpdateManagerReqPagePayload,
  Period,
} from 'src/models';

export const requestManagerReqPageSubscription = (payload: ID): Action => ({
  type: ManagerReqActions.ADD_PAGE_SUBSCRIPTION,
  payload,
});

export const removeManagerReqPageSubscription = (payload: ID): Action => ({
  type: ManagerReqActions.REMOVE_PAGE_SUBSCRIPTION,
  payload,
});

export interface ManagerReqShowParams {
  period?: Partial<VersionedPeriod>;
}

export const requestManagerReq = (payload: ManagerReqShowParams = {}, meta?: ActionMeta): Action => ({
  type: ManagerReqActions.SHOW,
  payload,
  meta,
});

export interface BaseContractDataParams {
  period?: Partial<VersionedPeriod>;
  query?: Partial<PageQuery>;
  meta?: Partial<RequestMeta>;
}

export const requestBaseContractData = (payload: Partial<BaseContractDataParams> = {}, meta?: ActionMeta): Action => ({
  type: ManagerReqActions.BASE_CONTRACT,
  payload,
  meta,
});

export interface RequestChangeOrdersProps {
  period?: Partial<VersionedPeriod>;
  query?: Partial<PageQuery>;
  meta?: Partial<RequestMeta>;
}

export const requestChangeOrdersData = (payload: RequestChangeOrdersProps = {}, meta?: ActionMeta): Action => ({
  type: ManagerReqActions.CHANGE_ORDERS,
  payload,
  meta,
});

export const requestChangeOrdersDataMore = (): Action => ({
  type: ManagerReqActions.CHANGE_ORDERS_MORE,
});

export const requestBaseContractDataMore = (): Action => ({
  type: ManagerReqActions.BASE_CONTRACT_MORE,
});

export const requestTotals = (period: ManagerReqShowParams = {}): Action => ({
  type: ManagerReqActions.TOTALS,
  payload: period,
});

export const requestManagerReqHistory = (period: ManagerReqShowParams = {}): Action => ({
  type: ManagerReqActions.HISTORY,
  payload: period,
});

export const patchLineItem = (line_item_id: ID, data: Record<string, unknown>, meta?: ActionMeta) => ({
  type: ManagerReqActions.PATCH_LINE_ITEM,
  payload: { line_item_id, data },
  meta,
});

export const postLineItem = (payload: Record<string, unknown>, meta?: ActionMeta) => ({
  type: ManagerReqActions.POST_LINE_ITEM,
  payload,
  meta,
});

export const deleteLineItem = (payload: Record<string, unknown>, meta?: ActionMeta) => ({
  type: ManagerReqActions.DELETE_LINE_ITEM,
  payload,
  meta,
});

export const performManagerReqAction = (selectedAction: string): Action => ({
  type: ManagerReqActions.PERFORM_ACTION,
  payload: selectedAction,
});

export const updateManagerReqPage = (payload: UpdateManagerReqPagePayload, meta?: ActionMeta): Action => ({
  type: ManagerReqActions.UPDATE_PAGE,
  payload: payload,
  meta: { ...meta, isUpdate: true },
});

export const getManagerReqPageStatus = (payload: Period, meta?: ActionMeta): Action => ({
  type: ManagerReqActions.GET_STATUS,
  payload,
  meta,
});
