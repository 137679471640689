import { CSSProperties } from 'react';
import { Property } from 'csstype';

import { ButtonBaseType, PageTab, RecordObject } from './general.model';
import { PredefinedLists, PredefinedNestedLists, PredefinedStateMachines, ResourceType } from './query.model';
import { UseIcons } from './interface.model';

export type IdLabelPair = { id: string; label: string };

export interface ResourceCompanyProps {
  resourceType: ResourceType;
  text: string;
  button: { icon: UseIcons; label: string; className?: string };
}

// new ui
export enum Decorator {
  Billing = 'billing',
  Retainage = 'retainage',
  RetainageLock = 'retainageLock',
  Edit = 'edit',
  Editable = 'editable',
  InputString = 'inputString',
  RadioBoolean = 'radioBoolean',
  CompanyInput = 'companyInput',
}

export interface ShowScenarioCard {
  id: string;
  isEditable?: boolean;
  isWithTooltip?: boolean;
  isWithHistory?: boolean;
  isWithCloseButton?: boolean;
  showAs: ShowAs;
  label: string;
  placeholder?: string;
  txtPath?: string[];
  dataField?: string;
  defaultValue?: string;
  items?: IdLabelPair[];
  confirmationText?: string;
  predefinedListName?: PredefinedLists; // items can be received from predefined lists
}

export interface Section {
  id: string;
  label?: string;
  isFoldable?: boolean;
  isFolded?: boolean;
  contentType?: ShowAs; // if missing -> tries directly rendering provided content component
  dropdown?: ShowScenarioDropdown;
  isEditable?: boolean;
}

export interface ShowScenarioSection extends Section {
  cards?: ShowScenarioCard[];
  isSingle?: boolean;
}

export interface ShowScenarioDropdown {
  id: string;
  predefinedListName: PredefinedLists;
  predefinedStateMachineName: PredefinedStateMachines;
  isEditable?: boolean;
}

export interface ShowScenarioHeader {
  title?: string;
  suffixDataField?: string;
  dropdown?: ShowScenarioDropdown;
  dropdownMenuItems?: ButtonBaseType[];
}

export interface ShowScenario {
  header?: ShowScenarioHeader;
  resource_type: ResourceType;
  sections: ShowScenarioSection[]; // sections below the title (i.e. with cards)
  tabs?: PageTab[]; // tabs row
  tabsContent?: RecordObject<Section[]>; // content for tabs
  totals?: IdLabelPair;
  customCards?: RecordObject<ShowScenarioCard[]>;
}

// end-of new ui

export enum ShowAs {
  RADIO = 'radio',
  TZ_DROPDOWN = 'timeZoneDropdown',
  TIME_ZONES = 'timeZones',
  CURRENCY_DROPDOWN = 'currencyDropdown',
  SUB_PROJECT_CARD = 'subProjectCard',
  CARDS = 'cards',
  CSI_AUTO_COMPLETE = 'csiAutoComplete',
  EMPTY = 'empty',
  FIXED = 'fixed',
  FLOAT = 'float',
  STRING = 'string',
  MULTILINE = 'multiline',
  REF = 'reference',
  CAT = 'categories',
  BOOL = 'boolean',
  FIN = 'financial',
  DATE = 'date',
  PCT = 'percent',
  CHANGE_ORDER_STATUS = 'changeOrderStatus',
  REQ_DETAIL_STATUS = 'reqDetailOrderStatus',
  PRJ_CO = 'prjCo',
  AUTOCOMPLETE = 'autocomplete',
  COMP_NAME = 'companyName',
  CO_NUMBER = 'coNumber',
  ICON = 'icon',
  ICONS = 'icons',
  TRADE_LINK = 'tradeLink',
  TRADE_LINK_SEARCHABLE_DROPDOWN = 'tradeLinkSearchableDropdown',
  TRADE_DETAIL_LINK = 'tradeDetailLink',
  SEPARATOR = 'separator',
  TOTAL = 'total',
  PHONE = 'phone',
  EMAIL = 'email',
  SWITCH = 'switch',
  INFO = 'info',
  ATTACHMENTS = 'attachments',
  ACTIONS = 'actions',
  CHECK = 'check',
  REASON = 'reason',
  REQ_NUMBER = 'reqNumber',
  REQ_PERIOD_ICON = 'reqPeriodIcon',
  REQ_PERIOD_DATE = 'reqPeriodDate',
  REQ_PERIOD_CALENDAR_UPLOAD = 'reqPeriodCalendar',
  RESOURCE_LINK = 'resourceLink',
  ROLE_DROPDOWN = 'roleDropdown',
  LINK_STATUS = 'linkStatus',
  LINK_BUTTON = 'linkButton',
  UPLOAD_PROFILE_PICTURE = 'uploadProfilePicture',
  PASSWORD = 'password',
  DROPDOWN = 'dropdown',
  NESTED_DROPDOWN = 'nestedDropdown',
  NESTED_CONTINGENCY_DROPDOWN = 'searchNestedContingencyDropdown',
  LEVELS_TREE_DROPDOWN = 'levelsTreeDropdown',
  ERRORS_COUNT = 'errorsCount',
  USER_POPUP = 'userPopup',
  LAST_ACTIVE = 'lastActive',
}

export const NUMERICAL_TYPES = [ShowAs.FIXED, ShowAs.FLOAT, ShowAs.FIN, ShowAs.PCT];

interface Popup {
  showAs: string;
}

export interface ScriptFilter {
  field: string; // on what field this filter should be applied
  autocompleteType?: string; // what autocomplete type to use
  placeholder?: string; // field placeholder and filter badge label
  dropdownPosition?: 'bottom' | 'right'; // position of the filter field dropdown
  predefinedListName?: PredefinedLists; // in case of Dropdown we can specify the name of predefined list to use
  predefinedNestedListName?: PredefinedNestedLists; // in case of Nested Dropdown we can specify the name of predefined nested list to use
  showAs?: ShowAs; // which parser to use for filter field
  readFilter?: string; // pass a filter value as data
}

// _init fields
export interface ScenarioConfig {
  quickFilters?: ScriptFilter[];
}

export interface Script extends ScenarioConfig {
  order?: number; // initial column order in the table (is not implemented yet)
  label?: string; // column's label in header
  placeholder?: string; // text label for cell parser
  isSortable?: boolean; // data can be sorted by this column
  isFilterable?: boolean; // data can be filtered by this column
  isEditable?: boolean; // for adding pencil icon
  filters?: ScriptFilter[]; // column can have several customizable filters instead of default one
  autocompleteType?: string; // type of autocomplete that is used for filtering data
  width?: string; // if width is now specified, column won't be rendered
  showAs?: ShowAs; // which parser to use for cell content rendering. default value is ShowAs.EMPTY
  style?: CSSProperties;
  sticky?: Record<string, unknown>;
  dataField?: string;
  customization?: string; // className or iconId for customization
  align?: Property.TextAlign; // cell content align
  isHighlighted?: boolean; // highlight cell with accent color
  decorator?: Decorator; // cell decorator, e.g. showing Popup/editing
  actionItems?: ButtonBaseType[]; // cell will have button with dropdown actions
  predefinedListName?: PredefinedLists; // items can be received from predefined lists
  isHidden?: boolean; // for alowed to hidden sorting
  hiddenSticky?: Record<string, unknown>; // for colapsing hidden column, which located before
  customText?: RecordObject<string>; // for customizing rendering text (mostly for boolean)
  customColor?: RecordObject<string>; // for customizing color
  popup?: Popup;
}

export type Scenario = Record<string, Script>;
