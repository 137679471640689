import { ButtonBaseType } from 'src/models';
import { PATHS } from './routes.data';
import { t } from 'src/tools/text.tools';

const TXT = t(['navbar', 'navigation']);

export const USER_NAV_DROPDOWN_ITEMS = [
  { id: 'settings', label: 'My profile' },
  { id: 'sign_out', label: 'Sign out' },
];

export interface NavbarButtonItem extends ButtonBaseType {
  paths: string[];
}

export const NAVBAR_MENU_ITEMS: NavbarButtonItem[] = [
  {
    id: 'requisitions',
    label: TXT('requisitions'),
    paths: [PATHS.REQ_SUMMARY, PATHS.MANAGER_REQ, PATHS.REQ_DETAIL],
  },
  {
    id: 'change_orders',
    label: TXT('change_orders'),
    paths: [PATHS.CHANGE_ORDERS],
  },
  {
    id: 'administrative',
    label: TXT('administrative'),
    paths: [PATHS.PROJECT_SHOW, PATHS.MAIN_CONTRACT_SHOW, PATHS.COMPANIES, PATHS.TRADES, PATHS.USERS, PATHS.ROLES],
  },
];
