import { AnyValue, RecordObject } from './general.model';

export enum FormScenarioType {
  TRADE_CONTRACT_CREATE = 'trade_contract_create',
}

export interface FormScriptPatch {
  defaultValue?: AnyValue;
  isDisabled?: boolean;
}

export type FormsScenarioPatch = RecordObject<FormScriptPatch>;
