import * as React from 'react';
import clsx from 'clsx';

import { ScrollableRow } from 'src/components/ScrollableRow';
import { SubprojectCard } from 'src/components/SubprojectCard/SubProjectCard';
import { useDimensions } from 'src/hooks';
import { ID, SubProject } from 'src/models';

import styles from './SubprojectPicker.module.scss';

interface Props {
  onSelect: (id: ID) => void;
  subProjects: SubProject[];
}

export function SubprojectPicker({ subProjects, onSelect }: Props) {
  const mainRef = React.useRef<HTMLDivElement>(null);

  const [width] = useDimensions(mainRef);

  function handleOnSelect(id: ID) {
    return function call() {
      onSelect(id);
    };
  }

  function renderSubProject(prj: SubProject) {
    return (
      <SubprojectCard
        key={prj.id}
        id={prj.id}
        imageUrl={prj.picture}
        label={prj.name}
        onClick={handleOnSelect(prj.id)}
        type={prj.subscription_type}
      />
    );
  }

  if (!subProjects.length) return <></>;

  return (
    <div ref={mainRef} className={clsx(styles.container, 'row')}>
      <ScrollableRow items={subProjects} renderItem={renderSubProject} itemWidth={224} width={width} idToPass='none' />
    </div>
  );
}
