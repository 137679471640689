import * as React from 'react';
import clsx from 'clsx';

import { ifTrue } from 'src/tools/logic.tools';

import style from './Switch.module.scss';

interface Props {
  isChecked?: boolean;
  isDouble?: boolean;
  isDisabled?: boolean;
  className?: string;
  label?: string;
  color?: string;
  onClick: () => void;
}

// new-ui
export function Switch({
  className,
  isChecked = false,
  isDouble = false,
  isDisabled,
  label,
  color = 'var(--primary-default)',
  onClick,
}: Props) {
  function handleOnClick() {
    if (!isDisabled) onClick();
  }

  return (
    <div
      data-testid='switch-container'
      className={clsx(style.container, 'row nowrap a-center', className, {
        [style.double]: isDouble,
        [style.disabled]: isDisabled,
        'c-pointer': !isDisabled,
      })}
      onClick={handleOnClick}
    >
      <input readOnly data-testid='switch-input' type='radio' checked={isChecked} />
      <div
        data-testid='switch-slider'
        className={style.slider}
        style={ifTrue(isChecked || isDouble, { backgroundColor: color })}
      />
      {label && <label>{label}</label>}
    </div>
  );
}

type PredefinedSwitchProps = Omit<Props, 'color'>;

export function SuccessSwitch(props: PredefinedSwitchProps) {
  return <Switch {...props} color='var(--success-default)' />;
}

export function Accent1Switch(props: PredefinedSwitchProps) {
  return <Switch {...props} color='var(--accent-1-default)' />;
}
