import { processError } from 'src/tools/events.tools';

import { Action, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain } from 'src/models/query.model';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { getCurrentSubprojectId } from '../store/app/app.getters';
import { sendInfo } from '../store/app/app.actions';
import { clearLevels, setAllLevels, setLevels, updateLevel } from 'src/store/levels/levels.reducer';
import { DeleteLevelProps, requestLevels, RequestLevelsProps, UpdateLevelProps } from 'src/store/levels/levels.actions';
import { logUnableToProceed } from 'src/tools/log.tools';
import { Level } from 'src/models';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { getLevelsQuery } from 'src/store/levels/levels.getters';

export function processLevels({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processGetLevels =
    ({ payload, meta }: Action<RequestLevelsProps>) =>
    () => {
      if (!payload) {
        logUnableToProceed('processLevels::processGetLevels', { payload });
        return;
      }

      setIsBusy({ id: `levels-${payload.level_type}`, state: true, meta });

      const project_id = getCurrentSubprojectId(getState());

      const query = payload.query ?? getLevelsQuery(getState())(payload.level_type);

      getService().request(
        {
          [QueryDomain.LEVELS]: QueryAction.GET,
          payload: {
            index: [project_id],
            params: {
              ...payload,
              query,
            },
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<Level[]>>(response.payload);

            dispatch(setLevels({ level_type: payload.level_type, data: result }));

            meta?.callback?.({ isOK: true, payload: result?.data });
          } else {
            processError({
              activityName: 'Request levels',
              response,
            });

            meta?.callback?.({ isOK: false });
          }

          setIsBusy({ id: `levels-${payload.level_type}`, state: false, meta });
        },
      );
    };

  const processCreateLevels = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'levels', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    getService().request(
      {
        [QueryDomain.LEVELS]: QueryAction.POST,
        payload: {
          data: action.payload,
          index: [project_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(sendInfo('Levels have been created.'));
          dispatch(clearLevels());

          action.meta?.callback?.({ isOK: true });
        } else {
          processError({
            activityName: 'Request levels create',
            response,
          });

          action.meta?.callback?.({ isOK: false });
        }

        dispatch(setCustomIsLoading({ id: 'levels', state: false }));
      },
    );
  };

  const processUpdateLevel =
    ({ payload, meta }: Action<UpdateLevelProps>) =>
    () => {
      if (!payload) {
        logUnableToProceed('processLevels::processUpdateLevel', { payload });
        return;
      }

      dispatch(setCustomIsUpdating({ id: `levels-${payload.level_type}`, state: true }));

      const project_id = getCurrentSubprojectId(getState());

      const { level_id, name } = payload;

      getService().request(
        {
          [QueryDomain.LEVELS]: QueryAction.PATCH,
          payload: {
            data: { name },
            index: [project_id, level_id],
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            dispatch(sendInfo('Level has been updated.'));

            const result = deserialize<ResponsePayload<Level>>(response.payload);

            if (result?.data) dispatch(updateLevel(result.data));

            meta?.callback?.({ isOK: true });
          } else {
            processError({
              activityName: 'Request level update',
              response,
            });

            meta?.callback?.({ isOK: false });
          }

          dispatch(setCustomIsUpdating({ id: `levels-${payload.level_type}`, state: false }));
        },
      );
    };

  const processDeleteLevel =
    ({ payload, meta }: Action<DeleteLevelProps>) =>
    () => {
      if (!payload) {
        logUnableToProceed('processLevels::processDeleteLevel', { payload });
        return;
      }

      dispatch(setCustomIsUpdating({ id: 'levels', state: true }));

      const project_id = getCurrentSubprojectId(getState());

      getService().request(
        {
          [QueryDomain.LEVELS]: QueryAction.DELETE,
          payload: {
            index: [project_id, payload.level_id],
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            dispatch(sendInfo('Level has been deleted.'));
            dispatch(requestLevels({ level_type: payload.level_type }));

            meta?.callback?.({ isOK: true });
          } else {
            processError({
              activityName: 'Request role delete',
              response,
            });

            meta?.callback?.({ isOK: false });
          }

          dispatch(setCustomIsUpdating({ id: 'levels', state: false }));
        },
      );
    };

  const processGetAllLevels = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'levels', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    getService().request(
      {
        [QueryDomain.LEVELS]: QueryAction.GET_ALL,
        payload: {
          index: [project_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Level[]>>(response.payload);

          if (result?.data) dispatch(setAllLevels(result?.data));

          action.meta?.callback?.({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request all roles',
            response,
          });

          action.meta?.callback?.({ isOK: false });
        }

        dispatch(setCustomIsLoading({ id: 'levels', state: false }));
      },
    );
  };

  return {
    processGetLevels,
    processCreateLevels,
    processUpdateLevel,
    processDeleteLevel,
    processGetAllLevels,
  };
}
