import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ID, Maybe, Retainage } from 'src/models';
import { INITIAL_RETAINAGE_STATE } from './retainage.model';

export const slice = createSlice({
  name: 'retainage',
  initialState: INITIAL_RETAINAGE_STATE,
  reducers: {
    clearRetainageState: () => INITIAL_RETAINAGE_STATE,
    setRetainage: (state, action: PayloadAction<Maybe<Retainage>>) => {
      state.retainage = action.payload;
    },
    deleteRetainage: (state, action: PayloadAction<ID>) => {
      if (action.payload === state.retainage?.id) delete state.retainage;
    },
  },
});

export const { clearRetainageState, setRetainage, deleteRetainage } = slice.actions;

export default slice.reducer;
