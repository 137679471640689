import { Maybe } from 'src/models/general.model';
import { RequiredDocument } from 'src/models';
import { TemplateContent } from 'src/models/documentTemplate.model';

export interface RequiredDocumentsState {
  buildInDocuments?: Maybe<RequiredDocument[]>;
  customDocuments?: Maybe<RequiredDocument[]>;
  templateContent?: Maybe<TemplateContent>;
}

export const INITIAL_REQUIRED_DOCUMENTS_STATE: RequiredDocumentsState = {};
