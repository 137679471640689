import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { getErrorMessage } from 'src/store/app/app.getters';
import { Factory } from 'src/components/Factory';

import classes from './Error.module.scss';

const ErrorModal = React.lazy(() => import('./ErrorModal'));

// new-ui
export function Error() {
  const message = useSelector(getErrorMessage);

  if (!message) return <></>;

  return (
    <div className={clsx(classes.container, 'column centered w-100p h-100p')}>
      <Factory>
        <ErrorModal message={message} />
      </Factory>
    </div>
  );
}
