import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_building'
      width='16'
      height='16'
      fill='none'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <g clipPath='url(#clip0_9354:421138)'>
        <path
          stroke='none'
          d='M15 14.733a.757.757 0 00-.533-.2h-.734V2.2A.74.74 0 0013 1.467a.74.74 0 00-.733.733v12.334h-2.2v-3.6c0-.2-.067-.4-.2-.534a.757.757 0 00-.534-.2h-2.8c-.2 0-.4.067-.533.2a.757.757 0 00-.2.534v3.6H3.6v-11.6H13c.4 0 .733-.734.733-.734S13.4 1.467 13 1.467H2.867c-.2 0-.334.066-.534.2-.066.133-.2.333-.2.533v12.334H1.4c-.2 0-.4.066-.533.2a.757.757 0 00-.2.533c0 .2.066.4.2.533.2.133.333.2.533.2h13.067c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.067-.4-.2-.534zm-6.333-.2H7.2V11.6h1.467v2.934z'
        />
        <path
          stroke='none'
          d='M9.467 6.4h.733c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.066-.4-.2-.533a.757.757 0 00-.533-.2h-.733c-.2 0-.4.066-.534.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.134.134.334.2.534.2z'
        />
        <path
          stroke='none'
          d='M9.467 9.267h.733c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.066-.4-.2-.533a.757.757 0 00-.533-.2h-.733c-.2 0-.4.066-.534.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.134.134.334.2.534.2z'
        />
        <path
          stroke='none'
          d='M5.733 6.4h.734c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.067-.4-.2-.533a.757.757 0 00-.533-.2h-.734c-.2 0-.4.066-.533.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.133.134.333.2.533.2z'
        />
        <path
          stroke='none'
          d='M5.733 9.267h.734c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.067-.4-.2-.533a.757.757 0 00-.533-.2h-.734c-.2 0-.4.066-.533.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.133.134.333.2.533.2z'
        />
      </g>
      <defs>
        <clipPath id='clip0_9354:421138'>
          <path fill='#fff' d='M0 0H16V16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
