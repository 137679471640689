import clsx from 'clsx';
import * as React from 'react';

import { ifTrue } from 'src/tools/logic.tools';

import { BatchItemState, FormDataBatchItem } from 'src/models/general.model';

import { CloseButton } from 'src/components/Button';
import { ProgressCircular } from 'src/components/ProgressCircular';
import { P4 } from 'src/components/Typography';
import { UseIcon } from 'src/components/UseIcon';

import classes from './UploadItem.module.scss';

const { FAIL, SUCCESS, IN_PROGRESS } = BatchItemState;

interface Props extends FormDataBatchItem {
  onRemove: () => void;
  onReload: () => void;
}

export function UploadItem({ id, progress, payload, state, message, onRemove, onReload }: Props) {
  return (
    <>
      <li
        id={id}
        data-for={id}
        data-progress={progress}
        title={message ?? ''}
        className={clsx('row a-center j-between w-100p', classes.container)}
      >
        <P4 className={clsx(classes.title, 't-ellipsis')}>{payload.get('title')}</P4>
        <div className={clsx('row nowrap', classes.buttons)}>
          {ifTrue(state === SUCCESS, <UseIcon iconId='check-circle-success' className={classes.status} />)}
          {ifTrue(
            state === FAIL,
            <UseIcon iconId='alert-error' className={classes.status} wrapElement='button' onClick={onReload} />,
          )}
          {ifTrue(state === IN_PROGRESS, <ProgressCircular value={progress ?? 0} className={classes.status} />)}
          <CloseButton onClick={onRemove} />
        </div>
      </li>
    </>
  );
}
