import * as React from 'react';

export default function IconOffice() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='55' height='41' viewBox='0 0 55 41' stroke='none'>
      <defs>
        <filter id='filter-1' width='160%' height='172.7%' x='-30%' y='-36.4%' filterUnits='objectBoundingBox'>
          <feColorMatrix
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' transform='translate(-165 -1300)'>
          <g filter='url(#filter-1)' transform='translate(169 1302) scale(1.3)'>
            <path
              stroke='none'
              d='M10.805 32.36h7.062v-7.22h4.245v7.22h7.061V.03H10.805v32.33zM21.278 3.84h4.244v4.245h-4.244V3.84zm0 6.822h4.244v4.245h-4.244v-4.245zm0 6.822h4.244v4.245h-4.244v-4.245zM14.456 3.84h4.245v4.245h-4.245V3.84zm0 6.822h4.245v4.245h-4.245v-4.245zm0 6.822h4.245v4.245h-4.245v-4.245zM30.822 2.926v22.86h2.727v6.575h6.43V2.926h-9.157zm5.833 19.755H32.79v-3.864h3.865v3.864zm0-6.21H32.79v-3.865h3.865v3.864zm0-6.211H32.79V6.395h3.865v3.865zM0 32.36h6.43v-6.574h2.726V2.926H0v29.435zM3.324 6.396h3.864v3.865H3.324V6.395zm0 6.211h3.864v3.864H3.324v-3.864zm0 6.21h3.864v3.865H3.324v-3.864z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
