import { processError } from 'src/tools/events.tools';

import { Action, ProcessProps, Response } from 'src/models/general.model';
import { QueryAction, QueryDomain } from 'src/models/query.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { sendInfo } from 'src/store/app/app.actions';

function processReports({ dispatch, getService }: ProcessProps) {
  const processReportRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'reports', state: true }));
    getService().request(
      { [QueryDomain.REPORT]: QueryAction.CREATE, payload: action.payload },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(sendInfo(`${action.payload.note} has been requested.`));
        } else processError({ activityName: 'Request report', response });

        dispatch(setCustomIsLoading({ id: 'reports', state: false }));
      },
    );
  };

  return { processReportRequest };
}

export { processReports };
