import * as React from 'react';
import ReactDOM from 'react-dom';

import { Either } from 'src/models/general.model';
import { logError } from 'src/tools/log.tools';

interface Props {
  children: React.ReactNode;
  parentId: string;
}

export function Portal({ parentId, children }: Props) {
  const [parent, setParent] = React.useState<Either<HTMLElement, null>>();

  React.useLayoutEffect(() => {
    const element = document.getElementById(parentId);

    setParent(element);

    if (!element) {
      logError('Portal: #dropdown element is not found in the document');
    }
  }, [parentId]);

  if (!parent) return <></>;

  return ReactDOM.createPortal(children, parent);
}
