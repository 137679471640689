import * as React from 'react';
import clsx from 'clsx';

import { ifTrue } from 'src/tools/logic.tools';
import { ID } from 'src/models';
import { ImageDefaultProjectImage } from 'src/assets';

import { P1 } from 'src/components/Typography';
import { Image } from 'src/components/Image';
import { UseIcon } from 'src/components/UseIcon';

import classes from './SubprojectCard.module.scss';
import imageClasses from './SubprojectCardImage.module.scss';

interface Props {
  id: ID;
  imageUrl: string;
  label: string;
  onClick: () => void;
  type: string;
}

// new-ui
export function SubprojectCard({ id, imageUrl, label, onClick, type }: Props) {
  function handleOnClick() {
    return onClick();
  }

  function renderSubIcon() {
    return <UseIcon iconId='subcontractor' className={clsx(classes.sub)} />;
  }

  return (
    <div className={clsx(classes.container, 'card-item-interactive column o-hidden')} onClick={handleOnClick}>
      <Image id={id} containerStyle={imageClasses} src={imageUrl} placeholderSrc={ImageDefaultProjectImage} />
      <P1 className={clsx(classes.label, 't-ellipsis')} title={label}>
        {label}
      </P1>
      {ifTrue(type === 'sub', renderSubIcon)}
    </div>
  );
}
