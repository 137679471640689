import { Maybe, Result } from 'src/models';
import { logError } from 'src/tools/log.tools';
import { success, fail } from 'src/tools/result.tools';

const getTokenName = () => `rp_token_${window.location.hostname}`;

const getToken = (): Maybe<string> => localStorage.getItem(getTokenName()) || undefined;

// get token without "Bearer "
const getCleanToken = () => getToken()?.replace('Bearer ', '');

function clearToken(): boolean {
  try {
    localStorage.removeItem(getTokenName());
    return true;
  } catch (err) {
    logError(err ?? 'Error in clearing token');
    return false;
  }
}

function setToken(token: string): boolean {
  clearToken();

  try {
    localStorage.setItem(getTokenName(), token);
    return true;
  } catch (err) {
    logError(err ?? 'Error in setting token');
    return false;
  }
}

function storeItem(key: string, value: string): Result<string> {
  try {
    localStorage.setItem(key, value);
    return success();
  } catch (err) {
    return fail(err.message);
  }
}

export function readItem<T>(key: string, defaultReturn: T): T {
  if ('localStorage' in globalThis) {
    try {
      const result = globalThis.localStorage.getItem(key) as unknown;

      return (result as T) || defaultReturn;
    } catch (err) {
      if (process.env.NODE_ENV !== 'test') logError('readItem from storage caught error.', err);

      return defaultReturn;
    }
  } else return defaultReturn;
}

function clearItem(key: string): Result<string> {
  try {
    localStorage.removeItem(key);
    return success();
  } catch (err) {
    return fail(err.message);
  }
}

export { getToken, clearToken, setToken, storeItem, clearItem, getCleanToken };
