import { createSelector } from 'reselect';
import { BatchItemState } from 'src/models';
import { RootState } from '..';

export const getFiles = (state: RootState) => state.files.files;

export const getPdfAnnotations = (state: RootState) => state.files.annotations;

export const getUploads = (state: RootState) => state.files.uploads ?? [];

export const getServiceInfo = (state: RootState) => state.files.serviceInfo;

export const getAvailableUploadItems = (state: RootState) =>
  state.files.uploads?.filter(item => item.state === BatchItemState.IDLE) ?? [];

export const getFailedUploadItems = (state: RootState) =>
  state.files.uploads?.filter(item => item.state === BatchItemState.FAIL) ?? [];

export const getUploaded = (state: RootState) =>
  state.files.uploads?.filter(item => item.state === BatchItemState.SUCCESS);

export const getQtyOfUploaded = createSelector(getUploaded, uploaded => uploaded?.length ?? 0);

export const getQtyOfUploads = createSelector(getUploads, uploads => uploads?.length ?? 0);

export const getHasFailedUploads = createSelector(getFailedUploadItems, failed => !!failed.length);
