import { Retainage } from 'src/models';
import { Action, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';
import { QueryAction, QueryDomain } from 'src/models/query.model';
import { sendInfo } from 'src/store/app/app.actions';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deleteRetainage, setRetainage } from 'src/store/retainage/retainage.reducer';
import { processError } from 'src/tools/events.tools';
import { deserialize } from 'src/tools/string.tools';

export function processRetainage({ dispatch, getService }: ProcessProps) {
  function processRetainageRequest(action: Action) {
    return function call() {
      dispatch(setCustomIsLoading({ id: 'retainage', state: true }));

      getService().request(
        { [QueryDomain.RETAINAGE]: QueryAction.GET, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<Retainage>>(response?.payload);

            dispatch(setRetainage(result?.data));
            dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
          } else {
            processError({
              activityName: 'Request to get retainage',
              response,
            });
            dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
          }
        },
      );
    };
  }

  function processRetainagePostRequest(action: Action) {
    return function call() {
      dispatch(setCustomIsLoading({ id: 'retainage', state: true }));

      getService().request(
        { [QueryDomain.RETAINAGE]: QueryAction.POST, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<Retainage>>(response?.payload);

            dispatch(setRetainage(result?.data));
            dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
            dispatch(sendInfo('Retainage has been created.'));
          } else {
            processError({
              activityName: 'Request to create retainage',
              response,
            });
            dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
          }

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
        },
      );
    };
  }

  function processRetainageUpdateRequest(action: Action) {
    return function call() {
      dispatch(setCustomIsUpdating({ id: 'retainage', state: true }));

      getService().request(
        { [QueryDomain.RETAINAGE]: QueryAction.PATCH, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<Retainage>>(response?.payload);

            dispatch(setRetainage(result?.data));
            dispatch(setCustomIsUpdating({ id: 'retainage', state: false }));
            dispatch(sendInfo('Retainage has been updated.'));
          } else {
            processError({
              activityName: 'Request to update retainage',
              response,
            });
            dispatch(setCustomIsUpdating({ id: 'retainage', state: false }));
          }

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
        },
      );
    };
  }

  function processRetainageDeleteRequest(action: Action) {
    return function call() {
      dispatch(setCustomIsLoading({ id: 'retainage', state: true }));

      getService().request(
        { [QueryDomain.RETAINAGE]: QueryAction.DELETE, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            dispatch(deleteRetainage(action.payload.retainage_id));
            dispatch(sendInfo('Retainage has been deleted.'));
          } else {
            processError({
              activityName: 'Request to delete retainage',
              response,
            });
          }

          dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
        },
      );
    };
  }

  return {
    processRetainageRequest,
    processRetainagePostRequest,
    processRetainageUpdateRequest,
    processRetainageDeleteRequest,
  };
}
