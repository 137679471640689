import { ButtonBaseType, PredefinedLists } from 'src/models';
import { FormScenario, FormShowAs } from 'src/models/form.model';

export const ADD_CHANGE_ORDER_FORM_SCENARIO: FormScenario = {
  company_id: {
    label: 'Company',
    showAs: FormShowAs.PROJ_SUBCONTRACTOR_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select company',
    type: 'number',
    isPrivate: true,
  },
  trade_contract_id: {
    label: 'Trade',
    showAs: FormShowAs.PROJ_COMPANY_TRADE_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select trade',
    type: 'number',
    readKey: 'company_id',
    writeKeys: ['csi_code', 'csi_description'],
    isEnabledFromKey: 'company_id',
  },
  csi_code: {
    label: 'CSI Number',
    showAs: FormShowAs.CSI_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select CSI number',
    readKey: 'csi_description',
  },
  cost: {
    label: 'Subcontractor Cost',
    showAs: FormShowAs.FIN,
    isRequired: true,
    placeholder: 'Enter cost',
    group: 'submission',
  },
  date_submitted: {
    label: 'Submitted Date',
    showAs: FormShowAs.DATE,
    isRequired: true,
    group: 'submission',
    defaultValue: new Date(),
  },
  is_contingency: {
    placeholder: 'Use of Contingency',
    showAs: FormShowAs.SWITCH,
    defaultValue: false,
    isPrivate: true,
  },
  contingency_line_item_id: {
    label: 'Contingency',
    showAs: FormShowAs.NESTED_CONTINGENCY_DROPDOWN,
    isRequired: true,
    placeholder: 'Select contingency line to pull from',
    isVisibleFromKey: 'is_contingency',
    readKey: 'trade_contract_id',
    additionalReadKeys: ['cost', 'is_contingency'],
    placeholderText: 'You first need to select a company and a trade to use contingency.',
  },
  aco: {
    placeholder: 'Anticipated CO',
    showAs: FormShowAs.SWITCH,
    defaultValue: false,
  },
  project_co_number: {
    label: 'Project CO numbers',
    showAs: FormShowAs.TAGS_INPUT,
    placeholder: 'Enter project CO numbers separated by spaces',
  },
  description: {
    label: 'Description',
    showAs: FormShowAs.TEXT,
    placeholder: 'Description and details',
  },
  reason: {
    label: 'Reason',
    showAs: FormShowAs.SEARCH_DROPDOWN,
    placeholder: 'Select reason',
    predefinedListName: PredefinedLists.CHANGE_ORDER_REASONS,
  },
  schedule_impact: {
    placeholder: 'Schedule Impact',
    showAs: FormShowAs.SWITCH,
    defaultValue: false,
  },
  schedule_impact_days: {
    label: 'Days',
    showAs: FormShowAs.NUMBER,
    placeholder: 'Enter days',
    isVisibleFromKey: 'schedule_impact',
    group: 'submission_impact',
  },
  impact_cost: {
    label: 'Cost of Impact',
    showAs: FormShowAs.FIN,
    placeholder: 'Enter amount',
    isVisibleFromKey: 'schedule_impact',
    group: 'submission_impact',
  },
};

export const ADD_CHANGE_ORDER_SUB_ONLY_FORM_SCENARIO: FormScenario = {
  company_id: {
    label: 'Company',
    showAs: FormShowAs.PROJ_SUBCONTRACTOR_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select company',
    type: 'number',
    isPrivate: true,
  },
  trade_contract_id: {
    label: 'Trade',
    showAs: FormShowAs.PROJ_COMPANY_TRADE_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select trade',
    type: 'number',
    readKey: 'company_id',
    writeKeys: ['csi_code', 'csi_description'],
    isEnabledFromKey: 'company_id',
  },
  csi_code: {
    label: 'CSI Number',
    showAs: FormShowAs.CSI_AUTO_COMPLETE,
    isRequired: true,
    placeholder: 'Select CSI number',
    readKey: 'csi_description',
  },
  cost: {
    label: 'Subcontractor Cost',
    showAs: FormShowAs.FIN,
    isRequired: true,
    placeholder: 'Enter cost',
    group: 'submission',
  },
  date_submitted: {
    label: 'Submitted Date',
    showAs: FormShowAs.DATE,
    isRequired: true,
    group: 'submission',
    defaultValue: new Date(),
  },
  aco: {
    placeholder: 'Anticipated CO',
    showAs: FormShowAs.SWITCH,
    defaultValue: false,
  },
  project_co_number: {
    label: 'Project CO numbers',
    showAs: FormShowAs.TAGS_INPUT,
    placeholder: 'Enter project CO numbers separated by spaces',
  },
  description: {
    label: 'Description',
    showAs: FormShowAs.TEXT,
    placeholder: 'Description and details',
  },
  reason: {
    label: 'Reason',
    showAs: FormShowAs.SEARCH_DROPDOWN,
    placeholder: 'Select reason',
    predefinedListName: PredefinedLists.CHANGE_ORDER_REASONS,
  },
  schedule_impact: {
    placeholder: 'Schedule Impact',
    showAs: FormShowAs.SWITCH,
    defaultValue: false,
  },
  schedule_impact_days: {
    label: 'Days',
    showAs: FormShowAs.NUMBER,
    placeholder: 'Enter days',
    isVisibleFromKey: 'schedule_impact',
    group: 'submission_impact',
  },
  impact_cost: {
    label: 'Cost of Impact',
    showAs: FormShowAs.FIN,
    placeholder: 'Enter amount',
    isVisibleFromKey: 'schedule_impact',
    group: 'submission_impact',
  },
};

export const CHANGE_ORDER_STATUSES: ButtonBaseType[] = [
  { id: 'anticipated', label: 'Anticipated' },
  { id: 'new', label: 'New' },
  { id: 'submitted_for_review', label: 'Submitted for Review' },
  { id: 'revise_and_resubmit_for_review', label: 'Revise & Resubmit for Review' },
  { id: 'approved_by_cm', label: 'Approved by CM' },
  { id: 'submitted_for_approval', label: 'Submitted for Approval' },
  { id: 'revise_and_resubmit_for_approval', label: 'Revise & Resubmit for Approval' },
  { id: 'approved', label: 'Approved' },
  { id: 'superseded', label: 'Superseded' },
  { id: 'void', label: 'Void' },
  { id: 'rejected', label: 'Rejected' },
];

export const EDIT_CHANGE_ORDER_TABS: ButtonBaseType[] = [
  { id: 'changeOrder', label: 'Change order' },
  { id: 'balances', label: 'Balances' },
];
