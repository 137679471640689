import * as React from 'react';

export default function SvgIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit='2'
      clipRule='evenodd'
      viewBox='0 0 16 20'
    >
      <path
        fill=''
        d='M0 9.041L2.459 11.5 0 13.959l9.178 9.178 9.041-9.041-2.459-2.459 2.459-2.459L9.041 0 0 9.041zm1.401 4.928l1.764-1.763 6.013 6.013 5.887-5.887 1.753 1.753-7.651 7.65-7.766-7.766zm7.766 2.849l5.192-5.192-.833-.833-4.237 3.926-2.087-2.137-1.213 1.057 3.178 3.179zm-3.88-3.881L3.86 11.511l5.192-5.192 3.773 3.773-3.501 3.244-2.067-2.115-1.97 1.716zm3.754-8.019l6.013 6.013 1.764-1.764-7.766-7.766-7.651 7.651 1.753 1.753 5.887-5.887z'
        transform='rotate(.123) scale(.86443)'
      />
    </svg>
  );
}
