import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_subcontractor'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M13.333 6.447v-.114a5.3 5.3 0 00-3.727-5.08h-.04a5.547 5.547 0 00-1.726-.24 5.473 5.473 0 00-5.174 5.434 2 2 0 000 3.773A5.334 5.334 0 008 15h.153a5.42 5.42 0 005.12-4.773 2 2 0 00.06-3.78zm-5.213 7.22a4 4 0 01-4.06-3.334h7.846a4.06 4.06 0 01-3.786 3.334zM12.666 9H3.333a.667.667 0 010-1.333h1.333a.667.667 0 100-1.334H4A4.267 4.267 0 016 2.9V5a.667.667 0 001.333 0V2.393c.181-.03.364-.05.547-.06H8c.223.003.446.024.666.06V5A.667.667 0 1010 5V2.88c.283.164.546.358.786.58A4 4 0 0112 6.333h-.667a.667.667 0 100 1.334h1.333a.667.667 0 110 1.333z'
      />
    </svg>
  );
}

export default Icon;
