import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_government'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        d='M14 6.667A.666.666 0 0014.668 6V4a.666.666 0 00-.455-.632l-6-2a.668.668 0 00-.422 0l-6 2A.666.666 0 001.334 4v2a.667.667 0 00.667.667h.666v4.79a1.997 1.997 0 00-1.333 1.876v1.334a.666.666 0 00.667.666h12a.667.667 0 00.666-.666v-1.334a1.997 1.997 0 00-1.333-1.877v-4.79h.667zM13.335 14H2.667v-.667a.667.667 0 01.667-.666h9.333a.668.668 0 01.667.666V14zm-9.333-2.667V6.667h1.333v4.666H4.001zm2.666 0V6.667h2.667v4.666H6.667zm4 0V6.667h1.334v4.666h-1.334zm-8-6V4.48l5.334-1.778 5.333 1.778v.853H2.667z'
      />
    </svg>
  );
}

export default Icon;
