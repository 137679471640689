import { processError } from 'src/tools/events.tools';

import { ContractTerm } from 'src/models/contract_terms.model';
import { Action, Maybe, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';
import { QueryAction, QueryDomain, ResourceType } from 'src/models/query.model';
import { PageQuery, RequestMeta } from 'src/models/results.model';
import { Trade, TradeParams } from 'src/models/trade.model';
import { setCompany } from 'src/store/companies/companies.reducer';
import {
  setCustomIsLoading,
  setCustomIsUpdating,
  setIsLoading,
  setIsUpdating,
} from 'src/store/loading/loading.reducer';
import {
  addTrades,
  getTradesQuery,
  setTrade,
  setTrades,
  setTradeScenario,
  setTradesMeta,
  setTradesPermissions,
  setTradesQuery,
} from 'src/store/trades/trades.reducer';
import { requestSingleCompany } from 'src/store/companies/companies.action';
import { requestRetainage } from 'src/store/retainage/retainage.actions';
import { requestTeam } from 'src/store/team/team.actions';
import { sendInfo, alertError } from 'src/store/app/app.actions';
import { getAppParams, getCurrentSubprojectId } from 'src/store/app/app.getters';
import { logUnableToProceed } from 'src/tools/log.tools';
import { deserialize, capitalize } from 'src/tools/string.tools';
import { pick } from 'ramda';
import { getTradesMeta } from 'src/store/trades/trades.getters';
import { toType } from 'src/tools/type.tools';
import { ShowScenario } from 'src/models';

function processTrades({ dispatch, getService, getState }: ProcessProps) {
  function makeQueryFromState(queryUpdate: Maybe<Partial<PageQuery>> = {}): Partial<PageQuery> {
    const query = getTradesQuery(getState()) ?? {};

    return {
      ...query,
      ...queryUpdate,
    };
  }

  function makeMetaFromState(metaUpdate: Maybe<Partial<RequestMeta>> = {}): Partial<RequestMeta> {
    const meta = getTradesMeta(getState()) ?? {};

    return pick(['page', 'per_page'], {
      ...meta,
      ...metaUpdate,
    });
  }

  const processTradeShowRequest = (action: Action<string>) => () => {
    dispatch(setIsLoading(true));
    dispatch(setCustomIsLoading({ id: 'company', state: true }));
    dispatch(setCustomIsLoading({ id: 'retainage', state: true }));
    dispatch(setCustomIsLoading({ id: 'team', state: true }));
    getService().request(
      {
        [QueryDomain.TRADE]: QueryAction.GET_ONE,
        payload: {
          trade_contract_id: action.payload,
          project_id: getCurrentSubprojectId(getState()),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Trade>>(response.payload);

          dispatch(setTrade(result?.data));
          dispatch(setTradesPermissions(result?.permissions));
          dispatch(setTradeScenario(toType<ShowScenario>(result?.scenario)));
          dispatch(setIsLoading(false));

          if (result?.data.company_id) {
            dispatch(requestSingleCompany(result?.data.company_id));
          } else {
            dispatch(setCustomIsLoading({ id: 'company', state: false }));
            dispatch(setCompany(undefined));
          }

          if (result?.data.id) {
            dispatch(requestRetainage(result.data.id, ContractTerm.TRADE_CONTRACT));
            dispatch(requestTeam(result.data.id, ResourceType.TRADE_CONTRACT));
          } else {
            dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
            dispatch(setCustomIsLoading({ id: 'team', state: false }));
          }
        } else {
          processError({ activityName: 'Request trade', response });
          dispatch(setIsLoading(false));
          dispatch(setCustomIsLoading({ id: 'company', state: false }));
          dispatch(setCustomIsLoading({ id: 'retainage', state: false }));
          dispatch(setCustomIsLoading({ id: 'team', state: false }));
        }
      },
    );
  };

  const processTradesRequest = (action: Action<TradeParams>) => () => {
    if (!action.meta?.isUpdate) dispatch(setCustomIsLoading({ id: 'trades', state: true }));
    else dispatch(setCustomIsUpdating({ id: 'trades', state: true }));

    getService().request(
      {
        [QueryDomain.TRADE]: QueryAction.GET,
        payload: {
          project_id: getCurrentSubprojectId(getState()),
          query: makeQueryFromState(action.payload?.query),
          meta: makeMetaFromState(action.payload?.meta),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Trade[]>>(response.payload);

          const data = result?.data ?? [];

          if (action.meta?.isUpdate) dispatch(addTrades(data));
          else dispatch(setTrades(data));

          dispatch(setTradesMeta(result?.meta));
          dispatch(setTradesQuery(result?.query));
          dispatch(setTradesPermissions(result?.permissions));
        } else {
          processError({ activityName: 'Request trades', response });
        }

        if (!action.meta?.isUpdate) dispatch(setCustomIsLoading({ id: 'trades', state: false }));
        else dispatch(setCustomIsUpdating({ id: 'trades', state: false }));
      },
    );
  };

  const processMoreTradesRequest = (action: Action<undefined>) => () => {
    const pagination = getTradesMeta(getState());

    if (typeof pagination?.next !== 'number') {
      logUnableToProceed('processMoreRequest', { page: pagination });
      return;
    }

    processTradesRequest({
      ...action,
      payload: {
        meta: { page: pagination.next },
      },
    })();
  };

  const processTradeCreateRequest = (action: Action) => () => {
    dispatch(setIsLoading(true));
    getService().request(
      { [QueryDomain.TRADE]: QueryAction.CREATE, payload: action.payload },
      (response: Response<string>) => {
        dispatch(setIsLoading(false));

        if (response.isOK) {
          dispatch(sendInfo('Trade has been created'));

          const result = deserialize<ResponsePayload<Trade>>(response.payload);

          if (action.meta?.callback) action.meta.callback(result?.data.id);
        } else {
          processError({ activityName: 'Request to create trade', response });
        }
      },
    );
  };

  const processTradeUpdateRequest = (action: Action) => () => {
    dispatch(setIsUpdating(true));

    const current = getState().trades.trade;

    const { trade_id } = action.payload;

    const trade_contract_id = getAppParams(getState()).tradeId;

    getService().request(
      {
        [QueryDomain.TRADE]: QueryAction.UPDATE,
        payload: {
          ...action.payload,
          project_id: getCurrentSubprojectId(getState()),
          trade_contract_id: trade_contract_id ?? trade_id,
        },
      },

      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Trade>>(response.payload);

          if (trade_contract_id) dispatch(setTrade(result?.data));

          dispatch(setIsUpdating(false));

          if (result?.data?.company_id) {
            dispatch(requestSingleCompany(result?.data?.company_id));
          } else {
            dispatch(setCompany(undefined));
          }

          if (trade_contract_id) dispatch(requestTeam(trade_contract_id, ResourceType.TRADE_CONTRACT));

          dispatch(sendInfo('Trade has been updated'));

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK, payload: result });
        } else {
          dispatch(setTrade(current));
          processError({
            activityName: 'Request to update trade',
            isLoadingFn: setIsUpdating,
            callbackFn: action.meta?.callback,
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
      },
    );
  };

  const processTradeDeleteRequest = (action: Action) => () => {
    dispatch(setIsUpdating(true));

    const current = getState().trades.trade;

    const trade_contract_id = getAppParams(getState()).tradeId;

    getService().request(
      {
        [QueryDomain.TRADE]: QueryAction.DELETE,
        payload: {
          project_id: getCurrentSubprojectId(getState()),
          trade_contract_id,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(setIsUpdating(false));

          dispatch(sendInfo('Trade has been deleted'));

          if (action.meta?.callback) action.meta.callback();
        } else {
          dispatch(setTrade(current));
          dispatch(
            alertError({
              title: 'Delete trade',
              content: capitalize(String(response?.message)) ?? 'Failed to perform the action',
              buttonLabel: 'Got it',
            }),
          );
        }
      },
    );
  };

  return {
    processTradeShowRequest,
    processTradesRequest,
    processMoreTradesRequest,
    processTradeCreateRequest,
    processTradeUpdateRequest,
    processTradeDeleteRequest,
  };
}

export { processTrades };
