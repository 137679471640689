import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_visibility-on'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 15a3 3 0 100-6 3 3 0 000 6z'
      />
    </svg>
  );
}

export default Icon;
