import { Action, ActionMeta, ID } from 'src/models/general.model';
import { RetainageActions } from 'src/models/query.model';
import { NewRetainage } from 'src/models/retainage.model';

export const requestRetainage = (resource_id: ID, resource_type: string): Action => ({
  type: RetainageActions.GET,
  payload: { resource_id, resource_type },
});

export const requestRetainageCreate = (
  data: NewRetainage,
  resource_id: ID,
  resource_type: string,
  meta?: ActionMeta,
): Action => ({
  type: RetainageActions.POST,
  payload: {
    data,
    resource_id,
    resource_type,
  },
  meta,
});

export const requestRetainageUpdate = (data: NewRetainage, retainage_id: ID, meta?: ActionMeta): Action => ({
  type: RetainageActions.UPDATE,
  payload: {
    data,
    retainage_id,
  },
  meta,
});

export const requestRetainageDelete = (retainage_id: ID): Action => ({
  type: RetainageActions.DELETE,
  payload: { retainage_id },
});
