import React, { useEffect, useState, forwardRef, lazy } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { Maybe } from 'src/models';
import { getCurrentSubprojectCurrencySymbol } from 'src/store/subProject/subProject.getters';
import { Factory } from 'src/components/Factory';

import classes from '../Input.module.scss';

const Cleave = lazy(() => import('src/components/Cleave'));

export interface InputNumberProps {
  onChange: (value: Maybe<number>) => void;
  onSubmit?: () => void;
  onDiscard?: () => void;
  value: Maybe<number>;
  isDisabled?: boolean;
  isReadonly?: boolean;
  autoFocus?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
  isSmall?: boolean;
  isXSmall?: boolean;
  placeholder?: string;
  className?: string;
  numeralDecimalScale?: number;
  stripLeadingZeroes?: boolean;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  min?: number;
  max?: number;
  isSelectAllOnFocus?: boolean;
  numeralIntegerScale?: number;
  numeralPositiveOnly?: boolean;
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      onChange,
      onSubmit,
      onDiscard,
      onKeyUp,
      isCurrency,
      isPercent,
      value,
      isDisabled,
      isReadonly,
      className,
      numeralDecimalScale = isCurrency ? 2 : 0,
      min,
      max,
      isSmall,
      isXSmall,
      // eslint-disable-next-line @getify/proper-arrows/params
      stripLeadingZeroes,
      isSelectAllOnFocus,
      numeralIntegerScale,
      numeralPositiveOnly,
      ...cleaveProps
    },
    ref,
  ) => {
    if (isSmall && isXSmall) {
      throw new Error('InputNumber can be either small or xsmall');
    }

    const [cachedValue, setCachedValue] = useState<Maybe<number>>();

    const [selectAllOnFocus, setSelectAllOnFocus] = useState(isSelectAllOnFocus);

    const currencySymbol = useSelector(getCurrentSubprojectCurrencySymbol);

    useEffect(() => {
      if (isDisabled) setCachedValue(undefined);
    }, [isDisabled]);

    function handleOnKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Enter' && onSubmit) {
        setCachedValue(undefined);
        onSubmit();
      } else if (e.key === 'Escape' && onDiscard) {
        setCachedValue(undefined);
        onDiscard();
      }

      if (onKeyUp) onKeyUp(e);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
      const regExp = new RegExp(`[,${currencySymbol}%]`, 'g');

      const cleanValue = e.target.value.replace(regExp, '');

      if (cleanValue !== '-') {
        let numberValue: Maybe<number> = cleanValue ? Number(cleanValue) : 0;

        if (Number.isNaN(numberValue)) numberValue = undefined;

        if (typeof numberValue === 'number') {
          if (typeof max === 'number' && numberValue > max) {
            numberValue = max;
          }

          setCachedValue(numberValue);
        }

        if (value !== numberValue) onChange(numberValue);
      } else {
        if (isSelectAllOnFocus) setSelectAllOnFocus(false);
      }
    }

    function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
      const regExp = new RegExp(`[,${currencySymbol}%]`, 'g');

      const cleanValue = e.target.value.replace(regExp, '');

      if (cleanValue && e.target.min && Number(cleanValue) < Number(e.target.min)) {
        onChange(Number(e.target.min));
      }

      setCachedValue(undefined);
    }

    function initRef(element: HTMLInputElement) {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    }

    function renderInitialValue() {
      if (typeof value === 'undefined') return '';

      if (numeralDecimalScale) return Number(value).toFixed(numeralDecimalScale);

      return String(value);
    }

    let prefix;

    if (isCurrency) prefix = currencySymbol;
    else if (isPercent) prefix = '%';

    return (
      <Factory>
        <Cleave
          data-testid='input-number'
          htmlRef={initRef}
          onChange={handleChange}
          onKeyUp={handleOnKeyUp}
          onBlur={handleBlur}
          className={clsx(classes.container, className, { [classes.small]: isSmall, [classes.xsmall]: isXSmall })}
          disabled={isDisabled}
          readOnly={isReadonly}
          value={cachedValue ?? renderInitialValue() ?? ''}
          min={min}
          max={max}
          options={{
            prefix,
            tailPrefix: isPercent,
            numeral: true,
            noImmediatePrefix: true,
            stripLeadingZeroes,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalScale,
            numeralIntegerScale,
            isSelectAllOnFocus: selectAllOnFocus,
            numeralPositiveOnly,
          }}
          {...cleaveProps}
        />
      </Factory>
    );
  },
);
