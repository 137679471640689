import { Maybe } from 'src/models/general.model';
import { MainContract } from 'src/models/main_contract.model';
import { ShowScenario } from 'src/models/scenario.model';
import { Permission } from 'src/models/permissions.model';

export interface MainContractState {
  pageData?: Maybe<MainContract>;
  scenario?: Maybe<ShowScenario>;
  permissions?: Maybe<Permission[]>;
}

export const INITIAL_MAIN_CONTRACT_STATE: MainContractState = {};
