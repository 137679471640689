import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { equals } from 'ramda';

import { getParams, setParams } from 'src/store/app/app.reducer';

interface Props {
  children: JSX.Element;
}

export function SyncParamsWithStore({ children }: Props) {
  const dispatch = useDispatch();

  const currentParams = useSelector(getParams);

  const params = useParams();

  useEffect(() => {
    if (!equals(currentParams, params)) dispatch(setParams(params));
  }, [dispatch, params, currentParams]);

  return children;
}
