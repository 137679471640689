export enum DrawColor {
  WARNING_DEFAULT = '#f3bb1d', // var(--warning-default)
  PRIMARY_DEFAULT = '#2c47e8', // var(--primary-default)
  PRIMARY_LIGHTER = '#cad1f9', // var(--primary-lighter)
  ACCENT_1_DEFAULT = '#00a3ff', // var(--accent-1-default)
  ERROR_DEFAULT = '#e52324', // var(--error-default)
  SUCCESS_DEFAULT = '#0bbf36', // var(--success-default)
  BLACK_DEFAULT = '#000', // var(--black-default)
  WHITE_DEFAULT = '#fff', // var(--white-default)
  GREY_DARK = '#92979d', // var(--grey-dark)
}

export enum DrawSize {
  SIZE_2 = 2,
  SIZE_3 = 3,
  SIZE_4 = 4,
  SIZE_5 = 5,
  SIZE_6 = 6,
}
