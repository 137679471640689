import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      stroke='none'
      viewBox='0 0 48 48'
      data-testid='icon_user-fill'
    >
      <path d='M40 42v-4a8 8 0 00-8-8H16a8 8 0 00-8 8v4' />
      <path d='M24 22a8 8 0 100-16 8 8 0 000 16z' />
    </svg>
  );
}

export default Icon;
