import { Result } from 'src/models/general.model';

export interface CellEditProps<T = unknown, V = string> {
  row: T;
  value: V;
  callback: (r: Result) => void;
}

export enum SpreadsheetResourse {
  MANAGER_REQ = 'manager_req',
  REQ_DETAIL = 'req_detail',
}
