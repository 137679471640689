import * as React from 'react';

import { UseIcon } from 'src/components/UseIcon';

import classes from './EditButton.module.scss';

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
}

export function EditButton(props: Props) {
  return <UseIcon className={classes.icon} iconId='edit' wrapElement='button' {...props} />;
}
