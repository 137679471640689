import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { closeAlert } from 'src/store/app/app.reducer';
import { getAlertMessage } from 'src/store/app/app.getters';
import { Factory } from 'src/components/Factory';

import classes from './Alert.module.scss';

const AlertModal = React.lazy(() => import('./AlertModal'));

// new-ui
export function Alert() {
  const dispatch = useDispatch();

  const message = useSelector(getAlertMessage);

  function handleOnClose() {
    dispatch(closeAlert());
  }

  if (!message) return <></>;

  return (
    <div className={clsx(classes.container, 'column centered w-100p h-100p')}>
      <Factory>
        <AlertModal message={message} onClose={handleOnClose} />
      </Factory>
    </div>
  );
}
