import { RootState } from 'src/store';
import { makeIsAllowedToUpdate } from 'src/tools/selectors.tools';

export const getCompany = (state: RootState) => state.companies.company;

export const getUserCompany = (state: RootState) => state.companies.userCompany;

export const getCompanies = (state: RootState) => state.companies.companies;

export const getCompaniesQuery = (state: RootState) => state.companies.query;

export const getCompaniesMeta = (state: RootState) => state.companies.meta;

export const getCompaniesAllowedToUpdate = makeIsAllowedToUpdate(getCompaniesMeta);

export const getCompaniesPermissions = (state: RootState) => state.companies.permissions;
