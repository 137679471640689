import { is, not } from 'ramda';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en'; // load on demand

import { CalendarMinMax } from 'src/models/calendar.model';
import { AnyValue, Maybe } from 'src/models/general.model';
import { toType } from './type.tools';

dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.locale('en');
dayjs.extend(utc);

export const fullDateFormatter = new Intl.DateTimeFormat('en', { day: 'numeric', month: 'long', year: 'numeric' });

export const relativeDate = (date: Date) => dayjs(date).fromNow();

export function getDate(value: AnyValue) {
  if (!value) return new Date();

  if (value instanceof Date) return value;

  try {
    if (typeof value === 'string') new Date(value);
  } catch (err) {
    return new Date();
  }

  return new Date();
}

export function makeCalendarMixMax(past: Date, current: [number, number]): Maybe<CalendarMinMax> {
  if (!past || not(is(Number, current[0])) || not(is(Number, current[1]))) return undefined;

  const pastDate = is(Date, past) ? past : new Date(past);

  return { min: [pastDate.getMonth() + 1, pastDate.getFullYear()], max: [current[0], current[1]] };
}

// date should be in UTC, output in UTC
export const dateToDateString = (date?: Date | string) => {
  if (!date) return '';

  const dateInstance: Date = is(Date, date) ? (date as Date) : new Date(date as string);

  if (Number.isNaN(dateInstance.valueOf())) return '';

  return dayjs(utcDateAsLocal(dateInstance)).format('MM/DD/YYYY');
};

export const dateToDateTimeString = (date?: Date | string, timezone?: number) => {
  if (!date) return '';

  const dateInstance: Date = is(Date, date) ? (date as Date) : new Date(date as string);

  if (Number.isNaN(dateInstance.valueOf())) return '';

  return timezone
    ? dayjs(dateInstance).utcOffset(timezone).format('MM/DD/YYYY hh:mm A')
    : dayjs(utcDateAsLocal(dateInstance)).format('MM/DD/YYYY hh:mm A');
};

// used in Calendar/CalendarDropdown
export function monthYearToDate([month, year]: [number, number]) {
  return new Date(year, month - 1, 1);
}

// user in CalendarDropdown
export const getOnlyMonth = (date: Date) => dayjs(date).format('MMM');

// used in Calendar
export const getMonthFullYear = (date: Date) => dayjs(date).format('MMM YYYY');

/**
 * Converts date from UTC to local timezone.
 *
 * To be used with libraries which support local timezone only. Use this function to provide the date to such library.
 * @param date Proper date.
 * @returns Shifted date.
 */
export const utcDateAsLocal = (date: Date) =>
  date && new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);

/**
 * Converts date from local timezone to UTC.
 *
 * To be used with libraries which support local timezone only. Use this function to consume the date received from such library.
 * @param date Shifted date.
 * @returns Proper date.
 */
export const localDateAsUtc = (date: Date) => date && new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export function adjustDate(date: unknown) {
  let parsedDate = toType<Date>(date);

  try {
    if (is(String, date)) parsedDate = new Date(date as string);

    return parsedDate.toISOString();
  } catch (err) {
    return new Date().toISOString();
  }
}

export function recentOnlyRelativeDate(date?: Date) {
  if (!date) return '';

  const d = new Date().valueOf();

  if (d - date.valueOf() < 86400000) return relativeDate(date);

  return dateToDateString(date);
}

export function recentOnlyRelativeDateTime(date?: Date) {
  if (!date) return '';

  const d = new Date().valueOf();

  if (d - date.valueOf() < 86400000) return relativeDate(date);

  return dateToDateTimeString(date);
}

export function isDiffMoreThan(date1: Date, date2: Date, diff: number) {
  return dayjs(date1).diff(date2, 'millisecond') > diff;
}

export function getLatestDate(dateArr: Dayjs[]) {
  return dayjs.max(dateArr);
}

export function parseTimeZoneLabelToMinutes(label: string) {
  const signIndex = label.indexOf('GMT') + 3;

  const startHoursIndex = signIndex + 1;

  const endHoursIndex = label.indexOf(':');

  const sign = label.slice(signIndex, signIndex + 1);

  const hours = label.slice(startHoursIndex, endHoursIndex);

  const minutes = label.slice(endHoursIndex + 1, endHoursIndex + 3);

  const time = Number(hours) * 60 + Number(minutes);

  const result = sign + time;

  return result;
}
