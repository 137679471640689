import * as React from 'react';
import clsx from 'clsx';

import { Button, ButtonProps } from '../Button';

import classes from './PlainButton.module.scss';

export type PlainButtonProps = Omit<ButtonProps, 'classNames'> & {
  className?: string;
};

// new-ui
export function PlainButton({ className, ...props }: PlainButtonProps) {
  return <Button classNames={{ ...classes, container: clsx(classes.container, className) }} {...props} />;
}
