import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { INITIAL_REQ_SUMMARY_STATE } from './reqSummary.model';
import {
  MaybeReqSummaryTermsData,
  MaybeReqSummaryTradesData,
  ReqSummaryBalance,
  ReqSummaryDashboardData,
  ReqSummaryPageData,
  ReqSummarySummaryRow,
} from 'src/models/reqSummary.model';
import { Maybe, RecordObject } from 'src/models/general.model';
import { Results } from 'src/models/results.model';
import { Permission } from 'src/models/permissions.model';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { Scenario, Sidebar, ManagerReqPageStatuses } from 'src/models';
import { DashboardScenario } from 'src/models/dashboardScenario.model';

export const slice = createSlice({
  name: 'reqSummary',
  initialState: INITIAL_REQ_SUMMARY_STATE,
  reducers: {
    clearReqSummaryState: () => INITIAL_REQ_SUMMARY_STATE,
    setReqSummaryPageData: (state, action: PayloadAction<Maybe<ReqSummaryPageData>>) => {
      state.pageData = omit(['current_period', 'selected_period'], action.payload) as ReqSummaryPageData;
      state.currentPeriod = action.payload?.current_period;
      state.selectedPeriod = action.payload?.selected_period;
    },
    setReqSummarySidebar: (state, action: PayloadAction<Maybe<Sidebar>>) => {
      state.sidebar = action.payload;
    },
    setReqSummaryPageBalance: (state, action: PayloadAction<Maybe<ReqSummaryBalance>>) => {
      state.pageBalance = action.payload;
    },
    setReqSummaryPagePermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.pagePermissions = action.payload;
    },
    setReqSummaryPageTotals: (state, action: PayloadAction<Maybe<Results<RecordObject<number>>>>) => {
      state.pageTotals = action.payload;
    },
    setReqSummarySummaryTable: (state, action: PayloadAction<Maybe<ReqSummarySummaryRow[]>>) => {
      state.summaryData = action.payload;
    },
    setReqSummarySummaryTotals: (state, action: PayloadAction<Maybe<RecordObject<number>>>) => {
      state.summaryTotals = action.payload;
    },
    setReqSummaryScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.summaryScenario = action.payload;
    },
    setReqSummaryTradesConstruction: (state, action: PayloadAction<MaybeReqSummaryTradesData>) => {
      state.tradesConstruction = action.payload;
    },
    setReqSummaryTradesPreConstruction: (state, action: PayloadAction<MaybeReqSummaryTradesData>) => {
      state.tradesPreConstruction = action.payload;
    },
    setReqSummaryGeneralRequirements: (state, action: PayloadAction<MaybeReqSummaryTradesData>) => {
      state.generalRequirements = action.payload;
    },
    setReqSummaryTradesUnawarded: (state, action: PayloadAction<MaybeReqSummaryTradesData>) => {
      state.tradesUnawarded = action.payload;
    },
    setReqSummaryGeneralConditions: (state, action: PayloadAction<MaybeReqSummaryTermsData>) => {
      state.generalConditions = action.payload;
    },
    setReqSummaryOtherTerms: (state, action: PayloadAction<MaybeReqSummaryTermsData>) => {
      state.otherTerms = action.payload;
    },
    setReqSummaryReqPeriods: (state, action: PayloadAction<Maybe<ReqPeriod[]>>) => {
      state.reqPeriods = action.payload;
    },
    setReqSummaryDashboardData: (state, action: PayloadAction<Maybe<ReqSummaryDashboardData>>) => {
      state.dashboardData = action.payload;
    },
    setReqSummaryDashboardScenario: (state, action: PayloadAction<Maybe<DashboardScenario>>) => {
      state.dashboardScenario = action.payload;
    },
    setPageStatusFromManagerReq: (state, action: PayloadAction<Maybe<ManagerReqPageStatuses>>) => {
      state.managerReqPageStatus = action.payload;
    },
  },
});

export const {
  clearReqSummaryState,
  setReqSummaryPageData,
  setReqSummaryPageTotals,
  setReqSummarySummaryTable,
  setReqSummarySummaryTotals,
  setReqSummaryScenario,
  setReqSummaryGeneralRequirements,
  setReqSummaryTradesConstruction,
  setReqSummaryTradesPreConstruction,
  setReqSummaryGeneralConditions,
  setReqSummaryTradesUnawarded,
  setReqSummaryOtherTerms,
  setReqSummaryPagePermissions,
  setReqSummaryReqPeriods,
  setReqSummarySidebar,
  setReqSummaryPageBalance,
  setReqSummaryDashboardData,
  setReqSummaryDashboardScenario,
  setPageStatusFromManagerReq,
} = slice.actions;

export default slice.reducer;
