import { Action, StateStore } from 'src/models';
import { ApiEndpoints } from 'src/models/api.model';
import { MainProject } from 'src/models/main_project.model';
import { ApiService } from 'src/services/api.service';
import {
  addMainProjects,
  setMainProjects,
  setMainProjectsMeta,
  setMainProjectsPermissions,
  setMainProjectsQuery,
} from 'src/store/portfolio/portfolio.reducer';
import { processErrorEvent } from 'src/tools/error.tools';
import { isLoadingOrUpdating } from 'src/tools/process.tools';

export async function processGetPortfolioRequest({ dispatch }: StateStore, action: Action) {
  const isBusy = isLoadingOrUpdating(dispatch);

  if (!action.meta?.isInitial) isBusy({ state: true, meta: action.meta });

  const response = await ApiService.instance().get<MainProject[]>({ endpoint: ApiEndpoints.MAIN_PROJECTS });

  if (response.isOK) {
    const { data, permissions, meta, query } = response.payload;

    dispatch(action.meta?.isUpdate ? addMainProjects(data) : setMainProjects(data));
    dispatch(setMainProjectsPermissions(permissions));

    if (!action.meta?.isInitial) isBusy({ state: false, meta: action.meta });

    dispatch(setMainProjectsMeta(meta));
    dispatch(setMainProjectsQuery(query));
  } else {
    if (!action.meta?.isInitial) isBusy({ state: false, meta: action.meta });

    processErrorEvent({ activityName: 'processGetPortfolioRequest', err: response.payload });
  }
}
