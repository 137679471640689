import * as React from 'react';

import { UseIcon } from 'src/components/UseIcon';

import classes from './InfoButton.module.scss';

interface Props {
  onClick: () => void;
}

export function InfoButton({ onClick }: Props) {
  return <UseIcon className={classes.icon} iconId='info-stroke' wrapElement='button' onClick={onClick} />;
}
