function Icon() {
  return (
    <svg width='18' height='20' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.167 13V11.6667C11.167 10.9594 10.886 10.2811 10.3859 9.78105C9.88585 9.28095 9.20757 9 8.50033 9H3.83366C3.12641 9 2.44814 9.28095 1.94804 9.78105C1.44794 10.2811 1.16699 10.9594 1.16699 11.6667V13'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13.833 4.33337V8.33337' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.833 6.33337H11.833' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.16667 6.33333C7.63943 6.33333 8.83333 5.13943 8.83333 3.66667C8.83333 2.19391 7.63943 1 6.16667 1C4.69391 1 3.5 2.19391 3.5 3.66667C3.5 5.13943 4.69391 6.33333 6.16667 6.33333Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default Icon;
