import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_check-circle'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M22 11.08V12a10 10 0 11-5.93-9.14'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M22 4L12 14.01l-3-3' />
    </svg>
  );
}

export default Icon;
