import { ID } from './general.model';
import { UserPreview } from './user.model';

export enum RoleLevel {
  PROJECT = 'project',
  MAIN_CONTRACT = 'main_contract',
  TRADE_CONTRACT = 'trade_contract',
}

export interface Role {
  id: ID;
  name: string;
  level: RoleLevel;
  permissions: string[];
  team_members: UserPreview[];
  team_count: number;
  editable: boolean;
}

export type Roles = Record<RoleLevel, Role[]>;

export interface RolePermission {
  id: ID;
  label: string;
  requires?: string[];
  required_by?: string[];
  description?: string;
}

export interface PermissionGroup {
  id: ID;
  label: string;
  permissions?: RolePermission[];
  sub_groups?: PermissionGroup[];
}

export interface PermissionTab {
  id: ID;
  label: string;
  groups: PermissionGroup[];
}
