import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Maybe, Picture, ID } from 'src/models';
import { resolveUrl } from 'src/tools/url.tools';
import { addPictureUrl } from 'src/store/attachments/attachments.reducer';
import { getPictureUrls } from 'src/store/attachments/attachments.getters';

export function useResolvePicturesUrl(pictures: Maybe<Picture[]> = [], preview = false) {
  const dispatch = useDispatch();

  const urlsCache = useSelector(getPictureUrls);

  const urls = pictures.map(p => urlsCache[p.id]);

  const isRequestedRef = useRef<Record<ID, boolean>>({});

  const getHandleAddUrl = useCallback(
    (index: number, id: ID) => {
      return function call(url: string) {
        dispatch(addPictureUrl({ id, url }));

        isRequestedRef.current[id] = false;
      };
    },
    [dispatch],
  );

  useEffect(() => {
    pictures?.forEach((picture: Picture, index: number) => {
      if (!urlsCache[picture.id] && !isRequestedRef.current[picture.id]) {
        isRequestedRef.current[picture.id] = true;

        resolveUrl(picture, getHandleAddUrl(index, picture.id), preview);
      }
    });
  }, [getHandleAddUrl, pictures, preview, urlsCache]);

  return urls;
}
