import { useMemo, useState } from 'react';
import { v4 as generateId } from 'uuid';

import { AnyLineItem, LineItemTransferElementData, LineItemTransferSum } from 'src/models/lineItem.model';

export function useLineItemTransfer(item: AnyLineItem) {
  const initialData: LineItemTransferElementData = useMemo(() => ({ key: generateId() }), []);

  const [data, setData] = useState<LineItemTransferElementData[]>([initialData]);

  const isFilledForm = useMemo(
    () => data.every(i => i.id && (i.contract_value || i.prior_periods || i.retainage_prior_periods)),
    [data],
  );

  const dataSum: LineItemTransferSum = useMemo(() => {
    let contract_value = 0;
    let prior_periods = 0;
    let retainage_prior_periods = 0;

    data.forEach(i => {
      if (i.contract_value) contract_value += i.contract_value;

      if (i.prior_periods) prior_periods += i.prior_periods;

      if (i.retainage_prior_periods) retainage_prior_periods += i.retainage_prior_periods;
    });

    return { contract_value, prior_periods, retainage_prior_periods };
  }, [data]);

  const dataTotal: LineItemTransferSum = useMemo(() => {
    return {
      contract_value: item.contract_value,
      prior_periods: item.prior_periods,
      retainage_prior_periods: item.retainage_prior_periods,
    };
  }, [item.contract_value, item.prior_periods, item.retainage_prior_periods]);

  const dataLeft: LineItemTransferSum = useMemo(() => {
    return {
      contract_value: dataTotal.contract_value - dataSum.contract_value,
      prior_periods: dataTotal.prior_periods - dataSum.prior_periods,
      retainage_prior_periods: dataTotal.retainage_prior_periods - dataSum.retainage_prior_periods,
    };
  }, [
    dataSum.contract_value,
    dataSum.prior_periods,
    dataSum.retainage_prior_periods,
    dataTotal.contract_value,
    dataTotal.prior_periods,
    dataTotal.retainage_prior_periods,
  ]);

  const isValid = useMemo(() => Object.values(dataLeft).every(v => v >= 0), [dataLeft]);

  return {
    isFilledForm,
    isValid,
    data,
    setData,
    dataSum,
    dataTotal,
    dataLeft,
  };
}
