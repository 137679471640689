/* eslint-disable max-lines */
// TODO: split this process into 2
import { clearToken, setToken } from 'src/services/storage.service';

import { processError } from 'src/tools/events.tools';
import { validateLoginObject } from 'src/tools/validate.tools';

import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  ResponsePayload,
  Invitation,
  Action,
  UserProfile,
  ShowScenario,
} from 'src/models';
import { AuthObject, TwoFactorAuthObject } from 'src/models/authentication.model';
import { sendInfo } from 'src/store/app/app.actions';
import { setIsAuthed } from 'src/store/app/app.reducer';
import { setCustomIsLoading, setIsLoading } from 'src/store/loading/loading.reducer';
import {
  clearLastTimeCodeSent,
  setInvitation,
  setLastTimeCodeSent,
  setUser,
  setUserProfile,
  setUserProfileScenario,
  setLoginData,
  updateUserToken,
} from 'src/store/user/user.reducer';
import { wipeout } from 'src/store/attachments/attachments.action';
import { deserialize, makeWelcomeMessage } from 'src/tools/string.tools';
import { post, patch } from '../services/api_old.service';
import { ServiceRequests } from '../data';
import { toType } from 'src/tools/type.tools';
import { convertToForm } from 'src/tools/form.tools';

function processUser({ dispatch, getService }: ProcessProps) {
  const processUserLoginRequest = (action: Action) => () => {
    dispatch(setIsLoading(true));

    getService().request(
      { [QueryDomain.USER]: QueryAction.LOGIN, payload: action.payload },
      (response: Response<unknown>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<AuthObject | TwoFactorAuthObject>>(response.payload as string);

          dispatch(setIsLoading(false));

          if (result && (result?.data as TwoFactorAuthObject).otp) {
            dispatch(setLoginData(action.payload));
            dispatch(setLastTimeCodeSent());

            action.meta?.callback?.({ isOK: true });
          } else if (result && validateLoginObject(result?.data)) {
            const data = result.data as AuthObject;

            dispatch(clearLastTimeCodeSent());
            dispatch(setUser(data.user));
            setToken(data.token!);
            dispatch(updateUserToken(data.token));
            dispatch(setIsAuthed(true));
            dispatch(sendInfo(makeWelcomeMessage(data.user.first_name)));
          }
        } else {
          processError({
            activityName: 'Request to login',
            isLoadingFn: setIsLoading,
            callbackFn: action.meta?.callback,
            isSkipError: !!action.payload.otp,
            response,
          });
        }
      },
    );
  };

  const processUserLogoutRequest = () => () => {
    dispatch(setIsLoading(true));
    getService().request({ [QueryDomain.USER]: QueryAction.LOGOUT }, (response: Response<unknown>) => {
      clearToken();
      dispatch(updateUserToken());
      dispatch(wipeout());
      dispatch(setIsAuthed(false));

      if (response.isOK) {
        dispatch(setIsLoading(false));
        dispatch(sendInfo('Have a nice day!'));
      } else {
        processError({
          activityName: 'Request to logout',
          isLoadingFn: setIsLoading,
          response,
        });
      }
    });
  };

  const processGetInviteRequest = (action: Action) => () => {
    dispatch(setIsLoading(true));

    getService().request(
      {
        [QueryDomain.USER]: QueryAction.GET_TEAM_INVITE,
        payload: {
          index: [action.payload.team_invite_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Invitation>>(response.payload);

          dispatch(setInvitation(result?.data));
        } else {
          processError({
            activityName: 'Request invitation',
            isLoadingFn: setIsLoading,
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setIsLoading(false));
      },
    );
  };

  const processGetUser = (action: Action) => () => {
    dispatch(setIsLoading(true));

    getService().request({ [QueryDomain.USER]: QueryAction.GET }, (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<UserProfile>>(response.payload);

        dispatch(setUserProfile(result?.data));
        dispatch(setUserProfileScenario(toType<ShowScenario>(result?.scenario)));
      } else {
        processError({
          activityName: 'Request user profile',
          isLoadingFn: setIsLoading,
          response,
        });
      }

      if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

      dispatch(setIsLoading(false));
    });
  };

  const processUserSignUpRequest = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'signUp', state: true }));

    try {
      const response = await post({
        service: ServiceRequests.USER_SIGN_UP,
        data: action.payload,
        headers: { 'Content-Type': 'multipart/form-data;' },
      });

      const data = response && {
        user: response.data.data,
        permissions: response.data.permissions,
        token: response.headers.authorization,
      };

      if (data && validateLoginObject(data)) {
        dispatch(setUser(data.user));
        setToken(data.token);
        dispatch(updateUserToken(data.token));
        dispatch(setIsAuthed(true));
        dispatch(sendInfo(makeWelcomeMessage(data.user.first_name)));
      }

      if (action.meta?.callback) action.meta.callback({ isOK: true, payload: data });
    } catch (err) {
      processError({
        activityName: 'Request to sign up',
        response: {
          isOK: false,
          status: err.response.status,
          payload: err.response?.data,
        },
      });

      if (action.meta?.callback) action.meta.callback({ isOK: false, message: err.message });
    }

    dispatch(setCustomIsLoading({ id: 'signUp', state: false }));
  };

  const processUpdateUserRequest = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'user', state: true }));

    try {
      const response = await patch({
        service: ServiceRequests.UPDATE_USER,
        data: convertToForm(action.payload),
        headers: { 'Content-Type': 'multipart/form-data;' },
      });

      const data = response.data;

      if (data) {
        dispatch(setUserProfile(data?.data));

        dispatch(sendInfo('Your profile was updated.'));
      }

      if (action.meta?.callback) action.meta.callback({ isOK: true, payload: data });
    } catch (err) {
      processError({
        activityName: 'Request to update user',
        response: {
          isOK: false,
          status: err.response.status,
          payload: err.response?.data,
        },
      });

      if (action.meta?.callback) action.meta.callback({ isOK: false, message: err.message });
    }

    dispatch(setCustomIsLoading({ id: 'user', state: false }));
  };

  const processSendUpdatePasswordToken = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'resetPassword', state: true }));

    getService().request(
      { [QueryDomain.USER]: QueryAction.SEND_UPDATE_PASSWORD_TOKEN },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(setLastTimeCodeSent());
          dispatch(sendInfo('Email has been sent!'));
        } else {
          processError({
            activityName: 'Request send password code',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'resetPassword', state: false }));
      },
    );
  };

  const processSendResetPasswordToken = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'changePassword', state: true }));

    getService().request(
      { [QueryDomain.USER]: QueryAction.SEND_RESET_PASSWORD_TOKEN, payload: { data: action.payload } },
      (response: Response<string>) => {
        if (!response.isOK) {
          processError({
            activityName: 'Request send password code',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK, message: response.message });

        dispatch(setCustomIsLoading({ id: 'changePassword', state: false }));
      },
    );
  };

  const processCheckUpdatePasswordToken = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'changePassword', state: true }));

    getService().request(
      { [QueryDomain.USER]: QueryAction.CHECK_UPDATE_PASSWORD_TOKEN, payload: { params: action.payload } },
      (response: Response<string>) => {
        if (!response.isOK) {
          processError({
            activityName: 'Request check password code',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'changePassword', state: false }));
      },
    );
  };

  const processUpdatePassword = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'changePassword', state: true }));

    getService().request(
      { [QueryDomain.USER]: QueryAction.UPDATE_PASSWORD, payload: { data: action.payload } },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(clearLastTimeCodeSent());
          dispatch(sendInfo('Password has been updated.'));
        } else {
          processError({
            activityName: 'Request update password',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'changePassword', state: false }));
      },
    );
  };

  const processResetPassword = (action: Action) => async () => {
    dispatch(setCustomIsLoading({ id: 'resetPassword', state: true }));

    getService().request(
      { [QueryDomain.USER]: QueryAction.RESET_PASSWORD, payload: { data: action.payload } },
      (response: Response<string>) => {
        if (response.isOK) dispatch(sendInfo('Password has been reset.'));
        else {
          processError({
            activityName: 'Request reset password',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'resetPassword', state: false }));
      },
    );
  };

  return {
    processUserLogoutRequest,
    processUserLoginRequest,
    processGetInviteRequest,
    processUserSignUpRequest,
    processGetUser,
    processUpdateUserRequest,
    processSendUpdatePasswordToken,
    processSendResetPasswordToken,
    processCheckUpdatePasswordToken,
    processUpdatePassword,
    processResetPassword,
  };
}

export { processUser };
