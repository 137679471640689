import { PageQuery, RequestMeta } from './results.model';
import { ID } from './general.model';
import { VersionedPeriod } from '.';

export interface ChangeOrderParams {
  query?: Partial<PageQuery>;
  meta?: Partial<RequestMeta>;
}

export interface ChangeOrder extends Record<string, unknown> {
  id: ID;
  age: string;
  categories: string[];
  change_author: string;
  co_number: string;
  csi_code: string;
  csi_description: string;
  date_approved: Date;
  date_submitted: Date;
  description: string;
  division_code: number;
  reason: string;
  status: string;
  trade_contract_id: ID;
  created_at: Date;
  updated_at: Date;
  project_co_number: string;
  company: string;
  trade: string;
  schedule_impact: boolean;
  schedule_impact_days: number;
  impact_cost: number;
  cost: number;
  insurance_cost: number;
  bond: number;
  tax_cost: number;
  fee_cost: number;
  gc_amount: number;
  total_cost: number;
  gc_rate: number;
  _attachments?: {
    is_new: boolean;
  };
  autobilling?: {
    this_period: boolean;
    stored_material: boolean;
  };
  current_period: VersionedPeriod;
}

export interface ChangeOrderHistory extends Record<string, unknown> {
  id: ID;
  status: string;
  created_at: Date;
  updated_at: Date;
  by: string;
  schedule_impact: boolean;
  schedule_impact_days: number;
  impact_cost: number;
  insurance_cost: number;
  bond: number;
  tax_cost: number;
  fee_cost: number;
  gc_amount: number;
  total_cost: number;
  gc_rate: number;
}

export enum COFinalStateStatuses {
  APPROVED = 'approved',
  VOID = 'void',
  SUPERSEDED = 'superseded',
  REJECTED = 'rejected',
}

export interface ContingencyLineItem {
  id: ID;
  resource_id: number;
  resource_type: string;
  section: string;
  group: string;
  category: string;
  description: string;
  csi_code: string;
  retainage_applied: boolean;
  amount_type: string;
  amount: number;
  contract_value: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_percentage: number;
  due_this_period: number;
  retainage_lock: boolean;
  group_id: string;
  _attachments: {
    is_new: boolean;
    num_documents: number;
    num_pictures: number;
    num_comments: number;
  };
  transfers: number;
  req_index: number;
  level_id: number;
  level_name: string;
}
