import { createSelector } from '@reduxjs/toolkit';

import { ReqSummaryTermsType, ReqSummaryTradeType } from 'src/models/reqSummary.model';
import { RootState } from '..';
import { makeAbilities } from 'src/tools/abilities.tools';
import { REQ_SUMMARY_TABS } from 'src/data';

export const getReqSummaryPagePermissions = (state: RootState) => state.reqSummary.pagePermissions;

export const getReqSummaryPageData = (state: RootState) => state.reqSummary.pageData;

export const getReqSummaryPageTotals = (state: RootState) => state.reqSummary.pageTotals;

export const getReqSummarySelectedPeriod = (state: RootState) => state.reqSummary.selectedPeriod;

export const getReqSummaryCurrentPeriod = (state: RootState) => state.reqSummary.currentPeriod;

export const getReqSummarySummaryData = (state: RootState) => state.reqSummary.summaryData;

export const getReqSummarySummaryScenario = (state: RootState) => state.reqSummary.summaryScenario;

export const getReqSummarySummaryTotals = (state: RootState) => state.reqSummary.summaryTotals;

export const getPageStatusFromManagerReq = (state: RootState) => state.reqSummary.managerReqPageStatus;

export const getReqSummaryTermsByType = (state: RootState) => (type: ReqSummaryTermsType) => {
  switch (type) {
    case ReqSummaryTermsType.GC: {
      return state.reqSummary.generalConditions;
    }
    case ReqSummaryTermsType.OTHER: {
      return state.reqSummary.otherTerms;
    }
    default:
      return;
  }
};

export const getReqSummaryTradesByType = (state: RootState) => (type: ReqSummaryTradeType) => {
  switch (type) {
    case ReqSummaryTradeType.CONSTRUCTION: {
      return state.reqSummary.tradesConstruction;
    }
    case ReqSummaryTradeType.PRE_CONSTRUCTION: {
      return state.reqSummary.tradesPreConstruction;
    }
    case ReqSummaryTradeType.GR: {
      return state.reqSummary.generalRequirements;
    }
    case ReqSummaryTradeType.UNAWARDED: {
      return state.reqSummary.tradesUnawarded;
    }
    default:
      return;
  }
};

export const getReqSummaryTradesMetaByType = (state: RootState) => (type: ReqSummaryTradeType) =>
  getReqSummaryTradesByType(state)(type)?.meta;

export const getReqSummaryTradesQueryByType = (state: RootState) => (type: ReqSummaryTradeType) =>
  getReqSummaryTradesByType(state)(type)?.query;

export const getReqSummaryTermsQueryByType = (state: RootState) => (type: ReqSummaryTermsType) =>
  getReqSummaryTermsByType(state)(type)?.query;

export const getReqSummaryTradesAllowedToUpdate = (state: RootState) => (type: ReqSummaryTradeType) => {
  const meta = getReqSummaryTradesMetaByType(state)(type);

  return !state.loading.isLoading && !state.loading.isUpdating && meta && meta?.page && meta.page !== meta.last;
};

export const getReqSummaryGeneralRequirements = (state: RootState) => state.reqSummary.generalRequirements;

export const getReqSummaryReqPeriods = (state: RootState) => state.reqSummary.reqPeriods;

export const getReqSummarySidebar = (state: RootState) => state.reqSummary.sidebar;

export const getReqSummaryPageBalance = (state: RootState) => state.reqSummary.pageBalance;

export const getReqSummaryDashboardData = (state: RootState) => state.reqSummary.dashboardData;

export const getReqSummaryDashboardScenario = (state: RootState) => state.reqSummary.dashboardScenario;

export const getReqSummaryCurrentReqPeriod = (state: RootState) =>
  state.reqSummary.reqPeriods?.find(p => p.is_current_period);

export const getReqSummarySelectedPeriodItem = createSelector(
  [getReqSummaryReqPeriods, getReqSummarySelectedPeriod],
  (periods, selectedPeriod) => selectedPeriod && periods?.find(p => p.id === selectedPeriod.id),
);

export const getIsReqSummaryCurrentPeriod = createSelector(
  [getReqSummarySelectedPeriodItem],
  period => !!period?.is_current_period,
);

export const getIsReqSummaryWorkingPeriod = createSelector(
  [getReqSummarySelectedPeriodItem],
  (period): boolean => !!period?.is_working_period,
);

export const getIsReqSummaryLockedPeriod = createSelector(
  [getReqSummarySelectedPeriodItem],
  (period): boolean => !!period?.is_locked,
);

export const getReqSummaryTabs = createSelector(
  [getReqSummaryPageData, getReqSummaryPagePermissions],
  (pageData, permissions) => {
    const abilities = makeAbilities(permissions);

    return REQ_SUMMARY_TABS.filter(t => pageData?.tabs.includes(t.id) && abilities.can('read', `${t.id}_tab`));
  },
);

export const getReqSummaryTabsIds = createSelector([getReqSummaryTabs], tabs => tabs.map(t => t.id));
