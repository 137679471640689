import { NotificationMessageType } from 'src/models/notification.model';
import { processTextIfNeeded } from 'src/tools/string.tools';
import { CONFIG } from 'src/config';

import classes from './Pill.module.scss';

interface Props {
  type: NotificationMessageType;
}

export function Pill({ type }: Props) {
  if (!type.title) return <></>;

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: type.background_color ?? CONFIG.defaultPillBackgroundColor }}
    >
      {processTextIfNeeded(type.title)}
    </div>
  );
}
