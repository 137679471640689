import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { renderWithIndex } from 'src/tools/render.tools';
import { ButtonBaseType, ID } from 'src/models';

import { useKeyPress } from 'src/hooks';

import { Item } from './Item';

import classes from './Menu.module.scss';

export interface Props<T> {
  className?: string;
  currentItemId?: ID;
  items?: T[];
  onItemClick: (id: ID, report?: string, context?: string) => void;
  onEnterPressed?: (e: KeyboardEvent) => void;
  renderItemLabel?: (item: T, isSelected: boolean, isActive: boolean) => string | JSX.Element;
  isSmall?: boolean;
  isDisableNavigation?: boolean;
  isHideSplits?: boolean;
}

export function Menu<T extends ButtonBaseType = ButtonBaseType>({
  className,
  items = [],
  onItemClick,
  isSmall,
  currentItemId,
  renderItemLabel,
  isDisableNavigation,
  onEnterPressed,
  isHideSplits,
}: Props<T>) {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const selectedItem = items[selectedIndex];

  function handleOnKeyDown(e: KeyboardEvent) {
    if (['ArrowUp', 'ArrowDown'].indexOf(e.code) > -1) e.preventDefault();
  }

  // prevent window scrolling when navigation is enabled
  useEffect(() => {
    if (!!items.length && !isDisableNavigation) {
      window.addEventListener('keydown', handleOnKeyDown, false);

      return () => window.removeEventListener('keydown', handleOnKeyDown);
    }
  }, [isDisableNavigation, items.length]);

  useKeyPress(
    {
      onEnter: handleClickSelected,
      onArrowUp: handleArrowUp,
      onArrowDown: handleArrowDown,
    },
    !!items.length && !isDisableNavigation,
  );

  function handleArrowDown(e: KeyboardEvent) {
    e.preventDefault();

    let nextIndex = selectedIndex;

    do {
      nextIndex += 1;

      if (nextIndex === items.length) {
        nextIndex = 0;
      }
    } while (nextIndex !== selectedIndex && items[nextIndex]?.isDisabled);

    if (items[nextIndex]) {
      setSelectedIndex(nextIndex);
    }
  }

  function handleArrowUp(e: KeyboardEvent) {
    e.preventDefault();

    let prevIndex = selectedIndex;

    do {
      prevIndex -= 1;

      if (prevIndex < 0) {
        prevIndex = items.length - 1;
      }
    } while (prevIndex !== selectedIndex && items[prevIndex]?.isDisabled);

    if (items[prevIndex]) {
      setSelectedIndex(prevIndex);
    }
  }

  function getItemHandleClick(id: ID, report?: string, context?: string) {
    return function call() {
      onItemClick(id, report, context);
    };
  }

  function getItemHandleMouseEnter(index: number, isDisabled?: boolean) {
    if (!isDisabled) {
      return function call() {
        setSelectedIndex(index);
      };
    }
  }

  function handleOnMouseLeave() {
    if (isDisableNavigation) setSelectedIndex(-1);
  }

  function handleClickSelected(e: KeyboardEvent) {
    e.preventDefault();

    if (selectedItem) {
      onItemClick(selectedItem.id, selectedItem.report, selectedItem.context);
    } else if (onEnterPressed) onEnterPressed(e);
  }

  function renderItem(item: T, index: number) {
    const { id, label, isDisabled, isSplitAfter, ...rest } = item;

    const isSelected = index === selectedIndex;

    const isActive = id === currentItemId;

    return (
      <Item
        {...rest}
        className={item.className}
        key={id}
        id={id}
        label={renderItemLabel ? renderItemLabel(item, isSelected, isActive) : label}
        onClick={getItemHandleClick(id, item.report || '', item.context || '')}
        onMouseEnter={getItemHandleMouseEnter(index, isDisabled)}
        isSelected={isSelected}
        isActive={isActive}
        isDisabled={isDisabled}
        isSmall={isSmall}
        isSplitAfter={!isHideSplits && isSplitAfter}
      />
    );
  }

  if (!items.length) {
    return null;
  }

  return (
    <div data-testid='menu' className={clsx(classes.container, 'column', className)} onMouseLeave={handleOnMouseLeave}>
      {renderWithIndex(items, renderItem)}
    </div>
  );
}
