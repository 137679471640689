import clsx from 'clsx';
import * as React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import classes from './ProgressCircular.module.scss';

interface Props {
  value: number;
  className?: string;
}

export function ProgressCircular({ value, className }: Props) {
  return <CircularProgressbar value={value ?? 0} strokeWidth={8} className={clsx(classes.container, className)} />;
}
