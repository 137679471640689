import { createSelector } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { ID, ManagerReqLine, ManagerReqTabs, Maybe, PageQuery, RecordObject, VersionedPeriod } from 'src/models';
import { RootState } from 'src/store';
import { DEFAULT_QUERY, MANAGER_REQ_TABS } from 'src/data';
import { makeIsAllowedToUpdate } from '../../tools/selectors.tools';
import { getUrlQuery } from 'src/store/app/app.getters';
import { makeAbilities } from 'src/tools/abilities.tools';
import { CONFIG } from 'src/config';

export const getManagerReqUploadInProgress = (state: RootState) => !!state.managerReq.uploadInProgress;

export const getManagerReqPageData = (state: RootState) => state.managerReq.page;

export const getManagerReqSidebar = (state: RootState) => state.managerReq.sidebar;

export const getManagerReqCurrentPeriod = (state: RootState) => state.managerReq.currentPeriod;

export const getManagerReqPageSelectedPeriodVersion = (state: RootState) => state.managerReq.selectedPeriod?.version;

export const getManagerReqPageCurrentPeriodVersion = (state: RootState) => state.managerReq.currentPeriod?.version;

export const getManagerReqSelectedPeriod = (state: RootState) => state.managerReq.selectedPeriod;

export const getManagerReqBaseContract = (state: RootState) => state.managerReq.baseContractData;

export const getManagerReqBaseContractItem = (state: RootState) => (id: ID) =>
  state.managerReq.baseContractData?.find(item => item.id === id);

export const getManagerReqBaseContractMeta = (state: RootState) => state.managerReq.baseContractMeta;

export const getManagerReqBaseContractQuery = (state: RootState) => state.managerReq.baseContractQuery;

export const getManagerReqBaseContractErrorsCount = (state: RootState) => state.managerReq.baseContractInfo?.errors;

export const getManagerReqBaseContractScenario = (state: RootState) => state.managerReq.baseContractScenario;

export const getManagerReqBaseContractTotals = (state: RootState) => state.managerReq.baseContractTotals;

export const getManagerReqChangeOrders = (state: RootState) => state.managerReq.changeOrdersData;

export const getManagerReqChangeOrdersMeta = (state: RootState) => state.managerReq.changeOrdersMeta;

export const getManagerReqChangeOrdersQuery = (state: RootState) => state.managerReq.changeOrdersQuery;

export const getManagerReqChangeOrdersScenario = (state: RootState) => state.managerReq.changeOrdersScenario;

export const getManagerReqChangeOrdersTotals = (state: RootState) => state.managerReq.changeOrdersTotals;

export const getManagerReqChangeOrdersAllowedToUpdate = makeIsAllowedToUpdate(getManagerReqChangeOrdersMeta);

export const getManagerReqBaseContractAllowedToUpdate = makeIsAllowedToUpdate(getManagerReqBaseContractMeta);

export const getManagerReqTabTotals = (state: RootState) => state.managerReq.tabTotals;

export const getManagerReqTabTotalsScenario = (state: RootState) => state.managerReq.tabTotalsScenario;

export const getManagerReqTabMeta = (state: RootState) => state.managerReq.meta;

export const getManagerReqHistory = (state: RootState) => state.managerReq.history;

export const getManagerReqReqPeriods = (state: RootState) => state.managerReq.reqPeriods;

export const getManagerReqPageStatus = (state: RootState) => state.managerReq.page?.status;

export const makeBaseContractQuery =
  (state: RootState) =>
  (query: Maybe<Partial<PageQuery>> = {}): PageQuery => ({
    ...DEFAULT_QUERY,
    ...getManagerReqSelectedPeriod(state),
    ...query,
  });

export const getIsManagerReqCurrentPeriod = createSelector(
  [getManagerReqCurrentPeriod, getManagerReqSelectedPeriod],
  (currentPeriod, selectedPeriod): boolean => !!currentPeriod && currentPeriod?.id === selectedPeriod?.id,
);

export const getIsManagerReqCurrentVersion = createSelector(
  [getManagerReqHistory, getManagerReqSelectedPeriod],
  (history, selectedPeriod): boolean =>
    !!selectedPeriod && history?.[history.length - 1]?.version === selectedPeriod?.version,
);

export const getManagerReqSelectedPeriodItem = createSelector(
  [getManagerReqReqPeriods, getManagerReqSelectedPeriod],
  (periods, selectedPeriod) => selectedPeriod && periods?.find(p => p.id === selectedPeriod.id),
);

export const getIsManagerReqWorkingPeriod = createSelector(
  [getManagerReqSelectedPeriodItem],
  (period): boolean => !!period?.is_working_period,
);

export const getIsManagerReqLockedPeriod = createSelector(
  [getManagerReqSelectedPeriodItem],
  (period): boolean => !!period?.is_locked,
);

export const makeVersionedQuery =
  (state: RootState) =>
  (period: Maybe<VersionedPeriod> | RecordObject = {}) => ({
    ...state.managerReq.currentPeriod,
    ...state.managerReq.selectedPeriod,
    ...period,
  });

export const makeManagerReqPeriodQuery = (state: RootState) => (query: Maybe<VersionedPeriod>) => ({
  ...omit(['version'], state.managerReq.currentPeriod),
  ...omit(['version'], state.managerReq.selectedPeriod),
  ...omit(['version'], query),
});

export const getManagerReqPagePermissions = (state: RootState) => state.managerReq.pagePermissions;

export const getManagerReqTabs = createSelector(
  [getManagerReqPageData, getManagerReqPagePermissions],
  (pageData, permissions) => {
    const abilities = makeAbilities(permissions);

    return MANAGER_REQ_TABS.filter(t => pageData?.tabs.includes(t.id) && abilities.can('read', `${t.id}_tab`));
  },
);

export const getManagerReqSelectedTab = createSelector([getUrlQuery], (urlQuery): ManagerReqTabs => {
  const tab = urlQuery.get('tab');

  if (tab && Object.values(ManagerReqTabs).includes(tab as ManagerReqTabs)) return tab as ManagerReqTabs;

  return CONFIG.managerReqDefaultTab;
});

export const getManagerReqBaseContractLevels = createSelector(getManagerReqBaseContract, data => [
  ...new Set(data?.map(row => row.level_name)),
]);

export const getManagerReqBaseContractSortedGroup = createSelector(
  [getManagerReqBaseContract, getManagerReqBaseContractLevels],
  (data, levels) => {
    const groups: RecordObject<RecordObject<Maybe<ManagerReqLine[]>>> = {};

    const groupKeys = [...new Set(data?.map(row => row.group))];

    for (const levelKey of levels) {
      groups[levelKey as string] = {};

      for (const key of groupKeys) {
        const groupKeyData = data?.filter(item => {
          if (item.level_name === levelKey) return item.group === key;
          else return;
        });

        const undefinedKeyData = data?.filter(item => {
          if (!item.level_name) return item.group === key;
          else return;
        });

        if (levelKey === 'undefined' && undefinedKeyData?.length !== 0) groups[levelKey][key] = undefinedKeyData;

        if (groupKeyData?.length !== 0) groups[levelKey as string][key] = groupKeyData;
      }
    }

    return groups;
  },
);
