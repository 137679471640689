import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      stroke='none'
      viewBox='0 0 16 16'
      data-testid='icon_sort-amount-down'
    >
      <path
        stroke='none'
        d='M4.193 9.527l-.193.2v-5.06a.667.667 0 00-1.333 0v5.06l-.194-.2a.67.67 0 10-.946.946l1.333 1.334c.063.06.138.108.22.14a.628.628 0 00.507 0 .668.668 0 00.22-.14l1.333-1.334a.669.669 0 10-.947-.946zm3.14-4.194H14A.667.667 0 0014 4H7.333a.667.667 0 000 1.333zm6.667 2H7.333a.667.667 0 100 1.334H14a.667.667 0 000-1.334zm0 3.334H7.333a.667.667 0 100 1.333H14a.667.667 0 100-1.333z'
      />
    </svg>
  );
}

export default Icon;
