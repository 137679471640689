import { useRef, useEffect } from 'react';

// TODO: add documentation - why it has been created, what does it do, how & etc.
export function useIsValidRef() {
  const isValidRef = useRef<boolean>(false);

  useEffect(() => {
    isValidRef.current = true;

    return () => {
      isValidRef.current = false;
    };
  }, []);

  return isValidRef;
}
