import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  AnyValue,
  Action,
  ResponsePayload,
  UserRole,
  TeamMembers,
  Member,
  InvitedMember,
  ResourceType,
} from 'src/models';
import { sendInfo } from 'src/store/app/app.actions';

import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import { deserialize } from 'src/tools/string.tools';
import {
  setRoles,
  updateMember,
  updateInvitedMember,
  deleteInvitedMember,
  setMembers,
  setInvitedMembers,
} from 'src/store/team/team.reducer';
import {
  deleteSubProjectTeamInvite,
  updateSubProjectTeamInvite,
  updateSubProjectTeamMember,
} from 'src/store/subProject/subProject.reducer';
import { requestSingleMainProject } from '../store/portfolio/portfolio.actions';

function processTeam({ dispatch, getService }: ProcessProps) {
  function processTeamRequest(action: Action) {
    return function call() {
      if (!action.meta?.isUpdate) dispatch(setCustomIsLoading({ id: 'team', state: true }));

      getService().request(
        { [QueryDomain.TEAM]: QueryAction.GET, payload: action.payload },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<TeamMembers>>(response?.payload);

            if (action.meta?.callback) {
              action.meta?.callback({ isOK: true, payload: result?.data.team_members });
            } else {
              dispatch(setMembers(result?.data.team_members));
              dispatch(setInvitedMembers(result?.data.invites));
            }
          } else {
            processError({
              activityName: 'Request to get team',
              response,
            });
          }

          if (!action.meta?.isUpdate) dispatch(setCustomIsLoading({ id: 'team', state: false }));
        },
      );
    };
  }

  const processCreateTeamRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'team', state: true }));

    getService().request(
      { [QueryDomain.TEAM]: QueryAction.POST, payload: action.payload },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<TeamMembers>>(response?.payload);

          dispatch(setMembers(result?.data.team_members));
          dispatch(setInvitedMembers(result?.data.invites));

          if (action.payload.resource_type === ResourceType.MAIN_PROJECT)
            dispatch(requestSingleMainProject(action.payload.resource_id));
        } else {
          processError({
            activityName: 'Request to create a team',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'team', state: false }));

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
      },
    );
  };

  const processRolesRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'roles', state: true }));

    getService().request(
      { [QueryDomain.TEAM]: QueryAction.ROLES, payload: action.payload },
      (response: Response<string>) => {
        if (response.isOK && response.payload) {
          const result = deserialize<ResponsePayload<UserRole[]>>(response.payload);

          if (result?.data) dispatch(setRoles({ ...action.payload, roles: result.data }));
        } else {
          processError({
            activityName: 'Request user roles',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'roles', state: false }));
      },
    );
  };

  const processUpdateMemberRequest = (payload: AnyValue) => () => {
    dispatch(setCustomIsUpdating({ id: 'team', state: true }));

    getService().request({ [QueryDomain.TEAM]: QueryAction.UPDATE, payload }, (response: Response<string>) => {
      if (response.isOK && response.payload) {
        const result = deserialize<ResponsePayload<Member>>(response.payload);

        if (result?.data) {
          dispatch(updateMember(result.data));
          dispatch(updateSubProjectTeamMember(result.data));
          dispatch(sendInfo('Team Member updated.'));
        }
      } else {
        processError({
          activityName: 'Request update member',
          response,
        });
      }

      dispatch(setCustomIsUpdating({ id: 'team', state: false }));
    });
  };

  const processUpdateInviteRequest = (payload: AnyValue) => () => {
    dispatch(setCustomIsUpdating({ id: 'team', state: true }));

    getService().request({ [QueryDomain.TEAM]: QueryAction.UPDATE_INVITE, payload }, (response: Response<string>) => {
      if (response.isOK && response.payload) {
        const result = deserialize<ResponsePayload<InvitedMember>>(response.payload);

        if (result?.data) {
          dispatch(updateInvitedMember(result.data));
          dispatch(updateSubProjectTeamInvite(result.data));
          dispatch(sendInfo('Team Invite updated.'));
        }
      } else {
        processError({
          activityName: 'Request update invite',
          response,
        });
      }

      dispatch(setCustomIsUpdating({ id: 'team', state: false }));
    });
  };

  const processCancelInviteRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'team', state: true }));

    getService().request(
      { [QueryDomain.TEAM]: QueryAction.DELETE, payload: action.payload },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(deleteInvitedMember(action.payload.team_invite_id));
          dispatch(deleteSubProjectTeamInvite(action.payload.team_invite_id));
          dispatch(sendInfo('Invitation canceled.'));
        } else {
          processError({
            activityName: 'Request cancel invited member',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsUpdating({ id: 'team', state: false }));
      },
    );
  };

  const processResendInviteRequest = (payload: AnyValue) => () => {
    dispatch(setCustomIsUpdating({ id: 'team', state: true }));

    getService().request({ [QueryDomain.TEAM]: QueryAction.RESEND, payload }, (response: Response<string>) => {
      if (response.isOK && response.payload) {
        dispatch(sendInfo('Invitation resent.'));
      } else {
        processError({
          activityName: 'Request cancel invitation for invited member',
          response,
        });
      }

      dispatch(setCustomIsUpdating({ id: 'team', state: false }));
    });
  };

  return {
    processTeamRequest,
    processCreateTeamRequest,
    processRolesRequest,
    processUpdateMemberRequest,
    processCancelInviteRequest,
    processResendInviteRequest,
    processUpdateInviteRequest,
  };
}

export { processTeam };
