import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_info-stroke'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 16v-4' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 8h.01' />
    </svg>
  );
}

export default Icon;
