import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { Invitation, LoginData, Maybe, ShowScenario, Scenario, User, UserProfile, UserAdmin } from 'src/models';
import { INITIAL_USER_STATE } from 'src/store/user/user.model';

export const slice = createSlice({
  name: 'user',
  initialState: INITIAL_USER_STATE,
  reducers: {
    clearState: () => INITIAL_USER_STATE,
    setUser: (state, action: PayloadAction<Maybe<User>>) => {
      state.current = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<Maybe<UserProfile>>) => {
      state.profile = action.payload;

      if (state.current) state.current = action.payload as User;
    },
    setUserProfileScenario: (state, action: PayloadAction<Maybe<ShowScenario>>) => {
      state.profileScenario = action.payload;
    },
    setUserAdmin: (state, action: PayloadAction<Maybe<UserAdmin[]>>) => {
      state.userAdmin = action.payload;
    },
    setUserAdminScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.userAdminScenario = action.payload;
    },
    updateUserAdminInfo: (state, action: PayloadAction<UserAdmin>) => {
      state.userAdmin = state.userAdmin?.map(i => (i.id === action.payload?.id ? action.payload : i));
    },
    clearUserProfile: state => {
      state.profile = INITIAL_USER_STATE.profile;
      state.profileScenario = INITIAL_USER_STATE.profileScenario;
    },
    setInvitation: (state, action: PayloadAction<Maybe<Invitation>>) => {
      state.invitation = action.payload;
    },
    setLastTimeCodeSent: state => {
      state.lastTimeCodeSent = new Date();
    },
    clearLastTimeCodeSent: state => {
      state.lastTimeCodeSent = undefined;
    },
    setLoginData: (state, action: PayloadAction<Maybe<LoginData>>) => {
      state.loginData = action.payload;
    },
    updateUserToken: (state, action: PayloadAction<Maybe<string>>) => {
      if (!action.payload) omit(['token'], state);
      else state.token = action.payload;
    },
  },
});

export const {
  updateUserToken,
  clearState,
  setUser,
  setInvitation,
  setUserProfile,
  setUserProfileScenario,
  clearUserProfile,
  setLastTimeCodeSent,
  clearLastTimeCodeSent,
  setLoginData,
  setUserAdmin,
  setUserAdminScenario,
  updateUserAdminInfo,
} = slice.actions;

export default slice.reducer;
