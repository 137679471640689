import { useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'src/store/user/user.actions';
import { DropdownMenu } from 'src/components/Dropdown/DropdownMenu';
import { USER_NAV_DROPDOWN_ITEMS } from 'src/data/navbar.data';
import { Avatar } from 'src/components/Avatar';
import { getUser } from 'src/store/user/user.getters';
import { Author, ID, Maybe } from 'src/models';
import { useNavigation } from 'src/hooks';
import { getParams } from 'src/store/app/app.reducer';
import { PATHS } from 'src/data/routes.data';
import { BaseComponentProps } from 'src/components/Dropdown';

import classes from './UserNavDropdown.module.scss';

export function UserNavDropdown() {
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const { mainId, subId } = useSelector(getParams);

  const { go, goTo } = useNavigation();

  const author: Maybe<Author> = useMemo(() => {
    if (user)
      return {
        photo_url: user.picture,
        full_name: user.full_name,
      };
  }, [user]);

  function handleOnItemClick(id: ID) {
    switch (id) {
      case 'sign_out':
        dispatch(logout());
        break;
      case 'settings':
        if (mainId && subId) goTo(PATHS.USER_SETTINGS_IN_PROJECT, { mainId, subId });
        else go(PATHS.USER_SETTINGS);

        break;
      default:
    }
  }

  function renderAvatar({ onClick }: BaseComponentProps) {
    return (
      <div className={clsx(classes['avatar-wrap'], 'c-pointer')} onClick={onClick}>
        <Avatar author={author} />
      </div>
    );
  }

  return (
    <DropdownMenu
      renderBaseComponent={renderAvatar}
      classNames={{ dropdown: classes.dropdown }}
      items={USER_NAV_DROPDOWN_ITEMS}
      onItemClick={handleOnItemClick}
      position='bottom-right'
      isSmall
    />
  );
}
