import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ID, PageTab } from 'src/models/general.model';
import { useSelector } from 'react-redux';
import { getUrlQuery } from 'src/store/app/app.getters';
import { useNavigation } from 'src/hooks/useNavigation';

interface UseTabsProps<T> {
  availableTabs?: T[];
  defaultTab?: ID;
}

/**
 * hook for creating route based tab functionality
 * @param availableTabs - all available tabs
 * @param defaultTab - default/initial tab
 * @returns tabs - all available tabs with "to" field
 * @returns handleTabSelect - function for manually tab switching
 * @returns selectedTab - currently selected tab
 */
export function useTabs<T extends PageTab>({ availableTabs, defaultTab }: UseTabsProps<T>) {
  const tabFromQuery = useSelector(getUrlQuery).get('tab');

  const { pathname } = useLocation();

  const { replace } = useNavigation();

  const selectedTab = useMemo(() => {
    if (tabFromQuery && availableTabs?.find(t => t.id === tabFromQuery)) return tabFromQuery;

    return defaultTab ?? availableTabs?.[0]?.id;
  }, [tabFromQuery, availableTabs, defaultTab]);

  const handleTabSelect = useCallback((tab: ID) => replace(`${pathname}?tab=${tab}`), [pathname, replace]);

  useEffect(() => {
    if (availableTabs?.length && !availableTabs?.find(t => t.id === tabFromQuery) && selectedTab)
      handleTabSelect(selectedTab);
  }, [availableTabs, handleTabSelect, selectedTab, tabFromQuery]);

  const tabs: T[] = useMemo(() => {
    function mapperFn(tab: T) {
      return { ...tab, to: `${pathname}?tab=${tab.id}` };
    }

    return availableTabs?.map(mapperFn) ?? [];
  }, [availableTabs, pathname]);

  return { tabs, selectedTab, handleTabSelect };
}
