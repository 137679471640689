import { createSelector } from '@reduxjs/toolkit';

import { ID, Maybe, RecordObject } from 'src/models/general.model';
import { RootState } from '..';
import { ReqDetailBaseContract } from 'src/models/reqDetail.model';

import { isAllowedToUpdate, makeIsAllowedToUpdate } from 'src/tools/selectors.tools';

export const getReqDetailUploadInProgress = (state: RootState) => !!state.reqDetail.uploadInProgress;

export const getReqDetailCsiCode = (state: RootState) => state.reqDetail.pageData?.csi_code;

export const getReqDetailCsiDescription = (state: RootState) => state.reqDetail.pageData?.csi_description;

export const getReqDetailPageData = (state: RootState) => state.reqDetail.pageData;

export const getReqDetailPageMeta = (state: RootState) => state.reqDetail.pageMeta;

export const getReqDetailHistory = (state: RootState) => state.reqDetail.history;

export const getReqDetailBaseContract = (state: RootState) => state.reqDetail.baseContract;

export const getReqDetailBaseContractMeta = (state: RootState) => state.reqDetail.baseContract?.meta;

export const getReqDetailBaseContractQuery = (state: RootState) => state.reqDetail.baseContract?.query;

export const getReqDetailBaseContractData = (state: RootState) => state.reqDetail.baseContract?.data;

export const getReqDetailBaseContractDataItem = (state: RootState) => (id: ID) =>
  state.reqDetail.baseContract?.data?.find(item => item.id === id);

export const getReqDetailBaseContractErrorsCount = (state: RootState) => state.reqDetail.baseContract?.info?.errors;

export const getReqDetailChangeOrders = (state: RootState) => state.reqDetail.changeOrders;

export const getReqDetailChangeOrdersMeta = (state: RootState) => state.reqDetail.changeOrders?.meta;

export const getReqDetailChangeOrdersQuery = (state: RootState) => state.reqDetail.changeOrders?.query;

export const getReqDetailChangeOrderErrorsCount = (state: RootState) => state.reqDetail.changeOrders?.info?.errors;

export const getReqDetailPageTotals = (state: RootState) => state.reqDetail.pageTotals;

export const getReqDetailTotalsScenario = (state: RootState) => state.reqDetail.pageTotalsScenario;

export const getReqDetailSelectedPeriod = (state: RootState) => state.reqDetail.selectedPeriod;

export const getReqDetailCurrentPeriod = (state: RootState) => state.reqDetail.currentPeriod;

export const getReqDetailReqPeriods = (state: RootState) => state.reqDetail.reqPeriods;

export const getIsReqDetailCurrentVersion = createSelector(
  [getReqDetailHistory, getReqDetailSelectedPeriod],
  (history, selectedPeriod): boolean =>
    !!selectedPeriod && history?.[history.length - 1]?.version === selectedPeriod?.version,
);

export const getIsReqDetailCurrentPeriod = createSelector(
  [getReqDetailCurrentPeriod, getReqDetailSelectedPeriod],
  (currentPeriod, selectedPeriod): boolean => !!currentPeriod && currentPeriod?.id === selectedPeriod?.id,
);

export const getReqDetailSelectedPeriodItem = createSelector(
  [getReqDetailReqPeriods, getReqDetailSelectedPeriod],
  (periods, selectedPeriod) => selectedPeriod && periods?.find(p => p.id === selectedPeriod.id),
);

export const getIsReqDetailWorkingPeriod = createSelector(
  [getReqDetailSelectedPeriodItem],
  (period): boolean => !!period?.is_working_period,
);

export const getIsReqDetailLockedPeriod = createSelector(
  [getReqDetailSelectedPeriodItem],
  (period): boolean => !!period?.is_locked,
);

export const getReqDetailSidebar = (state: RootState) => state.reqDetail.sidebar;

export const getReqDetailChangeOrdersAllowedToUpdate = makeIsAllowedToUpdate(
  getReqDetailChangeOrdersMeta,
  'changeOrder',
);

export const getReqDetailBCAllowedToUpdate = (state: RootState) =>
  isAllowedToUpdate(state, getReqDetailBaseContractMeta(state), 'baseContract');

export const getReqDetailPageBalance = (state: RootState) => state.reqDetail.pageBalance;

export const getReqDetailPageStatus = (state: RootState) => state.reqDetail.pageData?.status;

export const getReqDetailPageSelectedPeriodVersion = (state: RootState) => state.reqDetail.selectedPeriod?.version;

export const getReqDetailPageCurrentPeriodVersion = (state: RootState) => state.reqDetail.currentPeriod?.version;

export const getReqDetailPagePermissions = (state: RootState) => state.reqDetail.pagePermissions;

export const getReqDetailBaseContractLevels = createSelector(getReqDetailBaseContractData, data => [
  ...new Set(data?.map(row => row.level_name)),
]);

export const getReqDetailBaseContractSortedGroup = createSelector(
  [getReqDetailBaseContractData, getReqDetailBaseContractLevels],
  (data, levels) => {
    const groups: RecordObject<RecordObject<Maybe<ReqDetailBaseContract[]>>> = {};

    const groupKeys = [...new Set(data?.map(row => row.group))];

    for (const levelKey of levels) {
      groups[levelKey as string] = {};

      for (const key of groupKeys) {
        const groupKeyData = data?.filter(item => {
          if (item.level_name === levelKey) return item.group === key;
          else return;
        });

        const undefinedKeyData = data?.filter(item => {
          if (!item.level_name) return item.group === key;
          else return;
        });

        if (levelKey === 'undefined' && undefinedKeyData?.length !== 0) groups[levelKey][key] = undefinedKeyData;

        if (groupKeyData?.length !== 0) groups[levelKey as string][key] = groupKeyData;
      }
    }

    return groups;
  },
);
