import { Action, ActionMeta, AnyValue, AttachmentsContext, ID, RecordObject } from 'src/models/general.model';
import { RequiredDocumentsActions } from 'src/models/query.model';
import { Period } from 'src/models';

export function requestRequiredDocuments(resource_id: ID, meta?: ActionMeta) {
  return {
    type: RequiredDocumentsActions.GET,
    payload: {
      resource_id,
      resource_type: 'MainContract',
    },
    meta,
  };
}

interface UpdateParams {
  required_document_id: ID;
  title?: string;
  document_type?: string;
  pinned?: boolean;
}

export function updateRequiredDocument(payload: UpdateParams) {
  return {
    type: RequiredDocumentsActions.UPDATE,
    payload,
  };
}

export function createRequiredDocument(payload: RecordObject<AnyValue>, meta?: ActionMeta) {
  return {
    type: RequiredDocumentsActions.CREATE,
    payload,
    meta,
  };
}

export function deleteRequiredDocument(id: ID) {
  return {
    type: RequiredDocumentsActions.DELETE,
    payload: id,
  };
}

interface GenerateRequiredDocumentParams {
  id?: ID;
  resource_id: ID;
  resource_type: string;
  required_document_id: ID;
  period?: Period;
  context?: AttachmentsContext;
  completed?: boolean;
}

export const generateRequiredDocument = (payload: GenerateRequiredDocumentParams, meta?: ActionMeta): Action => ({
  type: RequiredDocumentsActions.GENERATE,
  payload,
  meta,
});

export function requestRequiredDocumentTemplate(required_document_id: ID) {
  return {
    type: RequiredDocumentsActions.GET_TEMPLATE_CONTENT,
    payload: required_document_id,
  };
}

export function updateRequiredDocumentTemplate(
  required_document_id: ID,
  content: RecordObject<string>,
  meta?: ActionMeta,
) {
  return {
    type: RequiredDocumentsActions.UPDATE_TEMPLATE_CONTENT,
    payload: { required_document_id, content },
    meta,
  };
}

export function resetRequiredDocumentTemplate(required_document_id: ID) {
  return {
    type: RequiredDocumentsActions.RESET_TEMPLATE_CONTENT,
    payload: required_document_id,
  };
}
