import { Maybe } from 'src/models/general.model';
import { Roles, PermissionTab, RoleLevel } from 'src/models';
import { Permission } from 'src/models/permissions.model';

export interface RolesState {
  roles?: Maybe<Roles>;
  permissionTabs?: Maybe<Record<RoleLevel, PermissionTab[]>>;
  pagePermissions?: Maybe<Permission[]>;
}

export const INITIAL_ROLES_STATE: RolesState = {};
