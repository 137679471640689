import { isNil, keys, forEach, not } from 'ramda';
import { compose } from 'redux';

function useForm() {
  const formData = new FormData();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setFormData(field: string, value?: any, name?: string) {
    function update(val: FormDataEntryValue) {
      if (val instanceof Blob) formData.append(field, val, name);
      else formData.append(field, val);
    }

    if (isNil(value)) return update;

    update(value);
    return;
  }

  return { formData, setFormData };
}

// TODO: remove
export function useConvertObjectToForm() {
  const { formData, setFormData } = useForm();

  function processData(data: unknown): Blob | string {
    if (typeof data === 'string' || data instanceof Blob) return data;

    if (Array.isArray(data)) return data.join(',');

    return JSON.stringify(data);
  }

  return function (object: Record<string, unknown>): FormData {
    const fn = (key: string): void => {
      if (not(isNil(object[key]))) {
        if (key === 'logo') setFormData('logo', object[key] as File, (object[key] as File).name);
        else if (key === 'file') setFormData('file', object[key] as File, (object[key] as File).name);
        else setFormData(key, processData(object[key]));
      }
    };

    compose(forEach(fn), keys)(object);

    return formData;
  };
}
