import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { makeIsAllowedToUpdate } from '../../tools/selectors.tools';

export const getTrades = (state: RootState) => state.trades.trades;

export const getSingleTrade = (state: RootState) => state.trades.trade;

export const getTradeShowScenario = (state: RootState) => state.trades.scenario;

export const getTradesLength = createSelector(getTrades, trades => trades?.length ?? 0);

export const getTradesMeta = (state: RootState) => state.trades.meta;

export const getTradesQuery = (state: RootState) => state.trades.query;

export const getTradesPermissions = (state: RootState) => state.trades.permissions;

export const getTradesAllowedToUpdate = makeIsAllowedToUpdate(getTradesMeta, 'trades');
