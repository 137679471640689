import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_ATTRIBUTE_CHANGES_STATE } from './attributeChanges.model';
import { Maybe } from 'src/models/general.model';
import { AttributeChange } from 'src/models';

export const slice = createSlice({
  name: 'attributeChanges',
  initialState: INITIAL_ATTRIBUTE_CHANGES_STATE,
  reducers: {
    clearAttributeChangesState: () => INITIAL_ATTRIBUTE_CHANGES_STATE,
    setAttributeChanges: (state, action: PayloadAction<Maybe<AttributeChange[]>>) => {
      state.attributeChanges = action.payload;
    },
  },
});

export const { clearAttributeChangesState, setAttributeChanges } = slice.actions;

export default slice.reducer;
