import * as React from 'react';
import clsx from 'clsx';

import { useNavigation } from 'src/hooks';
import { H7 } from 'src/components/Typography';
import { ifTrue } from 'src/tools/logic.tools';

import styles from './XxlPrimaryButton.module.scss';

interface Props {
  label: string;
  onClick?: () => void;
  to?: string;
  isSelected?: boolean;
}

// new-ui
export function XxlPrimaryButton({ label, onClick, isSelected, to }: Props) {
  if (!!to === !!onClick) {
    throw new Error('XxlPrimaryButton: to or onClick prop should be specified, not both');
  }

  const { go } = useNavigation();

  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (onClick) {
      onClick();
    }

    if (to) {
      if (!e.metaKey && !e.ctrlKey) {
        e.preventDefault();

        go(to);
      }
    }
  }

  const wrapElement = ifTrue(to, 'a', 'button');

  return React.createElement(
    wrapElement,
    {
      className: clsx(styles.container, 'row', { [styles.selected]: isSelected }),
      onClick: handleOnClick,
      href: to,
      rel: ifTrue(wrapElement === 'a', 'noopener noreferrer'),
    },
    <H7 className={styles.label}>{label}</H7>,
  );
}
