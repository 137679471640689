import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { concat } from 'ramda';

import { QueueItem, AttachmentFile, AnnotationsPayload, Progress, ServiceInfo } from 'src/models';
import { INITIAL_FILES_STATE } from './files.model';
import { updateFileProgress } from 'src/tools/object.tools';

import { BatchItemState, FormDataBatchItem, Maybe } from 'src/models/general.model';

export const slice = createSlice({
  name: 'files',
  initialState: INITIAL_FILES_STATE,
  reducers: {
    clearFilesState: () => INITIAL_FILES_STATE,
    addUpload: (state, action: PayloadAction<QueueItem<AttachmentFile>>) => {
      state.files = [...(state.files ?? []), action.payload];
    },
    removeUpload: (state, action: PayloadAction<string>) => {
      state.files = state.files?.filter(file => file.id !== action.payload);
    },
    updateProgress: (state, action: PayloadAction<Progress>) => {
      state.files = updateFileProgress(state.files, action.payload);
    },
    setPdfDocumentAnnotations: (state, action: PayloadAction<AnnotationsPayload>) => {
      state.annotations = {
        ...state.annotations,
        [action.payload.id]: action.payload.annotations,
      };
    },
    clearAnnotations: state => {
      if (state.annotations) {
        delete state.annotations;
      }
    },
    addUploads: (state, action: PayloadAction<Maybe<FormDataBatchItem[]>>) => {
      state.uploads = concat(state.uploads ?? [], action.payload ?? []);
    },
    removeUploads: state => {
      state.uploads = undefined;
    },
    removeFromUploadsById: (state, action: PayloadAction<string>) => {
      state.uploads = state.uploads?.filter(item => item.id !== action.payload);
    },
    setServiceInfo: (state, action: PayloadAction<ServiceInfo>) => {
      state.serviceInfo = action.payload;
    },
    switchUploadToIdle: (state, action: PayloadAction<string>) => {
      state.uploads = state.uploads?.map(upload => {
        if (upload.id === action.payload && upload.state === BatchItemState.FAIL)
          return { ...upload, state: BatchItemState.IDLE };

        return upload;
      });
    },
    updateUploadsState: (
      state,
      action: PayloadAction<{ ids: string[]; state: BatchItemState; message?: string; status?: number }>,
    ) => {
      state.uploads = state.uploads?.map(upload => {
        if (action.payload.ids.includes(upload.id)) {
          const updatedItem = { ...upload, state: action.payload.state };

          if (action.payload.message) return { ...updatedItem, message: action.payload.message };

          return updatedItem;
        }

        return upload;
      });
    },
    updateUploadProgressById: (state, action: PayloadAction<{ id: string; progress: number }>) => {
      state.uploads = state.uploads?.map(item => {
        if (item.id === action.payload.id) return { ...item, progress: action.payload.progress };

        return item;
      });
    },
  },
});

export const {
  clearFilesState,
  addUpload,
  removeUpload,
  updateProgress,
  setPdfDocumentAnnotations,
  clearAnnotations,
  addUploads,
  updateUploadsState,
  updateUploadProgressById,
  removeUploads,
  removeFromUploadsById,
  switchUploadToIdle,
  setServiceInfo,
} = slice.actions;

export default slice.reducer;
