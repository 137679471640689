import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ID } from 'src/models/general.model';
import { NotificationMessage, PageSubscription } from 'src/models/notification.model';

import { INITIAL_NOTIFICATIONS_STORE_STATE } from './notifications.model';

export const slice = createSlice({
  name: 'notifications',
  initialState: INITIAL_NOTIFICATIONS_STORE_STATE,
  reducers: {
    clearNotificationsState: () => INITIAL_NOTIFICATIONS_STORE_STATE,
    setMessages: (state, action: PayloadAction<NotificationMessage[]>) => {
      state.messages = action.payload;
    },
    addMessage: (state, action: PayloadAction<NotificationMessage>) => {
      if (state.messages && !state.messages.find(msg => msg.id === action.payload.id))
        state.messages = [action.payload, ...state.messages];
    },
    clearMessages: state => {
      state.messages = [];
    },
    removeMessage: (state, action: PayloadAction<ID>) => {
      state.messages = state.messages?.filter(msg => msg.id !== action.payload);
    },
    updateMessage: (state, action: PayloadAction<NotificationMessage>) => {
      const mapper = (msg: NotificationMessage) => {
        if (msg.id === action.payload.id) return { ...msg, ...action.payload };

        return msg;
      };

      state.messages = state.messages?.map(mapper);
    },
    addPageSubscription: (state, action: PayloadAction<PageSubscription>) => {
      // if undefined
      if (!state.pageSubscriptions) state.pageSubscriptions = { [String(action.payload.module)]: [action.payload.id] };
      // if module is present
      else if (String(action.payload.module) in state.pageSubscriptions)
        state.pageSubscriptions = {
          ...state.pageSubscriptions,
          [String(action.payload.module)]: [
            ...state.pageSubscriptions[String(action.payload.module)],
            action.payload.id,
          ],
        };
    },
    removePageSubscription: (state, action: PayloadAction<PageSubscription>) => {
      // if not undefined - we ignore it
      if (!state.pageSubscriptions) return state.pageSubscriptions;
      // if module is present
      else if (String(action.payload.module) in state.pageSubscriptions)
        state.pageSubscriptions = {
          ...state.pageSubscriptions,
          [String(action.payload.module)]: state.pageSubscriptions[String(action.payload.module)].filter(
            id => id !== action.payload.id,
          ),
        };
    },
  },
});

export const {
  clearNotificationsState,
  setMessages,
  addMessage,
  clearMessages,
  removeMessage,
  updateMessage,
  addPageSubscription,
  removePageSubscription,
} = slice.actions;

export default slice.reducer;
