import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_history'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M3 12a9 9 0 101.204-4.5m0 0V3m0 4.5H9M12 9v3h2'
      />
    </svg>
  );
}

export default Icon;
