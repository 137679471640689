import { processError } from 'src/tools/events.tools';
import { AnyValue, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';
import { QueryAction, QueryDomain } from 'src/models/query.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { FormsScenarioPatch } from 'src/models/uiScenarios.model';
import { addFormScenario } from 'src/store/uiScenarios/uiScenarios.reducer';

export function processUiScenarios({ dispatch, getService }: ProcessProps) {
  const processGetFormScenario = (payload: AnyValue) => () => {
    dispatch(setCustomIsLoading({ id: 'formScenario', state: true }));
    getService().request(
      { [QueryDomain.UI_SCENARIOS]: QueryAction.FORM, payload: { params: payload } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<FormsScenarioPatch>>(response.payload);

          dispatch(addFormScenario({ type: payload.form_type, scenario: result?.data }));
        } else {
          processError({
            activityName: 'Request form scenario',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'formScenario', state: false }));
      },
    );
  };

  return {
    processGetFormScenario,
  };
}
