import { Config, ID, PathParams, Tab, ToastMessage, AlertMessage, ErrorMessage, Maybe, Timezone } from 'src/models';

export interface AppState {
  alertMessage: Maybe<AlertMessage>;
  errorMessage: Maybe<ErrorMessage>;
  closedRequiredDocumentMessages: ID[];
  disableDragging: boolean;
  dropdownSelect: string | undefined;
  isApiOnline: boolean;
  isAuthed: boolean;
  isLoading: boolean;
  isManagerReqDetailHistoryOpen: boolean;
  isReqDetailHistoryOpen: boolean;
  isSidebarOpen: boolean;
  isUploadPopupOpen: boolean;
  messages: ToastMessage[];
  params: PathParams;
  resizableWindowIds: string[];
  rndIds: string[];
  rowSelect: string | undefined;
  serverConfig: Config | undefined;
  tabbedRnd: Tab[] | undefined;
  timezones?: Maybe<Timezone[]>;
  urlQuery: URLSearchParams;
}

export const INITIAL_APP_STATE: AppState = {
  alertMessage: undefined,
  errorMessage: undefined,
  closedRequiredDocumentMessages: [],
  disableDragging: false,
  dropdownSelect: undefined,
  isApiOnline: true,
  isAuthed: false,
  isLoading: true,
  isManagerReqDetailHistoryOpen: false,
  isReqDetailHistoryOpen: false,
  isSidebarOpen: false,
  isUploadPopupOpen: false,
  messages: [],
  params: {},
  resizableWindowIds: [],
  rndIds: [],
  rowSelect: undefined,
  serverConfig: undefined,
  tabbedRnd: undefined,
  urlQuery: new URLSearchParams(),
};
