import React from 'react';
import clsx from 'clsx';

import { MessageCode, UseIcons } from 'src/models';
import { t } from 'src/tools/text.tools';

import { UseIcon } from 'src/components/UseIcon';
import { CloseButton } from 'src/components/Button';
import { H7, P2 } from 'src/components/Typography';

import style from './Toast.module.scss';
import { CONFIG } from 'src/config';

const TXT = t(['toasts']);

const ICON_IDS: Record<MessageCode, UseIcons> = {
  [MessageCode.INFO]: 'check-circle-success',
  [MessageCode.WARNING]: 'info-stroke',
  [MessageCode.ERROR]: 'alert-error',
};

interface Props {
  id: string;
  code: MessageCode;
  content: string;
  onClose: (id: string) => void;
}

export function Toast({ id, code, content, onClose }: Props) {
  const closeTimeout = code === MessageCode.INFO ? CONFIG.toastInfoTimeout : CONFIG.toastDefaultTimeout;

  const handleClose = React.useCallback(() => onClose(id), [onClose, id]);

  React.useEffect(() => {
    const timeout = setTimeout(handleClose, closeTimeout);

    return () => clearTimeout(timeout);
  }, [handleClose, closeTimeout]);

  return (
    <div className={clsx(style.container, 'row a-start j-between', style[code])}>
      <UseIcon iconId={ICON_IDS[code]} className={style.icon} />
      <div className={style['content-wrapper']}>
        <H7>{TXT(code)}</H7>
        <P2>{content}</P2>
      </div>
      <CloseButton className={style.close} onClick={handleClose} isSmall />
    </div>
  );
}
