import { words } from 'tiny-case';

import { ProcessProps, QueryAction, Response, QueryDomain, Maybe, AnyValue, ContractTerms, Action } from 'src/models';
import {
  addSingleContractTerm,
  changeSingleContractTerm,
  deleteSingleContractTerm,
  setContractTerms,
} from 'src/store/contract_terms/contract_terms.reducer';
import { sendError, sendInfo } from 'src/store/app/app.actions';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import { logError, logUnableToProceed } from 'src/tools/log.tools';
import { deserialize } from 'src/tools/string.tools';

function processContractTerms({ dispatch, getService }: ProcessProps) {
  const processContractTermsRequest = (payload: AnyValue) => () => {
    dispatch(setCustomIsLoading({ id: 'terms', state: true }));
    getService().request(
      {
        [QueryDomain.CONTRACT_TERMS]: QueryAction.GET,
        payload,
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<Maybe<ContractTerms[]>>(response.payload);

          if (result) dispatch(setContractTerms(result));
        } else {
          dispatch(sendError(response.message ?? 'Error in getting contract terms.'));
          logError('Error in getting contract terms.', response);
        }

        dispatch(setCustomIsLoading({ id: 'terms', state: false }));
      },
    );
  };

  const processContractTermsCreateRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'terms', state: true }));
    getService().request(
      {
        [QueryDomain.CONTRACT_TERMS]: QueryAction.POST,
        payload: action.payload,
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const data = deserialize<Maybe<ContractTerms>>(response.payload);

          if (data) {
            dispatch(addSingleContractTerm(data!));
            dispatch(sendInfo(`Contract term "${words(data!.name).join(' ')}" was successfully created`));
          } else {
            logUnableToProceed('processContractTermsCreateRequest', 'data is missing', { response, parsed: data });
          }
        } else {
          processError({ activityName: 'Request to create terms', response });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsUpdating({ id: 'terms', state: false }));
      },
    );
  };

  const processContractTermsPatchRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'terms', state: true }));
    getService().request(
      {
        [QueryDomain.CONTRACT_TERMS]: QueryAction.PATCH,
        payload: action.payload,
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const data = deserialize<Maybe<ContractTerms>>(response.payload);

          if (data) {
            dispatch(changeSingleContractTerm(data!));
            dispatch(sendInfo(`Contract term "${words(data!.name).join(' ')}" was successfully updated`));
          } else {
            logUnableToProceed('processContractTermsPatchRequest', 'data is missing', { response, parsed: data });
          }
        } else {
          processError({ activityName: 'Request to update terms', response });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsUpdating({ id: 'terms', state: false }));
      },
    );
  };

  const processContractTermsDeleteRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'terms', state: true }));
    getService().request(
      {
        [QueryDomain.CONTRACT_TERMS]: QueryAction.DELETE,
        payload: { contract_term_id: action.payload.id },
      },
      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(deleteSingleContractTerm(action.payload.id));
          dispatch(sendInfo(`Contract term "${words(action.payload!.name).join(' ')}" was successfuly deleted`));
        } else {
          processError({ activityName: 'Request to delete terms', response });
        }

        dispatch(setCustomIsUpdating({ id: 'terms', state: false }));
      },
    );
  };

  return {
    processContractTermsRequest,
    processContractTermsCreateRequest,
    processContractTermsPatchRequest,
    processContractTermsDeleteRequest,
  };
}

export { processContractTerms };
