import { RowData } from 'src/contexts/row.context';
import { ID, RecordObject } from './general.model';
import { PageQuery, RequestMeta, VersionedPeriod } from './results.model';
import { Sidebar } from './sidebar.model';
import { Permission } from 'src/models/permissions.model';
import { ErrorsObject } from 'src/models/error.model';

// new-ui
export interface ReqDetailPageParams {
  period?: Partial<VersionedPeriod>;
  tradeId?: ID;
  query?: Partial<PageQuery>;
  meta?: Partial<RequestMeta>;
}

export type ReqDetailTotalsType = 'line_items' | 'change_orders' | 'main';

export interface ReqDetailTotalsParams {
  period?: Partial<VersionedPeriod>;
  type: ReqDetailTotalsType;
}

export interface UpdateReqDetailPagePayload {
  status: string;
}

export interface PageBalance {
  contract_value: number;
  left_to_schedule: number;
}

export interface ReqDetailPageData {
  company_name: string;
  csi_code?: string;
  csi_description?: string;
  id: string;
  req_start_date: Date;
  roll_notice: boolean;
  status: string;
  trade_contract_id: number;
  trade_name: string;
  trade_status: string;
  upload_in_progress: boolean;
}

export interface ReqDetailPageDataExtended extends ReqDetailPageData {
  current_period?: VersionedPeriod;
  selected_period?: VersionedPeriod;
  sidebar?: Sidebar;
}

export interface ReqDetailBaseContract extends RowData {
  index: number;
  type: string;
  trade_detail_id: string;
  section: string;
  category: string;
  csi_code: string;
  csi_description: string;
  description: string;
  group: string;
  group_id: string;
  amount: number;
  amount_type: string;
  retainage_applied: boolean;
  contract_value: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_percentage: number;
  retainage_capped: boolean;
  retainage_lock: boolean;
  due_this_period: number;
  level_name?: string;
  _attachments?: {
    is_new: boolean;
    num_comments: number;
    num_documents: number;
    num_pictures: number;
  };
  _page: number;
  _permissions?: Permission[];
  _errors?: ErrorsObject;
}

export type ReqDetailListSection = {
  [group: string]: {
    data: ReqDetailBaseContract[];
    label: string;
  };
} & { '#data': ReqDetailBaseContract[] };

export interface ReqDetailChangeOrder {
  id: ID;
  co_number: string;
  description: string;
  approved_change_orders: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_percentage: number;
  due_this_period: number;
  company: string; // moved from old
  trade: string; // moved from old
  _attachments?: {
    is_new: boolean;
    num_comments: number;
    num_documents: number;
    num_pictures: number;
  };
  _errors?: RecordObject<string>;
}

export interface ReqDetailLine {
  id: string;
  type: string;
  trade_detail_id: string;
  section: string;
  category: string;
  csi_code: string;
  csi_description: string;
  description: string;
  group: string;
  amount: number;
  amount_type: string;
  retainage_applied: boolean;
  contract_value: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_percentage: number;
  retainage_total: number;
  due_this_period: number;
  changes?: string[];
  level_name: string;
}

export type ReqDetailListData = {
  [index: string]: ReqDetailListData | ReqDetailListSection | ReqDetailLine[];
};

export enum ReqDetalStateStatuses {
  BILLING_REJECTED = 'billing_rejected',
  FINAL_REVIEW = 'final_review',
  MANAGER_REVIEW = 'manager_review',
  NOT_BILLING_THIS_PERIIOD = 'not_billing_this_period',
  PAY_APPLICATION_SUBMITTED = 'pay_application_submitted',
  PENCIL_REVIEW = 'pencil_review',
  PERIOD_APPROVED = 'period_approved',
  REVISE_AND_RESUBMIT = 'revise_and_resubmit',
  SUBMISSION_PERIOD = 'submission_period',
}
