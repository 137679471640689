import { PredefinedLists, PredefinedStateMachines } from 'src/models';

export const PROJECT_PREDEFINED_LISTS = [
  PredefinedLists.CHANGE_ORDER_STATUSES,
  PredefinedLists.MANAGER_REQ_STATUSES,
  PredefinedLists.REQ_DETAIL_STATUSES,
];

export const PROJECT_PREDEFINED_STATE_MACHINES = [
  PredefinedStateMachines.CHANGE_ORDER_STATUSES,
  PredefinedStateMachines.MANAGER_REQ_STATUSES,
];

export const TRADE_PREDEFINED_LISTS = [PredefinedLists.REQ_DETAIL_STATUSES];

export const TRADE_PREDEFINED_STATE_MACHINES = [PredefinedStateMachines.REQ_DETAIL_STATUSES];
