import { QueryClass } from 'src/services/query.service';
import {
  generalProcess,
  processComments,
  processCommentThread,
  processChangeOrders,
  processBilling,
  processReqDetail,
  processUser,
  processUserAdmin,
  processPictures,
  processTeam,
  processCompanies,
  processContractTerms,
  processReqSummary,
  processDocuments,
  processRequiredDocuments,
  processDrawings,
  processManagerReq,
  processSignatures,
  processAttributeChanges,
  processBillingVersion,
  processNotifications,
  processRoles,
  processLineItemsTransfers,
  processLevels,
  processHistoricalUploads,
  processOnboarding,
} from 'src/process';

import {
  Action,
  AnyValue,
  BillingActions,
  ChangeOrderActions,
  CommentsActions,
  CommentThreadActions,
  CompaniesActions,
  ContractTermsActions,
  DocumentsActions,
  RequiredDocumentsActions,
  GeneralActions,
  ManagerReqActions,
  MainProjectActions,
  PicturesActions,
  ProcessProps,
  ProjectActions,
  QueryActions,
  ReportActions,
  ReqDetailActions,
  ReqSummaryActions,
  RetainageActions,
  SocketQueryActions,
  StateStore,
  TeamActions,
  TradeActions,
  UserActions,
  MainContractActions,
  SignaturesActions,
  AttributeChangesActions,
  BillingVersionActions,
  AppActions,
  NotificationsActions,
  RolesActions,
  LineItemsTransfersActions,
  UiScenariosActions,
  LevelsActions,
  HistoricalUploadsActions,
  OnboardingActions,
} from 'src/models';
import { PAYLOAD_TYPE } from 'src/data';
import { processTrades } from 'src/process/trades.process';
import { processRetainage } from 'src/process/retainage.process';
import { processReports } from 'src/process/reports.process';
import { processMainContract } from 'src/process/mainContract.process';
// new-ui
import { processMainProject } from 'src/process/portfolio.process';
import { processProjects } from 'src/process/subProjects.process';
import { makeGenericObject } from 'src/tools/object.tools';
import { processPredefinedLists } from 'src/process/predefinedLists.process';
import { processUiScenarios } from 'src/process/uiScenarios.process';

type ActionsObject = Record<string, (arg0?: AnyValue) => () => void>;

let Service: QueryClass | undefined;

let storeObject: StateStore;

let ACTIONS: ActionsObject;

const getService = () => Service as QueryClass;

const makeProcessor = <T>(store: StateStore, fn: (arg0: ProcessProps) => T) =>
  fn({ dispatch: store.dispatch, getState: store.getState, getService });

export const socketQueryMiddleware =
  ({ dispatch, getState }: StateStore) =>
  (next: (arg0: Action) => void) =>
  async (action: Action) => {
    if (!storeObject) storeObject = { dispatch, getState };

    next(action);

    if (!Service) Service = QueryClass.instance(dispatch);

    const make = <T>(fn: (arg0: ProcessProps) => T) => makeProcessor({ dispatch, getState }, fn);

    function chooseCorrectPayload() {
      if (PAYLOAD_TYPE[action.type] === 'payload') return action.payload;

      return action;
    }

    if (Service && ACTIONS && ACTIONS[action.type]) {
      const fn = ACTIONS[action.type];

      if (fn) {
        const processFn = fn(chooseCorrectPayload());

        if (processFn) processFn();
      }
    }

    if (Service && !ACTIONS)
      ACTIONS = makeGenericObject(
        {
          [AppActions.GET_TIMEZONES]: make(generalProcess).processTimezonesRequest,
          [BillingActions.UPDATE]: make(processBilling).processUpdateRequest,
          [BillingVersionActions.UPDATE]: make(processBillingVersion).processBillingVersionUpdateRequest,
          [BillingVersionActions.MAKE_CURRENT]: make(processBillingVersion).processBillingVersionMakeCurrentRequest,
          [ChangeOrderActions.CREATE]: make(processChangeOrders).processAddRequest,
          [ChangeOrderActions.GET]: make(processChangeOrders).processRequest,
          [QueryActions.CHANGE_ORDERS_TOTALS]: make(processChangeOrders).processTotalsRequest,
          [GeneralActions.GET_AUTOCOMPLETE]: make(generalProcess).processAutocompleteRequest,
          [GeneralActions.GET_PLACES_AUTOCOMPLETE]: make(generalProcess).processPlacesAutocompleteRequest,
          [GeneralActions.GET_EMAIL_AUTOCOMPLETE]: make(generalProcess).processEmailAutocompleteRequest,
          [QueryActions.GET_CHANGE_ORDERS_MORE]: make(processChangeOrders).processMoreRequest,
          [MainProjectActions.SINGLE]: make(processMainProject).processSingleRequest,
          [MainProjectActions.CREATE]: make(processMainProject).processCreateRequest,
          [MainProjectActions.UPDATE]: make(processMainProject).processUpdateRequest,
          [QueryActions.GET_SINGLE_CHANGE_ORDER]: make(processChangeOrders).processSingleRequest,
          [ProjectActions.GET]: make(processProjects).processProjectsRequest,
          // [ProjectActions.CREATE]: make(processProjects).processCreateRequest,
          [ProjectActions.UPDATE]: make(processProjects).processUpdateRequest,
          [ProjectActions.GET_TEAM]: make(processProjects).processProjectTeam,
          [ProjectActions.GET_TEAM_MEMBER]: make(processProjects).processProjectTeamMember,
          [ProjectActions.GET_TEAM_INVITE]: make(processProjects).processProjectTeamInvite,
          [ProjectActions.SHOW]: make(processProjects).processCurrentProjectRequest,
          [TeamActions.GET]: make(processTeam).processTeamRequest,
          [TeamActions.GET_ROLES]: make(processTeam).processRolesRequest,
          [TeamActions.CREATE]: make(processTeam).processCreateTeamRequest,
          [TeamActions.UPDATE_MEMBER]: make(processTeam).processUpdateMemberRequest,
          [TeamActions.CANCEL_INVITE]: make(processTeam).processCancelInviteRequest,
          [TeamActions.RESEND_INVITE]: make(processTeam).processResendInviteRequest,
          [TeamActions.UPDATE_INVITE]: make(processTeam).processUpdateInviteRequest,
          [ReqDetailActions.BASE_CONTRACT_MORE]: make(processReqDetail).processBaseContractMoreRequest,
          [ReqDetailActions.BASE_CONTRACT]: make(processReqDetail).processBaseContractRequest,
          [ReqDetailActions.CHANGE_ORDERS]: make(processReqDetail).processOrdersRequest,
          [ReqDetailActions.HISTORY]: make(processReqDetail).processHistoryRequest,
          [ReqDetailActions.PATCH_BASE_CONTRACT]: make(processReqDetail).processPatchLineRequest,
          [ReqDetailActions.PERFORM_ACTION]: make(processReqDetail).processActions,
          [ReqDetailActions.POST_BASE_CONTRACT]: make(processReqDetail).processPostLineRequest,
          [ReqDetailActions.DELETE_LINE_ITEM]: make(processReqDetail).processDeleteLineRequest,
          [ReqDetailActions.UPDATE_PAGE]: make(processReqDetail).processPageUpdate,
          [ReqDetailActions.SHOW]: make(processReqDetail).processPageDataRequest,
          [ReqDetailActions.STATS]: make(processReqDetail).processStatisticsRequest,
          [ReqDetailActions.TOTALS]: make(processReqDetail).processTotalsRequest,
          [ReqDetailActions.PAGE_BALANCE_AND_TOTALS]: make(processReqDetail).processPageBalanceAndTotalsRequest,
          [ReqDetailActions.CHANGE_ORDERS_MORE]: make(processReqDetail).processOrdersMoreRequest,
          [QueryActions.GET_DRAWING]: make(processDrawings).processGetDrawingsRequest,
          [QueryActions.POST_DRAWING]: make(processDrawings).processPostDrawingsRequest,
          [MainContractActions.GET]: make(processMainContract).processGetMainContract,
          [MainContractActions.UPDATE]: make(processMainContract).processUpdateMainContract,
          [OnboardingActions.CREATE]: make(processOnboarding).processCreateOnboarding,
          // [UserActions.CHECK_TOKEN]: processUserCheckTokenRequest,
          [UserActions.GET]: make(processUser).processGetUser,
          [UserActions.UPDATE]: make(processUser).processUpdateUserRequest,
          [UserActions.SEND_UPDATE_PASSWORD_TOKEN]: make(processUser).processSendUpdatePasswordToken,
          [UserActions.SEND_RESET_PASSWORD_TOKEN]: make(processUser).processSendResetPasswordToken,
          [UserActions.CHECK_UPDATE_PASSWORD_TOKEN]: make(processUser).processCheckUpdatePasswordToken,
          [UserActions.UPDATE_PASSWORD]: make(processUser).processUpdatePassword,
          [UserActions.RESET_PASSWORD]: make(processUser).processResetPassword,
          [UserActions.GET_USER_ADMIN]: make(processUserAdmin).processGetUserAdmin,
          [UserActions.UPDATE_USER_ADMIN]: make(processUserAdmin).processUpdateUserAdmin,
          [SocketQueryActions.GET_SERVER_CONFIG]: make(generalProcess).processServerConfigRequest,
          [ChangeOrderActions.PATCH]: make(processChangeOrders).processPatchRequest,
          [ChangeOrderActions.HISTORY]: make(processChangeOrders).processHistoryRequest,
          [ChangeOrderActions.AUTOCOMPLETE]: make(processChangeOrders).processAutocompleteRequest,
          [ChangeOrderActions.CONTINGENCY_LIST]: make(processChangeOrders).processGetContingencyLineItemsList,
          [SocketQueryActions.UPDATE_REQ_PAGE]: make(processReqDetail).processPageUpdate,
          [SocketQueryActions.USER_LOGIN]: make(processUser).processUserLoginRequest,
          [SocketQueryActions.USER_LOGOUT]: make(processUser).processUserLogoutRequest,
          [SocketQueryActions.GET_INVITE]: make(processUser).processGetInviteRequest,
          [SocketQueryActions.SIGN_UP]: make(processUser).processUserSignUpRequest,
          [ReqSummaryActions.SHOW]: make(processReqSummary).processPageDataRequest,
          [ReqSummaryActions.TOTALS]: make(processReqSummary).processTotalsRequest,
          // [ReqSummaryActions.STATISTICS]: make(processReqSummary).processStatisticsRequest,
          [ReqSummaryActions.BILLING_STATUS]: make(processReqSummary).processBillingStatusRequest,
          [ReqSummaryActions.TRADES]: make(processReqSummary).processTradeRequest,
          [ReqSummaryActions.TERMS]: make(processReqSummary).processTermsRequest,
          [ReqSummaryActions.ACTION]: make(processReqSummary).processActions,
          [ReqSummaryActions.GET_TRADES_MORE]: make(processReqSummary).processTradeMoreRequest,
          [ReqSummaryActions.AUTOCOMPLETE]: make(processReqSummary).processAutocompleteRequest,
          [ReqSummaryActions.DASHBOARD]: make(processReqSummary).processDashboardRequest,
          [CommentsActions.GET]: make(processComments).processCommentsRequest,
          [CommentsActions.POST]: make(processComments).processCommentsPost,
          [CommentThreadActions.GET]: make(processCommentThread).processCommentThreadsRequest,
          [CommentThreadActions.SINGLE]: make(processCommentThread).processSingleCommentThreadRequest,
          [CommentThreadActions.POST]: make(processCommentThread).processCommentThreadPost,
          [CompaniesActions.GET]: make(processCompanies).processCompaniesRequest,
          [CompaniesActions.SINGLE]: make(processCompanies).processSingleCompanyRequest,
          [CompaniesActions.USER]: make(processCompanies).processUserCompanyRequest,
          [CompaniesActions.MORE]: make(processCompanies).processMoreCompaniesRequest,
          [CompaniesActions.ASSIGN]: make(processCompanies).processAssignCompanyRequest,
          [DocumentsActions.GET]: make(processDocuments).processDocumentsRequest,
          [DocumentsActions.GET_DOCUMENT]: make(processDocuments).processDocumentRequest,
          [DocumentsActions.UPDATE]: make(processDocuments).processDocumentUpdateRequest,
          [DocumentsActions.DELETE]: make(processDocuments).processDocumentDeleteRequest,
          [DocumentsActions.SEARCH]: make(processDocuments).processDocumentSearchRequest,
          [RequiredDocumentsActions.GENERATE]: make(processRequiredDocuments).processRequiredDocumentGenerateRequest,
          [RequiredDocumentsActions.GET]: make(processRequiredDocuments).processRequiredDocumentsRequest,
          [RequiredDocumentsActions.UPDATE]: make(processRequiredDocuments).processUpdateDocumentsRequest,
          [RequiredDocumentsActions.CREATE]: make(processRequiredDocuments).processCreateDocumentsRequest,
          [RequiredDocumentsActions.DELETE]: make(processRequiredDocuments).processDeleteDocumentsRequest,
          [RequiredDocumentsActions.GET_TEMPLATE_CONTENT]: make(processRequiredDocuments).processTemplateContentRequest,
          [RequiredDocumentsActions.UPDATE_TEMPLATE_CONTENT]:
            make(processRequiredDocuments).processUpdateTemplateContentRequest,
          [RequiredDocumentsActions.RESET_TEMPLATE_CONTENT]:
            make(processRequiredDocuments).processResetTemplateContentRequest,
          [PicturesActions.GET]: make(processPictures).processPicturesRequest,
          [PicturesActions.ADD_TAGS]: make(processPictures).processPictureTagsAddRequest,
          [PicturesActions.DELETE_TAGS]: make(processPictures).processPictureTagsDeleteRequest,
          [PicturesActions.UPDATE]: make(processPictures).processPictureUpdateRequest,
          [PicturesActions.DELETE]: make(processPictures).processPictureDeleteRequest,
          [ContractTermsActions.GET]: make(processContractTerms).processContractTermsRequest,
          [ContractTermsActions.CREATE]: make(processContractTerms).processContractTermsCreateRequest,
          [ContractTermsActions.PATCH]: make(processContractTerms).processContractTermsPatchRequest,
          [ContractTermsActions.DELETE]: make(processContractTerms).processContractTermsDeleteRequest,
          [LineItemsTransfersActions.GET_LINE_ITEM]: make(processLineItemsTransfers).processGetLineItem,
          [LineItemsTransfersActions.CREATE_LINE_ITEM]: make(processLineItemsTransfers).processCreateLineItem,
          [LineItemsTransfersActions.CREATE]: make(processLineItemsTransfers).processCreateTransfer,
          [LineItemsTransfersActions.AUTOCOMPLETE]: make(processLineItemsTransfers).processLineItemAutocomplete,
          [ManagerReqActions.SHOW]: make(processManagerReq).processPageDataRequest,
          [ManagerReqActions.GET_STATUS]: make(processManagerReq).processPageStatusRequest,
          [ManagerReqActions.BASE_CONTRACT]: make(processManagerReq).processBaseContractDataRequest,
          [ManagerReqActions.CHANGE_ORDERS]: make(processManagerReq).processChangeOrdersDataRequest,
          [ManagerReqActions.TOTALS]: make(processManagerReq).processTotalsRequest,
          [ManagerReqActions.HISTORY]: make(processManagerReq).processHistoryRequest,
          [ManagerReqActions.PATCH_LINE_ITEM]: make(processManagerReq).processPatchLineRequest,
          [ManagerReqActions.POST_LINE_ITEM]: make(processManagerReq).processPostLineRequest,
          [ManagerReqActions.DELETE_LINE_ITEM]: make(processManagerReq).processDeleteLineRequest,
          [ManagerReqActions.PERFORM_ACTION]: make(processManagerReq).processActions,
          [ManagerReqActions.UPDATE_PAGE]: make(processManagerReq).processUpdatePage,
          [ManagerReqActions.CHANGE_ORDERS_MORE]: make(processManagerReq).processOrdersMoreRequest,
          [ManagerReqActions.BASE_CONTRACT_MORE]: make(processManagerReq).processBaseContractMoreRequest,
          [NotificationsActions.UPDATE]: make(processNotifications).processNotificationsUpdate,
          [NotificationsActions.DELETE]: make(processNotifications).processNotificationsDelete,
          [TradeActions.SHOW]: make(processTrades).processTradeShowRequest,
          [TradeActions.GET]: make(processTrades).processTradesRequest,
          [TradeActions.GET_MORE]: make(processTrades).processMoreTradesRequest,
          [TradeActions.CREATE]: make(processTrades).processTradeCreateRequest,
          [TradeActions.UPDATE]: make(processTrades).processTradeUpdateRequest,
          [TradeActions.DELETE]: make(processTrades).processTradeDeleteRequest,
          [AttributeChangesActions.GET]: make(processAttributeChanges).processGetAttributeChanges,
          [RetainageActions.GET]: make(processRetainage).processRetainageRequest,
          [RetainageActions.POST]: make(processRetainage).processRetainagePostRequest,
          [RetainageActions.UPDATE]: make(processRetainage).processRetainageUpdateRequest,
          [RetainageActions.DELETE]: make(processRetainage).processRetainageDeleteRequest,
          [ReportActions.CREATE]: make(processReports).processReportRequest,
          [RolesActions.GET]: make(processRoles).processGetRoles,
          [RolesActions.CREATE]: make(processRoles).processCreateRole,
          [RolesActions.UPDATE]: make(processRoles).processUpdateRole,
          [RolesActions.DELETE]: make(processRoles).processDeleteRole,
          [RolesActions.PROJECT_PERMISSIONS]: make(processRoles).processGetProjectPermissions,
          [SignaturesActions.GET]: make(processSignatures).processSignaturesRequest,
          [SignaturesActions.CREATE]: make(processSignatures).processSignatureCreateRequest,
          [SignaturesActions.DELETE]: make(processSignatures).processSignatureDeleteRequest,
          [AppActions.PREDEFINED_LISTS]: make(processPredefinedLists).processGetPredefinedLists,
          [UiScenariosActions.GET_FORM]: make(processUiScenarios).processGetFormScenario,
          [LevelsActions.GET]: make(processLevels).processGetLevels,
          [LevelsActions.CREATE]: make(processLevels).processCreateLevels,
          [LevelsActions.UPDATE]: make(processLevels).processUpdateLevel,
          [LevelsActions.DELETE]: make(processLevels).processDeleteLevel,
          [LevelsActions.GET_ALL]: make(processLevels).processGetAllLevels,
          [HistoricalUploadsActions.GET]: make(processHistoricalUploads).processGetInfo,
          [HistoricalUploadsActions.GET_UNSUCCESSFUL_ITEMS]: make(processHistoricalUploads).processGetLineItems,
          [HistoricalUploadsActions.GET_MORE_UNSUCCESSFUL_ITEMS]:
            make(processHistoricalUploads).processGetMoreLineItems,
          [HistoricalUploadsActions.GET_ERRORS]: make(processHistoricalUploads).processGetLineItemsErrors,
          [HistoricalUploadsActions.GET_MORE_ERRORS]: make(processHistoricalUploads).processGetMoreLineItemsErrors,
          [HistoricalUploadsActions.UPDATE]: make(processHistoricalUploads).processUpdateLineItem,
          [HistoricalUploadsActions.UPDATE_BILLING]: make(processHistoricalUploads).processUpdateBilling,
        },
        undefined,
      );
  };
