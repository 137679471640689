/* eslint-disable max-lines */
import { processError } from 'src/tools/events.tools';

import { Action, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain } from 'src/models/query.model';
import { deserialize } from 'src/tools/string.tools';
import { logUnableToProceed } from 'src/tools/log.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import {
  requestHistoricalUploadsLineItems,
  requestHistoricalUploadsLineItemsErrors,
  RequestUpdateHistoricalLineItemProps,
} from 'src/store/historicalUploads/historicalUploads.actions';
import {
  FailureRecordType,
  HistoricalUploadsError,
  HistoricalUploadsInfo,
  HistoricalUploadsQuery,
  HistoricalUploadsUnsuccessful,
} from 'src/models/historicalUploads.model';
import {
  addHistoricalUploadsErrors,
  addHistoricalUploadsUnsuccessfulItems,
  removeHistoricalUploadsErrors,
  removeHistoricalUploadsUnsuccessfulItems,
  setHistoricalUploadsErrorItemsMeta,
  setHistoricalUploadsErrorItemsQuery,
  setHistoricalUploadsErrorItemsScenario,
  setHistoricalUploadsErrors,
  setHistoricalUploadsInfo,
  setHistoricalUploadsUnsuccessfulItems,
  setHistoricalUploadsUnsuccessfulItemsMeta,
  setHistoricalUploadsUnsuccessfulItemsQuery,
  setHistoricalUploadsUnsuccessfulItemsScenario,
  updateHistoricalUploadsErrors,
  updateHistoricalUploadsUnsuccessfulItem,
} from 'src/store/historicalUploads/historicalUploads.reducer';
import {
  getHistoricalUploadsErrorItemsMeta,
  getHistoricalUploadsErrorItemsQuery,
  getHistoricalUploadsUnsuccessfulItemsMeta,
  getHistoricalUploadsUnsuccessfulItemsQuery,
} from 'src/store/historicalUploads/historicalUploads.getters';
import { metaForNextPage } from 'src/tools/paging.tools';
import { ifTrue } from 'src/tools/logic.tools';

export function processHistoricalUploads({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processGetInfo =
    ({ payload, meta }: Action<HistoricalUploadsQuery>) =>
    () => {
      if (!payload) {
        logUnableToProceed('historicalUploads::processGetInfo', { payload });
        return;
      }

      setIsBusy({ id: 'historicalUploads', state: true, meta });

      getService().request(
        {
          [QueryDomain.HISTORICAL_UPLOADS]: QueryAction.GET,
          payload: {
            params: payload,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<HistoricalUploadsInfo>>(response.payload);

            dispatch(setHistoricalUploadsInfo(result?.data));

            meta?.callback?.({ isOK: true, payload: result?.data });
          } else {
            processError({
              activityName: 'Request historical uploads info',
              response,
            });

            meta?.callback?.({ isOK: false });
          }

          setIsBusy({ id: 'historicalUploads', state: false, meta });
        },
      );
    };

  const processGetLineItems =
    ({ payload, meta }: Action<HistoricalUploadsQuery>) =>
    () => {
      if (!payload) {
        logUnableToProceed('historicalUploads::processGetLineItems', { payload });
        return;
      }

      const { failure_record_type, ...params } = payload;

      setIsBusy({ id: 'historicalUploadsLineItems', state: true, meta });

      getService().request(
        {
          [QueryDomain.HISTORICAL_UPLOADS]: ifTrue(
            failure_record_type === FailureRecordType.LINE_ITEM,
            QueryAction.GET_BASE,
            QueryAction.GET_CHANGE_ORDERS,
          ),
          payload: {
            params,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<HistoricalUploadsUnsuccessful[]>>(response.payload);

            if (result) {
              if (meta?.isUpdate) dispatch(addHistoricalUploadsUnsuccessfulItems(result.data));
              else {
                dispatch(setHistoricalUploadsUnsuccessfulItemsQuery(payload));
                dispatch(setHistoricalUploadsUnsuccessfulItems(result.data));
              }

              dispatch(setHistoricalUploadsUnsuccessfulItemsMeta(result.meta));
              dispatch(setHistoricalUploadsUnsuccessfulItemsScenario(result.scenario));
            }

            meta?.callback?.({ isOK: true, payload: result?.data });
          } else {
            processError({
              activityName: 'Request unsuccessful line items',
              response,
            });

            meta?.callback?.({ isOK: false });
          }

          setIsBusy({ id: 'historicalUploadsLineItems', state: false, meta });
        },
      );
    };

  const processGetMoreLineItems = (action: Action) => () => {
    const query = getHistoricalUploadsUnsuccessfulItemsQuery(getState());

    const meta = getHistoricalUploadsUnsuccessfulItemsMeta(getState());

    if (!query || !meta?.next) {
      logUnableToProceed('historicalUploads::processGetMoreLineItems', { query, meta });
      return;
    }

    dispatch(
      requestHistoricalUploadsLineItems(
        {
          ...query,
          meta: metaForNextPage(meta),
        },
        { ...action.meta, isUpdate: true },
      ),
    );
  };

  const processGetLineItemsErrors =
    ({ payload, meta }: Action<HistoricalUploadsQuery>) =>
    () => {
      if (!payload) {
        logUnableToProceed('historicalUploads::processGetLineItemsErrors', { payload });
        return;
      }

      const { failure_record_type, ...params } = payload;

      setIsBusy({ id: 'historicalUploadsLineItemsErrors', state: true, meta });

      getService().request(
        {
          [QueryDomain.HISTORICAL_UPLOADS]: ifTrue(
            failure_record_type === FailureRecordType.LINE_ITEM,
            QueryAction.GET_BASE_ERRORS,
            QueryAction.GET_CHANGE_ORDERS_ERRORS,
          ),
          payload: {
            params,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<HistoricalUploadsError[]>>(response.payload);

            if (result) {
              if (meta?.isUpdate) dispatch(addHistoricalUploadsErrors(result.data));
              else {
                dispatch(setHistoricalUploadsErrorItemsQuery(payload));
                dispatch(setHistoricalUploadsErrors(result.data));
              }

              dispatch(setHistoricalUploadsErrorItemsMeta(result.meta));
              dispatch(setHistoricalUploadsErrorItemsScenario(result.scenario));
            }

            meta?.callback?.({ isOK: true, payload: result?.data });
          } else {
            processError({
              activityName: 'Request line items errors',
              response,
            });

            meta?.callback?.({ isOK: false });
          }

          setIsBusy({ id: 'historicalUploadsLineItemsErrors', state: false, meta });
        },
      );
    };

  const processGetMoreLineItemsErrors = (action: Action) => () => {
    const query = getHistoricalUploadsErrorItemsQuery(getState());

    const meta = getHistoricalUploadsErrorItemsMeta(getState());

    if (!query || !meta?.next) {
      logUnableToProceed('historicalUploads::processGetMoreLineItemsErrors', { query, meta });
      return;
    }

    dispatch(
      requestHistoricalUploadsLineItemsErrors(
        {
          ...query,
          meta: metaForNextPage(meta),
        },
        { ...action.meta, isUpdate: true },
      ),
    );
  };

  const processUpdateLineItem =
    ({ payload, meta }: Action<RequestUpdateHistoricalLineItemProps>) =>
    () => {
      if (!payload) {
        logUnableToProceed('historicalUploads::processUpdateLineItem', { payload });
        return;
      }

      setIsBusy({ id: 'historicalUploadsLineItems', state: true, meta: { isUpdate: true } });

      getService().request(
        {
          [QueryDomain.HISTORICAL_UPLOADS]: QueryAction.UPDATE_LINE_ITEM,
          payload: {
            data: payload,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<HistoricalUploadsUnsuccessful>>(response.payload);

            if (result?.data) dispatch(updateHistoricalUploadsUnsuccessfulItem(result.data));
            else dispatch(removeHistoricalUploadsUnsuccessfulItems(payload.id));

            meta?.callback?.({ isOK: true, payload: result?.data });
          } else {
            processError({
              activityName: 'Request update line item',
              response,
            });
          }

          setIsBusy({ id: 'historicalUploadsLineItems', state: false, meta: { isUpdate: true } });
        },
      );
    };

  const processUpdateBilling =
    ({ payload, meta }: Action<RequestUpdateHistoricalLineItemProps>) =>
    () => {
      if (!payload) {
        logUnableToProceed('historicalUploads::processUpdateBilling', { payload });
        return;
      }

      setIsBusy({ id: 'historicalUploadsLineItemsErrors', state: true, meta: { isUpdate: true } });

      getService().request(
        {
          [QueryDomain.HISTORICAL_UPLOADS]: QueryAction.UPDATE_BILLING,
          payload: {
            data: payload,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<HistoricalUploadsError>>(response.payload);

            if (result?.data) dispatch(updateHistoricalUploadsErrors(result.data));
            else dispatch(removeHistoricalUploadsErrors(payload.id));

            meta?.callback?.({ isOK: true, payload: result?.data });
          } else {
            processError({
              activityName: 'Request update line item billing',
              response,
            });
          }

          setIsBusy({ id: 'historicalUploadsLineItemsErrors', state: false, meta: { isUpdate: true } });
        },
      );
    };

  return {
    processGetInfo,
    processGetLineItems,
    processGetMoreLineItems,
    processGetLineItemsErrors,
    processGetMoreLineItemsErrors,
    processUpdateLineItem,
    processUpdateBilling,
  };
}
