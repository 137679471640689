import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_owner'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='none'
        fillRule='evenodd'
        d='M14.4722 0.861949C14.7326 1.1223 14.7326 1.54441 14.4722 1.80476L13.6103 2.66669L15.1389 4.19528C15.3993 4.45563 15.3993 4.87774 15.1389 5.13809L12.8056 7.47143C12.5452 7.73177 12.1231 7.73177 11.8628 7.47143L10.3342 5.94283L8.50321 7.77379C8.70818 8.051 8.87981 8.35234 9.01397 8.67167C9.23532 9.19856 9.35027 9.76404 9.35218 10.3355C9.3541 10.907 9.24294 11.4733 9.02512 12.0016C8.8073 12.53 8.48712 13.0101 8.083 13.4142C7.67889 13.8183 7.19883 14.1385 6.67046 14.3563C6.14209 14.5741 5.57586 14.6853 5.00435 14.6834C4.43285 14.6815 3.86738 14.5665 3.34048 14.3452C2.81358 14.1238 2.33568 13.8004 1.93428 13.3936L1.92928 13.3885C1.13993 12.5712 0.703181 11.4766 0.713054 10.3404C0.722928 9.20424 1.17866 8.11738 1.98209 7.31395C2.78553 6.51051 3.87238 6.05478 5.00857 6.04491C5.92379 6.03695 6.81203 6.31881 7.55007 6.84131L13.5294 0.861949C13.7898 0.6016 14.2119 0.6016 14.4722 0.861949ZM7.14874 8.23732C7.14253 8.23175 7.1364 8.22605 7.13036 8.22021C6.56455 7.67374 5.80675 7.37136 5.02016 7.37819C4.23357 7.38503 3.48113 7.70053 2.9249 8.25676C2.36868 8.81298 2.05317 9.56542 2.04634 10.352C2.03951 11.1374 2.34098 11.8941 2.8859 12.4597C3.16327 12.7401 3.49323 12.9631 3.85691 13.1159C4.22168 13.2691 4.61317 13.3487 5.00882 13.35C5.40448 13.3514 5.79649 13.2744 6.16228 13.1236C6.52807 12.9728 6.86042 12.7512 7.14019 12.4714C7.41996 12.1916 7.64163 11.8593 7.79243 11.4935C7.94323 11.1277 8.02018 10.7357 8.01886 10.34C8.01753 9.94435 7.93795 9.55287 7.78471 9.1881C7.63562 8.83322 7.41967 8.51045 7.14874 8.23732ZM11.277 5.00002L12.3342 6.05721L13.7247 4.66669L12.6675 3.6095L11.277 5.00002Z'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default Icon;
