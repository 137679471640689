import { processError } from 'src/tools/events.tools';

import { Action, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain } from 'src/models/query.model';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { PermissionTab, Role, Roles } from 'src/models';
import { getCurrentSubprojectId } from '../store/app/app.getters';
import {
  setRoles,
  addRole,
  updateRole,
  deleteRole,
  setProjectPermissions,
  setRolesPagePermissions,
} from '../store/roles/roles.reducer';
import { sendInfo } from '../store/app/app.actions';

function processRoles({ dispatch, getService, getState }: ProcessProps) {
  const processGetRoles = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'roles', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    getService().request(
      { [QueryDomain.ROLES]: QueryAction.GET, payload: { index: [project_id] } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Roles>>(response.payload);

          dispatch(setRoles(result?.data));
          dispatch(setRolesPagePermissions(result?.permissions));

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request roles',
            response,
          });

          if (action.meta?.callback) action.meta.callback({ isOK: false });
        }

        dispatch(setCustomIsLoading({ id: 'roles', state: false }));
      },
    );
  };

  const processCreateRole = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'roles', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    getService().request(
      {
        [QueryDomain.ROLES]: QueryAction.POST,
        payload: {
          data: action.payload,
          index: [project_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Role>>(response.payload);

          dispatch(sendInfo('Role has been created.'));

          if (result?.data) dispatch(addRole(result.data));

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request roles',
            response,
          });

          if (action.meta?.callback) action.meta.callback({ isOK: false });
        }

        dispatch(setCustomIsUpdating({ id: 'roles', state: false }));
      },
    );
  };

  const processUpdateRole = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'roles', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    const { role_id, ...data } = action.payload;

    getService().request(
      {
        [QueryDomain.ROLES]: QueryAction.PATCH,
        payload: {
          data,
          index: [project_id, role_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Role>>(response.payload);

          dispatch(sendInfo('Role has been updated.'));

          if (result?.data) dispatch(updateRole(result.data));

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request roles',
            response,
          });

          if (action.meta?.callback) action.meta.callback({ isOK: false });
        }

        dispatch(setCustomIsUpdating({ id: 'roles', state: false }));
      },
    );
  };

  const processDeleteRole = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'roles', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    getService().request(
      {
        [QueryDomain.ROLES]: QueryAction.DELETE,
        payload: {
          index: [project_id, action.payload.role_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(deleteRole(action.payload));

          if (action.meta?.callback) action.meta.callback({ isOK: true });
        } else {
          processError({
            activityName: 'Request roles',
            response,
          });

          if (action.meta?.callback) action.meta.callback({ isOK: false });
        }

        dispatch(setCustomIsUpdating({ id: 'roles', state: false }));
      },
    );
  };

  const processGetProjectPermissions = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'projectPermissions', state: true }));

    const project_id = getCurrentSubprojectId(getState());

    getService().request(
      {
        [QueryDomain.ROLES]: QueryAction.PERMISSIONS,
        payload: {
          params: {
            level: action.payload,
          },
          index: [project_id],
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<PermissionTab[]>>(response.payload);

          if (result?.data) dispatch(setProjectPermissions({ level: action.payload, data: result.data }));

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request roles',
            response,
          });

          if (action.meta?.callback) action.meta.callback({ isOK: false });
        }

        dispatch(setCustomIsLoading({ id: 'projectPermissions', state: false }));
      },
    );
  };

  return {
    processGetRoles,
    processCreateRole,
    processUpdateRole,
    processDeleteRole,
    processGetProjectPermissions,
  };
}

export { processRoles };
