import { lazy } from 'react';

export const LogIn = lazy(() => import('src/pages/LogIn'));

export const SignUp = lazy(() => import('src/pages/SignUp'));

export const ForgotPassword = lazy(() => import('src/pages/ForgotPassword'));

export const ResetPassword = lazy(() => import('src/pages/ResetPassword'));

export const TwoFactorAuthentication = lazy(() => import('src/pages/TwoFactorAuthentication'));

export const ManagerReq = lazy(() => import('src/pages/ManagerReq'));

export const Trades = lazy(() => import('src/pages/Trades'));

export const ReqSummary = lazy(() => import('src/pages/ReqSummary'));

export const ReqDetail = lazy(() => import('src/pages/ReqDetail'));

export const ChangeOrders = lazy(() => import('src/pages/ChangeOrders'));

export const Companies = lazy(() => import('src/pages/Companies'));

export const Users = lazy(() => import('src/pages/Users'));

export const TradeShow = lazy(() => import('src/pages/TradeShow'));

export const ChangeOrderShow = lazy(() => import('src/pages/ChangeOrderShow/ChangeOrderShow'));

export const MainContract = lazy(() => import('src/pages/MainContract'));

export const ProjectShow = lazy(() => import('src/pages/ProjectShow'));

export const UserSettings = lazy(() => import('src/pages/UserSettings'));

export const Roles = lazy(() => import('src/pages/Roles'));

export const TechnicalLayout = lazy(() => import('src/pages/TechnicalLayout'));

export const UserAdmin = lazy(() => import('src/pages/UserAdmin'));
