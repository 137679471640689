import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
      data-testid='icon_pen-tool'
    >
      <g fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' clipPath='url(#clip-pen-tool)'>
        <path d='M15.833 10L10 4.167l2.5-2.5L18.333 7.5l-2.5 2.5zM10.834 5l-6.25 1.25-2.917 12.084 12.083-2.917L15 9.167l-4.166-4.166zM1.667 18.334l6.322-6.322' />
        <path d='M10.833 10.833a1.667 1.667 0 10-3.333 0 1.667 1.667 0 003.333 0z' />
      </g>
      <defs>
        <clipPath id='clip-pen-tool'>
          <path fill='none' d='M0 0H20V20H0z' transform='rotate(-90 10 10)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
