import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
      data-testid='icon_link-add'
    >
      <path
        strokeWidth='0.1'
        d='M6.36 10.357a.715.715 0 00.508-.208h0l3.28-3.28A.72.72 0 009.131 5.85l-3.28 3.28s0 0 0 0a.717.717 0 00.509 1.226zm0 0s0 0 0 0v-.05.05s0 0 0 0zm7.468-3.895h0l-2.586 2.593a.717.717 0 01-1.018 0m3.604-2.593l-3.604 1.576a.716.716 0 000 1.017m3.604-2.593l.002-.001A3.037 3.037 0 009.54 2.17h-.001l-.001.001-2.594 2.587h0a.72.72 0 001.018 1.017l2.586-2.586h0a1.598 1.598 0 012.263 0h0a1.59 1.59 0 010 2.262h0l-2.587 2.587 3.604-1.576zm-3.604 2.593s0 0 0 0l.036-.035-.036.036s0 0 0 0zm-4.773 3.756h0a1.597 1.597 0 01-2.263 0h0a1.591 1.591 0 010-2.262h0l2.587-2.587a.72.72 0 00-1.017-1.017h0L2.17 9.538h0l-.002.002a3.037 3.037 0 004.291 4.29h0l.002-.001 2.593-2.587h0a.72.72 0 00-1.017-1.017L5.45 12.81zm8.549-.86h-.617v-.618a.717.717 0 10-1.433 0v.617h-.617a.717.717 0 100 1.433h.617V14a.717.717 0 001.433 0v-.617H14a.717.717 0 000-1.433z'
      />
    </svg>
  );
}

export default Icon;
