import { ActionMeta, ID } from 'src/models/general.model';
import { HistoricalUploadsActions, ResourceType } from 'src/models/query.model';
import { VersionedPeriod } from 'src/models';
import { HistoricalUploadsQuery } from 'src/models/historicalUploads.model';
import { FormType } from 'src/models/form.model';

export function requestHistoricalUploadsInfo(payload: HistoricalUploadsQuery, meta?: ActionMeta) {
  return {
    type: HistoricalUploadsActions.GET,
    payload,
    meta,
  };
}

export function requestHistoricalUploadsLineItems(payload: HistoricalUploadsQuery, meta?: ActionMeta) {
  return {
    type: HistoricalUploadsActions.GET_UNSUCCESSFUL_ITEMS,
    payload,
    meta,
  };
}

export function requestMoreHistoricalUploadsLineItems(meta?: ActionMeta) {
  return {
    type: HistoricalUploadsActions.GET_MORE_UNSUCCESSFUL_ITEMS,
    meta,
  };
}

export function requestHistoricalUploadsLineItemsErrors(payload: HistoricalUploadsQuery, meta?: ActionMeta) {
  return {
    type: HistoricalUploadsActions.GET_ERRORS,
    payload,
    meta,
  };
}

export function requestMoreHistoricalUploadsLineItemsErrors(meta?: ActionMeta) {
  return {
    type: HistoricalUploadsActions.GET_MORE_ERRORS,
    meta,
  };
}

export interface RequestUpdateHistoricalLineItemProps extends FormType {
  id: ID;
  resource_id: ID;
  resource_type: ResourceType;
  period: VersionedPeriod;
  section: string;
}

export function requestUpdateHistoricalUploadsLineItems(
  payload: RequestUpdateHistoricalLineItemProps,
  meta?: ActionMeta,
) {
  return {
    type: HistoricalUploadsActions.UPDATE,
    payload,
    meta,
  };
}

export interface RequestUpdateHistoricalBillingProps extends FormType {
  id: ID;
  resource_id: ID;
  resource_type: ResourceType;
  period: VersionedPeriod;
}

export function requestUpdateHistoricalUploadsBilling(payload: RequestUpdateHistoricalBillingProps, meta?: ActionMeta) {
  return {
    type: HistoricalUploadsActions.UPDATE_BILLING,
    payload,
    meta,
  };
}
