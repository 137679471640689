import { createContext } from 'react';

import { Script } from 'src/models/scenario.model';

interface SpreadsheetHeaderType extends Script {
  fieldName: string;
  isTotals?: boolean;
}

export const HeaderContext = createContext({} as SpreadsheetHeaderType);
