import { Action, AlertMessageContent, MessageCode } from 'src/models/general.model';
import { AppActions } from 'src/models/interface.model';
import { UserActions } from 'src/models/query.model';
import { addMessage, openAlert, openError } from './app.reducer';

export function sendInfo(content: string): Action {
  return addMessage({ code: MessageCode.INFO, content });
}

export function sendWarning(content: string): Action {
  return addMessage({ code: MessageCode.WARNING, content });
}

export function sendError(content: string): Action {
  return addMessage({ code: MessageCode.ERROR, content });
}

export function alertInfo(content: AlertMessageContent): Action {
  return openAlert({ code: MessageCode.INFO, ...content });
}

export function alertWarning(content: AlertMessageContent): Action {
  return openAlert({ code: MessageCode.WARNING, ...content });
}

export function alertError(content: AlertMessageContent): Action {
  return openAlert({ code: MessageCode.ERROR, ...content });
}

export function showError(content: AlertMessageContent): Action {
  return openError({ ...content });
}

export const checkToken = (): Action => ({ type: UserActions.CHECK_TOKEN });

export const closeResizableWindow = (payload: string): Action => ({ type: AppActions.CLOSE_RESIZABLE_WINDOW, payload });

export const requestTimezones = () => ({ type: AppActions.GET_TIMEZONES });
