import { RootState } from '..';
import { createSelector } from '@reduxjs/toolkit';
import { Maybe } from 'src/models';

export const getCurrentSubprojectId = (state: RootState) => state.app.params.subId;

export const getCurrentMainProjectId = (state: RootState) => state.app.params.mainId;

export const getAppParams = (state: RootState) => state.app.params;

export const getIsUploadPopupOpen = (state: RootState) => state.app.isUploadPopupOpen;

export const getAlertMessage = (state: RootState) => state.app.alertMessage;

export const getErrorMessage = (state: RootState) => state.app.errorMessage;

export const getTimezones = (state: RootState) => state.app.timezones;

export const getUrlQuery = (state: RootState) => state.app.urlQuery;

export const getResizableWindowId = (state: RootState) => state.app.resizableWindowIds;

export const getIsSidebarOpen = (state: RootState) => state.app.isSidebarOpen;

export const getSignUpInviteId = createSelector(
  [getUrlQuery],
  (urlQuery): Maybe<string> => urlQuery.get('invite_id') ?? undefined,
);
