import { ID, UiScenariosActions } from 'src/models';
import { FormScenarioType } from 'src/models/uiScenarios.model';

interface RequestFormScenarioProps {
  form_type: FormScenarioType;
  project_id?: ID;
}

export const requestFormScenario = (payload?: RequestFormScenarioProps) => ({
  type: UiScenariosActions.GET_FORM,
  payload,
});
