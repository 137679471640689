import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_download'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M7 10l5 5 5-5' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 15V3' />
    </svg>
  );
}

export default Icon;
