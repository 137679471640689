import * as React from 'react';

import { ResizableWindowContext } from 'src/contexts/resizableWindow.context';

export function Backdrop() {
  const { closeWindow, isActive } = React.useContext(ResizableWindowContext);

  function handleClose() {
    closeWindow();
  }

  if (!isActive) return null;

  return <div data-testid='backdrop' className='backdrop' onClick={handleClose} />;
}
