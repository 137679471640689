import * as React from 'react';
import clsx from 'clsx';

import { InputNumber, InputNumberProps } from '../Number';
import { UseIcon } from 'src/components/UseIcon';

import classes from './NumberWithButtons.module.scss';

export const NumberWithButtons = React.forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const { value, min, max } = props;

  function round(number: number, decimals: number): number {
    const arg = Number(number + 'e+' + decimals);

    return Number(Math.round(arg) + 'e-' + decimals);
  }

  function handleOnDecrement() {
    if (typeof value === 'number') {
      let nextValue = typeof min === 'number' ? Math.max(min, value - 1) : value - 1;

      if (props.numeralDecimalScale) nextValue = round(nextValue, props.numeralDecimalScale);

      props.onChange(nextValue);
    }
  }

  function handleOnIncrement() {
    if (typeof value === 'number') {
      let nextValue = typeof max === 'number' ? Math.min(max, value + 1) : value + 1;

      if (props.numeralDecimalScale) nextValue = round(nextValue, props.numeralDecimalScale);

      props.onChange(nextValue);
    }
  }

  const isDisabledDecrement = typeof value !== 'number' || (typeof min === 'number' && value <= min);

  const isDisabledIncrement = typeof value !== 'number' || (typeof max === 'number' && value >= max);

  return (
    <div className={classes.container}>
      <UseIcon
        className={classes.icon}
        iconId='minus'
        wrapElement='button'
        onClick={handleOnDecrement}
        isDisabled={isDisabledDecrement || props.isDisabled}
      />
      <InputNumber ref={ref} {...props} className={clsx(classes.input, props.className, 'w-100p h-100p')} />
      <UseIcon
        className={classes.icon}
        iconId='add'
        wrapElement='button'
        onClick={handleOnIncrement}
        isDisabled={isDisabledIncrement || props.isDisabled}
      />
    </div>
  );
});
