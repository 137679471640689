import { AttachmentsContext, ID } from './general.model';
import { Period } from './results.model';

export interface Picture {
  id: ID;
  title: string;
  url: string;
  preview_url: string;
  meta: {
    content_type: string;
    filename: string;
    size: string;
    taken_at?: Date;
  };
  custom_location: string;
  tags: string[];
  included_in_pay_application?: boolean;
  created_at?: Date;
  user: string;
  comment_threads?: ID[];
  has_comments?: boolean;
  has_drawings?: boolean;
}

export interface RequestDocumentsProps {
  resource_id: ID;
  resource_type: string; // TODO: switch to enum
  context?: AttachmentsContext;
  period?: Period;
}

export interface RequestDocumentProps {
  resource_id: ID;
  resource_type: string; // TODO: switch to enum
  context?: AttachmentsContext;
  period?: Period;
}

export enum PictureViewMode {
  EDIT = 'edit',
  VIEW = 'view',
  COMMENT = 'comment',
}

export interface PictureDrawing {
  picture_id: ID;
  content?: string;
}

export interface PictureTagsUpdateAction {
  pictureId: ID;
  tags: string[];
}
