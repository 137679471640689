import * as React from 'react';
import { t } from 'src/tools/text.tools';
import { PlainButton, PlainButtonProps } from '../PlainButton';

const TXT = t(['subNavbar', 'buttons']);

// new-ui
export function ActionsPlainButton(props: PlainButtonProps) {
  return <PlainButton isSmall label={TXT('actions')} rightIconId='chevron-down' {...props} />;
}
