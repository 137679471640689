import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='55'
      height='68'
      fill='none'
      viewBox='0 0 55 68'
      data-testid='icon_logo-trademark'
      stroke='none'
    >
      <path
        stroke='none'
        d='M40.741 63.152c0-.752.173-1.45.52-2.095.35-.645.832-1.151 1.447-1.52a3.808 3.808 0 012-.559c.715 0 1.38.187 1.995.56.618.368 1.1.875 1.447 1.519.35.644.525 1.343.525 2.095 0 .741-.17 1.432-.509 2.073a3.996 3.996 0 01-1.436 1.542 3.745 3.745 0 01-2.022.575c-.734 0-1.41-.192-2.028-.575a4.01 4.01 0 01-1.43-1.542 4.366 4.366 0 01-.509-2.073zm.475 0c0 .667.155 1.287.464 1.86.313.574.74 1.026 1.28 1.358a3.33 3.33 0 001.748.492 3.33 3.33 0 001.749-.492 3.466 3.466 0 001.273-1.358c.31-.577.464-1.197.464-1.86 0-.656-.15-1.267-.452-1.833a3.486 3.486 0 00-1.269-1.352 3.284 3.284 0 00-1.765-.502c-.626 0-1.21.165-1.754.497-.54.328-.965.776-1.274 1.346a3.786 3.786 0 00-.464 1.844zm2.587.352v1.927h-.548v-4.687h1.442c.547 0 .968.115 1.262.346.295.227.442.574.442 1.04 0 .495-.263.862-.788 1.1.268.097.455.248.559.453.104.2.156.443.156.726s.008.488.022.614c.015.127.04.233.073.319v.09h-.57c-.052-.135-.078-.474-.078-1.017 0-.332-.07-.567-.212-.704-.138-.138-.375-.207-.71-.207h-1.05zm0-.508h1.011c.302 0 .55-.079.743-.235a.752.752 0 00.296-.62c0-.317-.082-.542-.245-.676-.16-.138-.438-.21-.833-.218h-.972v1.748zM51.915 40.748L27.313 65.35 2.645 40.748l5.225-5.224 5.158 5.158.397.397 7.01 7.01.397.397 5.754 5.687.661.661 15.079-15.011 4.43-4.366 5.16 5.29zm-37.564-1.389l-5.158-5.158 18.12-18.121L39.02 27.72 27.313 39.425l-6.48-6.547-6.482 6.481zm1.323 1.257l5.158-5.159 6.481 6.547 13.029-12.962 5.158 5.159-3.836 3.835-14.35 14.22-5.093-5.093-6.547-6.547zM2.645 27.653L27.313 2.985l24.602 24.668-5.092 5.225-5.159-5.159-.66-.66-.662-.662L27.909 14.03l-.596-.595L8.994 31.753 7.87 32.878l-5.225-5.225zm45.5 6.548l6.416-6.548L27.313.34 0 27.653l6.547 6.548L0 40.748l27.313 27.247 27.248-27.247-6.415-6.547z'
      />
      <path
        stroke='none'
        d='M15.674 40.616l5.158-5.159 6.481 6.547 13.029-12.962 5.158 5.159-3.836 3.835-14.35 14.22-5.093-5.093-6.547-6.547z'
      />
    </svg>
  );
}

export default Icon;
