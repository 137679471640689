import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_filter'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M3 7V5a2 2 0 012-2h14a2 2 0 012 2v2M3 7l7 7v7l4-2v-5l7-7M3 7h18'
      />
    </svg>
  );
}

export default Icon;
