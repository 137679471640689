import { DisplayAs, Maybe } from 'src/models/general.model';
import { Meta, PageQuery } from 'src/models/results.model';
import { Trade } from 'src/models/trade.model';
import { ShowScenario } from 'src/models';
import { Permission } from 'src/models/permissions.model';

export interface TradesState {
  trades?: Maybe<Trade[]>;
  query?: Maybe<PageQuery>;
  trade?: Maybe<Trade>;
  displayAs: DisplayAs;
  meta?: Maybe<Meta>;
  scenario?: Maybe<ShowScenario>;
  permissions?: Maybe<Permission[]>;
}

export const INITIAL_TRADES_STATE: TradesState = {
  displayAs: DisplayAs.CARDS,
};
