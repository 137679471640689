import { applyMiddleware, createStore, Middleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import thunk from 'redux-thunk';

import 'src/services/query.service';

import AppReducer from './app/app.reducer';
import ChangeOrdersReducer from './change_orders/change_orders.reducer';
import CompaniesReducer from './companies/companies.reducer';
import ContractTermsReducer from './contract_terms/contract_terms.reducer';
import FilesReducer from './files/files.reducer';
import MainContractReducer from './mainContract/mainContract.reducer';
import RetainageReducer from './retainage/retainage.reducer';
import TeamReducer from './team/team.reducer';
import TradesReducer from './trades/trades.reducer';
import UserReducer from './user/user.reducer';
import AttachmentsReducer from './attachments/attachments.reducer';
import ManagerReqReducer from './managerReq/managerReq.reducer';
// new-ui
import LoadingReducer from './loading/loading.reducer';
import PortfolioReducer from './portfolio/portfolio.reducer';
import SubProjectReducer from './subProject/subProject.reducer';
import ReqDetailReducer from './reqDetail/reqDetail.reducer';
import ReqSummaryReducer from './reqSummary/reqSummary.reducer';
import SignaturesReducer from './signatures/signatures.reducer';
import RequiredDocumentsReducer from './requiredDocuments/requiredDocuments.reducer';
import AttributeChangesReducer from './attributeChanges/attributeChanges.reducer';
import NotificationsReducer from './notifications/notifications.reducer';
import PredefinedListsReducer from './predefinedLists/predefinedLists.reducer';
import RolesReducer from './roles/roles.reducer';
import UiScenariosReducer from './uiScenarios/uiScenarios.reducer';
import LevelsReducer from './levels/levels.reducer';
import HistoricalUploadsReducer from './historicalUploads/historicalUploads.reducer';

import {
  appMiddleware,
  socketMiddleware,
  socketQueryMiddleware,
  uploadMiddleware,
  filesMiddleware,
} from './middleware';
import { apiMiddleware } from './middleware/api.middleware';

const isDev = (): boolean => process.env.NODE_ENV === 'development';

const disableLogger = (): boolean => Boolean(process.env.REACT_APP_DISABLE_REDUX_LOGGER);

const logger = createLogger({
  collapsed: true,
  duration: true,
  timestamp: true,
});

// TODO: find the issue with type
const middlewares: unknown[] = [
  apiMiddleware,
  appMiddleware,
  socketMiddleware,
  socketQueryMiddleware,

  thunk,
  uploadMiddleware,
  filesMiddleware,
];

const middleware = isDev()
  ? composeWithDevTools(applyMiddleware(...(disableLogger() ? [] : [logger]), ...(middlewares as Middleware[])))
  : applyMiddleware(...(middlewares as Middleware[]));

const reducers = combineReducers({
  app: AppReducer,
  attachments: AttachmentsReducer,
  attributeChanges: AttributeChangesReducer,
  changeOrders: ChangeOrdersReducer,
  companies: CompaniesReducer,
  contractTerms: ContractTermsReducer,
  files: FilesReducer,
  historicalUploads: HistoricalUploadsReducer,
  levels: LevelsReducer,
  loading: LoadingReducer,
  mainContract: MainContractReducer,
  managerReq: ManagerReqReducer,
  notifications: NotificationsReducer,
  portfolio: PortfolioReducer,
  predefinedLists: PredefinedListsReducer,
  reqDetail: ReqDetailReducer,
  reqSummary: ReqSummaryReducer,
  requiredDocuments: RequiredDocumentsReducer,
  retainage: RetainageReducer,
  roles: RolesReducer,
  signatures: SignaturesReducer,
  subProject: SubProjectReducer,
  team: TeamReducer,
  trades: TradesReducer,
  uiScenarios: UiScenariosReducer,
  user: UserReducer,
});

const store = createStore(reducers, {}, middleware);

type RootState = ReturnType<typeof store.getState>;

export type { RootState };

export { reducers, store };
