import { processError } from 'src/tools/events.tools';

import { ProcessProps, QueryDomain, QueryAction, Response, ResponsePayload, Action, ID } from 'src/models';
import { setCommentThreads, addCommentThread } from 'src/store/attachments/attachments.reducer';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { CommentThread } from 'src/models/commentThread.model';
import { RequestCommentThreadProps, PostCommentThreadProps } from 'src/store/attachments/attachments.action';

function processCommentThread({ dispatch, getService }: ProcessProps) {
  const processCommentThreadsRequest = (payload: RequestCommentThreadProps) => () => {
    dispatch(setCustomIsLoading({ id: 'comments', state: true }));

    getService().request({ [QueryDomain.COMMENT_THREADS]: QueryAction.GET, payload }, (response: Response<string>) => {
      if (response.isOK) {
        const data = deserialize<ResponsePayload<CommentThread[]>>(response.payload);

        dispatch(setCommentThreads(data?.data));
      } else {
        processError({
          activityName: 'Request to get comment threads',
          response,
        });
      }

      dispatch(setCustomIsLoading({ id: 'comments', state: false }));
    });
  };

  const processSingleCommentThreadRequest = (payload: ID) => () => {
    dispatch(setCustomIsLoading({ id: 'comments', state: true }));

    getService().request({ [QueryDomain.COMMENT_THREADS]: QueryAction.GET, payload }, (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<CommentThread>>(response.payload);

        if (result?.data) {
          dispatch(addCommentThread({ data: result.data }));
        }
      } else {
        processError({
          activityName: 'Request to get single comment thread',
          response,
        });
      }

      dispatch(setCustomIsLoading({ id: 'comments', state: false }));
    });
  };

  const processCommentThreadPost = (action: Action<PostCommentThreadProps>) => () => {
    dispatch(setCustomIsUpdating({ id: 'comments', state: true }));

    getService().request(
      {
        [QueryDomain.COMMENT_THREADS]: QueryAction.POST,
        payload: action.payload,
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<CommentThread>>(response.payload);

          if (result?.data) {
            const { resource_id, resource_type } = action.payload ?? {};

            dispatch(addCommentThread({ data: result.data, resource_id, resource_type }));

            if (action.meta?.callback) action.meta.callback(result.data);
          }
        } else {
          processError({
            activityName: 'Request to post comment thread',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'comments', state: false }));
      },
    );
  };

  return { processCommentThreadsRequest, processSingleCommentThreadRequest, processCommentThreadPost };
}

export { processCommentThread };
