import { createConsumer, Cable, Channel } from 'actioncable-js-jwt';

import { Maybe, RecordObject } from 'src/models';
import { getCleanToken } from './storage.service';
import { productionLog } from 'src/tools/log.tools';
import {
  ApiSubscriptionCallbackFn,
  ApiWsMessage,
  ApiWsReceivingFn,
  BehaviorMessage,
} from 'src/models/connection.model';
import { NotificationMessage } from 'src/models/notification.model';

let instance: Maybe<SocketConnection>;

// direct ws connection to API
class SocketConnection {
  #cable: Cable;

  #mode: string;

  #channels: RecordObject<Channel> = {};

  static createInstance(mode: string, subscription: ApiWsReceivingFn[]): SocketConnection {
    instance = new SocketConnection(mode, subscription);

    return instance;
  }

  static getInstance(): Maybe<SocketConnection> {
    return instance;
  }

  static socketUrl(): string {
    let baseUrl;

    if (typeof window !== 'undefined' && typeof window.__ENVIRONMENT__ !== 'undefined') {
      baseUrl = window.__ENVIRONMENT__.REACT_APP_API_BASE_URL;
    } else {
      baseUrl = process.env.REACT_APP_API_BASE_URL;
    }

    const isSsl = baseUrl && !baseUrl.includes('localhost');

    return `${isSsl ? 'wss' : 'ws'}://${baseUrl}/cable` || '';
  }

  constructor(mode: string, subscriptions: ApiWsReceivingFn[]) {
    this.#mode = mode;

    this.#cable = createConsumer(SocketConnection.socketUrl(), getCleanToken());

    for (const subscription of subscriptions) {
      const { channel, payload, fn } = subscription;

      this.#channels = {
        ...(this.#channels ?? {}),
        [channel]: this.#cable.subscriptions.create({ channel, ...payload }, this._createChannel(channel, fn)),
      };
    }

    this.#cable.ensureActiveConnection();
  }

  addSubscription(subscription: ApiWsReceivingFn) {
    const { channel, payload, fn } = subscription;

    this.#channels = {
      ...(this.#channels ?? {}),
      [channel]: this.#cable.subscriptions.create({ channel, ...payload }, this._createChannel(channel, fn)),
    };
  }

  removeSubscription(channel: string) {
    if (this.#channels[channel]) this.#cable.subscriptions.remove(this.#channels[channel]);
  }

  private _createChannel(
    channel: string,
    // TODO: abstract
    fn: ApiSubscriptionCallbackFn<BehaviorMessage> | ApiSubscriptionCallbackFn<ApiWsMessage<NotificationMessage>>,
  ) {
    return {
      connected: this._connected(channel),
      disconnected: this._disconnected(channel),
      received: fn,
    };
  }

  private _connected(channel: string) {
    return () => {
      productionLog(`direct socket is connected to ${channel} (${this.#mode})`);
    };
  }

  private _disconnected(channel: string) {
    return () => {
      productionLog(`direct socket is disconnected from ${channel} (${this.#mode})`);
    };
  }

  public disconnect(): void {
    return this.#cable.disconnect();
  }
}

export { SocketConnection };
