import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='72'
      height='72'
      fill='none'
      viewBox='0 0 72 72'
      data-testid='icon_xls'
    >
      <g clipPath='url(#clip0)'>
        <path
          fill='#fff'
          d='M66.283 5.17h-46.16a2.88 2.88 0 00-2.879 2.88v55.779a2.88 2.88 0 002.88 2.88h46.16a2.88 2.88 0 002.879-2.88V8.049a2.88 2.88 0 00-2.88-2.88z'
          opacity='0.12'
        />
        <path fill='#21A366' d='M44.168 5.17h-24.04a2.858 2.858 0 00-2.857 2.88v12.499h26.897V5.169z' />
        <path fill='#33C481' d='M66.336 5.17H44.174v15.379h24.978v-12.5a2.859 2.859 0 00-2.816-2.88z' />
        <path fill='#107C41' d='M69.173 35.928h-25v15.38h25v-15.38z' />
        <path
          fill='#185C37'
          d='M44.168 51.307V35.928H17.271v27.88a2.858 2.858 0 002.88 2.879h46.18a2.858 2.858 0 002.88-2.88v-12.5H44.167z'
        />
        <path fill='#107C41' d='M44.164 20.55h-26.92v15.379h26.92v-15.38z' />
        <path fill='#21A366' d='M69.173 20.55h-25v15.379h25v-15.38z' />
        <g style={{ mixBlendMode: 'multiply' }} opacity='0.24'>
          <path fill='url(#pattern0)' d='M0.677 18.053H38.048V54.912000000000006H0.677z' />
          <path
            fill='#fff'
            d='M33.577 18.63H4.717a2.88 2.88 0 00-2.88 2.879v28.86a2.88 2.88 0 002.88 2.88h28.86a2.88 2.88 0 002.88-2.88V21.51a2.88 2.88 0 00-2.88-2.88z'
            opacity='0.24'
          />
        </g>
        <path
          fill='#107C41'
          d='M33.594 18.63H4.734a2.88 2.88 0 00-2.88 2.879v28.86a2.88 2.88 0 002.88 2.88h28.86a2.88 2.88 0 002.88-2.88V21.51a2.88 2.88 0 00-2.88-2.88z'
        />
        <path
          fill='#fff'
          d='M10.872 45.293l5.994-9.364-5.567-9.365h4.48l3.028 5.973c.277.555.47.981.576 1.258.192-.447.405-.895.619-1.322l3.242-5.909h4.117l-5.674 9.365 5.844 9.449h-4.266l-3.52-6.548a5.597 5.597 0 01-.405-.875 4.115 4.115 0 01-.405.853l-3.797 6.485h-4.266z'
        />
        <path
          style={{ mixBlendMode: 'soft-light' }}
          fill='url(#paint0_linear)'
          d='M33.577 18.63H4.717a2.88 2.88 0 00-2.88 2.879v28.86a2.88 2.88 0 002.88 2.88h28.86a2.88 2.88 0 002.88-2.88V21.51a2.88 2.88 0 00-2.88-2.88z'
          opacity='0.5'
        />
      </g>
      <defs>
        <pattern id='pattern0' width='1' height='1' patternContentUnits='objectBoundingBox'>
          <use transform='scale(.01351 .0137)' xlinkHref='#image0' />
        </pattern>
        <linearGradient
          id='paint0_linear'
          x1='7.853'
          x2='30.442'
          y1='16.368'
          y2='55.489'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' stopOpacity='0.5' />
          <stop offset='1' stopOpacity='0.7' />
        </linearGradient>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0H72V72H0z' />
        </clipPath>
        <image
          id='image0'
          width='74'
          height='73'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABJCAYAAACaRLDfAAAACXBIWXMAAC4jAAAuIwF4pT92AAABy0lEQVR4Xu3cMVLCUBSF4f8ylKI7EHvXoDuwdwOuTXtXIKyB3rgDwZpncfMMJOCcmnf+hglkmMk3ScoTpRRqERHAAlgCV0DQRgX4ATpgVw5R+qJ+1yNdA4/AM4nVElQHvAErYDvGmsME6QV46I9b6p58mgBWEXGENe8/FwxIjyRSK3dT7Ya89toHsK0H84iYkY/ZM8Od1BoS5DVfkwY74DMiNqWUPcCsP6m+wFtFqlWsJcNjCAxQ9SSXTSwOodw/GUrMUGKGEjOUmKHEDCVmKDFDiRlKzFBihhIzlJihxAwlZigxQ4kZSsxQYoYSM5SYocQMJWYoMUOJGUrMUGKGEjOUmKHEDCVmKDFDiRlKzFBihhIzlJihxAwlZigxQ4kZSsxQYoYSM5SYocQMJWYoMUOJGUrMUGKGEjOUmKHEDCVmKDFDiRlK7BBqMi7VcBOLCrUjh6a2p05qqEIadKTJX7N+9qeuca2Bb2A//ocG2pPXviYtujqJBMPQ1o6cLIPcervnxDTQBVefqA3wSloc3VHjjbsFcEdCPQG3XP5cUgG+gHcS6pMTg4AxOqZfKFuQSC0sJ9bFxC8S6ORrZwL190OCNdM5oNpZKHfcL4k/bZY4tSNlAAAAAElFTkSuQmCC'
        />
      </defs>
    </svg>
  );
}

export default Icon;
