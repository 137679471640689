import { processError } from 'src/tools/events.tools';

import { Action, ButtonBaseType, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain, ResourceType } from 'src/models/query.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { sendInfo } from '../store/app/app.actions';
import { LineItemForTransfer } from 'src/models/lineItem.model';
import { requestBaseContractData } from '../store/managerReq/managerReq.actions';
import { requestReqDetailBaseContract } from '../store/reqDetail/reqDetail.actions';

export function processLineItemsTransfers({ dispatch, getService }: ProcessProps) {
  const processGetLineItem = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'lineItemsTransfers', state: true }));

    getService().request(
      { [QueryDomain.LINE_ITEMS_TRANSFERS]: QueryAction.GET, payload: { params: action.payload } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<LineItemForTransfer>>(response.payload);

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request line item for transfer',
            callbackFn: action.meta?.callback,
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'lineItemsTransfers', state: false }));
      },
    );
  };

  const processCreateLineItem = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'lineItemsTransfers', state: true }));

    getService().request(
      {
        [QueryDomain.LINE_ITEMS_TRANSFERS]: QueryAction.CREATE,
        payload: {
          data: action.payload,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<LineItemForTransfer>>(response.payload);

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });

          if (action.payload.resource_type === ResourceType.MAIN_CONTRACT) dispatch(requestBaseContractData());
          else dispatch(requestReqDetailBaseContract(undefined, { isValid: true }));
        } else {
          processError({
            activityName: 'Request to create line item for transfer',
            callbackFn: action.meta?.callback,
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'lineItemsTransfers', state: false }));
      },
    );
  };

  const processCreateTransfer = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'lineItemsTransfers', state: true }));

    getService().request(
      {
        [QueryDomain.LINE_ITEMS_TRANSFERS]: QueryAction.POST,
        payload: {
          data: action.payload,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(sendInfo('Transfer has been made.'));

          if (action.payload.resource_type === ResourceType.MAIN_CONTRACT) dispatch(requestBaseContractData());
          else dispatch(requestReqDetailBaseContract(undefined, { isValid: true }));
        } else {
          processError({
            activityName: 'Request to create line item transfer',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'lineItemsTransfers', state: false }));
      },
    );
  };

  const processLineItemAutocomplete = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'lineItemsTransfersAutocomplete', state: true }));

    getService().request(
      {
        [QueryDomain.LINE_ITEMS_TRANSFERS]: QueryAction.AUTOCOMPLETE,
        payload: {
          params: action.payload,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ButtonBaseType[]>>(response.payload);

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request line items autocomplete for transfer',
            callbackFn: action.meta?.callback,
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'lineItemsTransfersAutocomplete', state: false }));
      },
    );
  };

  return {
    processGetLineItem,
    processCreateLineItem,
    processCreateTransfer,
    processLineItemAutocomplete,
  };
}
