import { Action, ActionMeta, ID, InputAddUser, InputInviteUser, ResourceType, TeamActions } from 'src/models';

// new-ui
export const requestUserRoles = (resource_id: ID, resource_type: string) => ({
  type: TeamActions.GET_ROLES,
  payload: { resource_id, resource_type },
});

interface CreateTeamMembersData {
  team_members: Omit<InputAddUser, 'details'>[];
  invites: InputInviteUser[];
}

export const createTeamMembers = (
  resource_id: ID,
  resource_type: string,
  data: CreateTeamMembersData,
  meta?: ActionMeta,
) => ({
  type: TeamActions.CREATE,
  payload: { resource_id, resource_type, data },
  meta,
});

export interface UpdateTeamMemberProps {
  team_user_id: ID;
  linked?: boolean;
  role_id?: ID;
}

export const updateTeamMember = (payload: UpdateTeamMemberProps) => ({
  type: TeamActions.UPDATE_MEMBER,
  payload,
});

export const resendTeamInvite = (email: string) => ({
  type: TeamActions.RESEND_INVITE,
  payload: { email },
});

export const cancelTeamInvite = (team_invite_id: ID, meta?: ActionMeta) => ({
  type: TeamActions.CANCEL_INVITE,
  payload: { team_invite_id },
  meta,
});

export interface UpdateTeamInviteProps {
  team_invite_id: ID;
  role_id: ID;
}

export const updateTeamInvite = (payload: UpdateTeamInviteProps) => ({
  type: TeamActions.UPDATE_INVITE,
  payload,
});

export const requestTeam = (resource_id: ID, resource_type: ResourceType, meta?: ActionMeta): Action => ({
  type: TeamActions.GET,
  payload: { resource_id, resource_type },
  meta,
});
