import { MouseEvent } from 'react';
import clsx from 'clsx';

import { Author } from 'src/models/user.model';
import { ifTrue } from 'src/tools/logic.tools';
import { getFirstLetter } from 'src/tools/string.tools';
import { UseIcon } from '../UseIcon';

import classes from './Avatar.module.scss';

interface Props {
  className?: string;
  author?: Author;
  isDisabled?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export function Avatar({ className, author, isDisabled, onClick }: Props) {
  function getContent() {
    if (!author) return <UseIcon className={classes.icon} iconId='logo' />;

    if (author.photo_url)
      return (
        <img
          className={clsx(classes.image, ifTrue(isDisabled, classes['image-disabled']))}
          src={author.photo_url}
          alt={getFirstLetter(author.full_name)}
        />
      );

    return getFirstLetter(author.full_name);
  }

  return (
    <div
      className={clsx(classes.container, className, ifTrue(isDisabled, classes.disabled))}
      title={author?.full_name}
      onClick={onClick}
    >
      {getContent()}
    </div>
  );
}
