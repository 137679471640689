import { io, Socket } from 'socket.io-client';
import { Dispatch } from '@reduxjs/toolkit';
import serialize from 'serialize-javascript';

import { logAlpha, logInfo, logUnableToProceed, productionLog } from 'src/tools/log.tools';
import { getBaseUrlWithHttp } from 'src/tools/global.tools';
import { deserialize } from 'src/tools/string.tools';
import { getToken } from './storage.service';

import { Action, AnyValue, Maybe, Response } from 'src/models/general.model';

let instance: QueryClass | undefined;

class QueryClass {
  static dispatch: Dispatch | undefined;

  public static instance(dispatch?: Dispatch, asNew?: boolean): QueryClass {
    if (!QueryClass.dispatch && !!dispatch) QueryClass.dispatch = dispatch;

    if (asNew) instance = undefined;

    if (!instance) instance = new QueryClass();

    return instance;
  }

  #service: Socket | undefined;

  constructor() {
    productionLog('initiating connection ⥂');
    this.#service = this._connect();

    productionLog('connection ►', this.#service.io._readyState);

    if (this.#service) {
      const service = this.#service as Socket;

      service.on('connect', () => {
        productionLog('connection ►', this.#service?.io._readyState || 'failed');
      });

      service.on('data', this._processIncomingData);
      service.on('welcome', (data: Response<string>) => {
        if (data && data.isOK) {
          productionLog('connection ►', 'ready');
          this._dispatch({ type: 'app/setServerConfig', payload: deserialize(data.payload, undefined) });
        } else {
          productionLog('connection ►', `failed. ${data.message}`);
        }
      });
    }
  }

  private _processIncomingData(response: string) {
    try {
      // TODO: provide detailed typings
      const result = deserialize<Maybe<AnyValue>>(response, undefined);

      if (result?.payload?.data && result?.payload?.domain && result?.payload?.action) {
        const data = deserialize<Maybe<AnyValue>>(result.payload.data, undefined);

        logInfo('socket data received', data);
        // if (QueryClass.dispatch)
        //   QueryClass.dispatch(DISPATCH_FNS[result.payload.domain][result.payload.action](data));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  private _dispatch(props: Action) {
    if (QueryClass.dispatch) QueryClass.dispatch(props);
  }

  private _connect() {
    return io(getBaseUrlWithHttp(), {
      transports: ['websocket'],
    });
  }

  public request(
    data: Record<string, unknown | Record<string, unknown>>,
    fn: (arg0: Response<AnyValue>) => void,
  ): void {
    if (this.#service) {
      this.#service.emit(
        'query',
        serialize({ ...data, payload: { ...(data.payload as Record<string, unknown>), token: getToken() } }),
        (response: Response<string>) => {
          if (typeof response === 'string') fn(JSON.parse(response));
          else fn(response);
        },
      );
    } else logUnableToProceed('request', 'service is not available');
  }

  public reports(data: Record<string, unknown>, fn: (arg0: Response<unknown>) => void): void {
    if (this.#service)
      this.#service.emit(
        'reports',
        { ...data, payload: { ...(data.payload as Record<string, unknown>), token: getToken() } },
        (response: Response<unknown>) => {
          logAlpha('Response to reports request:', response);
          fn(response);
        },
      );
    else logUnableToProceed('request', 'service is not available');
  }
}

export { QueryClass };
