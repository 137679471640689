/* eslint-disable max-lines */
// TODO: split this process into 2
import { processError } from 'src/tools/events.tools';

import { ProcessProps, QueryAction, Response, QueryDomain, Action } from 'src/models';
import { sendInfo } from 'src/store/app/app.actions';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';

function processOnboarding({ dispatch, getService }: ProcessProps) {
  const processCreateOnboarding = (action: Action) => async () => {
    getService().request(
      { [QueryDomain.ONBOARDING]: QueryAction.CREATE, payload: action.payload },
      (response: Response<string>) => {
        if (response.isOK) dispatch(sendInfo('Success. An email invitation was sent to the user.'));
        else {
          processError({
            activityName: 'Request onboarding',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });

        dispatch(setCustomIsLoading({ id: 'onboarding', state: false }));
      },
    );
  };

  return {
    processCreateOnboarding,
  };
}

export { processOnboarding };
