import { processError } from 'src/tools/events.tools';
import { deserialize } from 'src/tools/string.tools';

import { Action, Maybe, ProcessProps, Response } from 'src/models/general.model';
import { QueryAction, QueryDomain } from 'src/models/query.model';
import { Results } from 'src/models/results.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { setSignatures, addSignature, deleteSignature } from 'src/store/signatures/signatures.reducer';
import { Signature } from 'src/models';

function processSignatures({ dispatch, getService }: ProcessProps) {
  const processSignaturesRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'signatures', state: true }));

    getService().request({ [QueryDomain.SIGNATURES]: QueryAction.GET }, (response: Response<string>) => {
      dispatch(setCustomIsLoading({ id: 'signatures', state: false }));

      if (response.isOK) {
        const result = deserialize<Maybe<Results<Signature[]>>>(response.payload, undefined);

        const data = result?.data ?? [];

        dispatch(setSignatures(data));

        if (action.meta?.callback) action.meta.callback();
      } else {
        processError({
          activityName: 'Request signatures',
          callbackFn: action.meta?.callback,
          response,
        });
      }
    });
  };

  const processSignatureCreateRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'signatures', state: true }));

    getService().request(
      { [QueryDomain.SIGNATURES]: QueryAction.CREATE, payload: action.payload },
      (response: Response<string>) => {
        dispatch(setCustomIsLoading({ id: 'signatures', state: false }));

        if (response.isOK) {
          const result = deserialize<Maybe<Results<Signature>>>(response.payload, undefined);

          if (result?.data) {
            dispatch(addSignature(result?.data));
          }

          if (action.meta?.callback) action.meta.callback();
        } else {
          processError({
            activityName: 'Request to create signature',
            callbackFn: action.meta?.callback,
            response,
          });
        }
      },
    );
  };

  const processSignatureDeleteRequest = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'signatures', state: true }));

    getService().request(
      { [QueryDomain.SIGNATURES]: QueryAction.DELETE, payload: action.payload },
      (response: Response<string>) => {
        dispatch(setCustomIsLoading({ id: 'signatures', state: false }));

        if (response.isOK) {
          dispatch(deleteSignature(action.payload.signature_id));

          if (action.meta?.callback) action.meta.callback();
        } else {
          processError({
            activityName: 'Request to delete signature',
            callbackFn: action.meta?.callback,
            response,
          });
        }
      },
    );
  };

  return { processSignaturesRequest, processSignatureCreateRequest, processSignatureDeleteRequest };
}

export { processSignatures };
