import { ID } from './general.model';

export interface ContractTerms extends Record<string, unknown> {
  id: ID;
  name: string;
  base: string;
  applied: number;
  included?: string[];
  deadband?: number;
  resource_type: ContractTerm;
  resource_id: ID;
}

export enum ContractTerm {
  MAIN_CONTRACT = 'MainContract',
  TRADE_CONTRACT = 'TradeContract',
}

export enum AppliedTerms {
  GC = 'gc',
  BOND = 'bond',
  TAX = 'tax',
  FEE = 'fee',
  INSURANCE = 'insurance',
  OTHER = 'other',
}
