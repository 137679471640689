import { RecordObject } from './general.model';

export interface AttachmentFile {
  title: string;
  type: string;
}

export interface QueueItem<T> {
  id: string;
  payload: T;
  progress?: number;
}

export interface AnnotationsPayload {
  annotations: RecordObject;
  id: string;
}

export interface Progress {
  id: string;
  progress: number;
}

export enum BatchUploadActions {
  UPDATE_BATCH = 'updateBatch',
  REMOVE_UPLOAD = 'removeUpload',
  RELOAD = 'reload',
  CANCEL_ALL = 'cancelAll',
}

export interface ServiceInfo {
  service: string;
  params?: Record<string, string>;
  index?: string[];
}
