import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_paperclip'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M18.199 9.208l-7.659 7.659a5.003 5.003 0 11-7.075-7.075l7.659-7.659A3.335 3.335 0 0115.84 6.85l-7.666 7.658a1.667 1.667 0 11-2.359-2.358l7.075-7.067'
      />
    </svg>
  );
}

export default Icon;
