import { AnyValue, Result } from 'src/models';

export function success<T = AnyValue>(payload?: T): Result<T> {
  if (payload) return { isOK: true, payload };

  return { isOK: true };
}

export function fail(message?: string): Result {
  if (message) return { isOK: false, message };

  return { isOK: false };
}
