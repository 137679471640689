import clsx from 'clsx';

import { CONFIG } from 'src/config';

import classes from './NotificationsCounter.module.scss';

export interface Props {
  className?: string;
  value: number;
  max?: number;
}

// new-ui
export function NotificationsCounter({ value, className, max = CONFIG.maxNotificationsCounter }: Props) {
  if (!value) return <></>;

  const displayValue = value > max ? `${max}+` : value.toString();

  return (
    <div data-testid='notifications-counter' className={clsx(classes.container, className)}>
      {displayValue}
    </div>
  );
}
