import React from 'react';

function Icon() {
  return (
    <svg
      data-testid='icon_chevrons-right'
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      fill='none'
      viewBox='0 0 21 20'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M11.333 14.167L15.5 10l-4.167-4.167'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M5.5 14.167L9.667 10 5.5 5.833'
      />
    </svg>
  );
}

export default Icon;
