import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_upload-cloud-default'
      width='32'
      height='32'
      fill='none'
      stroke='var(--black-lighter)'
      viewBox='0 0 32 32'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21.333 21.333L15.999 16l-5.333 5.333M16 16v12'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M27.187 24.52A6.666 6.666 0 0024 12h-1.68a10.665 10.665 0 00-20.823.767A10.667 10.667 0 004 21.733'
      />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21.333 21.333L15.999 16l-5.333 5.333'
      />
    </svg>
  );
}

export default Icon;
