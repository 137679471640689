import { ID, RecordObject, RecordStringObject } from 'src/models/general.model';

export enum DocumentTemplateTab {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum TemplateShowAs {
  TEXT = 'text',
  MULTILINE_TEXT = 'multilineText',
}

export interface TemplateElementScript {
  showAs?: TemplateShowAs;
  max?: number; // max number of characters
}

export type TemplateScenario = RecordObject<TemplateElementScript>;

export interface TemplateContent {
  id: ID;
  is_default?: boolean;
  required_document_id: ID;
  template_type: string;
  title: string;
  content: RecordStringObject;
}
