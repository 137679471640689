import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { useNavigation } from 'src/hooks/useNavigation';

import { t } from 'src/tools/text.tools';
import { ID } from 'src/models/general.model';
import { getCurrentSubProject, getSubProjects } from 'src/store/subProject/subProject.getters';
import { setCurrentSubProject } from 'src/store/subProject/subProject.reducer';
import { getParams } from 'src/store/app/app.reducer';

import { LogoButton, XxlPrimaryButton } from '../Button';
import { SubprojectButton } from './SubprojectButton';
import { SubprojectPicker } from './SubprojectPicker';
import { ContentSlider } from './ContentSlider';
import { NotificationsDropdown } from 'src/domains/notifications';
import { UserNavDropdown } from './UserNavDropdown';
import { NAVBAR_MENU_ITEMS, NavbarButtonItem } from 'src/data/navbar.data';
import { render } from 'src/tools/render.tools';
import { useDomainPermissions } from 'src/hooks/useDomainPermissions';
import { PATHS } from 'src/data/routes.data';
import { resolvePathWithParams } from 'src/tools/url.tools';
import { H4 } from 'src/components/Typography';
import { HelpDropdown } from 'src/components/HelpDropdown';

import classes from './Navbar.module.scss';

const TXT = t(['navbar']);

export function Navbar() {
  const dispatch = useDispatch();

  const { goTo } = useNavigation();

  const subProjects = useSelector(getSubProjects);

  const currentSubProject = useSelector(getCurrentSubProject);

  const { mainId, subId } = useSelector(getParams);

  const { isAllowedPath } = useDomainPermissions();

  const [showBottomContent, setShowBottomContent] = React.useState(false);

  const isNotMainPage = !!mainId && !!subId;

  function handleOnSubprojectSelect(id: ID) {
    handleCloseBottomContent();

    if (String(id) !== subId) {
      dispatch(setCurrentSubProject(subProjects?.find(prj => String(prj.id) === String(id))));

      if (mainId && id) goTo(PATHS.REQ_SUMMARY, { mainId, subId: id });
    }
  }

  function handleOpenBottomContent() {
    setShowBottomContent(true);
  }

  function handleCloseBottomContent() {
    setShowBottomContent(false);
  }

  function isCurrentSection(sectionId: ID): boolean {
    return globalThis.location.pathname.includes(`/${sectionId}`);
  }

  function renderBetaNote() {
    return <H4 className={classes['beta-text']}>{TXT('beta')}</H4>;
  }

  function renderSectionButton({ id, label, paths }: NavbarButtonItem) {
    const availablePath = paths.find(isAllowedPath);

    if (!availablePath || !mainId || !subId) return;

    return (
      <XxlPrimaryButton
        key={id}
        label={label}
        isSelected={isCurrentSection(id)}
        to={resolvePathWithParams(availablePath, { mainId, subId })}
      />
    );
  }

  function renderSectionButtons() {
    return (
      <>
        {currentSubProject?.name && (
          <SubprojectButton
            name={currentSubProject?.name ?? ''}
            requestOpen={handleOpenBottomContent}
            requestClose={handleCloseBottomContent}
          />
        )}
        {render(NAVBAR_MENU_ITEMS, renderSectionButton)}
      </>
    );
  }

  function renderContentSlider() {
    return (
      <ContentSlider
        onMouseEnter={handleOpenBottomContent}
        onMouseLeave={handleCloseBottomContent}
        show={showBottomContent}
      >
        {subProjects?.length && <SubprojectPicker subProjects={subProjects} onSelect={handleOnSubprojectSelect} />}
      </ContentSlider>
    );
  }

  return (
    <nav className={clsx(classes.wrapper, 'w-100p')}>
      <div className={clsx(classes.container, 'row nowrap a-center j-between w-100p')}>
        <div className='row nowrap f-1 j-start'>
          <LogoButton to={PATHS.PORTFOLIO} />
          {isNotMainPage && renderSectionButtons()}
        </div>
        <div className='row items-p8 nowrap f-1 j-end'>
          {renderBetaNote()}
          <HelpDropdown />
          <UserNavDropdown />
          <NotificationsDropdown />
        </div>
      </div>
      {isNotMainPage && renderContentSlider()}
    </nav>
  );
}
