export function updatePrototypes() {
  // eslint-disable-next-line no-extend-native
  Array.prototype.last = function () {
    return this[this.length - 1];
  };
  // eslint-disable-next-line no-extend-native
  Array.prototype.lastIndex = function () {
    return this ? this.length - 1 : -1;
  };
  // eslint-disable-next-line no-extend-native
  Array.prototype.first = function () {
    return this ? this[0] : undefined;
  };
  // eslint-disable-next-line no-extend-native
  Array.prototype.isLast = function (index: number) {
    return this ? index === this.length - 1 : false;
  }; // eslint-disable-next-line no-extend-native
  Array.prototype.isLastItem = function <T>(item: T) {
    return this ? this.last() === item : false;
  };
}
