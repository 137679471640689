/* eslint-disable max-lines */
import { processError } from 'src/tools/events.tools';
import { logInfo, logUnableToProceed } from 'src/tools/log.tools';
import { checkIsValid } from 'src/tools/object.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { deserialize, capitalize } from 'src/tools/string.tools';

import {
  Action,
  AnyValue,
  ID,
  ProcessProps,
  RecordObject,
  Response,
  ResponsePayload,
  ActionResult,
  Maybe,
} from 'src/models/general.model';
import { QueryAction, QueryDomain, ResourceType } from 'src/models/query.model';
import {
  ReqDetailPageDataExtended,
  ReqDetailChangeOrder,
  ReqDetailBaseContract,
  PageBalance,
  ReqDetailPageParams,
  ReqDetailTotalsParams,
  ReqDetailTotalsType,
  UpdateReqDetailPagePayload,
} from 'src/models/reqDetail.model';
import { Period } from 'src/models/results.model';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import {
  getReqDetailBaseContractQuery,
  getReqDetailChangeOrdersQuery,
  getReqDetailSelectedPeriod,
  getReqDetailChangeOrdersMeta,
  getReqDetailBaseContractMeta,
  getReqDetailBaseContractDataItem,
} from 'src/store/reqDetail/reqDetail.getters';
import {
  setReqDetailPageData,
  setReqDetailPageMeta,
  setReqDetailPageTotals,
  setReqDetailBaseContract,
  addReqDetailBaseContract,
  setReqDetailBaseContractTotals,
  setReqDetailChangeOrdersTotals,
  setReqDetailChangeOrders,
  updateReqDetailChangeOrders,
  setReqDetailPageTotalsScenario,
  setReqDetailHistory,
  updateReqDetailLineItem,
  setReqDetailPageBalance,
  setReqDetailPagePermissions,
  setReqDetailSidebar,
  setReqDetailReqPeriods,
  setReqDetailUploadInProgress,
} from 'src/store/reqDetail/reqDetail.reducer';

import { sendInfo, alertError } from '../store/app/app.actions';
import { getAppParams } from '../store/app/app.getters';
import { History } from 'src/models/history.model';

import { omit, pick } from 'ramda';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { actionWrapper } from 'src/tools/action.tools';

function processReqDetail({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const makeReqDetailVersionedPeriod = (payload: ReqDetailPageParams = {}) =>
    'period' in payload ? payload.period : getReqDetailSelectedPeriod(getState());

  // every time we reload page data, we need to reload tables and totals
  function getTablesAndTotals(action: Action<ReqDetailPageParams>) {
    processBaseContractRequest(action)();

    processOrdersRequest(action)();

    processTotalsRequest({
      ...action,
      payload: { type: 'main' },
    })();
  }

  function getAllTotals(action: Action<undefined>) {
    const types: ReqDetailTotalsType[] = ['main', 'line_items', 'change_orders'];

    types.forEach(type =>
      processTotalsRequest({
        ...action,
        payload: { type },
      })(),
    );
  }

  function processPageDataRequest(action: Action<ReqDetailPageParams>) {
    return function call() {
      // early return if we left the page
      if (!checkIsValid(action.meta?.isValid)) return;

      setIsBusy({ meta: action.meta, state: true });

      const tradeId = getAppParams(getState()).tradeId;

      getService().request(
        {
          [QueryDomain.REQ_DETAIL]: QueryAction.SHOW,
          payload: {
            trade_contract_id: action.payload?.tradeId ?? tradeId,
            period: makeReqDetailVersionedPeriod(action.payload),
          },
        },

        (response: Response<string>) => {
          // early return if we left the page
          if (!checkIsValid(action.meta?.isValid)) return;

          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqDetailPageDataExtended>>(response.payload);

            dispatch(setReqDetailPageData(result?.data ? omit(['sidebar'], result?.data) : undefined));
            dispatch(setReqDetailPagePermissions(result?.permissions));
            dispatch(setReqDetailPageMeta(result?.meta));
            dispatch(setReqDetailSidebar(result?.data?.sidebar));
            dispatch(setReqDetailUploadInProgress(Boolean(result?.data.upload_in_progress)));
            setIsBusy({ meta: action.meta, state: false });

            processPageBalanceRequest(actionWrapper({}, action.meta))();
            processHistoryRequest()();
            getTablesAndTotals(actionWrapper({ meta: { page: 1 } }, action.meta));
            processGetReqPeriods()();
          } else {
            setIsBusy({ meta: action.meta, state: false });
            processError({
              activityName: 'Request req details page data',
              response,
            });
          }
        },
      );
    };
  }

  function processPageBalanceRequest(action: Action<ReqDetailPageParams>) {
    return function call() {
      // early return if we left the page
      if (!checkIsValid(action.meta?.isValid)) return;

      setIsBusy({ meta: action.meta, state: true });

      getService().request(
        {
          [QueryDomain.REQ_DETAIL]: QueryAction.GET_BALANCE,
          payload: {
            trade_contract_id: getAppParams(getState()).tradeId,
            period: makeReqDetailVersionedPeriod(action.payload),
          },
        },

        (response: Response<string>) => {
          // early return if we left the page
          if (!checkIsValid(action.meta?.isValid)) return;

          if (response.isOK) {
            const result = deserialize<ResponsePayload<PageBalance>>(response.payload);

            dispatch(setReqDetailPageBalance(result?.data));
          } else {
            processError({
              activityName: 'Request req details page balance',
              response,
            });
          }

          setIsBusy({ meta: action.meta, state: false });
        },
      );
    };
  }

  function processTotalsRequest(action: Action<ReqDetailTotalsParams>) {
    return function call() {
      if (!checkIsValid(action.meta?.isValid)) return;

      const type = action.payload?.type;

      if (!type) {
        logUnableToProceed('processTotalsRequest', { type });
        return;
      }

      setIsBusy({ id: 'totals', meta: action.meta, state: true });

      getService().request(
        {
          [QueryDomain.REQ_DETAIL]: QueryAction.GET_TOTALS,
          payload: {
            trade_contract_id: getAppParams(getState()).tradeId,
            period: makeReqDetailVersionedPeriod(action.payload),
            type,
          },
        },
        (response: Response<string>) => {
          if (!checkIsValid(action.meta?.isValid)) return;

          if (response.isOK) {
            const result = deserialize<ResponsePayload<RecordObject<number>>>(response.payload);

            switch (type) {
              case 'main':
                dispatch(setReqDetailPageTotals(result?.data));
                dispatch(setReqDetailPageTotalsScenario(result?.scenario));
                break;
              case 'line_items':
                dispatch(setReqDetailBaseContractTotals(result?.data));
                break;
              case 'change_orders':
                dispatch(setReqDetailChangeOrdersTotals(result?.data));
                break;
              default:
            }
          } else {
            processError({
              activityName: 'Request req detail page totals',
              response,
            });
          }

          dispatch(setCustomIsLoading({ id: 'totals', state: false }));
        },
      );
    };
  }

  function processBaseContractRequest(action: Action<ReqDetailPageParams>) {
    return function call() {
      // early return if we left the page
      if (!checkIsValid(action.meta?.isValid)) return;

      setIsBusy({ meta: action.meta, state: true, id: 'baseContract' });

      const period = makeReqDetailVersionedPeriod(action.payload);

      const meta = pick(['page', 'per_page'], getReqDetailBaseContractMeta(getState()) ?? {});

      const query = getReqDetailBaseContractQuery(getState()) || {};

      getService().request(
        {
          [QueryDomain.REQ_DETAIL]: QueryAction.GET,
          payload: {
            trade_contract_id: getAppParams(getState()).tradeId,
            period,
            query: action.payload?.query ? { ...query, ...action.payload.query } : query,
            meta: action.payload?.meta ? { ...meta, ...action.payload.meta } : meta,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqDetailBaseContract[]>>(response.payload);

            if (action.meta?.isUpdate && !action.meta.isInitial) dispatch(addReqDetailBaseContract(result));
            else dispatch(setReqDetailBaseContract(result));

            setIsBusy({ meta: action.meta, state: false, id: 'baseContract' });

            processTotalsRequest({
              ...action,
              payload: { period, type: 'line_items' },
            })();
          } else {
            processError({
              activityName: 'Request req detail line items',
              response,
            });

            setIsBusy({ meta: action.meta, state: false, id: 'baseContract' });
          }
        },
      );
    };
  }

  const processBaseContractMoreRequest = (action: Action<string>) => {
    return function call() {
      const { meta } = action;

      const pagination = getReqDetailBaseContractMeta(getState());

      if (typeof pagination?.next !== 'number') {
        logUnableToProceed('processBaseContractMoreRequest', { pagination });
        return;
      }

      processBaseContractRequest({
        ...action,
        payload: { meta: { page: pagination.next } },
        meta: { ...meta, isUpdate: true },
      })();
    };
  };

  function processOrdersRequest(action: Action<ReqDetailPageParams>) {
    return function call() {
      if (!checkIsValid(action.meta?.isValid)) return;

      setIsBusy({ id: 'changeOrder', state: true, meta: action.meta });

      const meta = pick(['page', 'per_page'], getReqDetailChangeOrdersMeta(getState()) ?? {});

      const query = getReqDetailChangeOrdersQuery(getState()) || {};

      getService().request(
        {
          [QueryDomain.REQ_DETAIL]: QueryAction.GET_CHANGE_ORDERS,
          payload: {
            trade_contract_id: getAppParams(getState()).tradeId,
            period: makeReqDetailVersionedPeriod(action.payload),
            query: action.payload?.query ? { ...query, ...action.payload.query } : query,
            meta: action.payload?.meta ? { ...meta, ...action.payload.meta } : meta,
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqDetailChangeOrder[]>>(response.payload);

            if (action.meta?.isUpdate) {
              dispatch(updateReqDetailChangeOrders(result));
            } else {
              dispatch(setReqDetailChangeOrders(result));
            }

            setIsBusy({ id: 'changeOrder', state: false, meta: action.meta });

            processTotalsRequest({
              ...action,
              payload: { type: 'change_orders' },
            })();
          } else {
            processError({
              activityName: 'Request req detail change orders',
              response,
            });

            setIsBusy({ id: 'changeOrder', state: false, meta: action.meta });
          }
        },
      );
    };
  }

  function processOrdersMoreRequest(action: Action<undefined>) {
    return function call() {
      const pagination = getReqDetailChangeOrdersMeta(getState());

      if (typeof pagination?.next !== 'number') {
        logUnableToProceed('processOrdersMoreRequest', { page: pagination });
        return;
      }

      processOrdersRequest({
        ...action,
        payload: {
          meta: { page: pagination.next },
        },
        meta: {
          ...action.meta,
          isUpdate: true,
        },
      })();
    };
  }

  // every time we edit line item or bill we need to update balance totals and Base Contract Totals
  function processPageBalanceAndTotalsRequest(action: Action<undefined>) {
    return function call() {
      const emptyAction = actionWrapper(undefined, action.meta);

      processPageBalanceRequest(emptyAction)();

      getAllTotals(emptyAction);
    };
  }

  function processPageUpdate(action: Action<UpdateReqDetailPagePayload>) {
    return function call() {
      if (!checkIsValid(action.meta?.isValid)) return;

      setIsBusy({ id: 'status', state: true, meta: action.meta });

      getService().request(
        {
          [QueryDomain.REQ_DETAIL]: QueryAction.UPDATE,
          payload: {
            trade_contract_id: getAppParams(getState()).tradeId,
            body: {
              ...action.payload,
              period: getReqDetailSelectedPeriod(getState()),
            },
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqDetailPageDataExtended>>(response.payload);

            dispatch(setReqDetailPageData(result?.data));

            setIsBusy({ id: 'status', state: false, meta: action.meta });

            processPageDataRequest(actionWrapper({ period: undefined }, action.meta))();
          } else {
            setIsBusy({ id: 'status', state: false, meta: action.meta });

            processError({
              activityName: 'Request req detail update',
              response,
            });
          }

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
        },
      );
    };
  }

  const processActions = (payload: string) => () => {
    setIsBusy({ id: 'actions', state: true });

    getService().request(
      {
        [QueryDomain.REQ_DETAIL]: QueryAction.ACTION,
        payload: {
          data: {
            selected_action: payload,
            trade_contract_id: getAppParams(getState()).tradeId,
            period: getReqDetailSelectedPeriod(getState()),
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<Maybe<ActionResult>>(response.payload);

          dispatch(sendInfo(result?.data ?? 'Action was performed'));
          setIsBusy({ id: 'actions', state: false });

          processPageDataRequest(actionWrapper(undefined, { isValid: true, isUpdate: true, isInitial: true }))();
          processHistoryRequest()();
        } else {
          setIsBusy({ id: 'actions', state: false });

          processError({ activityName: 'Request req detail action', response });
        }
      },
    );
  };

  // TODO: finish after API update
  const processHistoryRequest = (payload?: Period) => () => {
    dispatch(setCustomIsLoading({ id: 'history', state: true }));

    const period = payload || getReqDetailSelectedPeriod(getState()) || {};

    getService().request(
      {
        [QueryDomain.REQ_DETAIL]: QueryAction.GET_HISTORY,
        payload: {
          trade_contract_id: getAppParams(getState()).tradeId,
          period,
        },
      },
      (response: Response<unknown>) => {
        if (response.isOK) {
          // TODO: remove Set
          const result = deserialize<Set<History>>(response.payload as string, new Set<History>());

          dispatch(setReqDetailHistory(Array.from(result ?? [])));
        } else {
          processError({
            activityName: 'Request req detail history',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'history', state: false }));
      },
    );
  };

  const processPostLineRequest = (action: Action<Record<string, unknown>>) => () => {
    const { tradeId } = getAppParams(getState());

    const period = getReqDetailSelectedPeriod(getState());

    if (!tradeId || !period) {
      logUnableToProceed('processPostLineRequest', { tradeId, period });
      return;
    }

    dispatch(setCustomIsLoading({ id: 'form', state: true }));
    getService().request(
      {
        [QueryDomain.REQ_DETAIL]: QueryAction.POST,
        payload: {
          data: {
            ...action.payload,
            period,
            resource_id: tradeId,
            resource_type: ResourceType.TRADE_CONTRACT,
          },
        },
      },
      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(sendInfo('Line Item was created.'));
          processPageDataRequest(actionWrapper(undefined, { isValid: true, isUpdate: true, isInitial: true }))();
        } else {
          processError({ activityName: 'Request line item create', response });
        }

        if (action.meta?.callback) action.meta.callback(response);

        dispatch(setCustomIsLoading({ id: 'form', state: false }));
      },
    );
  };

  type PatchLineItemPayload = {
    line_item_id: ID;
    data: Record<string, unknown>;
  };

  const processPatchLineRequest = (action: Action<PatchLineItemPayload>) => () => {
    const { tradeId } = getAppParams(getState());

    const period = getReqDetailSelectedPeriod(getState());

    if (!tradeId || !period || !action.payload) {
      logUnableToProceed('processPostLineRequest', { tradeId, period, action });
      return;
    }

    dispatch(setCustomIsLoading({ id: 'form', state: true }));

    const { line_item_id, data } = action.payload;

    getService().request(
      {
        [QueryDomain.LINE_ITEMS]: QueryAction.PATCH,
        payload: {
          line_item_id,
          data: {
            ...data,
            period,
            resource_id: tradeId,
            resource_type: ResourceType.TRADE_CONTRACT,
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqDetailBaseContract>>(response.payload);

          const oldItem = getReqDetailBaseContractDataItem(getState())(line_item_id);

          const isGroupChanged = oldItem?.group !== result?.data.group;

          // if group wasn't changed, no need to update the whole page data
          if (result?.data && !isGroupChanged) {
            dispatch(updateReqDetailLineItem(result.data));
            processPageBalanceAndTotalsRequest(actionWrapper(undefined, { isValid: true, isUpdate: true }))();
          } else processPageDataRequest(actionWrapper(undefined, { isValid: true, isUpdate: true, isInitial: true }))();

          dispatch(sendInfo('Line Item was updated.'));
          dispatch(setCustomIsLoading({ id: 'form', state: false }));
        } else {
          dispatch(setCustomIsLoading({ id: 'form', state: false }));

          processError({ activityName: 'Request line item update', response });
        }

        if (action.meta?.callback) action.meta.callback(response);
      },
    );
  };

  const processDeleteLineRequest = (action: Action<PatchLineItemPayload>) => () => {
    const { tradeId } = getAppParams(getState());

    const period = getReqDetailSelectedPeriod(getState());

    if (!tradeId || !period || !action.payload) {
      logUnableToProceed('processDeleteLineRequest', { tradeId, period, action });
      return;
    }

    dispatch(setCustomIsLoading({ id: 'form', state: true }));

    const { line_item_id } = action.payload;

    getService().request(
      {
        [QueryDomain.REQ_DETAIL]: QueryAction.DELETE_LINE_ITEM,
        payload: {
          line_item_id,
          data: {
            period,
            resource_id: tradeId,
            resource_type: ResourceType.TRADE_CONTRACT,
          },
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          processPageDataRequest(actionWrapper(undefined, { isValid: true, isUpdate: true, isInitial: true }))();

          dispatch(sendInfo('Line tem was deleted.'));
          dispatch(setCustomIsLoading({ id: 'form', state: false }));
        } else {
          dispatch(setCustomIsLoading({ id: 'form', state: false }));

          dispatch(
            alertError({
              title: 'Request line item delete',
              content: capitalize(String(response?.message)) ?? 'Failed to perform the action',
              buttonLabel: 'Got it',
            }),
          );
        }

        if (action.meta?.callback) action.meta.callback(response);
      },
    );
  };

  const processStatisticsRequest = (payload: AnyValue) => () => {
    logInfo('processStatisticsRequest', { payload });
    // dispatch(setCustomIsLoading({ id: 'stats', state: true }));
    // getService().request(
    //   {
    //     [QueryDomain.REQ_DETAIL]: QueryAction.STATISTICS,
    //     payload: makeVersionedQuery(getState())(payload.query),
    //   },
    //   (response: Response<unknown>) => {
    //     if (response.isOK) {
    //       const data = deserialize<Maybe<Set<StatisticsPeriod>>>(response.payload as string, undefined);
    //       dispatch(setStatistics(Array.from(data || [])));
    //     } else {
    //       processError({
    //         err: response.message,
    //         activityName: 'Request req detail statistics',
    //         payload: response.payload,
    //       });
    //     }
    //     dispatch(setCustomIsLoading({ id: 'stats', state: false }));
    //   },
    // );
  };

  const processGetReqPeriods = () => () => {
    const trade_contract_id = getAppParams(getState()).tradeId;

    if (!trade_contract_id) {
      logUnableToProceed('processGetReqPeriods', { trade_contract_id });

      return;
    }

    dispatch(setCustomIsUpdating({ id: 'reqPeriods', state: true }));

    getService().request(
      { [QueryDomain.REQ_DETAIL]: QueryAction.GET_PERIODS, payload: { trade_contract_id } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<ReqPeriod[]>>(response.payload);

          dispatch(setReqDetailReqPeriods(result?.data));
        } else {
          processError({
            activityName: 'Request REQ periods',
            response,
          });
        }

        dispatch(setCustomIsUpdating({ id: 'reqPeriods', state: false }));
      },
    );
  };

  return {
    processActions,
    processBaseContractRequest,
    processBaseContractMoreRequest,
    processHistoryRequest,
    processOrdersMoreRequest,
    processOrdersRequest,
    processPageDataRequest,
    processPageUpdate,
    processPatchLineRequest,
    processPostLineRequest,
    processDeleteLineRequest,
    processStatisticsRequest,
    processTotalsRequest,
    processPageBalanceAndTotalsRequest,
    processGetReqPeriods,
  };
}

export { processReqDetail };
