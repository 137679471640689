import { ID, Maybe, RecordObject } from './general.model';
import { Meta, PageQuery, Period, RequestMeta } from './results.model';
import { Scenario } from './scenario.model';
import { Sidebar } from './sidebar.model';
import { ReqPeriod } from 'src/models/reqPeriod.model';

export enum ReqSummaryTabs {
  SUMMARY = 'summary',
  CONSTRUCTION = 'construction',
  PRE_CONSTRUCTION = 'pre_construction',
  GR = 'gr',
  UNAWARDED = 'unawarded',
  GC = 'gc',
  OTHER = 'other',
}

export interface ReqSummaryPageData {
  current_period: Period;
  id: ID;
  req_start_date: Date;
  selected_period: Period;
  sidebar?: Sidebar;
  status: string;
  tabs: ReqSummaryTabs[];
  trades_count: number;
}

export interface ReqSummarySummaryRow {
  amended_contract_value: number;
  approved_change_orders: number;
  balance_to_finish: number;
  contract_value: number;
  due_this_period: number;
  percentage_completed: number;
  prior_periods: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_total: number;
  stored_material: number;
  this_period: number;
  total_completed: number;
  description: string;
}

export interface ReqSummarySummaries {
  data: ReqSummarySummaryRow[];
  totals: RecordObject<number>;
  scenario: Scenario;
}

export interface ReqSummaryTradesTableData {
  id: number;
  company: string;
  company_id: number;
  trade_detail: string;
  status: string;
  gc_prior_periods: number;
  gc_this_period: number;
  gc_total: number;
  contract_value: number;
  approved_change_orders: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_total: number;
  due_this_period: number;
}

export interface ReqSummaryTermsTableData {
  contract_value: number;
  approved_change_orders: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_total: number;
  due_this_period: number;
  description: string;
}

export interface ReqSummaryTableData<T> {
  data: Maybe<T[]>;
  query?: PageQuery;
  meta?: Maybe<Meta>;
  totals: Maybe<Record<string, number>>;
  scenario: Maybe<Scenario>;
}

export type MaybeReqSummaryTradesData = Maybe<ReqSummaryTableData<ReqSummaryTradesTableData>>;

export type MaybeReqSummaryTermsData = Maybe<ReqSummaryTableData<ReqSummaryTermsTableData>>;

export interface ReqSummaryBalance {
  contract_value: number;
  left_to_award: number;
}

export interface ReqSummaryDashboardData {
  name: string;
  period: ReqPeriod;
  due_this_period: number;
  percentage_completed: number;
  contract_value: number;
  approved_change_orders: number;
  amended_contract_value: number;
  balance_to_finish: number;
  retainage_total: number;
  remaining_due: number;
  complete_and_pending: number;
}

export interface ReqSummaryDashboardInfo {
  name: string;
  period: ReqPeriod;
  due_this_period: number;
}

export enum ReqSummaryTradeType {
  CONSTRUCTION = 'construction',
  PRE_CONSTRUCTION = 'pre_construction',
  GR = 'gr',
  UNAWARDED = 'unawarded',
  SUMMARY = 'summary',
}

export enum ReqSummaryTermsType {
  OTHER = 'other',
  GC = 'gc',
  SUMMARY = 'summary',
}

export interface ReqSummaryBasicQuery {
  period?: Period;
}

export interface ReqSummaryTradeRequestParams extends ReqSummaryBasicQuery {
  type?: ReqSummaryTradeType;
}

export interface ReqSummaryTradeRequestArgs {
  params?: ReqSummaryTradeRequestParams;
  query?: Partial<PageQuery>;
  meta?: RequestMeta;
}

export interface ReqSummaryTermsRequestParams {
  period?: Period;
  type?: ReqSummaryTermsType;
}

export interface ReqSummaryTermsRequestArgs {
  params?: ReqSummaryTermsRequestParams;
  query?: Partial<PageQuery>;
}
