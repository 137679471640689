import { Ability } from '@casl/ability';
import { PERMISSIONS_VERIFICATION_MODEL } from 'src/data/permissions.data';
import { Maybe } from 'src/models/general.model';
import { Permission } from 'src/models/permissions.model';
import { checkArrayOfObjectsForKeys } from 'src/validators/object';
import { logIncorrectData } from './log.tools';

export function makeAbilities(permissions: Maybe<Permission[]>) {
  if (!permissions || !Array.isArray(permissions) || !permissions.length) return new Ability([]);

  if (checkArrayOfObjectsForKeys(permissions, PERMISSIONS_VERIFICATION_MODEL)) return new Ability(permissions);

  logIncorrectData('makeAbilities', 'Data is present, but incorrect.', { permissions });

  return new Ability([]);
}
