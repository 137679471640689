import { Action, ActionMeta, ID } from 'src/models/general.model';
import { MainProjectActions, OnboardingActions } from 'src/models/query.model';
import { Meta } from 'src/models/results.model';

export const requestCreateMainProject = (payload: FormData, meta?: ActionMeta): Action => ({
  type: MainProjectActions.CREATE,
  payload,
  meta,
});

interface UpdateMainProjectProps {
  data: FormData;
  main_project_id: ID;
}

export const requestUpdateMainProject = (payload: UpdateMainProjectProps, meta?: ActionMeta): Action => ({
  type: MainProjectActions.UPDATE,
  payload,
  meta,
});

export const requestMainProjects = (payload?: Meta, meta?: ActionMeta): Action => ({
  type: MainProjectActions.GET,
  payload,
  meta,
});

export const requestSingleMainProject = (payload: ID): Action => ({
  type: MainProjectActions.SINGLE,
  payload,
});

export const requestOnboarding = (payload: Record<string, unknown>, meta?: ActionMeta): Action => ({
  type: OnboardingActions.CREATE,
  payload,
  meta,
});
