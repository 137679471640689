import { v4 as uuidv4 } from 'uuid';
import { append, filter, includes, not, unionWith } from 'ramda';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { INITIAL_APP_STATE } from './app.model';
import { RootState } from 'src/store';
import { AlertMessage, ErrorMessage, Config, ID, Maybe, PathParams, Tab, Timezone, ToastMessage } from 'src/models';

import { storeItem } from 'src/services/storage.service';

export const slice = createSlice({
  name: 'app',
  initialState: INITIAL_APP_STATE,
  reducers: {
    // TODO: update
    clearState: state => {
      state.tabbedRnd = undefined;
      state.messages = [];
      state.alertMessage = undefined;
      state.errorMessage = undefined;
      state.dropdownSelect = undefined;
      state.rowSelect = undefined;
      state.isApiOnline = true;
      state.closedRequiredDocumentMessages = [];
    },
    setIsAuthed: (state, action: PayloadAction<boolean>) => {
      state.isAuthed = action.payload;
    },
    setIsApiOnline: (state, action: PayloadAction<boolean>) => {
      state.isApiOnline = action.payload;
    },
    setIsManagerReqHistoryOpen: state => {
      storeItem('historyIsOpen', String(not(state.isManagerReqDetailHistoryOpen)));
      state.isManagerReqDetailHistoryOpen = not(state.isManagerReqDetailHistoryOpen);
    },
    setIsReqDetailsHistoryOpen: state => {
      storeItem('historyIsOpen', String(not(state.isReqDetailHistoryOpen)));
      state.isReqDetailHistoryOpen = not(state.isReqDetailHistoryOpen);
    },
    setServerConfig: (state, action: PayloadAction<Config | undefined>) => {
      state.serverConfig = action.payload;
    },
    setAppIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTabbedRnd: (state, action: PayloadAction<Tab[]>) => {
      const cmp = (x: Tab, y: Tab) => x.id === y.id;

      state.tabbedRnd = unionWith(cmp, state.tabbedRnd ?? [], action.payload);
    },
    filterTabbedRnd: (state, action: PayloadAction<ID>) => {
      if (state.tabbedRnd) state.tabbedRnd = state.tabbedRnd.filter((tab: Tab) => tab.id !== action.payload);
    },
    setSingleRnd: (state, action: PayloadAction<string>) => {
      if (not(includes(action.payload, state.rndIds))) state.rndIds = append(action.payload, state.rndIds);
    },
    clearSingleRnd: (state, action: PayloadAction<string>) => {
      const fn = (el: string) => el !== action.payload;

      state.rndIds = filter(fn, state.rndIds);
    },
    clearAllRnds: state => {
      state.rndIds = [];
    },
    clearTabbedRnd: state => {
      state.tabbedRnd = undefined;
    },
    setDisableDragging: (state, action: PayloadAction<boolean>) => {
      state.disableDragging = action.payload;
    },
    addMessage: (state, action: PayloadAction<Omit<ToastMessage, 'id'>>) => {
      state.messages = [...state.messages, { ...action.payload, id: uuidv4() }];
    },
    openAlert: (state, action: PayloadAction<AlertMessage>) => {
      state.alertMessage = action.payload;
    },
    closeAlert: state => {
      state.alertMessage = undefined;
    },
    openError: (state, action: PayloadAction<ErrorMessage>) => {
      state.errorMessage = action.payload;
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter(msg => msg.id !== action.payload);
    },
    setDropdownSelect: (state, action: PayloadAction<string>) => {
      state.dropdownSelect = action.payload;
    },
    setRowSelect: (state, action: PayloadAction<string>) => {
      state.rowSelect = action.payload;
    },
    setParams: (state, action: PayloadAction<PathParams>) => {
      state.params = action.payload;
    },
    setUrlQuery: (state, action: PayloadAction<URLSearchParams>) => {
      state.urlQuery = action.payload;
    },
    setActiveResizableWindowId: (state, action: PayloadAction<string>) => {
      state.resizableWindowIds = [...state.resizableWindowIds.filter(id => id !== action.payload), action.payload];
    },
    removeResizableWindowId: (state, action: PayloadAction<string>) => {
      state.resizableWindowIds = state.resizableWindowIds.filter(id => id !== action.payload);
    },
    setIsUploadPopupIsOpen: (state, action: PayloadAction<Maybe<boolean>>) => {
      state.isUploadPopupOpen = Boolean(action.payload);
    },
    setIsSidebarOpen: (state, action: PayloadAction<Maybe<boolean>>) => {
      state.isSidebarOpen = Boolean(action.payload);
    },
    addClosedRequiredDocumentMessage: (state, action: PayloadAction<ID>) => {
      if (!state.closedRequiredDocumentMessages.includes(action.payload)) {
        state.closedRequiredDocumentMessages = [...state.closedRequiredDocumentMessages, action.payload];
      }
    },
    clearClosedRequiredDocumentMessage: state => {
      state.closedRequiredDocumentMessages = [];
    },
    setTimezones: (state, action: PayloadAction<Timezone[]>) => {
      state.timezones = action.payload;
    },
  },
});

export const getParams = (state: RootState) => state.app.params;

export const getServerConfig = (state: RootState) => state.app.serverConfig;

export const getIsApiOnline = (state: RootState) => state.app.isApiOnline;

export const getAppIsLoading = (state: RootState) => state.app.isLoading;

export const getTabbedRnd = (state: RootState) => state.app.tabbedRnd;

export const getMessages = (state: RootState) => state.app.messages;

export const getDropdownSelect = (state: RootState) => state.app.dropdownSelect;

export const getRowSelect = (state: RootState) => state.app.rowSelect;

export const getAllRndIDs = (state: RootState) => state.app.rndIds;

export const getIsHistoryOpen = (state: RootState) => state.app.isReqDetailHistoryOpen;

export const getIsManagerHistoryOpen = (state: RootState) => state.app.isManagerReqDetailHistoryOpen;

export const getDisableDragging = (state: RootState) => state.app.disableDragging;

export const getIsAuthed = (state: RootState) => state.app.isAuthed;

export const getActiveResizableWindowId = (state: RootState) =>
  state.app.resizableWindowIds[state.app.resizableWindowIds.length - 1];

export const getIsSidebarOpen = (state: RootState) => state.app.isSidebarOpen;

export const getClosedRequiredDocumentMessages = (state: RootState) => state.app.closedRequiredDocumentMessages;

export const isClosedRequiredDocumentMessage = (state: RootState) => (id: ID) =>
  state.app.closedRequiredDocumentMessages.includes(id);

export const {
  clearState,
  clearTabbedRnd,
  filterTabbedRnd,
  setAppIsLoading,
  setTabbedRnd,
  addMessage,
  openAlert,
  closeAlert,
  openError,
  removeMessage,
  setDropdownSelect,
  setRowSelect,
  setServerConfig,
  setSingleRnd,
  clearSingleRnd,
  setIsApiOnline,
  setIsAuthed,
  clearAllRnds,
  setDisableDragging,
  setIsManagerReqHistoryOpen,
  setIsReqDetailsHistoryOpen,
  setParams,
  setActiveResizableWindowId,
  removeResizableWindowId,
  setIsUploadPopupIsOpen,
  setIsSidebarOpen,
  addClosedRequiredDocumentMessage,
  clearClosedRequiredDocumentMessage,
  setTimezones,
  setUrlQuery,
} = slice.actions;

export default slice.reducer;
