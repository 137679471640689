import clsx from 'clsx';
import * as React from 'react';

import styles from './Paragraph.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  dataTestId?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  'data-tip'?: string;
  'data-for'?: string;
}

// new-ui
export function P1({ children, className, dataTestId, ...opts }: Props) {
  return (
    <p className={clsx(styles.p1, className)} data-testid={dataTestId} {...opts}>
      {children}
    </p>
  );
}

export function P2({ children, className, dataTestId, ...opts }: Props) {
  return (
    <p className={clsx(styles.p2, className)} data-testid={dataTestId} {...opts}>
      {children}
    </p>
  );
}

export function P3({ children, className, dataTestId, ...opts }: Props) {
  return (
    <p className={clsx(styles.p3, className)} data-testid={dataTestId} {...opts}>
      {children}
    </p>
  );
}

export function P4({ children, className, dataTestId, ...opts }: Props) {
  return (
    <p className={clsx(styles.p4, className)} data-testid={dataTestId} {...opts}>
      {children}
    </p>
  );
}

export function P5({ children, className, dataTestId, ...opts }: Props) {
  return (
    <p className={clsx(styles.p5, className)} data-testid={dataTestId} {...opts}>
      {children}
    </p>
  );
}

export function P6({ children, className, dataTestId, ...opts }: Props) {
  return (
    <p className={clsx(styles.p6, className)} data-testid={dataTestId} {...opts}>
      {children}
    </p>
  );
}
