import { v4 as generateId } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import { upload } from 'src/services/file.service';
import { processError } from 'src/tools/events.tools';
import { convertFormWithFilesToFormDataArray } from 'src/tools/form.tools';
import { makeStringFromTemplate } from 'src/tools/string.tools';

import { AnyValue, RecordObject, Result } from 'src/models/general.model';
import { AttachmentFile, BatchUploadActions, QueueItem } from 'src/models/files.model';
import { addUpload, updateProgress, removeUpload, addUploads } from 'src/store/files/files.reducer';
import { requestCompanies } from 'src/store/companies/companies.action';
import { clearCompaniesState } from 'src/store/companies/companies.reducer';
import { getAppParams } from 'src/store/app/app.getters';
import { sendInfo, sendError } from 'src/store/app/app.actions';

import { ServiceRequests } from 'src/data/api_endpoints';
import { getIsAuthed } from 'src/store/app/app.reducer';
import { getUserInvitation } from 'src/store/user/user.getters';
import { t } from 'src/tools/text.tools';

const TXT = t(['files']);

export function useUploader() {
  const dispatch = useDispatch();

  const invitation = useSelector(getUserInvitation);

  const subId = useSelector(getAppParams).subId ?? invitation?.project_id;

  const isAuthed = useSelector(getIsAuthed);

  function updateProgressData(id: string) {
    return function update(progress: number) {
      dispatch(updateProgress({ id, progress }));
    };
  }

  function respond(id: string, item: string, action: string) {
    return function res(result: Result) {
      dispatch(removeUpload(id));

      if (result.isOK) {
        // dispatch(sendInfo(makeStringFromTemplate(TEXT.files.success, [item, `${action}ed`])));
      } else {
        dispatch(sendError(result.message || makeStringFromTemplate(TXT('error'), [item, `${action}ed`])));
      }
    };
  }

  async function uploadPicture(data: FormData, item: AttachmentFile) {
    const uploadItem: QueueItem<AttachmentFile> = {
      id: generateId(),
      payload: {
        title: item.title,
        type: item.type,
      },
    };

    dispatch(addUpload(uploadItem));

    return upload({
      service: ServiceRequests.UPLOAD_PICTURE,
      onUploadProgress: updateProgressData(uploadItem.id),
      data: data,
      respond: respond(uploadItem.id, uploadItem.payload.title, 'upload'),
    });
  }

  async function uploadDocument(data: FormData, item: AttachmentFile) {
    const uploadItem: QueueItem<AttachmentFile> = {
      id: generateId(),
      payload: {
        title: item.title,
        type: item.type,
      },
    };

    dispatch(addUpload(uploadItem));

    return upload({
      service: ServiceRequests.UPLOAD_DOCUMENT,
      onUploadProgress: updateProgressData(uploadItem.id),
      data: data,
      respond: respond(uploadItem.id, uploadItem.payload.title, 'upload'),
    });
  }

  async function uploadCompanyForm(data: FormData): Promise<Result> {
    const result = await upload({
      service: ServiceRequests.UPLOAD_COMPANY_FORM,
      data,
      params: {
        project_id: subId,
        invite_id: invitation?.id,
      },
    });

    if (result.isOK) {
      dispatch(clearCompaniesState());

      if (isAuthed) {
        if (subId) {
          dispatch(requestCompanies());
        }

        dispatch(sendInfo('Company has been created.'));
      }
    } else {
      processError({ activityName: 'Request to upload company form', response: result });
    }

    return result;
  }

  interface BatchUploadPayload {
    callback: (isOK: boolean) => void;
    service: string;
    params?: Record<string, string>;
    index?: string[];
  }

  interface BatchUploadOptions {
    form: RecordObject<AnyValue>;
    fileField: string;
    nameField?: string;
  }

  async function batchUpload({ form, fileField, nameField }: BatchUploadOptions, payload: BatchUploadPayload) {
    dispatch(addUploads(convertFormWithFilesToFormDataArray(form, fileField, nameField)));

    dispatch({ type: BatchUploadActions.UPDATE_BATCH, payload });
  }

  return { uploadPicture, uploadDocument, uploadCompanyForm, batchUpload };
}
