import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='20'
      fill='none'
      viewBox='0 0 17 20'
      data-testid='icon_stamp'
    >
      <path
        fill='#0BBE35'
        stroke='#0BBE35'
        strokeWidth='0.6'
        d='M8.482 16.523c-2.149 0-4.297-.013-6.446.013-.594.012-.834-.19-.809-.81a32.48 32.48 0 000-2.717c-.025-.62.228-.81.822-.797.758.013 1.516 0 2.262-.113a4.322 4.322 0 001.517-.57c1.49-.897 1.642-2.553.316-3.69C4.79 6.676 4.5 5.222 4.968 3.604 5.512 1.745 7.445.81 9.556 1.316c1.858.442 2.944 2.174 2.628 4.159-.151.922-.543 1.694-1.276 2.326-1.403 1.188-1.226 2.87.354 3.805.986.581 2.072.644 3.185.619 1.402-.025 1.34-.114 1.327 1.327 0 .72-.026 1.429.012 2.15.026.594-.19.821-.796.821-2.174-.013-4.347 0-6.508 0zm1.971-4.323c-.076-.114-.114-.177-.152-.215-1.643-1.58-1.58-3.388.089-4.918.392-.366.72-.897.872-1.415.467-1.618-.405-3.173-1.896-3.527-1.794-.417-3.324.392-3.652 1.997-.24 1.176 0 2.225.998 3.06 1.567 1.314 1.58 3.273.114 4.676-.076.076-.152.165-.303.316 1.34.026 2.59.026 3.93.026zm4.436.935H2.125v2.478h12.764v-2.478zM8.482 18.81H2.1c-.19 0-.392.026-.556-.037-.127-.051-.291-.24-.291-.354a.62.62 0 01.278-.43c.152-.076.366-.038.556-.038h12.827c.165 0 .367-.038.493.038.14.076.316.253.316.392s-.164.328-.316.392c-.164.075-.366.038-.556.038h-6.37z'
      />
    </svg>
  );
}

export default Icon;
