import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { makePluralIfNeeded, makeStringFromTemplate } from 'src/tools/string.tools';
import { render } from 'src/tools/render.tools';
import { ifTrue } from 'src/tools/logic.tools';
import { makePercent } from 'src/tools/number.tools';
import { t } from 'src/tools/text.tools';

import { FormDataBatchItem } from 'src/models/general.model';
import { getQtyOfUploaded, getQtyOfUploads, getUploads } from 'src/store/files/files.getters';

import { H6, P6 } from 'src/components/Typography';
import { ProgressBar } from 'src/components/ProgressBar';

import { CloseButton } from 'src/components/Button';
import { TextTooltip } from 'src/components/TextTooltip';
import { UploadItem } from './UploadItem';

import classes from './UploadProgressPopup.module.scss';
import { getIsUploadPopupOpen } from 'src/store/app/app.getters';
import { getActiveResizableWindowId, getIsSidebarOpen, setIsUploadPopupIsOpen } from 'src/store/app/app.reducer';
import { requestCancelAll, requestReload, requestRemoveUpload } from 'src/store/files/files.actions';
import { removeUploads } from 'src/store/files/files.reducer';

const TXT = t(['uploadFile', 'popup']);

// new-ui
export function UploadProgressPopup() {
  const dispatch = useDispatch();

  const uploads = useSelector(getUploads);

  const qtyOfUploads = useSelector(getQtyOfUploads);

  const qtyOfUploaded = useSelector(getQtyOfUploaded);

  const isOpen = useSelector(getIsUploadPopupOpen);

  const isSidebarOpen = useSelector(getIsSidebarOpen);

  const isModalOpen = !!useSelector(getActiveResizableWindowId);

  if (!isOpen) return <></>;

  function handleClosePopup() {
    dispatch(setIsUploadPopupIsOpen(false));
    dispatch(removeUploads());
  }

  function handleRemoveItem(id: string) {
    return function call() {
      dispatch(requestRemoveUpload(id));
    };
  }

  function handleCancelAll() {
    dispatch(requestCancelAll());
  }

  function handleReloadItem(id: string) {
    return function call() {
      dispatch(requestReload(id));
    };
  }

  function renderMessage() {
    return (
      <P6>
        {makeStringFromTemplate(TXT('progressFiles'), [
          qtyOfUploaded,
          makePluralIfNeeded(qtyOfUploaded, 'file', false),
          qtyOfUploads,
        ])}
      </P6>
    );
  }

  function renderTitle() {
    return (
      <H6 className={classes.title}>
        {makeStringFromTemplate(TXT('title'), [qtyOfUploads, makePluralIfNeeded(qtyOfUploads, 'file', false)])}
      </H6>
    );
  }

  function renderSubTitle() {
    return (
      <div className={clsx('row w-100p a-center j-between', classes['sub-title-row'])}>
        {renderMessage()}
        <button
          data-for='cancel-tooltip'
          data-tip={TXT(['cancel', 'tooltip'])}
          className={classes['cancel-button']}
          onClick={handleCancelAll}
        >
          {TXT(['cancel', 'label'])}
        </button>
        <TextTooltip id='cancel-tooltip' />
      </div>
    );
  }

  function renderUpload(upload: FormDataBatchItem) {
    return (
      <UploadItem
        key={upload.id}
        {...upload}
        onRemove={handleRemoveItem(upload.id)}
        onReload={handleReloadItem(upload.id)}
      />
    );
  }

  return (
    <div
      className={clsx('column j-start', classes.container, 'animate__animated animate__slideInUp', {
        [classes['sidebar-offset']]: isSidebarOpen && !isModalOpen,
      })}
    >
      <header className={clsx('row a-center j-between w-100p', classes.header)}>
        {renderTitle()}
        <CloseButton className={classes.close} onClick={handleClosePopup} />
      </header>
      <section className={clsx('column')}>
        {renderSubTitle()}
        <ProgressBar
          hideValue
          value={makePercent(qtyOfUploaded, qtyOfUploads)}
          className={classes['main-progress-bar']}
        />
      </section>
      <ul className={clsx(classes.uploads, 'column scroll-bar', ifTrue(qtyOfUploads > 6, 'scroll-bar-enable'))}>
        {render(uploads, renderUpload)}
      </ul>
    </div>
  );
}
