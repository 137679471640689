import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ManagerReqDetailPage,
  ManagerReqLine,
  ManagerReqTotals,
  Maybe,
  Meta,
  PageInfo,
  PageQuery,
  Results,
  Scenario,
  Sidebar,
  VersionedPeriod,
  ID,
} from 'src/models';
import { LineItemChangeIndex } from 'src/models/lineItem.model';
import { INITIAL_MANAGE_REQ_STATE } from './managerReq.model';
import { History } from 'src/models/history.model';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { insertData, updateData } from 'src/tools/array.tools';
import { Permission } from 'src/models/permissions.model';
import { ReqDetailChangeOrder } from 'src/models/reqDetail.model';

export const slice = createSlice({
  name: 'managerReq',
  initialState: INITIAL_MANAGE_REQ_STATE,
  reducers: {
    clearManagerReqState: state => ({ ...INITIAL_MANAGE_REQ_STATE, current: state.current }),
    setManagerReqData: (state, action: PayloadAction<Maybe<ManagerReqDetailPage>>) => {
      state.page = action.payload;
    },
    setManagerReqCurrentPeriod: (state, action: PayloadAction<Maybe<VersionedPeriod>>) => {
      state.currentPeriod = action.payload;
    },
    setManagerReqSelectedPeriod: (state, action: PayloadAction<Maybe<VersionedPeriod>>) => {
      state.selectedPeriod = action.payload;
    },
    setManagerReqSidebar: (state, action: PayloadAction<Maybe<Sidebar>>) => {
      state.sidebar = action.payload;
    },

    setManagerReqMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.meta = action.payload;
    },

    setManagerReqHistory: (state, action: PayloadAction<History[]>) => {
      state.history = action.payload;
    },

    updateManagerReqHistory: (state, action: PayloadAction<History>) => {
      state.history = state.history.map(h => (h.id === action.payload.id ? action.payload : h));
    },

    setManagerReqBaseContract: (state, action: PayloadAction<Maybe<Results<ManagerReqLine[]>>>) => {
      state.baseContractData = action.payload?.data;
      state.baseContractPages = [action.payload?.meta?.page ?? 1];
    },

    updateManagerReqBaseContract: (state, action: PayloadAction<Maybe<Results<ManagerReqLine[]>>>) => {
      if (state.baseContractData && action.payload) {
        const { data, meta, info } = action.payload;

        const pages = state.baseContractPages ?? [];

        const isNewPage = !meta?.page || !pages.includes(meta.page);

        state.baseContractData = !isNewPage
          ? updateData(state.baseContractData, data)
          : insertData(state.baseContractData, data);

        state.baseContractInfo = info;
        state.baseContractPages = [...pages, meta?.page ?? 1];
      }
    },

    removeManagerReqBaseContractLineItem: (state, action: PayloadAction<ID>) => {
      if (state.baseContractData) {
        const removingLineIndex = state.baseContractData.findIndex(line => line.id === action.payload);

        state.baseContractData.splice(removingLineIndex, 1);
      }
    },

    updateManagerReqLineItem: (state, { payload: item }: PayloadAction<ManagerReqLine>) => {
      if (state.baseContractData)
        state.baseContractData = state.baseContractData.map(i => (i.id === item.id ? item : i));
    },

    updateManagerReqLineItemIndex: (
      state,
      { payload: { target_row_id, next_row_id } }: PayloadAction<LineItemChangeIndex>,
    ) => {
      if (state.baseContractData) {
        const targetLineItem = state.baseContractData.find(i => i.id === target_row_id);

        const nextLineItem = state.baseContractData.find(i => i.id === next_row_id);

        const targetReqIndex = targetLineItem?.req_index;

        if (targetLineItem && nextLineItem) {
          const targetLineItemIndex = state.baseContractData.indexOf(targetLineItem);

          const nextLineItemIndex = state.baseContractData.indexOf(nextLineItem);

          // swap lineItems indexes in state
          [state.baseContractData[nextLineItemIndex], state.baseContractData[targetLineItemIndex]] = [
            state.baseContractData[targetLineItemIndex],
            state.baseContractData[nextLineItemIndex],
          ];

          // swap req_inexes
          targetLineItem.req_index = nextLineItem.req_index;
          nextLineItem.req_index = targetReqIndex;

          state.baseContractData = state.baseContractData.map(i => (i.id === next_row_id ? nextLineItem : i));
          state.baseContractData = state.baseContractData.map(i => (i.id === target_row_id ? targetLineItem : i));
        }
      }
    },

    setInitialManagerReqBaseContract: (state, action: PayloadAction<ManagerReqLine[]>) => {
      if (state.baseContractData) state.baseContractData = action.payload;
    },

    clearManagerReqBaseContract: state => {
      state.baseContractData = undefined;
      state.baseContractPages = undefined;
      state.changeOredersPages = undefined;
    },

    setManagerReqBaseContractMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.baseContractMeta = action.payload;
    },
    setManagerReqBaseContractQuery: (state, action: PayloadAction<Maybe<PageQuery>>) => {
      state.baseContractQuery = action.payload;
    },
    setManagerReqBaseContractInfo: (state, action: PayloadAction<Maybe<PageInfo>>) => {
      state.baseContractInfo = action.payload;
    },
    setManagerReqBaseContractScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.baseContractScenario = action.payload;
    },
    setManagerReqBaseContractTotals: (state, action: PayloadAction<Maybe<ManagerReqTotals>>) => {
      state.baseContractTotals = action.payload;
    },
    setManagerReqChangeOrders: (state, action: PayloadAction<Maybe<Results<ReqDetailChangeOrder[]>>>) => {
      state.changeOrdersData = action.payload?.data;
      state.changeOrdersPages = [action.payload?.meta?.page ?? 1];
    },
    updateManagerReqChangeOrders: (state, action: PayloadAction<Maybe<Results<ReqDetailChangeOrder[]>>>) => {
      if (state.changeOrdersData && action.payload) {
        const { data, meta } = action.payload;

        const pages = state.changeOrdersPages ?? [];

        const isNewPage = !meta?.page || !pages.includes(meta.page);

        state.changeOrdersData = !isNewPage
          ? updateData(state.changeOrdersData, data)
          : insertData(state.changeOrdersData, data);

        state.changeOrdersPages = [...pages, meta?.page ?? 1];
      }
    },
    setManagerReqChangeOrdersMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.changeOrdersMeta = action.payload;
    },
    setManagerReqChangeOrdersQuery: (state, action: PayloadAction<Maybe<PageQuery>>) => {
      state.changeOrdersQuery = action.payload;
    },
    setManagerReqChangeOrdersScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.changeOrdersScenario = action.payload;
    },
    setManagerReqChangeOrdersTotals: (state, action: PayloadAction<Maybe<ManagerReqTotals>>) => {
      state.changeOrdersTotals = action.payload;
    },
    setManagerReqTotals: (state, action: PayloadAction<Maybe<ManagerReqTotals>>) => {
      state.tabTotals = action.payload;
    },
    setManagerReqTotalsScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.tabTotalsScenario = action.payload;
    },
    setManagerReqReqPeriods: (state, action: PayloadAction<Maybe<ReqPeriod[]>>) => {
      state.reqPeriods = action.payload;
    },
    setManagerReqPagePermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.pagePermissions = action.payload;
    },
    setManagerReqUploadInProgress: (state, action: PayloadAction<Maybe<boolean>>) => {
      state.uploadInProgress = Boolean(action.payload);
    },
  },
});

export const {
  clearManagerReqState,
  setManagerReqBaseContract,
  updateManagerReqBaseContract,
  clearManagerReqBaseContract,
  setManagerReqBaseContractMeta,
  setManagerReqBaseContractQuery,
  setManagerReqBaseContractScenario,
  setManagerReqBaseContractTotals,
  setManagerReqChangeOrders,
  updateManagerReqChangeOrders,
  setManagerReqChangeOrdersMeta,
  setManagerReqChangeOrdersQuery,
  setManagerReqChangeOrdersScenario,
  setManagerReqChangeOrdersTotals,
  setManagerReqCurrentPeriod,
  setManagerReqData,
  setManagerReqHistory,
  setManagerReqMeta,
  setManagerReqSelectedPeriod,
  setManagerReqTotals,
  setManagerReqTotalsScenario,
  setManagerReqSidebar,
  setManagerReqReqPeriods,
  updateManagerReqHistory,
  setManagerReqPagePermissions,
  updateManagerReqLineItem,
  setManagerReqUploadInProgress,
  setManagerReqBaseContractInfo,
  updateManagerReqLineItemIndex,
  setInitialManagerReqBaseContract,
  removeManagerReqBaseContractLineItem,
} = slice.actions;

export default slice.reducer;
