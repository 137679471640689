import ReactTooltip, { TooltipProps } from 'react-tooltip';
import clsx from 'clsx';

import { TOOLTIP } from 'src/config';
import { Portal } from '../Portal';

export function TextTooltip(props: Partial<TooltipProps>) {
  return (
    <Portal parentId='tooltips'>
      <ReactTooltip {...TOOLTIP} {...props} className={clsx(TOOLTIP.className, props.className)} />
    </Portal>
  );
}
