import ReactDOM from 'react-dom';

import { MaybeNull } from 'src/models/general.model';

export function clearModals() {
  const modalsElements = document.getElementById('modal')?.children ?? [];

  for (let i = 0; i <= modalsElements.length; i += 1) removeElement(modalsElements[i]);
}

export const removeById = (id: string) => removeElement(document.getElementById(id));

export function removeElement(element: MaybeNull<Element>) {
  if (element) {
    ReactDOM.unmountComponentAtNode(element);
    element.remove();
  }
}
