import { processError } from 'src/tools/events.tools';

import { Action, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain } from 'src/models/query.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { AttributeChange } from 'src/models';
import { setAttributeChanges } from 'src/store/attributeChanges/attributeChanges.reducer';

function processAttributeChanges({ dispatch, getService }: ProcessProps) {
  const processGetAttributeChanges = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'attributeChanges', state: true }));
    getService().request(
      { [QueryDomain.ATTRIBUTE_CHANGES]: QueryAction.GET, payload: action.payload },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<AttributeChange[]>>(response.payload);

          dispatch(setAttributeChanges(result?.data));

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request attribute changes',
            callbackFn: action.meta?.callback,
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'attributeChanges', state: false }));
      },
    );
  };

  return {
    processGetAttributeChanges,
  };
}

export { processAttributeChanges };
