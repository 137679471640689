import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_zoom-in'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M11 19a8 8 0 100-16 8 8 0 000 16z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M21 21l-4.35-4.35' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M11 8v6' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M8 11h6' />
    </svg>
  );
}

export default Icon;
