import { useMemo } from 'react';
import { createCanBoundTo } from '@casl/react';
import clsx from 'clsx';

import { t } from 'src/tools/text.tools';

import { ID, Maybe } from 'src/models/general.model';
import { getMainProjectPermissions } from 'src/store/portfolio/portfolio.getters';
import { useAbilities } from 'src/hooks';
import { UserPreview } from 'src/models';

import { SecondaryButton } from '../Button';
import { H3, P2 } from '../Typography';
import { TeamMembersPreview } from 'src/domains/team/TeamMembersPreview';

import styles from './MainProjectCard.module.scss';

const TXT = t(['mainProject', 'buttons']);

interface Props {
  id: ID;
  name: string;
  address: Maybe<string>;
  team_count: number;
  team_members: UserPreview[];
  onEdit: () => void;
  onAddSubProject: () => void;
  onClickMembersPreview: () => void;
}

// new-ui
export function MainProjectCard({
  id,
  name,
  address,
  onEdit,
  onAddSubProject,
  team_count,
  team_members,
  onClickMembersPreview,
}: Props) {
  const abilities = useAbilities(state => getMainProjectPermissions(state)(id));

  const Can = createCanBoundTo(abilities);

  const members = useMemo(() => {
    return team_members.filter(member => member.linked);
  }, [team_members]);

  function handleOnEditClick() {
    onEdit();
  }

  function handleOnAddSubprojectClick() {
    onAddSubProject();
  }

  return (
    <div className={clsx(styles.container, 'column a-start j-between')}>
      <div className={clsx(styles.block, 'column a-start j-start w-100p')}>
        <H3 className='t-ellipsis w-100p' title={name}>
          {name}
        </H3>
        <P2 className={styles.address} title={address}>
          {address}
        </P2>
      </div>
      <Can I='read' a='team'>
        <TeamMembersPreview userPreviews={members} totalCount={team_count} onClick={onClickMembersPreview} />
      </Can>
      <div className='row w-100p j-start'>
        <Can I='update' a='main_project'>
          <SecondaryButton isSmall label={TXT('editMainProject')} onClick={handleOnEditClick} />
        </Can>
        <Can I='create' a='project'>
          <div className='space-horizontal' />
          <SecondaryButton
            isSmall
            label={TXT('addSubProject')}
            rightIconId='add'
            onClick={handleOnAddSubprojectClick}
          />
        </Can>
      </div>
    </div>
  );
}
