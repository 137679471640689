import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_load-primary'
      width='32'
      height='32'
      fill='none'
      stroke='none'
      viewBox='0 0 32 32'
    >
      <path
        stroke='none'
        fill='var(--primary-default)'
        d='M15.733 5.6a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333zm9.428 3.772a2.533 2.533 0 110-5.067 2.533 2.533 0 010 5.067zm3.905 9.295a2.4 2.4 0 110-4.8 2.4 2.4 0 010 4.8zm-3.905 9.294a2.267 2.267 0 110-4.533 2.267 2.267 0 010 4.533zm-9.428 3.772a2.133 2.133 0 110-4.266 2.133 2.133 0 010 4.266zm-9.428-4.038a2 2 0 110-4 2 2 0 010 4zM2.4 18.133a1.867 1.867 0 110-3.733 1.867 1.867 0 010 3.733zm3.905-9.561a1.733 1.733 0 110-3.467 1.733 1.733 0 010 3.467z'
      />
    </svg>
  );
}

export default Icon;
