import { processError } from 'src/tools/events.tools';
import { pick } from 'ramda';

import { Action, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import {
  PredefinedLists,
  PredefinedNestedLists,
  PredefinedStateMachines,
  QueryAction,
  QueryDomain,
} from 'src/models/query.model';
import { setCustomIsLoading } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import {
  PredefinedListsObject,
  PredefinedNestedListsObject,
  PredefinedStateMachinesObject,
} from 'src/models/predefined_lists.model';
import {
  addPredefinedLists,
  addPredefinedNestedLists,
  addPredefinedStateMachines,
  setPredefinedLists,
  setPredefinedNestedLists,
  setPredefinedStateMachines,
} from 'src/store/predefinedLists/predefinedLists.reducer';

export function processPredefinedLists({ dispatch, getService }: ProcessProps) {
  const processGetPredefinedLists = (action: Action) => () => {
    dispatch(setCustomIsLoading({ id: 'predefinedLists', state: true }));
    getService().request(
      { [QueryDomain.PREDEFINED_LISTS]: QueryAction.GET, payload: { params: action.payload } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<PredefinedListsObject & PredefinedStateMachinesObject>>(
            response.payload,
          );

          if (result?.data) {
            const lists = pick(Object.values(PredefinedLists), result.data) as PredefinedListsObject;

            const nestedLists = pick(Object.values(PredefinedNestedLists), result.data) as PredefinedNestedListsObject;

            const stateMachines = pick(
              Object.values(PredefinedStateMachines),
              result.data,
            ) as PredefinedStateMachinesObject;

            if (action.payload.lists === 'initial') {
              dispatch(setPredefinedLists(lists));
              dispatch(setPredefinedNestedLists(nestedLists));
              dispatch(setPredefinedStateMachines(stateMachines));
            } else {
              dispatch(addPredefinedLists(lists));
              dispatch(addPredefinedNestedLists(nestedLists));
              dispatch(addPredefinedStateMachines(stateMachines));
            }
          }

          if (action.meta?.callback) action.meta.callback({ isOK: true, payload: result?.data });
        } else {
          processError({
            activityName: 'Request attribute changes',
            callbackFn: action.meta?.callback,
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'predefinedLists', state: false }));
      },
    );
  };

  return {
    processGetPredefinedLists,
  };
}
