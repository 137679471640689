import { processError } from 'src/tools/events.tools';

import {
  Response,
  ProcessProps,
  AnyValue,
  QueryAction,
  QueryDomain,
  ReqPayDocument,
  RecordObject,
  ResponsePayload,
  RequestDocumentsProps,
  ID,
  Action,
} from 'src/models';
import { getDocuments } from 'src/store/attachments/attachments.getters';
import { requestDocument } from 'src/store/attachments/attachments.action';
import { setDocuments, setDocument, updateDocument, deleteDocument } from 'src/store/attachments/attachments.reducer';
import { setPdfDocumentAnnotations } from 'src/store/files/files.reducer';
import { setCustomIsLoading, setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { logUnableToProceed } from 'src/tools/log.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';

function processDocuments({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processDocumentsRequest =
    ({ payload, meta }: Action<RequestDocumentsProps>) =>
    () => {
      dispatch(setDocuments([]));
      setIsBusy({ id: 'documents', state: true, meta });
      getService().request(
        {
          [QueryDomain.DOCUMENTS]: QueryAction.GET,
          payload,
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqPayDocument[]>>(response.payload);

            if (result) dispatch(setDocuments(result.data ?? []));
          } else {
            processError({ activityName: 'Request documents', response });
          }

          setIsBusy({ id: 'documents', state: false, meta });
        },
      );
    };

  const processDocumentRequest =
    ({ payload, meta }: Action<RequestDocumentsProps>) =>
    () => {
      dispatch(setCustomIsUpdating({ id: 'documents', state: true }));

      const documentId = getDocuments(getState())?.find(d => d.required_document_id === Number(meta?.id))?.id;

      getService().request(
        {
          [QueryDomain.DOCUMENTS]: QueryAction.GET,
          payload,
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqPayDocument[]>>(response.payload);

            if (result && meta?.id) {
              const document = result.data.find(d => d.required_document_id === Number(meta.id));

              if (document) {
                dispatch(setDocument(document));

                if (document?.id === documentId) {
                  if (meta?.isUpdate)
                    dispatch(
                      requestDocument(payload as RequestDocumentsProps, { id: meta.id, isUpdate: meta.isUpdate }),
                    );
                }
              }
            }
          } else {
            processError({ activityName: 'Request documents', response });
          }

          dispatch(setCustomIsUpdating({ id: 'documents', state: false }));
        },
      );
    };

  interface UpdateDocumentParams {
    document_id: ID;
    included_in_pay_application?: boolean;
    title?: string;
  }

  const processDocumentUpdateRequest =
    ({ payload, meta }: Action) =>
    () => {
      dispatch(setCustomIsUpdating({ id: 'documents', state: true }));

      const { document_id, ...patchData } = payload as UpdateDocumentParams;

      getService().request(
        {
          [QueryDomain.DOCUMENTS]: QueryAction.UPDATE,
          payload: { document_id, data: patchData },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<ReqPayDocument>>(response.payload);

            if (result?.data) dispatch(updateDocument(result.data));
          } else {
            processError({ activityName: 'Request document update', response });
          }

          dispatch(setCustomIsUpdating({ id: 'documents', state: false }));

          meta?.callback?.();
        },
      );
    };

  const processDocumentDeleteRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'documents', state: true }));

    const { payload: document_id } = action;

    if (!document_id) {
      logUnableToProceed('processPictureDeleteRequest', { document_id });
      return;
    }

    getService().request(
      {
        [QueryDomain.DOCUMENTS]: QueryAction.DELETE,
        payload: { document_id },
      },
      (response: Response<unknown>) => {
        if (response.isOK) {
          dispatch(deleteDocument(document_id));
        } else {
          processError({ activityName: 'Request document delete', response });
        }

        dispatch(setCustomIsUpdating({ id: 'documents', state: false }));

        action.meta?.callback?.({ isOK: true });
      },
    );
  };

  const processDocumentSearchRequest = (payload: { document_id: ID; search: string }) => () => {
    dispatch(setCustomIsLoading({ id: 'search', state: true }));
    getService().request(
      {
        [QueryDomain.DOCUMENTS]: QueryAction.SEARCH,
        payload,
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<RecordObject<AnyValue>>>(response.payload);

          dispatch(setPdfDocumentAnnotations({ id: String(payload.document_id), annotations: result!.data }));
        } else {
          processError({ activityName: 'Request document search', response });
        }

        dispatch(setCustomIsLoading({ id: 'search', state: false }));
      },
    );
  };

  return {
    processDocumentsRequest,
    processDocumentRequest,
    processDocumentUpdateRequest,
    processDocumentDeleteRequest,
    processDocumentSearchRequest,
  };
}

export { processDocuments };
