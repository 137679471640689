import { processError } from 'src/tools/events.tools';

import { AnyValue, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain } from 'src/models/query.model';
import { setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { deserialize } from 'src/tools/string.tools';
import { updateMessage, removeMessage } from 'src/store/notifications/notifications.reducer';
import { NotificationMessage } from 'src/models/notification.model';

function processNotifications({ dispatch, getService }: ProcessProps) {
  const processNotificationsUpdate = (payload: AnyValue) => () => {
    dispatch(setCustomIsUpdating({ id: 'notifications', state: true }));

    getService().request({ [QueryDomain.NOTIFICATIONS]: QueryAction.PATCH, payload }, (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<NotificationMessage>>(response.payload);

        if (result?.data) dispatch(updateMessage(result?.data));
      } else {
        processError({
          activityName: 'Request notifications update',
          response,
        });
      }

      dispatch(setCustomIsUpdating({ id: 'notifications', state: false }));
    });
  };

  const processNotificationsDelete = (payload: AnyValue) => () => {
    dispatch(setCustomIsUpdating({ id: 'notifications', state: true }));

    getService().request({ [QueryDomain.NOTIFICATIONS]: QueryAction.DELETE, payload }, (response: Response<string>) => {
      if (response.isOK) {
        dispatch(removeMessage(payload.notification_id));
      } else {
        processError({
          activityName: 'Request notifications delete',
          response,
        });
      }

      dispatch(setCustomIsUpdating({ id: 'notifications', state: false }));
    });
  };

  return {
    processNotificationsUpdate,
    processNotificationsDelete,
  };
}

export { processNotifications };
