import { Helmet } from 'react-helmet';

export function PSPDFKitHelmet() {
  return (
    <Helmet>
      <link rel='prefetch' href='/pspdfkit-lib/chunk-standalone-375f5232b6e41a45.js' as='script' />
      <link rel='prefetch' href='/pspdfkit-lib/pspdfkit.css' as='style' />
      <link rel='prefetch' href='/pspdfkit-lib/chunk-locale-en-cae06a35fdd00894.js' as='script' />
      <link rel='prefetch' href='/pspdfkit-lib/pspdfkit-91a38b3f5549cf64.wasm.js' as='script' />
      <link rel='prefetch' href='/pspdfkit-lib/pspdfkit-647117a58f7b8ef7.wasm' />
    </Helmet>
  );
}
