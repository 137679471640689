import clsx from 'clsx';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useId, useNavigation } from 'src/hooks';
import { ifTrue } from 'src/tools/logic.tools';
import { UseIcons } from 'src/models/interface.model';

import { UseIcon } from '../UseIcon';
import { TextTooltip } from '../TextTooltip';

import classes from './Button.module.scss';

export interface ButtonProps {
  buttonSize?: number;
  classNames?: { readonly [key: string]: string };
  isDisabled?: boolean;
  isSelected?: boolean;
  isSmall?: boolean;
  isXSmall?: boolean;
  label?: string | JSX.Element;
  leftIconId?: UseIcons;
  onClick?: () => void;
  isPrevent?: boolean; // prevent default event
  rightIconId?: UseIcons;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom' | 'right' | 'left';
  to?: string;
  style?: React.CSSProperties;
  dataTestId?: string;
  targetBlank?: boolean;
  checkingFocus?: { isSidebarOpen: boolean };
  animationName?: string;
  className?: string;
}

// new-ui
export function Button({
  buttonSize,
  classNames,
  isDisabled,
  isSelected = false,
  isSmall,
  isXSmall,
  label,
  leftIconId,
  onClick,
  isPrevent,
  rightIconId,
  tooltip,
  tooltipPosition,
  to,
  style,
  dataTestId,
  targetBlank,
  checkingFocus,
  animationName,
  className,
}: ButtonProps) {
  if (!!to === !!onClick) {
    throw new Error('Button: to or onClick prop should be specified, not both');
  }

  if (isSmall && isXSmall) {
    throw new Error('Button can be either small or xsmall');
  }

  const ref = React.useRef(null);

  const [isFocus, setIsFocus] = React.useState(false);

  const tooltipId = useId();

  const { go } = useNavigation();

  const { pathname, search } = useLocation();

  const isNewPath = !!to && to !== `${pathname}${search}`;

  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (isPrevent) e.preventDefault();

    if (onClick) {
      onClick();
    }

    if (to && !targetBlank) {
      if (isDisabled || (!e.metaKey && !e.ctrlKey)) {
        e.preventDefault();

        if (!isDisabled && isNewPath) go(to);
      }
    }
  }

  function handleOnMouseUp() {
    if (checkingFocus) {
      if (!checkingFocus?.isSidebarOpen && !isFocus && onClick) onClick();
      else return;
    }
  }

  function handleOnFocus() {
    setIsFocus(true);
  }

  function renderIcon(iconId?: UseIcons, left = false) {
    if (!iconId) return;

    return (
      <UseIcon
        iconId={iconId}
        className={clsx(
          'row centered',
          left ? classes['icon-left'] : classes['icon-right'],
          classNames?.['icon-update'],
        )}
        style={ifTrue(buttonSize, { height: buttonSize, width: buttonSize })}
      />
    );
  }

  function addPadding() {
    if (!leftIconId && !rightIconId) return '';

    let style = '';

    let size;

    if (isSmall) {
      size = 'small';
    } else if (isXSmall) {
      size = 'xsmall';
    } else {
      size = 'large';
    }

    if (leftIconId && label) style = clsx(style, classes[`${size}-with-left`]);

    if (rightIconId && label) style = clsx(style, classes[`${size}-with-right`]);

    // TODO: add option for large
    if (leftIconId && rightIconId && label) style = clsx(style, classes[`${size}-with-both`]);

    return style;
  }

  function renderLabel() {
    const title = typeof label === 'string' && !isDisabled ? label : '';

    return (
      <span title={title} className={clsx(classes.label, classNames?.label)}>
        {label}
      </span>
    );
  }

  function renderTooltip() {
    return <TextTooltip id={tooltipId} place={tooltipPosition} />;
  }

  let sizeClassName;

  if (isSmall) {
    sizeClassName = classes.small;
  } else if (isXSmall) {
    sizeClassName = classes.xsmall;
  } else {
    sizeClassName = classes.large;
  }

  const wrapElement = ifTrue(to, 'a', 'button');

  return React.createElement(
    wrapElement,
    {
      className: clsx(
        classes.container,
        classNames?.container,
        sizeClassName,
        addPadding(),
        (leftIconId || rightIconId) && !label && classes['icon-only'],
        ifTrue(classNames?.selected && isSelected, classNames?.selected),
        // so we could style additionally without any props
        ifTrue(isSelected, 'selected'),
        ifTrue(!isDisabled && isNewPath, 'c-pointer'),
        ifTrue(!label && leftIconId, classes['icon-only']),
        ifTrue(animationName, classes[`${animationName}`]),
        className,
      ),
      'data-testid': dataTestId,
      disabled: isDisabled,
      onClick: handleOnClick,
      href: to,
      rel: ifTrue(wrapElement === 'a', 'noopener noreferrer'),
      // type='button' is important here, so the button click won't be triggered on Enter press in the form
      type: ifTrue(wrapElement === 'button', 'button'),
      style,
      target: ifTrue(targetBlank, '_blank'),
      onMouseUp: checkingFocus ? handleOnMouseUp : undefined,
      onFocus: handleOnFocus,
      ref,
    },
    <span className={clsx(classes['inner-container'], 'row centered nowrap')} data-tip={tooltip} data-for={tooltipId}>
      {ifTrue(leftIconId, renderIcon(leftIconId, true))}
      {ifTrue(label, renderLabel)}
      {ifTrue(rightIconId, renderIcon(rightIconId))}
      {ifTrue(tooltip, renderTooltip)}
    </span>,
  );
}
