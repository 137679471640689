import { makeMatchObject } from 'src/tools/object.tools';

export const MATCH_CURRENCY_KEY_FLAG_URL = makeMatchObject(
  {
    AED: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg',
    EUR: 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg',
    USD: 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg',
  },
  '',
);
