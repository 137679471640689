import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_map-pin-new'
      width='24'
      height='24'
      fill='none'
      stroke='none'
      viewBox='0 0 24 24'
    >
      <path fill='#fff' stroke='none' d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1118 0z' />
      <path fill='#00A3FF' stroke='none' d='M12 12a2 2 0 100-4 2 2 0 000 4z' />
    </svg>
  );
}

export default Icon;
