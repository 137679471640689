import { createContext } from 'react';
import { Ability } from '@casl/ability';

import { ChangeOrder } from 'src/models/change_order.model';
import { AnyValue, Either, ID, Maybe, RecordObject, TotalByStatus } from 'src/models/general.model';
import { ManagerReqLine } from 'src/models/manager_req.model';
import { MainProject } from 'src/models/main_project.model';
import { ReqSummaryTermsTableData, ReqSummaryTradesTableData } from 'src/models/reqSummary.model';
import { Scenario } from 'src/models/scenario.model';
import { Trade } from 'src/models/trade.model';
import { UserAdmin } from 'src/models/user.model';

import { SpreadsheetStyles } from 'src/models/styles.model';
import {
  ReqDetailBaseContract,
  ReqDetailListSection,
  ReqDetailChangeOrder,
  ReqDetailLine,
} from 'src/models/reqDetail.model';
import { FormType } from 'src/models/form.model';
import { History } from 'src/models/history.model';
import { InvitedMember, Member, Meta, PageQuery, Result } from 'src/models';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { ManagerReqCOAutobilling } from 'src/models/mainContract.model';
import { HistoricalUploadsError, HistoricalUploadsUnsuccessful } from 'src/models/historicalUploads.model';

export type SpreadsheetData = Maybe<
  | ManagerReqLine[]
  | ReqDetailLine[]
  | ReqDetailChangeOrder[]
  | ReqSummaryTradesTableData[]
  | ReqSummaryTermsTableData[]
  | ChangeOrder[]
  | MainProject[]
  | Trade[]
  | ReqDetailListSection
  | ReqDetailBaseContract[]
  | History[]
  | ReqPeriod[]
  | InvitedMember[]
  | Member[]
  | ManagerReqCOAutobilling[]
  | RecordObject[]
  | HistoricalUploadsUnsuccessful[]
  | HistoricalUploadsError[]
  | UserAdmin[]
>;

// Data grouping definition
export interface Group {
  isSticky?: boolean; // the row with group name can be sticky
  className?: string | ((row: AnyValue) => string); // you can add a custom class to the row with group name
  key: string | ((row: AnyValue) => Maybe<string>); // unique key/field to determine group of the current row
  label?: string | ((row: AnyValue) => Maybe<string>); // label of the group, if now set, key is used
}

interface SpreadsheetContextType {
  abilities?: Ability; // abilities to handle permissions
  actions?: RecordObject<Maybe<(...args: AnyValue[]) => void>>; // object of events and listeners each event is a sting 'cellName.eventName'
  checkedRowsKeys?: string[]; // for ParserContext.isChecked calculation by rowKey
  checkedRows?: ID[]; // for ParserContext.isChecked calculation by row.id
  data: SpreadsheetData;
  design?: SpreadsheetStyles;
  highlightedRows?: ID[];
  isDisabled?: boolean; // disable billing and highlighted columns will be grey
  isEmbedded?: boolean; // if true => remove shadow, rounded corners
  isHiddenHeader?: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  movingLine?: ID;
  isStripped?: boolean; // table's rows will be stripped (default value is true)
  groups?: Group[];
  meta?: Maybe<Meta>; // to display records number in query bar
  query?: Maybe<Partial<PageQuery>>; // to display records number in query bar
  onQueryChange?: (query: Partial<PageQuery>) => void; // changing query by filtering/sorting/searching
  onSettingsChange?: (hiddenColumns: FormType) => void;
  requestMore?: (key?: string) => void; // request more data
  scenario: Scenario;
  title?: string; // Table title, displayed as Accordion label
  totals?: Record<string, number>; // values for totals row
  totalsByStatus?: Record<string, TotalByStatus[]>; // values for totals dropdown (by each status)
  errorsCount?: number; // number of errors in the table to display in the top
  TXT?: (key: Either<string, string[]>) => string; // TXT function for customization texts in table (e.g. tooltips on disabled elements)
  uiBlockerText?: string; // use this field when you need to block the table (e.g. while uploading)
  resource?: string; // for checking tabs
  onMoveLineItemCancel?: () => void;
  onMoveLineItemSave?: (lineItem: RecordObject<AnyValue>) => void;
  existingGroups?: RecordObject<RecordObject<Maybe<AnyValue[]>>>;
  isSubmissionIsOpen?: boolean;
  isLockedPeriod?: boolean;
  nextColumns?: string[]; // for hidden sticky column
  onChange?: (id: ID, fieldName: string, value: AnyValue, callback?: (data: Result) => void) => void;
  isPageEditable?: boolean;
}

export const SpreadsheetContext = createContext({} as SpreadsheetContextType);
