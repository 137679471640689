import * as React from 'react';

export default function IconHouse() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='38' viewBox='0 0 35 38' stroke='none'>
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' transform='translate(-311 -1302)'>
          <g transform='translate(311 1302)'>
            <path
              stroke='none'
              d='M32.1466026 7.04247863L32.1466026 -4.26325641e-14 25.7648611 -4.26325641e-14 25.7648611 2.39316239 5.26630342 2.39316239 1.00123932 10.3703953 33.9259188 10.3703953z'
            />
            <path
              stroke='none'
              d='M32.717 34.776v-19.06h2.21v-3.102H0v3.101h2.21v19.06H.113v2.244h34.814v-2.243h-2.21zm-7.6-16.104h2.243v3.19h-2.243v-3.19zm-8.775 0h2.243v3.19h-2.243v-3.19zm-6.532 8.7H7.567V24.18H9.81v3.19zm0-5.51H7.567v-3.19H9.81v3.19zm11.567 12.914h-2.243V27.77h-3.34v7.005H13.55v-9.249h7.827v9.249zm3.74-10.595h2.243v3.19h-2.243v-3.19z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
