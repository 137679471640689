import { Meta, PageQuery } from 'src/models/results.model';
import { MainProject } from 'src/models/main_project.model';
import { Maybe } from 'src/models/general.model';
import { Permission } from 'src/models/permissions.model';

export interface PortfolioStateModel {
  meta?: Maybe<Meta>;
  mainProjects?: Maybe<MainProject[]>;
  query?: Maybe<PageQuery>;
  permissions?: Maybe<Permission[]>;
}

export const INITIAL_PORTFOLIO_STATE: PortfolioStateModel = {};
