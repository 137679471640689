import {
  ChangeOrder,
  Meta,
  Totals,
  PageQuery,
  Scenario,
  Maybe,
  ShowScenario,
  ChangeOrderHistory,
  VersionedPeriod,
} from 'src/models';
import { Permission } from 'src/models/permissions.model';

export interface ChangeOrdersState {
  meta?: Maybe<Meta>;
  orders?: Maybe<ChangeOrder[]>;
  scenario?: Maybe<Scenario>;
  query?: Maybe<PageQuery>;
  order?: Maybe<ChangeOrder>;
  showScenario?: Maybe<ShowScenario>;
  showTotals: boolean;
  totals?: Maybe<Record<string, Totals>>;
  history?: Maybe<ChangeOrderHistory[]>;
  pagePermissions?: Maybe<Permission[]>;
  currentPeriod?: Maybe<VersionedPeriod>;
}

export const INITIAL_CHANGE_ORDERS_STATE: ChangeOrdersState = {
  showTotals: true,
};
