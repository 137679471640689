import * as React from 'react';

import { Button, ButtonProps } from '../Button';

import classes from './PlainWhiteButton.module.scss';

export type PlainWhiteButtonProps = Omit<ButtonProps, 'classNames'>;

// new-ui
export function PlainWhiteButton(props: PlainWhiteButtonProps) {
  return <Button classNames={classes} {...props} />;
}
