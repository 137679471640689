import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { FormScenario } from 'src/models/form.model';
import { FormScenarioType } from 'src/models/uiScenarios.model';
import { requestFormScenario } from 'src/store/uiScenarios/uiScenarios.actions';
import { ID, Maybe, RecordObject } from 'src/models';
import { getFormUiScenario } from 'src/store/uiScenarios/uiScenarios.getters';
import { getCustomIsLoading } from 'src/store/loading/loading.reducer';

/**
 * hook for patching form scenario
 * @param originalScenario - original form scenario
 * @param type - scenario name
 * @param params - additional params (e.g. project_id)
 * @return scenario - patched form scenario
 * @return isLoading - patching status
 */
export const useFormScenario = (
  originalScenario: FormScenario,
  type: FormScenarioType,
  { project_id }: RecordObject<Maybe<ID>> = {},
) => {
  const dispatch = useDispatch();

  const patch = useSelector(getFormUiScenario)(type);

  const isLoading = useSelector(getCustomIsLoading)('formScenario');

  const scenario = useMemo(() => {
    const patchedKeys = Object.keys(patch ?? {});

    if (!patchedKeys.length) return originalScenario;

    const result = { ...originalScenario };

    patchedKeys.forEach(key => {
      if (result[key]) result[key] = { ...result[key], ...patch?.[key] };
    });

    return result;
  }, [originalScenario, patch]);

  useEffect(() => {
    if (!patch)
      dispatch(
        requestFormScenario({
          form_type: FormScenarioType.TRADE_CONTRACT_CREATE,
          project_id,
        }),
      );
  }, [dispatch, patch, project_id]);

  return { scenario, isLoading, name: type };
};
