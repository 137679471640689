import React from 'react';

function Icon() {
  return (
    <svg
      data-testid='icon_user-plus'
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <g fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' clipPath='url(#clip-user-plus)'>
        <path d='M10.667 14v-1.333A2.667 2.667 0 008 10H3.334a2.667 2.667 0 00-2.667 2.667V14M13.333 5.333v4M15.333 7.333h-4M5.667 7.333a2.667 2.667 0 100-5.333 2.667 2.667 0 000 5.333z' />
      </g>
      <defs>
        <clipPath id='clip-user-plus'>
          <path stroke='none' d='M0 0H16V16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
