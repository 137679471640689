import { MainProjectActions, ProjectActions, UserActions } from 'src/models';
import { Action, Maybe, StateStore, VoidPromise } from 'src/models/general.model';
import { processGetPortfolioRequest, processSubProjectCreateRequest, processUserCheckTokenRequest } from 'src/process';
import { ApiService } from 'src/services/api.service';
import { productionLog } from 'src/tools/log.tools';

type ProcessFn = (store: StateStore, action: Action) => Promise<void>;

let service: Maybe<ApiService> = undefined;

const ACTIONS = new Map<string, ProcessFn>();

/**
 * Middleware to forward process requests
 */
export function apiMiddleware(state: StateStore) {
  return function nextFn(next: (_arg0: Action) => void) {
    return async function withAction(action: Action): VoidPromise {
      next(action);

      // initiate & setup ApiService
      if (!service) service = ApiService.instance(state);

      // setup actions
      if (!ACTIONS.size) {
        productionLog('setting up action processes');
        ACTIONS.set(UserActions.CHECK_TOKEN, processUserCheckTokenRequest);
        ACTIONS.set(MainProjectActions.GET, processGetPortfolioRequest);
        ACTIONS.set(ProjectActions.CREATE, processSubProjectCreateRequest);
      }

      // update API token
      if (action.type === 'user/updateUserToken') service?.updateToken(action.payload);
      else if (ACTIONS) {
        const processFn = ACTIONS.get(action.type);

        if (processFn) processFn(state, action);
      }
    };
  };
}
