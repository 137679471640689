import { Maybe } from 'src/models/general.model';
import { Meta, Scenario } from 'src/models';
import {
  HistoricalUploadsError,
  HistoricalUploadsInfo,
  HistoricalUploadsQuery,
  HistoricalUploadsUnsuccessful,
} from 'src/models/historicalUploads.model';

export interface HistoricalUploadsState {
  info?: Maybe<HistoricalUploadsInfo>;
  unsuccessfulItems?: Maybe<HistoricalUploadsUnsuccessful[]>;
  unsuccessfulItemsQuery?: Maybe<HistoricalUploadsQuery>;
  unsuccessfulItemsMeta?: Maybe<Meta>;
  unsuccessfulItemsScenario?: Maybe<Scenario>;
  errorItems?: Maybe<HistoricalUploadsError[]>;
  errorItemsQuery?: Maybe<HistoricalUploadsQuery>;
  errorItemsMeta?: Maybe<Meta>;
  errorItemsScenario?: Maybe<Scenario>;
}

export const INITIAL_HISTORICAL_UPLOADS_STATE: HistoricalUploadsState = {};
