import * as React from 'react';
import { PlainButton, PlainButtonProps } from '../PlainButton';
import { t } from 'src/tools/text.tools';
import { ifTrue } from 'src/tools/logic.tools';

const TXT = t(['subNavbar', 'buttons']);

interface Props extends Omit<PlainButtonProps, 'label'> {
  suffix?: string;
}

// new-ui
export function SettingsPlainButton({ suffix, ...props }: Props) {
  return (
    <PlainButton
      isSmall
      {...props}
      label={`${TXT('settings')}${ifTrue(suffix, ` ${suffix}`, '')}`}
      leftIconId='settings'
      rightIconId='chevron-down'
    />
  );
}
