import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      stroke='none'
      viewBox='0 0 20 20'
      data-testid='icon_dollar-fill'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M10 18.75a8.75 8.75 0 100-17.5 8.75 8.75 0 000 17.5z'
      />
      <g stroke='#fff' fill='none' strokeLinecap='round' strokeLinejoin='round' clipPath='url(#icon_dollar-fill-clip)'>
        <path d='M10 5.417v9.166M12.083 7.083H8.958a1.458 1.458 0 000 2.917h2.084a1.458 1.458 0 010 2.917H7.5' />
      </g>
      <defs>
        <clipPath id='icon_dollar-fill-clip'>
          <path fill='#fff' d='M0 0H10V10H0z' transform='translate(5 5)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
