import { pick } from 'ramda';
import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  Maybe,
  AnyValue,
  Company,
  PageQuery,
  ID,
  Action,
  ResponsePayload,
  RequestMeta,
} from 'src/models';
import { processError } from 'src/tools/events.tools';
import { sendInfo } from 'src/store/app/app.actions';
import {
  setCompanies,
  setCompaniesMeta,
  setCompaniesQuery,
  setCompany,
  setUserCompany,
  addCompanies,
  clearCompaniesState,
  setCompaniesPermissions,
} from 'src/store/companies/companies.reducer';
import { getCompaniesMeta, getCompaniesQuery } from 'src/store/companies/companies.getters';
import { setCustomIsLoading, setIsLoading, setIsUpdating } from 'src/store/loading/loading.reducer';
import { getCurrentSubprojectId } from 'src/store/app/app.getters';
import { logUnableToProceed } from 'src/tools/log.tools';
import { deserialize } from 'src/tools/string.tools';
import { CompaniesParams } from '../store/companies/companies.model';

function processCompanies({ dispatch, getService, getState }: ProcessProps) {
  const makeQueryFromState = (query: Maybe<Partial<PageQuery>> = {}): PageQuery =>
    ({
      ...getCompaniesQuery(getState()),
      ...query,
    } as PageQuery);

  const makeMetaFromState = (metaUpdate: Maybe<Partial<RequestMeta>>): Partial<RequestMeta> =>
    pick(['page', 'per_page'], {
      ...getCompaniesMeta(getState()),
      ...metaUpdate,
    });

  const processCompaniesRequest =
    (params: CompaniesParams = {}) =>
    () => {
      dispatch(setIsLoading(true));

      getService().request(
        {
          [QueryDomain.COMPANY]: QueryAction.GET,
          payload: {
            project_id: getCurrentSubprojectId(getState()),
            query: makeQueryFromState(params.query),
            meta: makeMetaFromState(params.meta),
          },
        },
        (response: Response<string>) => {
          if (response.isOK) {
            const result = deserialize<ResponsePayload<Company[]>>(response.payload);

            dispatch(setCompanies(result?.data));
            dispatch(setCompaniesQuery(result?.query));
            dispatch(setCompaniesMeta(result?.meta));
            dispatch(setCompaniesPermissions(result?.permissions));
          } else {
            processError({
              activityName: 'Request to get companies',
              response,
            });
          }

          dispatch(setIsLoading(false));
        },
      );
    };

  const processSingleCompanyRequest = (payload: AnyValue) => () => {
    dispatch(setCustomIsLoading({ id: 'company', state: true }));

    getService().request(
      {
        [QueryDomain.COMPANY]: QueryAction.GET_ONE,
        payload: { ...payload, project_id: getCurrentSubprojectId(getState()) },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Company>>(response.payload);

          dispatch(setCompany(result?.data));
        } else {
          processError({
            activityName: 'Request to get a company',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'company', state: false }));
      },
    );
  };

  const processUserCompanyRequest = (payload: AnyValue) => () => {
    dispatch(setCustomIsLoading({ id: 'company', state: true }));

    getService().request(
      {
        [QueryDomain.COMPANY]: QueryAction.GET_ONE,
        payload: { ...payload, project_id: getCurrentSubprojectId(getState()) },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Company>>(response.payload);

          dispatch(setUserCompany(result?.data));
        } else {
          processError({
            activityName: 'Request to get user company',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'company', state: false }));
      },
    );
  };

  const processMoreCompaniesRequest = () => () => {
    const pagination = getCompaniesMeta(getState());

    if (typeof pagination?.next !== 'number') {
      logUnableToProceed('processMoreCompaniesRequest', { page: pagination });
      return;
    }

    dispatch(setIsUpdating(true));

    getService().request(
      {
        [QueryDomain.COMPANY]: QueryAction.GET,
        payload: {
          project_id: getCurrentSubprojectId(getState()),
          query: makeQueryFromState(),
          meta: makeMetaFromState({ page: pagination.next }),
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Company[]>>(response.payload);

          dispatch(addCompanies(result?.data || []));
          dispatch(setCompaniesQuery(result?.query));
          dispatch(setCompaniesMeta(result?.meta));
        } else {
          processError({
            activityName: 'Request to get more companies',
            response,
          });
        }

        dispatch(setIsUpdating(false));
      },
    );
  };

  interface AssignCompanyPayload {
    company_id: ID;
    roles: string[];
  }

  const processAssignCompanyRequest = (action: Action<AssignCompanyPayload>) => () => {
    getService().request(
      {
        [QueryDomain.COMPANY]: QueryAction.ASSIGN,
        payload: {
          project_id: getCurrentSubprojectId(getState()),
          ...action.payload,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          dispatch(clearCompaniesState());
          processCompaniesRequest()();
          dispatch(sendInfo('Company has been assigned.'));
        } else {
          processError({
            activityName: 'Request to assign a company',
            response,
          });
        }

        if (action.meta?.callback) action.meta.callback(response);
      },
    );
  };

  return {
    processCompaniesRequest,
    processSingleCompanyRequest,
    processUserCompanyRequest,
    processMoreCompaniesRequest,
    processAssignCompanyRequest,
  };
}

export { processCompanies };
