import { Maybe, RecordObject } from 'src/models/general.model';
import { Permission } from 'src/models/permissions.model';
import {
  ReqSummaryPageData,
  MaybeReqSummaryTermsData,
  MaybeReqSummaryTradesData,
  ReqSummaryBalance,
  ReqSummarySummaryRow,
  ReqSummaryDashboardData,
} from 'src/models/reqSummary.model';
import { Period, Results } from 'src/models/results.model';
import { ReqPeriod } from 'src/models/reqPeriod.model';
import { Scenario, Sidebar, ManagerReqPageStatuses } from 'src/models';
import { DashboardScenario } from 'src/models/dashboardScenario.model';

export interface ReqSummaryState {
  pageData?: Maybe<ReqSummaryPageData>;
  pageTotals?: Maybe<Results<RecordObject<number>>>;
  summaryData?: Maybe<ReqSummarySummaryRow[]>;
  summaryTotals?: Maybe<RecordObject<number>>;
  summaryScenario?: Maybe<Scenario>;
  tradesConstruction?: MaybeReqSummaryTradesData;
  tradesPreConstruction?: MaybeReqSummaryTradesData;
  generalRequirements?: MaybeReqSummaryTradesData;
  tradesUnawarded?: MaybeReqSummaryTradesData;
  generalConditions?: MaybeReqSummaryTermsData;
  otherTerms?: MaybeReqSummaryTermsData;
  selectedPeriod?: Maybe<Period>;
  currentPeriod?: Maybe<Period>;
  pagePermissions?: Maybe<Permission[]>;
  reqPeriods?: Maybe<ReqPeriod[]>;
  sidebar?: Maybe<Sidebar>;
  pageBalance?: Maybe<ReqSummaryBalance>;
  dashboardData?: Maybe<ReqSummaryDashboardData>;
  dashboardScenario?: Maybe<DashboardScenario>;
  managerReqPageStatus?: Maybe<ManagerReqPageStatuses>;
}

export const INITIAL_REQ_SUMMARY_STATE: ReqSummaryState = {};
