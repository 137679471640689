import * as React from 'react';
import clsx from 'clsx';

import { Maybe } from 'src/models';
import { Badge } from 'src/components/Badge';
import { renderWithIndex } from 'src/tools/render.tools';

import classes from './Tags.module.scss';

interface Props {
  onChange: (value: string[]) => void;
  value: Maybe<string[]>;
  separator?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  className?: string;
}

export function InputTags({
  onChange,
  placeholder,
  value = [],
  isDisabled,
  autoFocus,
  className,
  separator = ' ',
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = React.useState(false);

  const [isEditLast, setIsEditLast] = React.useState(false);

  const badges = isEditLast ? value.slice(0, value.length - 1) : value;

  const inputValue = isEditLast && value.length ? value[value.length - 1] : '';

  function handleOnFocus(): void {
    setIsFocused(true);
  }

  function handleOnBlur(): void {
    setIsFocused(false);

    if (inputValue) {
      setIsEditLast(false);
    }
  }

  function handleOnClick(): void {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = e.target.value;

    if (inputValue) {
      onChange([...badges, inputValue]);
    } else {
      onChange(badges);
    }

    setIsEditLast(!!inputValue);
  }

  function handleOnKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Backspace' && !inputValue) {
      e.preventDefault();
      setIsEditLast(true);
    } else if (e.key === separator || e.key === 'Enter') {
      if (inputValue) {
        setIsEditLast(false);
      }

      e.preventDefault();
    }
  }

  function getHandleOnClose(index: number) {
    return function call() {
      const newValue = value.slice();

      newValue.splice(index, 1);

      onChange(newValue);
    };
  }

  function renderTag(tag: string, index: number) {
    if (!tag) {
      return null;
    }

    return <Badge key={index} label={tag} onClose={!isDisabled ? getHandleOnClose(index) : undefined} />;
  }

  return (
    <div
      data-testid='input-tags'
      className={clsx(classes.container, 'row a-start o-hidden w-100p', className, {
        [classes.focus]: isFocused,
        [classes.disabled]: isDisabled,
      })}
      onClick={handleOnClick}
    >
      <div className={clsx(classes.tags, 'row wrap')}>{renderWithIndex(badges, renderTag)}</div>
      <input
        ref={inputRef}
        type='text'
        placeholder={placeholder}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        disabled={isDisabled}
        autoFocus={autoFocus}
        value={inputValue}
      />
    </div>
  );
}
