import * as React from 'react';
import { PrimaryButton, PrimaryButtonProps } from '../PrimaryButton';
import { t } from 'src/tools/text.tools';

const TXT = t(['buttons']);

type Props = Omit<PrimaryButtonProps, 'leftIconId' | 'label'>;

// new-ui
export function PrevPrimaryButton(props: Props) {
  return <PrimaryButton dataTestId='prev-button' isSmall {...props} label={TXT('previous')} leftIconId='arrow-left' />;
}
