import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_check'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M20 6L9 17l-5-5' />
    </svg>
  );
}

export default Icon;
