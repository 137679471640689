import { useState, useMemo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ID, RecordStringObject } from 'src/models';
import { DOCUMENTATION, CONNECT_TO_SUPPORT, PATHS } from 'src/data/routes.data';
import { unifyPath } from 'src/tools/url.tools';
import { t } from 'src/tools/text.tools';

import { BaseComponentProps } from 'src/components/Dropdown';
import { DropdownMenu } from 'src/components/Dropdown/DropdownMenu';
import { UseIcon } from 'src/components/UseIcon';

import classes from './HelpDropdown.module.scss';

const TXT = t(['helpTopics']);

export function HelpDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const [topic, setTopic] = useState('');

  const { pathname } = useLocation();

  const unifiedPaths = useMemo(() => {
    const result: RecordStringObject = {};

    const pathsArr = Object.entries(PATHS).map(p => [p[0], unifyPath(p[1])]);

    pathsArr.forEach(el => (result[el[0]] = el[1]));

    return result;
  }, []);

  const getCurrentTopic = useCallback(() => {
    let result = '';

    const currentPath = unifyPath(pathname);

    for (const key in unifiedPaths) {
      if (unifiedPaths[key] === currentPath) result = key;
    }

    return result;
  }, [pathname, unifiedPaths]);

  const items = [
    { id: 'documentation', label: 'Documentation' },
    { id: 'topic_help', label: `Get Help on These Topics '${topic}'` },
    { id: 'get_support', label: 'Get Support' },
  ];

  useEffect(() => {
    const topic = getCurrentTopic();

    setTopic(TXT(topic));
  }, [getCurrentTopic]);

  function handleOnItemClick(id: ID) {
    switch (id) {
      case 'documentation':
        window.open(DOCUMENTATION);
        break;
      case 'topic_help':
        break;
      case 'get_support':
        window.open(CONNECT_TO_SUPPORT);
        break;
      default:
    }
  }

  function renderButton({ onClick }: BaseComponentProps) {
    return (
      <div className={classes['dropdown-wrapper']}>
        <UseIcon iconId='question-fill' onClick={onClick} className={classes.icon} />
      </div>
    );
  }

  return (
    <DropdownMenu
      renderBaseComponent={renderButton}
      classNames={{ container: classes.container }}
      items={items}
      onItemClick={handleOnItemClick}
      dropdownMinWidth='fit-content'
      position='bottom-right'
      onRequestOpenClose={setIsOpen}
      isOpen={isOpen}
      isSmall
    />
  );
}
