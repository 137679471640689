import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ID, Maybe } from 'src/models/general.model';
import { ShowScenario } from 'src/models/scenario.model';
import { SubProjectTeam, SubProject, SubProjectTeamSortBy } from 'src/models/sub_project.model';
import { INITIAL_SUBPROJECT_STATE } from './subProject.model';
import { InvitedMember, Member } from 'src/models/team.model';
import { Permission } from 'src/models/permissions.model';

export const slice = createSlice({
  name: 'subProject',
  initialState: INITIAL_SUBPROJECT_STATE,
  reducers: {
    clearSubProjectState: () => INITIAL_SUBPROJECT_STATE,
    setCurrentSubProject: (state, action: PayloadAction<Maybe<SubProject>>) => {
      state.currentSubProject = action.payload;
    },
    setSubProjectScenario: (state, action: PayloadAction<Maybe<ShowScenario>>) => {
      state.scenario = action.payload;
    },
    setSubProjects: (state, action: PayloadAction<Maybe<SubProject[]>>) => {
      state.subProjects = action.payload;
    },
    updateSubProject: (state, action: PayloadAction<SubProject>) => {
      state.subProjects = state.subProjects?.map(p => (p.id === action.payload.id ? action.payload : p));

      if (state.currentSubProject?.id === action.payload.id) state.currentSubProject = action.payload;
    },
    addSubProject: (state, action: PayloadAction<SubProject>) => {
      state.subProjects = state.subProjects && [...state.subProjects, action.payload];
    },
    setSubProjectTeam: (state, action: PayloadAction<Maybe<SubProjectTeam>>) => {
      state.subProjectTeam = action.payload;
    },
    setSubProjectTeamPagePermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.teamPagePermissions = action.payload;
    },
    setSubProjectTeamMember: (state, action: PayloadAction<Maybe<Member[]>>) => {
      state.subProjectTeamMember = action.payload;
    },
    updateSubProjectTeamMember: (state, action: PayloadAction<Member>) => {
      state.subProjectTeamMember = state.subProjectTeamMember?.map(m =>
        m.id === action.payload.id ? action.payload : m,
      );
    },
    deleteSubProjectTeamInvite: (state, action: PayloadAction<ID>) => {
      const firstItem = state.subProjectTeamInvite?.[0];

      if (!firstItem) return;

      state.subProjectTeamInvite = state.subProjectTeamInvite?.filter(m => m.id !== action.payload);

      if (firstItem.id === action.payload && state.subProjectTeam)
        state.subProjectTeam.invites = state.subProjectTeam.invites.filter(i => i.email !== firstItem.email);
    },
    setSubProjectTeamInvite: (state, action: PayloadAction<Maybe<InvitedMember[]>>) => {
      state.subProjectTeamInvite = action.payload;
    },
    updateSubProjectTeamInvite: (state, action: PayloadAction<InvitedMember>) => {
      state.subProjectTeamInvite = state.subProjectTeamInvite?.map(i =>
        i.id === action.payload.id ? action.payload : i,
      );
    },
    setSubProjectTeamSearch: (state, action: PayloadAction<string>) => {
      state.subProjectTeamSearch = action.payload;
    },
    setSubProjectTeamSortBy: (state, action: PayloadAction<SubProjectTeamSortBy>) => {
      state.subProjectTeamSortBy = action.payload;
    },
    setSubProjectPermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.permissions = action.payload;
    },
    setSubProjectLockAndRollInProgress: (state, action: PayloadAction<boolean>) => {
      if (state.currentSubProject) state.currentSubProject.lock_and_roll_in_progress = action.payload;
    },
    setCurrentSubProjectSubmissionOpen: (state, action: PayloadAction<boolean>) => {
      if (state.currentSubProject) state.currentSubProject.submission_open = action.payload;
    },
    clearSubProjectTeamData: state => {
      state.subProjectTeam = undefined;
      state.subProjectTeamMember = undefined;
      state.subProjectTeamInvite = undefined;
      state.subProjectTeamSearch = INITIAL_SUBPROJECT_STATE.subProjectTeamSearch;
      state.subProjectTeamSortBy = INITIAL_SUBPROJECT_STATE.subProjectTeamSortBy;
    },
  },
});

export const {
  setSubProjectScenario,
  setSubProjects,
  setCurrentSubProject,
  updateSubProject,
  addSubProject,
  clearSubProjectState,
  setSubProjectTeam,
  setSubProjectTeamMember,
  setSubProjectTeamInvite,
  setSubProjectTeamSearch,
  setSubProjectTeamSortBy,
  clearSubProjectTeamData,
  updateSubProjectTeamMember,
  deleteSubProjectTeamInvite,
  updateSubProjectTeamInvite,
  setSubProjectPermissions,
  setSubProjectTeamPagePermissions,
  setSubProjectLockAndRollInProgress,
  setCurrentSubProjectSubmissionOpen,
} = slice.actions;

export default slice.reducer;
