import { processError } from 'src/tools/events.tools';

import { MainContract } from 'src/models/main_contract.model';
import { Action, AnyValue, ProcessProps, Response, ResponsePayload } from 'src/models/general.model';

import { QueryAction, QueryDomain, ResourceType } from 'src/models/query.model';
import { ShowScenario } from 'src/models/scenario.model';
import {
  setMainContract,
  setMainContractPermissions,
  setMainContractScenario,
} from 'src/store/mainContract/mainContract.reducer';
import { setCompany } from 'src/store/companies/companies.reducer';
import { clearContractTermsState } from 'src/store/contract_terms/contract_terms.reducer';
import { setIsUpdating } from 'src/store/loading/loading.reducer';
import { sendInfo } from 'src/store/app/app.actions';
import { requestSingleCompany } from 'src/store/companies/companies.action';
import { requestTeam } from 'src/store/team/team.actions';
import { deserialize } from 'src/tools/string.tools';
import { IsBusyOptions, setIsLoadingOrUpdating } from 'src/tools/process.tools';
import { getCurrentMainContractId } from '../store/subProject/subProject.getters';
import { getCurrentSubprojectId } from 'src/store/app/app.getters';
import { logUnableToProceed } from 'src/tools/log.tools';
import { requestRequiredDocuments } from '../store/requiredDocuments/requiredDocuments.actions';

function processMainContract({ dispatch, getService, getState }: ProcessProps) {
  const setIsBusy = (options: IsBusyOptions) => setIsLoadingOrUpdating(dispatch, options);

  const processGetMainContract = (payload: AnyValue) => () => {
    setIsBusy({ state: true });
    getService().request({ [QueryDomain.MAIN_CONTRACT]: QueryAction.GET, payload }, (response: Response<string>) => {
      if (response.isOK) {
        const result = deserialize<ResponsePayload<MainContract, ShowScenario>>(response.payload);

        dispatch(setMainContract(result?.data));
        dispatch(setMainContractScenario(result?.scenario));
        dispatch(setMainContractPermissions(result?.permissions));

        if (result?.data?.company_id) {
          dispatch(requestSingleCompany(result?.data?.company_id as string));
        } else {
          dispatch(setCompany(undefined));
        }

        dispatch(requestTeam(payload.main_contract_id, ResourceType.MAIN_CONTRACT));
        dispatch(requestRequiredDocuments(payload.main_contract_id, { isValid: true }));
      } else {
        processError({
          activityName: 'Request main contract',
          response,
        });
      }

      setIsBusy({ state: false });
    });
  };

  const processUpdateMainContract = (action: Action) => () => {
    dispatch(setIsUpdating(true));

    const project_id = getCurrentSubprojectId(getState());

    const main_contract_id = getCurrentMainContractId(getState());

    if (!main_contract_id) {
      logUnableToProceed('processUpdateMainContract', { main_contract_id });

      return;
    }

    getService().request(
      {
        [QueryDomain.MAIN_CONTRACT]: QueryAction.UPDATE,
        payload: {
          ...action.payload,
          project_id,
          main_contract_id,
        },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<MainContract>>(response.payload);

          dispatch(clearContractTermsState());
          dispatch(setMainContract(result?.data));
          dispatch(setIsUpdating(false));

          if (result?.data?.company_id) {
            dispatch(requestSingleCompany(result?.data?.company_id as string));
          } else {
            dispatch(setCompany(undefined));
          }

          dispatch(requestTeam(main_contract_id, ResourceType.MAIN_CONTRACT));
          dispatch(sendInfo('Main Contract has been updated'));
        } else {
          processError({
            activityName: 'Request to update main contract',
            response,
            isLoadingFn: setIsUpdating,
          });
        }

        if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
      },
    );
  };

  return {
    processGetMainContract,
    processUpdateMainContract,
  };
}

export { processMainContract };
