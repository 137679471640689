import { useCallback } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteItem } from 'src/models/routes.model';
import { getIsAuthed } from 'src/store/app/app.reducer';
import { getSignUpInviteId } from 'src/store/app/app.getters';

import { PortfolioRoutes } from './Portfolio.routes';
import { PSPDFKitHelmet } from './PSPDFKitHelmet';
import { Factory } from 'src/components/Factory';
import { LogIn, SignUp, ForgotPassword, ResetPassword, TwoFactorAuthentication } from 'src/pages';
import { PATHS } from 'src/data/routes.data';

export function Routes() {
  const isAuthed = useSelector(getIsAuthed);

  const inviteId = useSelector(getSignUpInviteId);

  const ProtectedRoute = useCallback(
    ({ component, path }: RouteItem) => (isAuthed ? component : <Redirect from={path} to={PATHS.LOGIN} />),
    [isAuthed],
  );

  const PublicRoute = useCallback(
    ({ component, path }: RouteItem) =>
      !isAuthed || (isAuthed && inviteId) ? component : <Redirect from={path} to={PATHS.HOME} />,
    [isAuthed, inviteId],
  );

  function render(component: React.ReactNode) {
    return <Factory component={component} />;
  }

  // TODO: move contents of PSPDFKitHelmet into Headers
  return (
    <>
      <PSPDFKitHelmet />
      <Switch>
        <ProtectedRoute path={PATHS.PORTFOLIO} component={<PortfolioRoutes />} />
        <PublicRoute path={PATHS.LOGIN} component={render(<LogIn />)} />
        <PublicRoute path={PATHS.SIGNUP} component={render(<SignUp />)} />
        <PublicRoute path={PATHS.FORGOT_PASSWORD} component={render(<ForgotPassword />)} />
        <PublicRoute path={PATHS.RESET_PASSWORD} component={render(<ResetPassword />)} />
        <PublicRoute path={PATHS.TWO_FACTOR_AUTHENTICATION} component={render(<TwoFactorAuthentication />)} />
        <Redirect to={PATHS.PORTFOLIO} />
      </Switch>
    </>
  );
}
