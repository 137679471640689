import { Redirect, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import { render } from 'src/tools/render.tools';

import { RouteItem } from 'src/models/routes.model';

import { Navbar } from 'src/components/Navbar';
import { ProtectedRoute } from './ProtectedRoute';
import { PATHS } from '../data/routes.data';

import { ROUTES } from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { getParams } from '../store/app/app.reducer';
import { requestSubProjects } from '../store/subProject/subProject.actions';
import { setSubProjects } from '../store/subProject/subProject.reducer';

// new-ui
export function PortfolioRoutes() {
  const dispatch = useDispatch();

  const { mainId } = useSelector(getParams);

  useEffect(() => {
    if (mainId) {
      dispatch(requestSubProjects(mainId));

      return () => {
        dispatch(setSubProjects());
      };
    }
  }, [dispatch, mainId]);

  function renderRoute(props: RouteItem) {
    return <ProtectedRoute key={props.path} {...props} />;
  }

  return (
    <div className='portfolio-container'>
      <Navbar />
      <Switch>
        {render(ROUTES, renderRoute)}
        <Redirect to={PATHS.PORTFOLIO} />
      </Switch>
    </div>
  );
}
