import { RootState } from '..';
import { PredefinedLists, PredefinedNestedLists, PredefinedStateMachines } from 'src/models';
import { ifTrue } from 'src/tools/logic.tools';

export const getPredefinedList = (state: RootState) => (name?: PredefinedLists) => {
  return ifTrue(name, state.predefinedLists.lists?.[name!]);
};

export const getPredefinedNestedList = (state: RootState) => (name?: PredefinedNestedLists) =>
  ifTrue(name, state.predefinedLists.nestedLists?.[name!]);

export const getPredefinedListMatch = (state: RootState) => (name?: PredefinedLists | PredefinedNestedLists) =>
  ifTrue(name, state.predefinedLists.listsMatches?.[name!]);

export const getPredefinedStateMachine = (state: RootState) => (name?: PredefinedStateMachines) =>
  ifTrue(name, state.predefinedLists.stateMachines?.[name!]);
