import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      fill='none'
      viewBox='0 0 17 16'
      data-testid='icon_trade'
    >
      <g stroke='none' clipPath='url(#clip)'>
        <path d='M15.5 14.733a.757.757 0 00-.533-.2h-.734V6.8a.74.74 0 00-.733-.733.74.74 0 00-.733.733v7.734h-2.2v-3.6c0-.2-.067-.4-.2-.534a.757.757 0 00-.534-.2h-2.8c-.2 0-.4.067-.533.2a.757.757 0 00-.2.534v3.6H4.1v-11.6h5.067A.74.74 0 009.9 2.2a.74.74 0 00-.733-.733h-5.8c-.2 0-.334.066-.534.2-.066.133-.2.333-.2.533v12.334H1.9c-.2 0-.4.066-.533.2a.757.757 0 00-.2.533c0 .2.066.4.2.533.2.133.333.2.533.2h13.067c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.067-.4-.2-.534zm-6.333-.2H7.7V11.6h1.467v2.934zM12.167 2.933h.733v.734c0 .2.067.4.2.533s.333.2.533.2.4-.067.534-.2a.757.757 0 00.2-.533v-.734h.733c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.066-.4-.2-.533a.757.757 0 00-.533-.2h-.733V.733c0-.2-.067-.4-.2-.533-.2-.133-.334-.2-.534-.2s-.4.067-.533.2a.757.757 0 00-.2.533v.734h-.733c-.2 0-.4.066-.534.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.134.134.334.2.534.2z' />
        <path d='M9.967 6.4h.733c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.066-.4-.2-.533a.757.757 0 00-.533-.2h-.733c-.2 0-.4.066-.534.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.134.134.334.2.534.2zM9.967 9.266h.733c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.066-.4-.2-.533a.757.757 0 00-.533-.2h-.733c-.2 0-.4.066-.534.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.134.134.334.2.534.2zM6.233 6.4h.734c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.067-.4-.2-.533a.757.757 0 00-.533-.2h-.734c-.2 0-.4.066-.533.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.133.134.333.2.533.2zM6.233 9.266h.734c.2 0 .4-.066.533-.2a.757.757 0 00.2-.533c0-.2-.067-.4-.2-.533a.757.757 0 00-.533-.2h-.734c-.2 0-.4.066-.533.2a.757.757 0 00-.2.533c0 .2.067.4.2.533.133.134.333.2.533.2z' />
      </g>
      <defs>
        <clipPath id='clip-trade'>
          <path stroke='none' d='M0 0H16V16H0z' transform='translate(.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
