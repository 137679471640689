import { is } from 'ramda';
import { RecordObject, VerificationModel } from 'src/models/general.model';

export function validateObject(data: unknown) {
  if (data !== Object(data) || Array.isArray(data) || typeof data === 'function') return false;

  return true;
}

export function validateStringObject(data: unknown) {
  if (!is(Object, data)) return false;

  let result = true;

  Object.keys(data as RecordObject<string>).forEach(el => {
    if (typeof (data as RecordObject<string>)[el] !== 'string') result = false;
  });
  return result;
}

function validateArrayOfObjects(data: unknown[]) {
  let result = true;

  for (const el of data) {
    if (!validateObject(el)) result = false;
  }

  return result;
}

// new-ui
// TODO: implement required and type check
export function checkArrayOfObjectsForKeys(data: unknown, _rules: VerificationModel) {
  if (!Array.isArray(data)) return false;

  if (!validateArrayOfObjects(data)) return false;

  const result = true;

  return result;
}
