import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      stroke='none'
      viewBox='0 0 16 16'
      data-testid='icon_link-broken'
    >
      <path
        stroke='none'
        d='M3.174 7.06a.672.672 0 10.173-1.333l-1.173-.294a.666.666 0 10-.347 1.287L3 7.033a.52.52 0 00.174.027zm2.573-3.727a.667.667 0 00.667.494c.057.009.116.009.173 0a.667.667 0 00.467-.814L6.74 1.84a.667.667 0 10-1.286.347l.293 1.146zM8.967 10A.667.667 0 008 10l-2.333 2.373a1.473 1.473 0 01-2.04 0 1.434 1.434 0 010-2.04L6 8a.667.667 0 10-.94-.94L2.667 9.387a2.79 2.79 0 003.947 3.946l2.353-2.353a.667.667 0 000-.98zM3.454 4.393a.667.667 0 001.133-.47.667.667 0 00-.193-.47l-.86-.86a.667.667 0 00-.94.94l.86.86zm10.72 4.887L13 8.967a.667.667 0 10-.333 1.286l1.173.313h.174a.672.672 0 10.173-1.333l-.013.047zm-3.92 3.386a.667.667 0 00-1.287.347l.313 1.174a.667.667 0 00.667.493.773.773 0 00.173 0 .668.668 0 00.474-.82l-.34-1.194zm2.293-1.046a.666.666 0 00-.94.94l.86.86a.667.667 0 00.94 0 .667.667 0 000-.94l-.86-.86zm1.587-6.953a2.774 2.774 0 00-4.747-2L7.034 5.04A.681.681 0 108 6l2.334-2.373a1.473 1.473 0 012.04 0 1.434 1.434 0 010 2.04L10 8a.667.667 0 000 .94.667.667 0 00.94 0l2.394-2.327c.51-.52.797-1.218.8-1.946z'
      />
    </svg>
  );
}

export default Icon;
