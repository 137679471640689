import * as React from 'react';
import { t } from 'src/tools/text.tools';
import { SecondaryButton } from '../SecondaryButton';
import { ButtonProps } from 'src/components/Button/Button';

const TXT = t(['subNavbar', 'buttons']);

type Props = Omit<ButtonProps, 'label'>;

// new-ui
export function FilterSecondaryButton(props: Props) {
  return <SecondaryButton isSmall {...props} label={TXT('filter')} rightIconId='filter' />;
}
