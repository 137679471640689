import { Either, GroupedData, Maybe, PageTab, ID } from './general.model';
import { VersionedPeriod } from './results.model';
import { Sidebar } from './sidebar.model';
import { ErrorsObject } from 'src/models/error.model';

export enum ManagerReqTabs {
  GC = 'gc',
  BOND = 'bond',
  TAX = 'tax',
  FEE = 'fee',
  INSURANCE = 'insurance',
  OTHER = 'other',
  CONTINGENCY = 'contingency',
}

export enum ManagerReqTableSections {
  GC_BASE = 'gc_base',
  GC_BOND_BASE = 'bond_base',
  INSURANCE_BASE = 'insurance_base',
  TAX_BASE = 'tax_base',
  FEE_BASE = 'fee_base',
  OTHER_BASE = 'other_base',
  CONTINGENCY_BASE = 'contingency_base',
  GC_CHANGE_ORDERS = 'gc_change_orders',
  BOND_CHANGE_ORDERS = 'bond_change_orders',
  INSURANCE_CHANGE_ORDERS = 'insurance_change_orders',
  TAX_CHANGE_ORDERS = 'tax_change_orders',
  FEE_CHANGE_ORDERS = 'fee_change_orders',
}

export enum ManagerReqTabSections {
  GC = 'gc',
  BOND = 'bond',
  TAX = 'tax',
  FEE = 'fee',
  INSURANCE = 'insurance',
  OTHER = 'other',
  CONTINGENCY = 'contingency',
}

interface ManagerReqPageTabOptions
  extends Record<string, Maybe<Either<ManagerReqTableSections, ManagerReqTabSections>>> {
  baseContract?: ManagerReqTableSections;
  changeOrders?: ManagerReqTableSections;
  totals?: ManagerReqTabSections;
}

export interface ManagerReqPageTab extends PageTab {
  id: ManagerReqTabs;
  options?: ManagerReqPageTabOptions;
}

export interface ManagerReqDetailPage {
  req_start_date: Date;
  status: string;
  id: ID;
  tabs: ManagerReqTabs[];
  upload_in_progress: boolean;
}

export interface ManagerReqDetailData extends ManagerReqDetailPage {
  current_period: VersionedPeriod;
  selected_period: VersionedPeriod;
  sidebar?: Sidebar;
}

export interface UpdateManagerReqPagePayload {
  status: string;
}

export interface ManagerReqLine {
  id: string;
  trade_detail_id: number;
  section: string;
  group: string;
  category: string;
  description: string;
  csi_code: string;
  csi_description: string;
  retainage_applied: boolean;
  amount_type: string;
  amount: number;
  contract_value: number;
  amended_contract_value: number;
  prior_periods: number;
  this_period: number;
  stored_material: number;
  total_completed: number;
  percentage_completed: number;
  balance_to_finish: number;
  retainage_prior_periods: number;
  retainage_this_period: number;
  retainage_percentage: number;
  retainage_total: number;
  retainage_capped: boolean;
  retainage_lock: boolean;
  due_this_period: number;
  changes?: string[];
  level_name?: string;
  req_index?: number;
  _attachments?: {
    is_new: boolean;
  };
  _page: number;
  _errors?: ErrorsObject;
}

export type ManagerReqTable = GroupedData<ManagerReqLine>;

export type ManagerReqTotals = Record<keyof ManagerReqTable, number>;

export enum ManagerReqPageStatuses {
  FINAL_REVIEW = 'final_review',
  PENCIL_REVIEW = 'pencil_review',
  PERIOD_APPROVED = 'period_approved',
}
