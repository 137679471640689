import { ID } from 'src/models/general.model';
import { RootState } from '..';

export const getMainProjects = (state: RootState) => state.portfolio.mainProjects;

export const getMainProjectsMeta = (state: RootState) => state.portfolio.meta;

export const getMainProjectsQuery = (state: RootState) => state.portfolio.query;

export const getMainProjectsPermissions = (state: RootState) => state.portfolio.permissions;

export const getMainProjectPermissions = (state: RootState) => (mainProjectId: ID) =>
  state.portfolio.mainProjects?.find(prj => prj.id === mainProjectId)?._permissions;
