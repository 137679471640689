import * as React from 'react';
import clsx from 'clsx';

import { ID } from 'src/models';
import { P4, P2 } from 'src/components/Typography/Paragraph';
import { UseIcon } from 'src/components/UseIcon';

import classes from './Item.module.scss';

interface Props {
  id: ID;
  label: string | JSX.Element;
  onClick: () => void;
  onMouseEnter?: () => void;
  isDisabled?: boolean;
  isSmall?: boolean;
  isSelected?: boolean;
  isSplitAfter?: boolean;
  isHighlightedText?: boolean;
  isActive?: boolean;
  isChecked?: boolean;
  className?: string;
  tooltip?: string;
}

export function Item({
  id,
  label,
  onClick,
  onMouseEnter,
  isDisabled,
  isSmall,
  isSelected,
  isSplitAfter,
  isHighlightedText,
  isActive,
  isChecked,
  className,
  tooltip,
}: Props) {
  function handleOnClick(e: React.MouseEvent) {
    e.stopPropagation();

    if (!isDisabled) {
      onClick();
    }
  }

  const P = isSmall ? P4 : P2;

  function renderCheck() {
    if (isChecked === undefined) {
      return null;
    }

    return <span className={classes['icon-wrapper']}>{isChecked && <UseIcon iconId='check' />}</span>;
  }

  function renderContent() {
    if (typeof label !== 'string') {
      return label;
    }

    return (
      <P className='row a-center' title={tooltip || label}>
        {renderCheck()}
        {label}
      </P>
    );
  }

  return (
    <button
      data-testid={`menu-item-${id}`}
      className={clsx(
        classes.container,
        {
          [classes.small]: isSmall,
          [classes.selected]: isSelected,
          [classes['split-after']]: isSplitAfter,
          [classes['highlight-text']]: isHighlightedText,
          [classes.active]: isActive,
          [classes.disabled]: isDisabled,
        },
        className,
      )}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      disabled={isDisabled}
    >
      {renderContent()}
    </button>
  );
}
