import { makeMatchObject } from 'src/tools/object.tools';

export const EXTENSIONS = makeMatchObject<string>(
  {
    'application/pdf': 'pdf',
    'application/xlsx': 'xlsx',
    'text/csv': 'csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xlsx',
  },
  'txt',
);
