import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      fill='none'
      stroke='none'
      viewBox='0 0 14 14'
      data-testid='icon_check-outline'
    >
      <g strokeLinecap='round' strokeLinejoin='round' clipPath='url(#clip0)'>
        <path stroke='none' d='M7,12.9c3.3,0,6-2.7,6-6s-2.7-6-6-6c-3.3,0-6,2.7-6,6S3.7,12.9,7,12.9z' />
        <path stroke='#fff' strokeWidth='1.5' d='M9.9,5.5l-4,3.6L4.1,7.5' />
      </g>
    </svg>
  );
}

export default Icon;
