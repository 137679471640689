import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { RootState } from 'src/store';
import { Maybe, ShowScenario } from 'src/models';
import { Permission } from 'src/models/permissions.model';
import { useAbilities } from 'src/hooks/useAbilities';
import { getShowScenarioWithPermissions } from 'src/tools/scenarioPermission.tools';

/**
 * hook for patching show page scenario with permission
 * @param getScenario - show scenario getter
 * @param getPermissions - show page permissions getter
 * @return ShowScenario - patched scenario
 */
export const useShowScenarioWithPermissions = (
  getScenario: (state: RootState) => Maybe<ShowScenario>,
  getPermissions: (state: RootState) => Maybe<Permission[]>,
): Maybe<ShowScenario> => {
  const scenario = useSelector(getScenario);

  const abilities = useAbilities(getPermissions);

  return useMemo(() => getShowScenarioWithPermissions(scenario, abilities), [scenario, abilities]);
};
