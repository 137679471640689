import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_file-text'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z'
      />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M16 17H8' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M16 13H8' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M10 9H8' />
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M14 2v6h6' />
    </svg>
  );
}

export default Icon;
