import clsx from 'clsx';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseComponentProps, Dropdown } from 'src/components/Dropdown';
import { UseIcon } from 'src/components/UseIcon';
import { t } from 'src/tools/text.tools';
import { P1, P3 } from 'src/components/Typography';
import { ifTrue } from 'src/tools/logic.tools';
import { NotificationItem } from './NotificationItem';
import { render } from 'src/tools/render.tools';
import { SectionHeader } from './SectionHeader';
import { ID } from 'src/models';
import { getNotificationMessages, getUnreadNotificationsCount } from 'src/store/notifications/notifications.getters';
import { NotificationMessage } from 'src/models/notification.model';
import { NotificationsCounter } from 'src/components/NotificationsCounter';
import { requestNotificationsDelete, requestNotificationsUpdate } from 'src/store/notifications/notifications.actions';

import classes from './NotificationsDropdown.module.scss';

const TXT = t(['notificationDropdown']);

function getDayAgoTimestamp() {
  return new Date().valueOf() - 24 * 60 * 60 * 1000;
}

export function NotificationsDropdown() {
  const dispatch = useDispatch();

  const [dayAgoTimestamp, setDayAgoTimestamp] = useState(getDayAgoTimestamp());

  const notifications = useSelector(getNotificationMessages);

  const unreadNotificationsCount = useSelector(getUnreadNotificationsCount);

  const { newNotifications, oldNotifications } = useMemo(() => {
    const newNotifications: NotificationMessage[] = [];

    const oldNotifications: NotificationMessage[] = [];

    notifications?.forEach(item => {
      if (item.created_at.valueOf() >= dayAgoTimestamp) newNotifications.push(item);
      else oldNotifications.push(item);
    });

    return { newNotifications, oldNotifications };
  }, [notifications, dayAgoTimestamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDayAgoTimestamp(getDayAgoTimestamp());
    }, 60 * 1000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  function getHandleOnRead(notification_id: ID) {
    return function call() {
      dispatch(requestNotificationsUpdate({ notification_id, read: true }));
    };
  }

  function getHandleOnDelete(notification_id: ID) {
    return function call() {
      dispatch(requestNotificationsDelete(notification_id));
    };
  }

  function renderButton({ onClick }: BaseComponentProps) {
    return (
      <UseIcon iconId='notification' className={classes.icon} wrapElement='button' onClick={onClick}>
        <NotificationsCounter className={classes.counter} value={unreadNotificationsCount} />
      </UseIcon>
    );
  }

  function renderItem(item: NotificationMessage) {
    return (
      <NotificationItem
        key={item.id}
        notification={item}
        onRead={getHandleOnRead(item.id)}
        onDelete={getHandleOnDelete(item.id)}
      />
    );
  }

  function renderSection(title: string, items: NotificationMessage[]) {
    if (!items.length) return;

    return (
      <>
        <SectionHeader title={title} />
        {render(items, renderItem)}
      </>
    );
  }

  function renderNoData() {
    return <P3 className={classes['no-data']}>{TXT('noData')}</P3>;
  }

  return (
    <Dropdown
      classNames={{ dropdown: clsx(classes.dropdown, 'column a-start') }}
      renderBaseComponent={renderButton}
      dropdownWidth='45rem'
      position='bottom-right'
    >
      <P1 className={clsx(classes.header, 'w-100p')}>{TXT('notifications')}</P1>
      {renderSection(TXT('new'), newNotifications)}
      {renderSection(TXT('earlier'), oldNotifications)}
      {ifTrue(!notifications?.length, renderNoData)}
    </Dropdown>
  );
}
