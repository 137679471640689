import { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { equals } from 'ramda';

import { useDimensions } from './useDimensions';
import { useThrottledFn } from './useThrottledFn';
import { Maybe, RecordObject } from 'src/models';

// T - list item type
interface Params<T> {
  type?: 'vertical' | 'horizontal';
  items: T[];
  stickyItems?: RecordObject<boolean>;
  getItemSize: (item: T) => number;
  containerRef: RefObject<HTMLElement>;
  isHidden?: boolean;
}

interface Return<T> {
  visibleItems: T[];
  offset: number;
  totalSize: number;
}

export const useVirtualList = <T>({
  type = 'vertical',
  items,
  getItemSize,
  containerRef,
  isHidden,
  stickyItems,
}: Params<T>): Return<T> => {
  const [containerWidth, containerHeight] = useDimensions(containerRef);

  const containerSize = type === 'vertical' ? containerHeight : containerWidth;

  const scrollType = type === 'vertical' ? 'scrollTop' : 'scrollLeft';

  const [offset, setOffset] = useState(0);

  const [totalSize, setTotalSize] = useState(0);

  const [visibleItems, setVisibleItems] = useState<T[]>([]);

  const startIndexRef = useRef(0);

  const endIndexRef = useRef(0);

  const visibleItemsRef = useRef<T[]>([]);

  const updateScroll = useCallback(() => {
    if (containerRef.current && !isHidden) {
      const RENDER_GAP = 1; // render 100% above and below the viewport

      const scroll = containerRef.current[scrollType];

      const viewPortSize = containerSize || (type === 'vertical' ? window.innerHeight : window.innerWidth);

      const renderStart = Math.max(0, scroll - viewPortSize * RENDER_GAP);

      const renderEnd = scroll + viewPortSize * (1 + RENDER_GAP);

      let startIndex = 0;

      let newStartOffset = 0;

      let currentOffset = 0;

      let endIndex = items.length;

      const newVisibleItems: T[] = [];

      // last sticky item before visible items (it should be rendered)
      let lastStickyItem: Maybe<T>;

      items.forEach((item, index) => {
        const itemSize = getItemSize(item);

        const isSticky = typeof item === 'string' && !!stickyItems?.[item];

        const itemEndOffset = currentOffset + itemSize;

        if (itemEndOffset <= renderStart && isSticky) lastStickyItem = item;

        if (itemEndOffset > renderStart && currentOffset < renderEnd) {
          if (!newVisibleItems.length) {
            startIndex = index;
            newStartOffset = currentOffset;

            if (lastStickyItem) newVisibleItems.push(lastStickyItem);
          }

          endIndex = index + 1;

          newVisibleItems.push(item);
        }

        currentOffset = itemEndOffset;
      });

      setOffset(newStartOffset);
      setTotalSize(currentOffset);

      if (
        startIndexRef.current !== startIndex ||
        endIndexRef.current !== endIndex ||
        !equals(newVisibleItems, visibleItemsRef.current)
      ) {
        startIndexRef.current = startIndex;
        endIndexRef.current = endIndex;
        setVisibleItems(newVisibleItems);
      }
    } else {
      setOffset(0);
      setTotalSize(0);
      setVisibleItems([]);
      startIndexRef.current = 0;
      endIndexRef.current = 0;
    }
  }, [containerRef, isHidden, scrollType, containerSize, type, items, getItemSize, stickyItems]);

  const updateScrollThrottled = useThrottledFn(updateScroll, 500);

  useLayoutEffect(updateScroll, [updateScroll]);

  useEffect(() => {
    const { current } = containerRef;

    if (current) {
      current.addEventListener('scroll', updateScrollThrottled);

      return () => current.removeEventListener('scroll', updateScrollThrottled);
    }
  }, [containerRef, scrollType, updateScrollThrottled]);

  return {
    visibleItems,
    offset,
    totalSize,
  };
};
