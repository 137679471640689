import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_alert-error'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <path
        stroke='var(--error-default)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        fill='none'
        d='M10.48 2.667h11.04l7.813 7.813v11.04l-7.814 7.813H10.48L2.666 21.52V10.48l7.813-7.813zM16 21.333h.013M16 10.667V16'
      />
    </svg>
  );
}

export default Icon;
