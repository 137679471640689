import { ID } from 'src/models/general.model';
import { PageQuery } from 'src/models/results.model';

export enum LevelType {
  BELOW = 'below',
  ABOVE = 'above',
  OTHER = 'other',
}

export interface Level {
  id: ID;
  name: string;
  level_type: LevelType;
  label: string;
  index: number;
  _attachments: {
    is_new: boolean;
    num_documents: number;
    num_pictures: number;
    num_comments: number;
  };
}

export interface LevelsData {
  data: Level[];
  query: Partial<PageQuery>;
}
