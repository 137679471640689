import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_MAIN_CONTRACT_STATE } from './mainContract.model';
import { MainContract } from 'src/models/main_contract.model';
import { Maybe } from 'src/models/general.model';
import { ShowScenario } from 'src/models/scenario.model';
import { Permission } from 'src/models/permissions.model';

export const slice = createSlice({
  name: 'mainContract',
  initialState: INITIAL_MAIN_CONTRACT_STATE,
  reducers: {
    clearMainContractState: () => INITIAL_MAIN_CONTRACT_STATE,
    setMainContract: (state, action: PayloadAction<Maybe<MainContract>>) => {
      state.pageData = action.payload;
    },
    setMainContractScenario: (state, action: PayloadAction<Maybe<ShowScenario>>) => {
      state.scenario = action.payload;
    },
    setMainContractPermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.permissions = action.payload;
    },
  },
});

export const { clearMainContractState, setMainContract, setMainContractScenario, setMainContractPermissions } =
  slice.actions;

export default slice.reducer;
