import { Action, ActionMeta } from 'src/models/general.model';
import { ReqSummaryActions } from 'src/models/query.model';
import {
  ReqSummaryTermsRequestArgs,
  ReqSummaryTradeRequestArgs,
  ReqSummaryTradeType,
} from 'src/models/reqSummary.model';
import { Period } from 'src/models';

interface ReqSummaryActionProps {
  selected_action: string;
  end_date?: Date;
}

export const performReqSummaryAction = (payload: ReqSummaryActionProps, meta?: ActionMeta): Action => ({
  type: ReqSummaryActions.ACTION,
  payload,
  meta,
});

export const requestReqSummary = (period?: Period): Action => ({
  type: ReqSummaryActions.SHOW,
  payload: period,
});

export const requestReqSummaryTrades = (payload: ReqSummaryTradeRequestArgs): Action => ({
  type: ReqSummaryActions.TRADES,
  payload,
});

export const requestReqSummaryTerms = (payload: ReqSummaryTermsRequestArgs): Action => ({
  type: ReqSummaryActions.TERMS,
  payload,
});

export function fetchAdditionalTrades(type: ReqSummaryTradeType) {
  return { type: ReqSummaryActions.GET_TRADES_MORE, payload: type };
}

export function requestAutocomplete(query: string, domain: string, meta?: ActionMeta) {
  return { type: ReqSummaryActions.AUTOCOMPLETE, payload: { domain, query }, meta };
}

export function requestReqSummaryDashboard(meta?: ActionMeta) {
  return { type: ReqSummaryActions.DASHBOARD, meta };
}
