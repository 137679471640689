import { Either, ID } from '.';

export enum SocketDomains {
  CHANGE_ORDER = 'change_order',
  REQ_DETAIL = 'req_detail',
  MANAGER_REQ = 'req_managers',
  REQ_SUMMARY = 'req_summary',
}

export enum SocketTypes {
  PDF = 'pdf',
  XLSX = 'xlsx',
  PAY = 'pay_application',
  REQ_DETAIL = 'req_detail',
  MATERIAL = 'material_log',
  BANK = 'bank_report',
  PAY_REPORT = 'pay_application_report',
  WAIVER = 'waiver',
  REQ_SUMMARY = 'req_summary',
}

export interface SocketPeriod {
  period: ID;
  version?: ID;
}

export interface SocketParams {
  request_id: string;
  note?: string;
  request_timestamp?: string;
  domain?: SocketDomains;
  type?: ID;
  ids?: Either<string, number>[];
  extra?: SocketPeriod | Record<string, unknown>;
}
