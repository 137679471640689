import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_CHANGE_ORDERS_STATE } from './change_orders.model';
import {
  Meta,
  ChangeOrder,
  PageQuery,
  Totals,
  Scenario,
  Maybe,
  ShowScenario,
  ChangeOrderHistory,
  VersionedPeriod,
} from 'src/models';
import { Permission } from 'src/models/permissions.model';
import { updateData } from 'src/tools/array.tools';

export const slice = createSlice({
  name: 'changeOrders',
  initialState: INITIAL_CHANGE_ORDERS_STATE,
  reducers: {
    clearState: () => INITIAL_CHANGE_ORDERS_STATE,
    setChangeOrderShowTotals: (state, action: PayloadAction<boolean>) => {
      state.showTotals = action.payload;
    },
    setChangeOrders: (state, action: PayloadAction<ChangeOrder[]>) => {
      state.orders = action.payload;
    },
    setChangeOrder: (state, action: PayloadAction<Maybe<ChangeOrder>>) => {
      state.order = action.payload;
    },
    setShowScenario: (state, action: PayloadAction<Maybe<ShowScenario>>) => {
      state.showScenario = action.payload;
    },
    setChangeOrderTotals: (state, action: PayloadAction<Maybe<Record<string, Totals>>>) => {
      state.totals = action.payload;
    },
    setChangeOrderHistory: (state, action: PayloadAction<Maybe<ChangeOrderHistory[]>>) => {
      state.history = action.payload;
    },
    setChangeOrderScenario: (state, action: PayloadAction<Maybe<Scenario>>) => {
      state.scenario = action.payload;
    },
    setChangeOrderQuery: (state, action: PayloadAction<Maybe<PageQuery>>) => {
      state.query = action.payload;
    },
    setChangeOrdersMeta: (state, action: PayloadAction<Maybe<Meta>>) => {
      state.meta = action.payload;
    },
    addChangeOrders: (state, action: PayloadAction<ChangeOrder[]>) => {
      state.orders = state.orders ? [...state.orders, ...action.payload] : action.payload;
    },
    updateChangeOrders: (state, action: PayloadAction<ChangeOrder[]>) => {
      state.orders = updateData(state.orders, action.payload);
    },
    setChangeOrdersPagePermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.pagePermissions = action.payload;
    },
    setChangeOrderPeriod: (state, action: PayloadAction<Maybe<VersionedPeriod>>) => {
      state.currentPeriod = action.payload;
    },
  },
});

export const {
  addChangeOrders,
  clearState,
  setChangeOrder,
  setChangeOrderQuery,
  setChangeOrderScenario,
  setChangeOrderShowTotals,
  setChangeOrderTotals,
  setChangeOrderHistory,
  setChangeOrders,
  setChangeOrdersMeta,
  setShowScenario,
  setChangeOrdersPagePermissions,
  updateChangeOrders,
  setChangeOrderPeriod,
} = slice.actions;

export default slice.reducer;
