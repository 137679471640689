import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_ROLES_STATE } from './roles.model';
import { ID, Maybe } from 'src/models/general.model';
import { Role, Roles, RoleLevel, PermissionTab } from 'src/models';
import { Permission } from 'src/models/permissions.model';

export const slice = createSlice({
  name: 'roles',
  initialState: INITIAL_ROLES_STATE,
  reducers: {
    clearRolesState: () => INITIAL_ROLES_STATE,
    setRoles: (state, action: PayloadAction<Maybe<Roles>>) => {
      state.roles = action.payload;
    },
    addRole: (state, action: PayloadAction<Role>) => {
      if (state.roles) {
        state.roles[action.payload.level] = state.roles[action.payload.level] ?? [];

        state.roles[action.payload.level] = [...state.roles[action.payload.level], action.payload];
      }
    },
    updateRole: (state, action: PayloadAction<Role>) => {
      if (state.roles)
        state.roles[action.payload.level] = state.roles?.[action.payload.level]?.map(r =>
          r.id === action.payload.id ? action.payload : r,
        );
    },
    deleteRole: (state, action: PayloadAction<{ level: RoleLevel; role_id: ID }>) => {
      if (state.roles)
        state.roles[action.payload.level] = state.roles?.[action.payload.level]?.filter(
          r => r.id !== action.payload.role_id,
        );
    },
    setProjectPermissions: (state, action: PayloadAction<{ level: RoleLevel; data: PermissionTab[] }>) => {
      state.permissionTabs = state.permissionTabs ?? ({} as Record<RoleLevel, PermissionTab[]>);
      state.permissionTabs[action.payload.level] = action.payload.data;
    },
    setRolesPagePermissions: (state, action: PayloadAction<Maybe<Permission[]>>) => {
      state.pagePermissions = action.payload;
    },
  },
});

export const {
  clearRolesState,
  setRoles,
  addRole,
  updateRole,
  deleteRole,
  setProjectPermissions,
  setRolesPagePermissions,
} = slice.actions;

export default slice.reducer;
