import run from './validators/check';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './tools/serviceWorker';
import { productionLog } from './tools/log.tools';
import { updatePrototypes } from './tools/prototype.tools';
import { makeCurrencyList } from 'src/tools/currency.tools';
import { store } from 'src/store';
import './types';

import 'src/style/layout.css';
import 'src/style/import.scss';

import { App } from './app';
import { Toasts } from './components/Toasts';
import { UploadProgressPopup } from './domains/uploadProgressPopup';
import { Alert } from './domains/alert';
import { Error } from './domains/error';
import reportWebVitals from './reportWebVitals';
import { history } from './routes/history';

updatePrototypes();
makeCurrencyList();

const loadApp = (): null => {
  try {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>,
      document.getElementById(run()),
    );
  } catch (err) {
    productionLog('App:', err);
  }

  ReactDOM.render(
    <Provider store={store}>
      <Toasts />
    </Provider>,
    document.getElementById('messages'),
  );

  ReactDOM.render(
    <Provider store={store}>
      <UploadProgressPopup />
    </Provider>,
    document.getElementById('progress'),
  );

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Alert />
      </Router>
    </Provider>,
    document.getElementById('alert'),
  );

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Error />
      </Router>
    </Provider>,
    document.getElementById('error'),
  );

  return null;
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Load the app after the __ENVIRONMENT__ has been set.
const script = document.createElement('script');

script.onload = loadApp;
script.setAttribute('src', '/environment.js');

document.body.appendChild(script);
