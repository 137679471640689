import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-testid='icon_refresh'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M23 4v6h-6' />
      <path
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M20.49 15a9 9 0 11-2.12-9.36L23 10'
      />
    </svg>
  );
}

export default Icon;
