import { patch } from 'src/services/api_old.service';
import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  Action,
  ResponsePayload,
  SubProject,
  ShowScenario,
  ResourceType,
  SubProjectTeam,
  Member,
  InvitedMember,
} from 'src/models';
import { sendInfo } from 'src/store/app/app.actions';
import { ServiceRequests } from 'src/data';
import { setCustomIsLoading, setIsLoading, setIsUpdating } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import {
  setCurrentSubProject,
  setSubProjectPermissions,
  setSubProjects,
  setSubProjectScenario,
  setSubProjectTeam,
  setSubProjectTeamInvite,
  setSubProjectTeamMember,
  setSubProjectTeamPagePermissions,
  updateSubProject,
} from 'src/store/subProject/subProject.reducer';
import { getCurrentSubProject, getCurrentSubprojectCompanyId } from 'src/store/subProject/subProject.getters';
import { deserialize } from 'src/tools/string.tools';
import { getAppParams, getCurrentSubprojectId } from '../store/app/app.getters';
import { requestSingleCompany } from 'src/store/companies/companies.action';
import { setCompany } from 'src/store/companies/companies.reducer';
import { requestTeam } from '../store/team/team.actions';
import { convertToForm } from 'src/tools/form.tools';

function processProjects({ dispatch, getService, getState }: ProcessProps) {
  // new-ui
  const processProjectsRequest = (action: Action) => () => {
    const { subId } = getAppParams(getState());

    const currentSubProject = getCurrentSubProject(getState());

    const isInitial = !currentSubProject || String(currentSubProject.id) !== subId;

    dispatch(setIsLoading(true));

    getService().request(
      {
        [QueryDomain.SUB_PROJECT]: QueryAction.GET,
        payload: { params: { main_project_id: action.payload } },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<SubProject[]>>(response.payload);

          dispatch(setSubProjects(result?.data));

          const domainPermissions = result?.data[0]?._domains;

          const canViewTeam = domainPermissions?.find(permission => permission.subject === 'users_index');

          const { subId } = getAppParams(getState());

          if (isInitial) {
            const currentSubProject = result?.data.find(prj => String(prj.id) === subId);

            dispatch(setCurrentSubProject(currentSubProject));

            if (currentSubProject?.company_id) {
              dispatch(requestSingleCompany(currentSubProject?.company_id as string));
            } else {
              dispatch(setCompany(undefined));
            }

            if (subId && canViewTeam) dispatch(requestTeam(subId, ResourceType.SUB_PROJECT));
          }
        } else {
          processError({
            activityName: 'Request projects',
            response,
          });
        }

        dispatch(setIsLoading(false));
      },
    );
  };

  const processProjectTeam = () => () => {
    const { subId } = getAppParams(getState());

    dispatch(setCustomIsLoading({ id: 'projectTeam', state: true }));

    getService().request(
      {
        [QueryDomain.SUB_PROJECT]: QueryAction.GET_TEAM,
        payload: { project_id: subId },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<SubProjectTeam>>(response.payload);

          dispatch(setSubProjectTeam(result?.data));
          dispatch(setSubProjectTeamPagePermissions(result?.permissions));
        } else {
          processError({
            activityName: 'Request project team',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'projectTeam', state: false }));
      },
    );
  };

  const processCurrentProjectRequest = () => () => {
    const { subId } = getAppParams(getState());

    dispatch(setIsLoading(true));

    getService().request(
      {
        [QueryDomain.SUB_PROJECT]: QueryAction.SHOW,
        payload: { index: [subId] },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<SubProject, ShowScenario>>(response.payload);

          dispatch(setCurrentSubProject(result?.data));
          dispatch(setSubProjectPermissions(result?.permissions));
          dispatch(setSubProjectScenario(result?.scenario));
        } else {
          processError({
            activityName: 'Request single project',
            response,
          });
        }

        dispatch(setIsLoading(false));
      },
    );
  };

  const processProjectTeamMember = (team_member_id: string) => () => {
    const { subId } = getAppParams(getState());

    dispatch(setCustomIsLoading({ id: 'projectTeamMember', state: true }));

    getService().request(
      {
        [QueryDomain.SUB_PROJECT]: QueryAction.GET_TEAM_MEMBER,
        payload: { project_id: subId, team_member_id },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<Member[]>>(response.payload);

          dispatch(setSubProjectTeamMember(result?.data));
        } else {
          processError({
            activityName: 'Request project team member',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'projectTeamMember', state: false }));
      },
    );
  };

  const processProjectTeamInvite = (email: string) => () => {
    const { subId } = getAppParams(getState());

    dispatch(setCustomIsLoading({ id: 'projectTeamInvite', state: true }));

    getService().request(
      {
        [QueryDomain.SUB_PROJECT]: QueryAction.GET_TEAM_INVITE,
        payload: { project_id: subId, email },
      },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<InvitedMember[]>>(response.payload);

          dispatch(setSubProjectTeamInvite(result?.data));
        } else {
          processError({
            activityName: 'Request project team invite',
            response,
          });
        }

        dispatch(setCustomIsLoading({ id: 'projectTeamInvite', state: false }));
      },
    );
  };

  const processUpdateRequest = (action: Action) => async () => {
    const subProjectId = getCurrentSubprojectId(getState());

    dispatch(setIsUpdating(true));

    try {
      const result = await patch({
        service: ServiceRequests.PROJECTS_UPDATE,
        index: [subProjectId as string],
        data: convertToForm(action.payload),
        headers: { 'Content-Type': 'multipart/form-data;' },
      });

      const updatedProject = result?.data.data;

      const subProjectPrevCompanyId = getCurrentSubprojectCompanyId(getState());

      if (updatedProject) dispatch(updateSubProject(updatedProject));

      if (subProjectPrevCompanyId !== updatedProject?.company_id) {
        if (updatedProject?.company_id) dispatch(requestSingleCompany(updatedProject?.company_id as string));
        else dispatch(setCompany(undefined));
      }

      if (action.meta?.callback) action.meta.callback({ isOK: true, payload: updatedProject });

      dispatch(sendInfo('Project has been updated'));
    } catch (err) {
      processError({
        activityName: 'Request update sub project',
        response: {
          isOK: false,
          status: err.response.status,
          payload: err.response?.data,
        },
      });

      if (action.meta?.callback) action.meta.callback({ isOK: false, message: err.message });
    }

    dispatch(setIsUpdating(false));
  };

  return {
    processProjectsRequest,
    processCurrentProjectRequest,
    processUpdateRequest,
    processProjectTeam,
    processProjectTeamMember,
    processProjectTeamInvite,
  };
}

export { processProjects };
