import * as React from 'react';
import clsx from 'clsx';

import style from './LoadingInline.module.scss';

export function LoadingInline() {
  return (
    <div data-testid='loading-inline' className={clsx(style.container, 'row nowrap a-center j-between')}>
      <span />
      <span />
      <span />
      <span />
    </div>
  );
}
