import { SetStateAction, useCallback, useEffect } from 'react';
import { Maybe, Scenario } from 'src/models';

export function useStickyHiddenColumn(
  query: Maybe<Array<string>>,
  scenario: Maybe<Scenario>,
  setNextColumns: (value: SetStateAction<string[]>) => void,
) {
  const getStickyHiddenColumn = useCallback(() => {
    if (query?.length) {
      const nextColumns = [];

      for (const label of query) {
        if (scenario?.[label]?.sticky) {
          const nextColumnOrder = (scenario?.[label]?.order as number) + 1;

          for (const column in scenario) {
            const item = scenario[column];

            if (item.sticky) {
              if (item.order && item.order >= nextColumnOrder) nextColumns.push(column);
            }
          }
        }
      }

      setNextColumns(nextColumns);
    } else {
      setNextColumns([]);
    }
  }, [query, scenario, setNextColumns]);

  useEffect(() => {
    getStickyHiddenColumn();
  }, [getStickyHiddenColumn]);
}
