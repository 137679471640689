import { useDispatch } from 'react-redux';

import { upload } from 'src/services/file.service';

import { AttachmentFile, ID, QueueItem, Result } from 'src/models';
import { addUpload, updateProgress, removeUpload } from 'src/store/files/files.reducer';
import { sendError } from 'src/store/app/app.actions';

import { ServiceRequests } from 'src/data';
import { makeStringFromTemplate } from 'src/tools/string.tools';
import { t } from 'src/tools/text.tools';

const TXT = t(['files']);

export function useUpdateDocument() {
  const dispatch = useDispatch();

  function updateProgressData(id: string) {
    return function update(progress: number) {
      dispatch(updateProgress({ id, progress }));
    };
  }

  function respond(id: string, item: string, action: string) {
    return function res(result: Result) {
      dispatch(removeUpload(id));

      if (!result.isOK) {
        dispatch(sendError(result.message || makeStringFromTemplate(TXT('error'), [item, `${action}ed`])));
      }
    };
  }

  return async function updateDocument(id: ID, data: FormData, item: AttachmentFile) {
    const uploadItem: QueueItem<AttachmentFile> = {
      id: String(id),
      payload: {
        title: item.title,
        type: item.type,
      },
    };

    dispatch(addUpload(uploadItem));

    return upload({
      method: 'PATCH',
      service: ServiceRequests.UPDATE_DOCUMENT,
      index: [String(id)],
      onUploadProgress: updateProgressData(uploadItem.id),
      data,
      respond: respond(uploadItem.id, uploadItem.payload.title, 'upload'),
    });
  };
}
