import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { append, assoc, dissoc, map } from 'ramda';

import { INITIAL_ATTACHMENTS_STATE } from './attachments.model';
import { ID, Maybe, Picture, PictureDrawing, PictureTagsUpdateAction, ReqPayDocument, ResourceType } from 'src/models';
import { Comment } from 'src/models/comment.model';
import { CommentThread } from 'src/models/commentThread.model';

export const slice = createSlice({
  name: 'attachments',
  initialState: INITIAL_ATTACHMENTS_STATE,
  reducers: {
    clearAttachmentsState: state => {
      state.comments = INITIAL_ATTACHMENTS_STATE.comments;
      state.commentThreads = INITIAL_ATTACHMENTS_STATE.commentThreads;
      state.documents = INITIAL_ATTACHMENTS_STATE.documents;
      state.pictures = INITIAL_ATTACHMENTS_STATE.pictures;
      state.pictureUrls = INITIAL_ATTACHMENTS_STATE.pictureUrls;
    },
    setComments: (state, action: PayloadAction<Maybe<Comment[]>>) => {
      state.comments = action.payload;
    },
    setCommentThreads: (state, action: PayloadAction<Maybe<CommentThread[]>>) => {
      state.commentThreads = action.payload;
    },
    addCommentThread: (
      state,
      action: PayloadAction<{ data: CommentThread; resource_id?: ID; resource_type?: ResourceType }>,
    ) => {
      const { data, resource_id, resource_type } = action.payload;

      state.commentThreads = state.commentThreads ? [...state.commentThreads, data] : [data];

      if (state.pictures && resource_type === ResourceType.PICTURE) {
        state.pictures = state.pictures?.map(p => (p.id === resource_id ? { ...p, has_comments: true } : p));
      }
    },
    clearCommentThreads: state => {
      state.commentThreads = INITIAL_ATTACHMENTS_STATE.commentThreads;
    },
    addCommentToThread: (state, action: PayloadAction<{ id: ID; data: Comment }>) => {
      if (state.commentThreads) {
        const thread = state.commentThreads.find(i => i.id === action.payload.id);

        if (thread) {
          thread.comments = [...thread.comments, action.payload.data];
        }
      }
    },
    updateComments: (state, action: PayloadAction<Comment>) => {
      state.comments = append(action.payload, state.comments ?? []);
    },
    setDocuments: (state, action: PayloadAction<ReqPayDocument[]>) => {
      state.documents = action.payload;
      state.submissionsDocuments = action.payload.filter(d => !!d.required_document_id);
      state.backupDocuments = action.payload.filter(d => !d.required_document_id);
    },
    setDocument: (state, action: PayloadAction<ReqPayDocument>) => {
      const document = state.documents?.find(d => d.required_document_id === action.payload.required_document_id);

      state.documents = state.documents?.map(d =>
        d.required_document_id === document?.required_document_id ? action.payload : d,
      );
      state.submissionsDocuments = state.submissionsDocuments?.map(d =>
        d.required_document_id === document?.required_document_id ? action.payload : d,
      );
    },
    updateDocument: (state, action: PayloadAction<ReqPayDocument>) => {
      const updateDoc = (doc: ReqPayDocument) => (doc.id !== action.payload.id ? doc : action.payload);

      state.documents = state.documents?.map(updateDoc);

      if (action.payload.required_document_id) {
        state.submissionsDocuments = state.submissionsDocuments?.map(updateDoc);
      } else {
        state.backupDocuments = state.backupDocuments?.map(updateDoc);
      }
    },
    addDocument: (state, action: PayloadAction<ReqPayDocument>) => {
      state.documents = state.documents && [...state.documents, action.payload];

      if (action.payload.required_document_id) {
        state.submissionsDocuments = state.submissionsDocuments && [...state.submissionsDocuments, action.payload];
      } else {
        state.backupDocuments = state.backupDocuments && [...state.backupDocuments, action.payload];
      }
    },
    deleteDocument: (state, action: PayloadAction<ID>) => {
      const notDeleted = (doc: ReqPayDocument) => doc.id !== action.payload;

      state.documents = state.documents?.filter(notDeleted);

      state.submissionsDocuments = state.submissionsDocuments?.filter(notDeleted);
      state.backupDocuments = state.backupDocuments?.filter(notDeleted);
    },
    setPictures: (state, action: PayloadAction<Maybe<Picture[]>>) => {
      state.pictures = action.payload;
    },
    setPicturesDrawings: (state, action: PayloadAction<Maybe<PictureDrawing>>) => {
      state.pictureDrawings = action.payload;

      if (state.pictures && action.payload) {
        const { picture_id, content } = action.payload;

        state.pictures = state.pictures?.map(p => (p.id === picture_id ? { ...p, has_drawings: !!content } : p));
      }
    },
    clearPicturesDrawings: state => {
      state.pictureDrawings = INITIAL_ATTACHMENTS_STATE.pictureDrawings;
    },
    updatePicture: (state, action: PayloadAction<Picture>) => {
      const mapperFn = (picture: Picture) => {
        if (picture.id === action.payload.id) return action.payload;
        else return picture;
      };

      if (!state.pictures) state.pictures = [action.payload];
      else state.pictures = map(mapperFn, state.pictures);
    },
    deletePicture: (state, action: PayloadAction<ID>) => {
      state.pictures = state.pictures?.filter(pic => pic.id !== action.payload);
      state.pictureUrls = dissoc(String(action.payload), state.pictureUrls);
    },
    addPictureUrl: (state, action: PayloadAction<{ id: ID; url: string }>) => {
      state.pictureUrls[action.payload.id] = action.payload.url;
    },
    updatePictureTags: (state, action: PayloadAction<PictureTagsUpdateAction>) => {
      const mapperFn = (picture: Picture) => {
        if (picture.id === action.payload.pictureId) {
          return assoc('tags', action.payload.tags, picture);
        } else {
          return picture;
        }
      };

      if (state.pictures && state.pictures.length > 0) state.pictures = map(mapperFn, state.pictures);
    },
  },
});

export const {
  clearAttachmentsState,
  setComments,
  setDocuments,
  setDocument,
  deleteDocument,
  updateDocument,
  addDocument,
  setPictures,
  setPicturesDrawings,
  clearPicturesDrawings,
  updatePictureTags,
  updateComments,
  updatePicture,
  deletePicture,
  addCommentToThread,
  addCommentThread,
  setCommentThreads,
  clearCommentThreads,
  addPictureUrl,
} = slice.actions;

export default slice.reducer;
